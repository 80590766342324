import React from 'react';
import style from './Paragraph.module.scss';
import RichText from 'components/elements/contentful/RichText/RichText';
import { Button } from '@mozaic-ds/react';

const Paragraph = ({ data }) => {
  try {
    const { title, imageFlow, imageDesktop, paragraph, ctaLabel, ctaUrl } = data.data.dataSource.fields;

    return (
      <section className={`${style.Paragraph}`}>
        {title && <h2 className={style.Paragraph__title}>{title}</h2>}

        <div
          className={`
            ${style.Paragraph__block}
            ${style[`Paragraph__block--${imageFlow}`]}
          `}
        >
          <div className={style.Paragraph__block__text}>
            <RichText content={paragraph} />
            {ctaLabel && ctaUrl && <Button href={ctaUrl}>{ctaLabel}</Button>}
          </div>

          {imageDesktop && (
            <div className={style.Paragraph__block__image}>
              <img src={imageDesktop?.fields?.file?.url} loading="lazy" alt={imageDesktop?.fields?.title} />
            </div>
          )}
        </div>
      </section>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default Paragraph;
