import style from './TextImage.module.scss';
import RichText from 'components/elements/contentful/RichText/RichText';

const classNames = (...classes) => {
  return classes.join(' ');
};

const TextImage = ({ data }) => {
  try {
    const { imagePosition, title, titleParagraph, mainImage960x960px } = data.data.dataSource.fields;
    return (
      <div className={classNames(style.TextImage, imagePosition === true && style.TextImage__left)}>
        <div className={classNames(style.TextImage__text, imagePosition === true && style.TextImage__left__text)}>
          <h2>{title}</h2>
          <RichText content={titleParagraph} />
        </div>
        <img src={mainImage960x960px.fields.file.url} alt={mainImage960x960px.fields.title} />
      </div>
    );
  } catch (e) {
    console.log(e);
    return <></>;
  }
};

export default TextImage;
