import style from './MoreIdeas.module.scss';
import { Button } from '@mozaic-ds/react';

const MoreIdeas = ({ data }) => {
  try {
    const { encarts, title, subtitle } = data.data.dataSource.fields;
    return (
      <article className={style.MoreIdeas}>
        <div className={style.MoreIdeas__container}>
          <h2 className={style.MoreIdeas__title}>{title}</h2>

          <p className={style.MoreIdeas__text}>{subtitle}</p>

          <div className={style.MoreIdeas__grid}>
            {encarts.map((idea) => {
              const { cta, ctaUrl, image, titre, subtitle } = idea.fields;
              return (
                <article className={style.MoreIdeas__item} key={idea.sys.id}>
                  <img className={style.MoreIdeas__item__image} src={image.fields.file.url} alt="" />

                  <h3 className={style.MoreIdeas__item__title}>{titre}</h3>
                  <span className={style.MoreIdeas__item__subtitle}>{subtitle}</span>
                  <Button theme="primary" width="full" href={ctaUrl}>
                    {cta}
                  </Button>
                </article>
              );
            })}
          </div>
        </div>
      </article>
    );
  } catch (error) {
    console.log(error);
    return <></>;
  }
};

export default MoreIdeas;
