import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

const Logout = () => {
  const [display, setDisplay] = useState(true);
  const router = useRouter();

  useEffect(() => {
    setTimeout(() => {
      setDisplay(false);
      router.push('/');
    }, 5000);
  }, []);

  return (
    <>
      {display && (
        <div>
          <h1 className="text-[3.6rem] my-12 font-[700]">Vous êtes déconnecté</h1>
          <p className="text-[1.6rem] mb-15 font-[400]">
            Vous avez été déconnecté et allez être redirigé vers la page d&rsquo; accueil dans 5 secondes.
          </p>
        </div>
      )}
    </>
  );
};

export default Logout;
