import TutoHeader from './TutoHeader/TutoHeader';
import TutoMaterials from './TutoMaterials/TutoMaterials';
import TutoPictos from './TutoPictos/TutoPictos';
import TutoSteps from './TutoSteps/TutoSteps';
import TutoTips from './TutoTips/TutoTips';
import TutoList from './TutoList/TutoList';

export default {
  TutoHeader,
  TutoMaterials,
  TutoSteps,
  TutoPictos,
  TutoTips,
  TutoList,
};
