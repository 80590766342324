import Picto from 'components/elements/Picto/Picto';
import style from './TutoPictos.module.scss';

const TutoPictos = ({ data }) => {
  const { timing, qtyOfPersonn, editoType, difficulty, budget } = data.data.dataSource.fields;

  const DificultyPictos = ({ data }) => {
    const values = { Facile: 1, Intermédiaire: 2, Difficile: 3 };
    const value = values[data];
    return [...Array(3)].map((_, i) => (
      <Picto
        key={i}
        icon={editoType.toString() === 'Recette' ? 'toque--bordered' : 'hammer'}
        data-active={i + 1 <= value}
        className={style.TutoPictos__item__picto}
      />
    ));
  };

  const BudgetPictos = ({ data }) => {
    const values = { '1€ à 14€': 1, '15€ à 30€': 2, 'Plus de 30€': 3 };
    const value = values[data];
    return [...Array(3)].map((_, i) => <Picto key={i} icon="cost" data-active={i + 1 <= value} className={style.TutoPictos__item__picto} />);
  };

  return (
    <ul className={style.TutoPictos}>
      <li className={style.TutoPictos__item} tabIndex={0} aria-label={`durée : ${timing}`}>
        <Picto icon="clock" className={style.TutoPictos__item__picto} />
        <span className={style.TutoPictos__item__description}>{timing}</span>
      </li>

      {difficulty && (
        <li className={style.TutoPictos__item} tabIndex={0} aria-label={`difficulté : ${difficulty}`}>
          <DificultyPictos data={difficulty} />
        </li>
      )}

      {editoType.toString() === 'Recette' ? (
        <li className={style.TutoPictos__item} tabIndex={0} aria-label={`pour ${qtyOfPersonn} personnes`}>
          <Picto icon="account--bordered" className={style.TutoPictos__item__picto} />
          <span className={style.TutoPictos__item__description}>{qtyOfPersonn} personnes</span>
        </li>
      ) : (
        <li className={style.TutoPictos__item} tabIndex={0} aria-label={`budget: ${budget}`}>
          <BudgetPictos data={budget} />
        </li>
      )}
    </ul>
  );
};

export default TutoPictos;
