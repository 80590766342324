import PLP from 'components/elements/PLP/PLP';

const WorkshopsList = () => {
  return (
    <PLP
      type="workshops"
      index={process.env.NEXT_PUBLIC_ALGOLIA_INDEX_PRODUCT}
      config={{ hitsPerPage: 24, filters: 'type_id: "workshop" AND visibility_catalog: 1' }}
    />
  );
};

export default WorkshopsList;
