import Picto from '../Picto/Picto';
import { Button, useModals, Layer, LayerHeader, LayerTitle, LayerBody } from '@mozaic-ds/react';
import React from 'react';
import style from './Reward.module.scss';
import alert from 'helpers/utils/alert';

const Reward = (card) => {
  const { status, additionnal_data, attributes, model_label, calc_mode, id } = card;
  const startDate = new Date(attributes.validity_start_date).toLocaleDateString('fr-FR');
  const endDate = new Date(attributes.validity_end_date).toLocaleDateString('fr-FR');
  const { open, close } = useModals();

  const activeReward = () => {
    // TODO  post reward with action
    const response = 200;
    if (response === 200) {
      alert('success', 'Votre recompense a bien été activé', 5000);
    } else {
      alert('error', 'votre rècompense nest pas activé', 5000);
    }
    close(`reward-${id}`);
  };

  return (
    <div className={style.Reward}>
      {status === 'AVAILABLE' && <p className={style.Reward__cart}>Disponible dans mon panier</p>}
      <svg className={style.Reward__fillName} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 197.3 134.3">
        <path
          fill="#D2824E"
          d="M84.6,115.5c11.9,0.5,26.9,2.1,40.4,1.8c6-10.3,11.5-19.3,27.9-17.9c9.2,2,17.8,8.4,17.2,16 c30.8,6,28.2-4.2,26-27.2c-1.8-17.5-25.7-14.8-37.5-23.7C150.9-6.7,121.1,2.7,45.9,1c-11.6,2.6-33-6.1-38.6,3.9 c-7.3,29.3-7.8,60.6-7,90c0.4,11,2.8,20.7,16.8,19c1.4-3.8,1.9-6.8,5.4-8.3c10.1-7,33.5-8,37.1,4c1.8,2,0.2,6.1,2,6.7 C69.2,116.6,76.9,115.5,84.6,115.5L84.6,115.5z"
        />

        <path
          fill="#DDCABC"
          d="M60.3,117.6C64.2,106,48.9,92.2,31,99.8c-7.5,2.8-12.5,4-14,11.8C11.3,141.2,57.2,140.3,60.3,117.6z M128.6,124.6c10.5,21.5,48.5,2.7,40.8-14.8C160,85.6,114.7,101.3,128.6,124.6z"
        />
      </svg>
      <span className={style.Reward__text}>{additionnal_data && additionnal_data.description}</span>
      <div className={style.Reward__actions}>
        {status !== 'AVAILABLE' ? (
          <>
            <div className="text-center font-bold">Récompense activée</div>
            <div className="text-center">
              Valable du {startDate} au {endDate}
            </div>
            <div className="text-center">
              <Picto className={style.Reward__actions__icon} icon="check" />
            </div>
          </>
        ) : (
          <>
            <div className={style.Reward__actions__dispo}>
              <p>
                <span className="uppercase text-[1.2rem]">
                  Récompense disponible <Picto className="ml-[5rem]" icon="check" />
                </span>
              </p>
            </div>
            <div>
              <Button size="full" theme="primary" onClick={() => open(`reward-${id}`)}>
                Activer la recompense
              </Button>
            </div>

            <Layer id={`reward-${id}`} className={style.Reward__modal}>
              <LayerHeader id={`reward-${id}`}>
                <LayerTitle id={`reward-${id}`}>
                  <span className={style.Reward__modal__head}>Souhaitez vous activer cette récompense ?</span>
                </LayerTitle>
              </LayerHeader>
              <LayerBody id={`reward-${id}`} className="text-center">
                <svg className={style.Reward__fillName} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 197.3 134.3">
                  <path
                    fill="#D2824E"
                    d="M84.6,115.5c11.9,0.5,26.9,2.1,40.4,1.8c6-10.3,11.5-19.3,27.9-17.9c9.2,2,17.8,8.4,17.2,16 c30.8,6,28.2-4.2,26-27.2c-1.8-17.5-25.7-14.8-37.5-23.7C150.9-6.7,121.1,2.7,45.9,1c-11.6,2.6-33-6.1-38.6,3.9 c-7.3,29.3-7.8,60.6-7,90c0.4,11,2.8,20.7,16.8,19c1.4-3.8,1.9-6.8,5.4-8.3c10.1-7,33.5-8,37.1,4c1.8,2,0.2,6.1,2,6.7 C69.2,116.6,76.9,115.5,84.6,115.5L84.6,115.5z"
                  />

                  <path
                    fill="#DDCABC"
                    d="M60.3,117.6C64.2,106,48.9,92.2,31,99.8c-7.5,2.8-12.5,4-14,11.8C11.3,141.2,57.2,140.3,60.3,117.6z M128.6,124.6c10.5,21.5,48.5,2.7,40.8-14.8C160,85.6,114.7,101.3,128.6,124.6z"
                  />
                </svg>
                <p className={style.Reward__modal__title}>{additionnal_data && additionnal_data.description}</p>
                <Button size="full" theme="primary" onClick={activeReward}>
                  Activer la recompense
                </Button>
                <Button className="mt-7" size="full" theme="secondary" onClick={() => close(`reward-${id}`)}>
                  Annuler
                </Button>
                <ul className={style.Reward__modal__conditions}>
                  <li className={style.Reward__modal__conditions__panel}>
                    <Picto icon="star--bordered" />
                    <h4 className="font-bold">Vos points</h4>
                    <p>
                      Le nombre de points correspondant à votre récompense sera déduit du solde de points de votre compte fidélité. Votre récompense
                      sera alors visible dans votre panier et <span className="font-bold">valable 3 mois.</span>
                    </p>
                  </li>
                  <li className={style.Reward__modal__conditions__panel}>
                    <Picto icon="chat" />
                    <h4 className="font-bold">Vous avez changé d avis</h4>
                    <p>
                      Vous avez activé votre recompense mais, entre temps, vous avez changé d’avis, veuillez vous rapprocher de votre service client
                      afin qu ils fassent le nécessaire.
                    </p>
                  </li>
                </ul>
              </LayerBody>
            </Layer>
          </>
        )}
      </div>
    </div>
  );
};

export default Reward;
