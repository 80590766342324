import React from 'react';
import Picto from 'components/elements/Picto/Picto';
import ImageGallery from 'react-image-gallery';
import { Magnifier, GlassMagnifier, MOUSE_ACTIVATION, TOUCH_ACTIVATION } from 'react-image-magnifiers';
import { Close, PrevArrow, NextArrow } from './Controls/Controls';
import { createFocusLoop, destroyFocusLoop } from 'helpers/utils/keyboardNav';
import style from './MediasGallery.module.scss';
import getMedia from '../../../helpers/utils/getMedia';

/**
 * DOCS
 * https://github.com/xiaolin/react-image-gallery
 * https://github.com/adamrisberg/react-image-magnifiers
 */

const MediasGallery = ({ data }) => {
  const { thumbnails_position, type } = data;
  let imageReceipe;
  const gallery = React.useRef(null);
  const [isFullScreen, setFullScreen] = React.useState(false);
  const [startIndex, setStartIndex] = React.useState(0);
  let items;
  if (type === 'none' || type === 'contentful') {
    if (data) imageReceipe = data?.data?.dataSource?.fields.reciepePictures;
    items = imageReceipe && imageReceipe.map((picture) => getMedia(type, picture, imageWithZoom));
  } else if (type === 'magento') {
    items = getMedia(type, null, imageWithZoom);
  }
  /*const items =
    imageReceipe &&
    imageReceipe.map((picture) => {
      return {
        // params are different if image is from contentful, algolia or Magento
        thumbnail: picture.fields.file.url + '?w=90&h=90',
        original: picture.fields.file.url + '?w=600&h=600',
        fullscreen: picture.fields.file.url + '?w=1080&h=1080',
        fullscreenZoomed: picture.fields.file.url + '?w=1600&h=1600',
        originalAlt: '',
        thumbnailAlt: '',
        renderItem: imageWithZoom,
      };
    });*/

  // TO ADD A VIDEO USE THIS FORMAT (With Youtube ID)
  // {
  //   videoID: 'JVOAr2vUw9k',
  //   thumbnail: true,
  //   renderThumbInner: videoThumbnail,
  //   renderItem: video,
  // }

  function videoThumbnail(item) {
    return (
      <span className={style.MediasGallery__video__thumbnail}>
        <img className={style.MediasGallery__video__thumbnail__image} src={`https://img.youtube.com/vi/${item.videoID}/mqdefault.jpg`} alt="" />
        <span className={style.MediasGallery__video__thumbnail__picto}>
          <Picto icon="circle--play" />
        </span>
      </span>
    );
  }

  function video(item) {
    return (
      <div className={style.MediasGallery__video} data-fullScreen={isFullScreen}>
        <iframe src={`https://www.youtube.com/embed/${item.videoID}`} title="YouTube video player" allowFullScreen />
      </div>
    );
  }

  function imageWithZoom(item) {
    return isFullScreen ? (
      <Magnifier
        imageAlt={item.originalAlt}
        imageSrc={item.fullscreen}
        largeImageSrc={item.fullscreenZoomed}
        mouseActivation={MOUSE_ACTIVATION.CLICK}
        touchActivation={TOUCH_ACTIVATION.DOUBLE_TAP}
      />
    ) : (
      <GlassMagnifier
        square
        cursorStyle="grab"
        magnifierBorderColor="currentColor"
        magnifierBorderSize={1}
        magnifierSize="50%"
        imageAlt={item.originalAlt}
        imageSrc={item.original}
        largeImageSrc={item.fullscreen}
      />
    );
  }

  function onScreenChange(state) {
    setStartIndex(gallery.current.getCurrentIndex());
    setFullScreen(state);

    setTimeout(() => {
      document.body.classList.toggle('is-blocked', state);

      const modal = '.image-gallery';
      const focusableItems = ['button[data-action="close"]', 'button[data-action="next"]', 'button[data-action="prev"]'];

      if (state) createFocusLoop(modal, focusableItems);
      else destroyFocusLoop(modal);
    }, 0);
  }

  return (
    <div className={style.MediasGallery} data-fullscreen={isFullScreen}>
      <ImageGallery
        ref={gallery}
        startIndex={startIndex}
        items={items}
        showThumbnails={thumbnails_position !== 'none'}
        thumbnailPosition={thumbnails_position !== 'none' ? thumbnails_position : 'top'}
        showBullets={false}
        autoPlay={false}
        lazyLoad={true}
        slideDuration={250}
        infinite={false}
        showPlayButton={false}
        useBrowserFullscreen={false}
        showFullscreenButton={true}
        stopPropagation={true}
        renderFullscreenButton={Close}
        renderLeftNav={PrevArrow}
        renderRightNav={NextArrow}
        onScreenChange={onScreenChange}
        onClick={() => gallery.current.fullScreen()}
        onTouchEnd={() => gallery.current.fullScreen()}
      />
    </div>
  );
};

export default MediasGallery;
