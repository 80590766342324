import style from './StoreExperts.module.scss';

const mock = [
  {
    img: 'https://photos.zodio.fr/zodio-magento/admin_user/1/1631544173_J_rome.jpg',
    name: 'Jérôme',
    details: 'Leader Communauté cuisine',
  },
  {
    img: 'https://photos.zodio.fr/zodio-magento/admin_user/1/1631544320_Elise.jpg',
    name: 'Elise',
    details: 'Leader Communauté créa',
  },
  {
    img: 'https://photos.zodio.fr/zodio-magento/admin_user/1/1631544036_H_l_ne.jpg',
    name: 'Hélène',
    details: 'Experte pâtisserie',
  },
  {
    img: 'https://photos.zodio.fr/zodio-magento/admin_user/1/1631544252_Alice.jpg',
    name: 'Alice',
    details: 'Experte table',
  },
  {
    img: 'https://photos.zodio.fr/zodio-magento/admin_user/1/1631544114_Laetitia.jpg',
    name: 'Laetitia',
    details: 'Experte créa DIY',
  },
  {
    img: 'https://photos.zodio.fr/zodio-magento/admin_user/1/1631544423_Salom_.jpg',
    name: 'Salomé',
    details: 'Experte Chambre',
  },
];

const StoreExperts = () => {
  return (
    <section className={style.StoreExperts}>
      <div className={style.StoreExperts__content}>
        <h2 className={style.StoreExperts__content__title}>Nos Experts</h2>

        <ul className={style.StoreExperts__content__list}>
          {mock.map((member) => (
            <li key={member.name} className={style.StoreExperts__content__list__item}>
              <div className={style.StoreExperts__member}>
                <img className={style.StoreExperts__member__image} src={member.img} alt={member.name} />
                <h3 className={style.StoreExperts__member__name}>{member.name}</h3>
                <p className={style.StoreExperts__member__details}>{member.details}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default StoreExperts;
