import style from './WorkshopReinsurrance.module.scss';
import Subtitle from 'components/elements/Subtitle/Subtitle';
import Picto from 'components/elements/Picto/Picto';
import { publishItems } from 'helpers/utils/publishItems';

const WorkshopReinsurrance = ({ data }) => {
  try {
    const { title, iconsUnivers } = data.data.dataSource.fields;

    return (
      <section className={style.WorkshopReinsurrance}>
        <Subtitle title={title} />

        <ul className={style.WorkshopReinsurrance__items}>
          {publishItems(iconsUnivers).map((item) => {
            const { id, text } = item.fields;

            return (
              <li className={style.WorkshopReinsurrance__item} key={item.sys.id}>
                <Picto icon={id} className={style.WorkshopReinsurrance__item__picto} />
                <p className={style.WorkshopReinsurrance__text}>{text}</p>
              </li>
            );
          })}
        </ul>
      </section>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default WorkshopReinsurrance;
