import style from './TutoMaterials.module.scss';
import RichText from 'components/elements/contentful/RichText/RichText';

const TutoMaterials = ({ data }) => {
  const { reciepeDetails } = data.data.dataSource.fields;
  return (
    <section className={style.TutoMaterials}>
      <RichText content={reciepeDetails} />
    </section>
  );
};

export default TutoMaterials;
