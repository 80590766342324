import React from 'react';
import style from './ReviewForm.module.scss';
import { useForm, useController } from 'react-hook-form';
import { rules } from 'helpers/utils/formRules';
import { useModals, Field, RatingStarsResult, RatingStarsInput, TextArea, TextInput, Button, Tooltip, FileUploader } from '@mozaic-ds/react';

const ReviewForm = () => {
  const [review, setReview] = React.useState({});
  const [score, setScore] = React.useState();
  const { close } = useModals();

  const {
    register,
    setValue,
    handleSubmit,
    trigger,
    control,
    formState: { errors, isSubmitted },
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const {
    field: { value: images, onChange },
  } = useController({ control, name: 'images', rules: rules.reviews.images, defaultValue: [] });

  const onImageChange = React.useCallback(
    (event) => {
      const { name, size, type } = event.target?.files[0] || {};
      onChange([...images, { name, size, type }]);
      trigger('images');
    },
    [images, onChange],
  );

  const onImageRemove = React.useCallback(
    (fileName) => {
      onChange(images.filter((item) => item.name !== fileName));
      trigger('images');
    },
    [images, onChange],
  );

  function onSubmit(data) {
    console.log('onSubmit review', data);
    setReview(data);
    setTimeout(() => close('review-layer'), 7000);
  }

  return (
    <div className={style.ReviewForm}>
      <div className={style.ReviewForm__product}>
        <img
          className={style.ReviewForm__product__image}
          src={'https://photos.zodio.fr/zodio-magento/catalog/products/source/3/0/3052910056339_1_A.jpg'}
          alt=""
        />

        <p className={style.ReviewForm__product__title}>
          {'Sirop de Fruit de la Passion 70cl Monin'} <span>{10152243}</span>
        </p>
      </div>

      {!review.score ? (
        <form className={style.ReviewForm__form} onSubmit={handleSubmit(onSubmit)}>
          <p className={style.ReviewForm__form__description}>Les champs marqués d&apos;un astérisque (*) sont obligatoires</p>

          <Field
            className="w-[100%]"
            htmlFor="review-score"
            label={`Votre note${rules.reviews.score?.required ? '*' : ''}`}
            error={errors?.score?.message}
          >
            <RatingStarsInput
              id="review-score"
              size="l"
              score={score}
              onChange={(value) => {
                setScore(value);
                setValue('score', value, { shouldValidate: true });
              }}
            />
            <input type="hidden" {...register('score', rules.reviews.score)} />
          </Field>

          <Field
            htmlFor="review-score"
            label={`Titre de votre avis${rules.reviews.title?.required ? '*' : ''}`}
            className="w-[100%] my-[1.6rem]"
            error={errors?.title?.message}
          >
            <TextInput
              placeholder="Donner un titre à votre avis"
              id="review-score"
              isValid={isSubmitted && !errors.title}
              isInvalid={errors.title}
              {...register('title', rules.reviews.title)}
            />
          </Field>

          <Field
            htmlFor="review-message"
            className="w-[100%] my-[1.6rem]"
            label={`Votre avis${rules.reviews.message?.required ? '*' : ''}`}
            error={errors?.message?.message}
          >
            <Tooltip placement="right" label={<TooltipInner />}>
              <span className={style.ReviewForm__form__tooltip}>?</span>
            </Tooltip>

            <p className={style.ReviewForm__form__description}>
              Le descriptif de votre avis doit contenir entre {rules.reviews.message.minLength.value} et {rules.reviews.message.maxLength.value}{' '}
              caractères. <br /> Cliquez sur ? pour en savoir plus
            </p>

            <TextArea
              id="review-message"
              rows="7"
              placeholder="Décrire votre expérience du produit (caractéristiques, fonctionnalités, améliorations éventuelles sur le produit, ...)"
              {...register('message', rules.reviews.message)}
              isValid={isSubmitted && !errors.message}
              isInvalid={errors.message}
            />
          </Field>

          <Field htmlFor="review-image" className="w-[100%] my-[1.6rem]" label="Les images de votre avis" error={errors?.images?.message}>
            <FileUploader files={images} accept="image/*" onChange={onImageChange} onRemove={onImageRemove}>
              Ajouter une image
            </FileUploader>
          </Field>

          <p className={style.ReviewForm__form__description}>Les images doivent être au format .jpeg ou .png</p>

          <Button className="my-[1.6rem]" width="fit" type="submit">
            Publier votre avis
          </Button>

          <p className={style.ReviewForm__form__description}>
            En soumettant mon avis, j&apos;accepte de recevoir une notification quand mon avis aura été accepté, refusé ou qu&apos;il aura obtenu une
            réponse.
          </p>
        </form>
      ) : (
        <div className={style.ReviewForm__confirm}>
          <h2 className={style.ReviewForm__confirm__title}>Merci pour votre avis !</h2>

          <p>
            Les équipes Zôdio traiteront votre avis dans les meilleurs délais. Les commentaires acceptés seront publiés dans un délai de 3 à 5 jours.
          </p>

          <h3 className={style.ReviewForm__confirm__subtitle}>Votre avis</h3>

          <div className={style.ReviewForm__confirm__review}>
            <div className={style.ReviewForm__confirm__review__stars}>
              <RatingStarsResult score={review.score} size="m" />
            </div>

            <div className={style.ReviewForm__confirm__review__details}>
              <strong>{review.title}</strong>
              <p>{review.message}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const TooltipInner = () => (
  <div className={style.ReviewForm__form__tooltip__inner}>
    <h2>Nos conseils pout rédiger un avis</h2>

    <p>Votre avis peut porter sur :</p>

    <ul>
      <li>Votre propre expérience du produit</li>
      <li>Les caractéristiques et fonctionnalités du produit</li>
      <li>Tout conseil pour améliorer le produit</li>
    </ul>

    <p>Nous ne publierons pas :</p>

    <ul>
      <li>Des avis autour de la logistique (livraison, SAV)</li>
      <li>Des avis sur un autre produit</li>
      <li>Des avis à visée commerciale</li>
      <li>Des liens publicitaires vers d&apos;autres sites</li>
      <li>Des propos à caractère obscène ou illégaux</li>
    </ul>
  </div>
);

export default ReviewForm;
