import style from './TextTwoParagraph.module.scss';
import RichText from 'components/elements/contentful/RichText/RichText';

const TextTwoParagraph = ({ data }) => {
  try {
    const { title, paragraph2Columns } = data.data.dataSource.fields;
    console.log(data.data.dataSource.fields);
    return (
      <div className={style.TextTwoParagraph}>
        <h2 className={style.TextTwoParagraph__title}>{title}</h2>
        <div className={style.TextTwoParagraph__text}>
          <RichText content={paragraph2Columns} />
        </div>
      </div>
    );
  } catch (e) {
    console.error(e);
    return <></>;
  }
};

export default TextTwoParagraph;
