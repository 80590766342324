import style from './InformationsPersonnelles.module.scss';
import Navigation from '../partials/Navigation/Navigation';
import React, { useContext, useEffect } from 'react';
import BlockInformation from './partials/BlockInformation/BlockInformation';
import BreadcrumbAccount from '../partials/BreadcrumbAccount/BreadcrumbAccount';
import { useRouter } from 'next/router';
import mocks from '../account-mock';
import { UserContext } from '../../../../frontastic';
import Contact from '../../../generic/Contact/Contact';
import Container from '../Container/Container';

const InformationsPersonnelles = ({ data }) => {
  const { addresses } = mocks;

  const { user } = useContext(UserContext);
  console.log(user);

  const router = useRouter();

  useEffect(() => {
    if (!user) router.push('/connexion');
  }, []);

  if (!user) return <></>;

  return (
    <>
      <BreadcrumbAccount name="Mes informations personnelles" />
      <div className={style['informations']}>
        <div>
          <Navigation />
        </div>
        <div className={style['informations__blocs']}>
          <h2>Mes informations</h2>
          <div className={style['informations__blocs__formulaire']}>
            <BlockInformation firstname={user?.firstname} lastname={user?.lastname} email={user?.email} prefix={user?.prefix} type="infos" />
            <BlockInformation type="password" />
            {/* <BlockInformation type="blog" />*/}
          </div>
          <div className="mt-8">
            <BlockInformation type="communication" />
          </div>
          <div className={style['informations__adress']}>
            <BlockInformation address={addresses} type="address" />
          </div>
          <div className="mt-14">
            <Contact data={data} type="account" />
          </div>
        </div>
      </div>
    </>
  );
};
const InformationsProteges = ({ ...props }) => {
  return (
    <Container>
      <InformationsPersonnelles {...props} />
    </Container>
  );
};
export default InformationsPersonnelles;
