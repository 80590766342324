import style from './LoyaltyProgramCard.module.scss';
import PipeString from 'components/elements/PipeString/PipeString';
import Link from 'next/link';

const LoyaltyProgramCard = ({ data, bloc, underline }) => {
  const { image, illustration, title, subtitle, titleHousky, text, url, cta } = data.fields;

  const ariaLabel = `${title.replace(/\|/gi, ' ') || ''} ${titleHousky || ''} ${subtitle || ''}`;

  return (
    <article className={`${style.LoyaltyProgramCard} ${image ? style['LoyaltyProgramCard--with-image'] : ''}`} tabIndex="0" aria-label={ariaLabel}>
      {image && <img className={style.LoyaltyProgramCard__image} src={image.fields.file.url} alt="" />}

      <div className={style.LoyaltyProgramCard__content}>
        {illustration && <img className={style.LoyaltyProgramCard__content__icon} src={illustration.fields.file.url} alt="" />}

        {title && (
          <h3 className={style.LoyaltyProgramCard__content__title}>
            <PipeString string={title} />
          </h3>
        )}

        {titleHousky && <p className={style.LoyaltyProgramCard__content__titleHousky}>{titleHousky}</p>}

        {underline && <hr />}

        {subtitle && (
          <p
            className={`
              ${style.LoyaltyProgramCard__content__subtitle} 
              ${bloc ? style['LoyaltyProgramCard__content__subtitle--block'] : ''}
            `}
          >
            {subtitle}
          </p>
        )}

        {text && <p className={style.LoyaltyProgramCard__content__text}>{text}</p>}

        {url && cta && (
          <Link href={url} className={style.LoyaltyProgramCard__content__cta}>
            <a>{cta}</a>
          </Link>
        )}
      </div>
    </article>
  );
};

export default LoyaltyProgramCard;
