import React from 'react';
import Link from 'next/link';
import Picto from 'components/elements/Picto/Picto';
import { publishItems } from 'helpers/utils/publishItems';

const Reinsurance = ({ data }) => {
  try {
    const { fields } = data.data.dataSource;

    return (
      <section className="reinsurance">
        <div className="reinsurance__items container">
          {publishItems(fields?.icons).map((item) => {
            const { icon, title } = item.fields;

            return (
              <Link key={`reinsurance-item--${title}`} href="#">
                <a className="reinsurance__item">
                  <Picto icon={icon} />
                  <span>{title}</span>
                </a>
              </Link>
            );
          })}
        </div>

        <p className="reinsurance__text container">{fields.title}</p>
      </section>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default Reinsurance;
