import RichText from 'components/elements/contentful/RichText/RichText';
import style from './SeoBlock.module.scss';

const SeoBlock = ({ data }) => {
  try {
    const { seoText } = data.data.dataSource.fields;

    return (
      <section className={style.SeoBlock}>
        <RichText content={seoText} />
      </section>
    );
  } catch (error) {
    console.log(error);
    return <></>;
  }
};

export default SeoBlock;
