import React, { useContext } from 'react';
import style from 'components/scopes/login/Login.module.scss';
import PasswordStrengh from 'components/elements/PasswordStrengh/PasswordStrengh';
import Loader from 'components/elements/Loader/Loader';
import LoyaltyCard from 'components/elements/LoyaltyCard/LoyaltyCard';
import PipeString from 'components/elements/PipeString/PipeString';
import { rules } from 'helpers/utils/formRules';
import { useForm } from 'react-hook-form';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { UserContext, useZodioAccount } from 'frontastic';
import alert from 'helpers/utils/alert';
import { Button, Field, TextInput, CheckBox, Toggle, DateInput, PasswordInput } from '@mozaic-ds/react';

const Register = () => {
  const router = useRouter();
  const { email, referer } = router.query;

  const [loading, setLoading] = React.useState(false);
  const [currentPassword, setCurrentPassword] = React.useState('');
  const [displayBirthdate, setDisplayBirthdate] = React.useState(false);
  const [prefilledEmail, setPrefilledEmail] = React.useState(router.query?.email);

  const { createAccount } = useZodioAccount();
  const { setUser } = useContext(UserContext);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { touchedFields, errors },
  } = useForm({
    defaultValues: { email },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  async function onSubmit(data) {
    if (Object.keys(errors).length) return;

    setLoading(true);

    try {
      await createAccount(data).then((res) => setUser(res));
      alert('success', 'Votre compte a été créé avec succès ! ', 7000);
      router.push(referer || '/');
    } catch (error) {
      console.error(error);
      alert('error', "Une erreur s'est produite. Veuillez contacter le service client.", 7000);
    } finally {
      setLoading(false);
    }
  }

  try {
    return (
      <section className={style.Login}>
        <div className={style.Login__card} data-visible="true">
          <h2 className={style.Login__card__title}>Mon compte</h2>

          <p className={style.Login__card__text}>
            <PipeString string="Veuillez saisir les informations nécessaires|à la création du compte" />
          </p>

          <form className={style.Login__card__form} noValidate onSubmit={handleSubmit(onSubmit)}>
            <fieldset>
              <legend>
                Contact <span>* champs obligatoires</span>
              </legend>
              {prefilledEmail ? (
                <div className={style.Login__card__form__help}>
                  <span className={style.Login__card__form__help__label}>Adresse email</span>

                  <button type="button" className={style.Login__card__form__help__button} onClick={() => setPrefilledEmail(undefined)}>
                    Modifier
                  </button>

                  <span className={style.Login__card__form__help__value}>{getValues('email')}</span>
                  <input type="hidden" {...register('email')} />
                </div>
              ) : (
                <Field className="mb-[1em]" htmlFor="email" label={`Adresse email${rules.email.required ? '*' : ''}`} error={errors?.email?.message}>
                  <TextInput id="email" {...register('email', rules.email)} isValid={touchedFields.email && !errors.email} isInvalid={errors.email} />
                </Field>
              )}
              <Field
                htmlFor="password"
                help="8 caractères, 1 majuscule et 1 minuscule minimum, 1 chiffre, 1 caractère spécial."
                label={`Choisissez votre mot de passe${rules.password.required ? '*' : ''}`}
                error={errors?.password?.message}
              >
                <PasswordStrengh password={currentPassword} />

                <PasswordInput
                  id="password"
                  labelShow={<span className="w-[4.5rem] block">Voir</span>}
                  labelHide={<span className="w-[4.5rem] block">Cacher</span>}
                  isValid={touchedFields.password && !errors.password}
                  isInvalid={errors.password}
                  onKeyUp={(e) => setCurrentPassword(e.target.value)}
                  {...register('password', rules.password)}
                />
              </Field>
            </fieldset>

            <fieldset>
              <legend>Informations personnelles</legend>
              <Field htmlFor="gender" label={`Civilité${rules.gender.required ? '*' : ''}`} error={errors?.gender?.message}>
                <div className={style.Login__card__form__row}>
                  <Button
                    type="button"
                    width="full"
                    theme={errors?.gender ? 'secondary-danger' : 'secondary'}
                    isDisabled={getValues('gender') === 'Mme'}
                    onClick={() => setValue('gender', 'Mme', { shouldValidate: true })}
                  >
                    Madame
                  </Button>

                  <Button
                    type="button"
                    width="full"
                    theme={errors?.gender ? 'secondary-danger' : 'secondary'}
                    isDisabled={getValues('gender') === 'M.'}
                    onClick={() => setValue('gender', 'M.', { shouldValidate: true })}
                  >
                    Monsieur
                  </Button>

                  <input type="hidden" {...register('gender', rules.gender)} />
                </div>
              </Field>

              <Field htmlFor="firstname" label={`Prénom${rules.firstname.required ? '*' : ''}`} error={errors?.firstname?.message}>
                <div className={style.Login__card__form__row}>
                  <TextInput
                    id="firstname"
                    {...register('firstname', rules.firstname)}
                    isValid={touchedFields.firstname && !errors.firstname}
                    isInvalid={errors.firstname}
                  />
                </div>
              </Field>

              <Field htmlFor="lastname" label={`Nom${rules.lastname.required ? '*' : ''}`} error={errors?.lastname?.message}>
                <div className={style.Login__card__form__row}>
                  <TextInput
                    id="lastname"
                    {...register('lastname', rules.lastname)}
                    isValid={touchedFields.lastname && !errors.lastname}
                    isInvalid={errors.lastname}
                  />
                </div>
              </Field>

              <Field htmlFor="phone" label={`Numéro de téléphone${rules.phone.required ? '*' : ''}`} error={errors?.phone?.message}>
                <div className={style.Login__card__form__row}>
                  <TextInput
                    type="tel"
                    id="phone"
                    placeholder="Numéro de téléphone"
                    {...register('phone', rules.phone)}
                    isValid={touchedFields.phone && !errors.phone}
                    isInvalid={errors.phone}
                  />
                </div>
              </Field>
            </fieldset>

            <fieldset>
              <legend>Carte de fidélité</legend>
              <div className="mt-[-1rem]">
                <LoyaltyCard>
                  <ul className={style.Login__card__form__loyalty}>
                    <li className={style.Login__card__form__loyalty__item}>
                      <strong>Je collectionne des points</strong>
                      <span>grâce à mes rencontres</span>
                    </li>

                    <li className={style.Login__card__form__loyalty__item}>
                      <strong>Je retrouve mes points</strong>
                      <span>dans mon compte client</span>
                    </li>

                    <li className={style.Login__card__form__loyalty__item}>
                      <strong>J&apos;active mes récompenses</strong>
                      <span>Lors de mon passage en caisse ou sur zodio.fr</span>
                    </li>
                  </ul>
                </LoyaltyCard>
              </div>

              <CheckBox className="w-[100%]" {...register('loyalty')} onChange={(e) => setDisplayBirthdate(e.target.checked)}>
                Je profite d&apos;offres exclusives grâce au programme de fidélité Entre nous gratuitement
              </CheckBox>

              {displayBirthdate && (
                <Field
                  className="mt-[2rem]"
                  htmlFor="birthdate"
                  label={`Date de naissance${rules.birthdate.required ? '*' : ''}`}
                  error={errors?.birthdate?.message}
                >
                  <DateInput
                    size="m"
                    id="birthdate"
                    {...register('birthdate', rules.birthdate)}
                    isInvalid={errors.birthdate}
                    isValid={touchedFields.birthdate && !errors.birthdate}
                  />
                </Field>
              )}
            </fieldset>

            <fieldset>
              <legend>Newsletter</legend>

              <Toggle size="s" {...register('newsletter')}>
                Je souhaite être informé de L&apos;actualité de Zôdio
              </Toggle>
            </fieldset>

            <div className={style.Login__card__form__mentions}>
              En créant votre compte, vous acceptez la{' '}
              <Link href="/politique-des-donnees-personnelles">
                <a>politique de gestion des données personnelles</a>
              </Link>{' '}
              et les{' '}
              <Link href="/conditions-generales-de-vente">
                <a>conditions générales</a>
              </Link>{' '}
              de Zôdio
            </div>

            <div className={style.Login__card__form__button}>
              <Button type="submit" width="full" isDisabled={Object.keys(errors).length}>
                Valider
              </Button>
            </div>
          </form>
        </div>

        {loading && (
          <div className={style.Login__loader}>
            <Loader className={style.Login__loader__picto} />
          </div>
        )}
      </section>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default Register;
