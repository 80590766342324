export default [
  {
    type: 'tuto',
    href: '/test',
    text: "<span>LAYER CAKE DINOSAURE D'ANNIVERSAIRE</span><span>La recette de : <strong>Margot</strong></span>",
    img: 'https://photos.zodio.fr/zodio-magento/sheet/1/1651132064_ZODIO_-_T_-_DINO_ANNIVERSAIRE_042.jpg?height=640&width=640&canvas=640,640&fit=crop&bg-color=fff&enable=upscale',
  },
  {
    type: 'image',
    href: '',
    img: 'https://photos.zodio.fr/zodio-magento/catalog/product/1/1561023846_15610238118618995944464782213403.jpg?height=640&width=640&canvas=640,640&fit=crop&bg-color=fff&enable=upscale',
  },
  {
    type: 'tuto',
    href: '/test',
    text: '<span>GROS GÂTEAU DE PÂQUES AU CHOCOLAT</span><span>La recette de : <strong>Caroline</strong></span>',
    img: 'https://photos.zodio.fr/zodio-magento/sheet/1/1672314138_ZODIO_-_MESSAGE_2_-_PRINTEMPS_2023_-_G_teau_de_P_ques_027.jpg?height=640&width=640&canvas=640,640&fit=crop&bg-color=fff&enable=upscale',
  },
  {
    type: 'image',
    href: '',
    img: 'https://photos.zodio.fr/zodio-magento/catalog/product/1/1568904659_20190918_173202.jpg?height=640&width=640&canvas=640,640&fit=crop&bg-color=fff&enable=upscale',
  },
  {
    type: 'image',
    href: '',
    img: 'https://photos.zodio.fr/zodio-magento/catalog/product/1/1570441494_Pi_ce_mont_e_c_ne_2.jpg?height=640&width=640&canvas=640,640&fit=crop&bg-color=fff&enable=upscale',
  },
  {
    type: 'image',
    href: '',
    img: 'https://photos.zodio.fr/zodio-magento/catalog/product/1/1574097798_1E8AF6E1-E9C8-41DE-B906-6214C94AFE31.png?height=640&width=640&canvas=640,640&fit=crop&bg-color=fff&enable=upscale',
  },
  {
    type: 'tuto',
    href: '/test',
    text: "<span>LAYER CAKE DINOSAURE D'ANNIVERSAIRE</span><span>La recette de : <strong>Margot</strong></span>",
    img: 'https://photos.zodio.fr/zodio-magento/sheet/1/1651132064_ZODIO_-_T_-_DINO_ANNIVERSAIRE_042.jpg?height=640&width=640&canvas=640,640&fit=crop&bg-color=fff&enable=upscale',
  },
  {
    type: 'image',
    href: '',
    img: 'https://photos.zodio.fr/zodio-magento/catalog/product/1/1561023846_15610238118618995944464782213403.jpg?height=640&width=640&canvas=640,640&fit=crop&bg-color=fff&enable=upscale',
  },
  {
    type: 'tuto',
    href: '/test',
    text: '<span>GROS GÂTEAU DE PÂQUES AU CHOCOLAT</span><span>La recette de : <strong>Caroline</strong></span>',
    img: 'https://photos.zodio.fr/zodio-magento/sheet/1/1672314138_ZODIO_-_MESSAGE_2_-_PRINTEMPS_2023_-_G_teau_de_P_ques_027.jpg?height=640&width=640&canvas=640,640&fit=crop&bg-color=fff&enable=upscale',
  },
  {
    type: 'image',
    href: '',
    img: 'https://photos.zodio.fr/zodio-magento/catalog/product/1/1568904659_20190918_173202.jpg?height=640&width=640&canvas=640,640&fit=crop&bg-color=fff&enable=upscale',
  },
  {
    type: 'image',
    href: '',
    img: 'https://photos.zodio.fr/zodio-magento/catalog/product/1/1570441494_Pi_ce_mont_e_c_ne_2.jpg?height=640&width=640&canvas=640,640&fit=crop&bg-color=fff&enable=upscale',
  },
  {
    type: 'image',
    href: '',
    img: 'https://photos.zodio.fr/zodio-magento/catalog/product/1/1574097798_1E8AF6E1-E9C8-41DE-B906-6214C94AFE31.png?height=640&width=640&canvas=640,640&fit=crop&bg-color=fff&enable=upscale',
  },
];
