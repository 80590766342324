import { usePagination } from 'react-instantsearch-hooks-web';
import { Pagination as MosaicPagination } from '@mozaic-ds/react';
import style from './Pagination.module.scss';

const Pagination = () => {
  const { pages, nbPages, currentRefinement, refine } = usePagination();

  function onPaginate(page) {
    refine(page - 1);
    window.scrollTo(0, 0);
  }

  return nbPages > 1 ? (
    <div className={style.Pagination}>
      <MosaicPagination
        pagesTotal={nbPages}
        currentPage={1 + currentRefinement}
        onChange={onPaginate}
        options={pages.map((page) => ({ value: page + 1, label: `Page ${page + 1} sur ${nbPages}` }))}
      />
    </div>
  ) : null;
};

export default Pagination;
