import CheckoutTemplate from '../partials/CheckoutTemplate/CheckoutTemplate';
import CheckoutLoyalty from '../partials/CheckoutLoyalty/CheckoutLoyalty';
import CartItems from '../partials/CartItems/CartItems';

const Cart = () => {
  try {
    return (
      <CheckoutTemplate step="cart">
        <CartItems />
        <CheckoutLoyalty />
      </CheckoutTemplate>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};
export default Cart;
