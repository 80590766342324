import Link from 'next/link';
import style from './BreadcrumbAccount.module.scss';

const BreadcrumbAccount = ({ name }) => {
  return (
    <nav aria-label="Breadcrumb" className={style.BreadcrumbAccount}>
      <ol className={style.BreadcrumbAccount__list}>
        <li className={style.BreadcrumbAccount__list__item}>
          <Link href="/">
            <a>Accueil</a>
          </Link>
        </li>
        <li className={style.BreadcrumbAccount__list__item}>
          {name ? (
            <Link href="/test/mon-compte/mon-compte">
              <a>Mon compte</a>
            </Link>
          ) : (
            <span className={style['BreadcrumbAccount__list__item--current']} aria-current="page">
              Mon compte
            </span>
          )}
        </li>
        {name && (
          <li className={style.BreadcrumbAccount__list__item}>
            <span className={style['BreadcrumbAccount__list__item--current']} aria-current="page">
              {name}
            </span>
          </li>
        )}
      </ol>
    </nav>
  );
};

export default BreadcrumbAccount;
