export default {
  reviews: {
    title: 'Tous les avis',
    list: [
      {
        id: '56415616',
        name: 'Stéphanie971',
        note: 5,
        text: "Idem que l'avis sur les verres open up précedent. Le modèle Open Up est un trés beau modèle. Les verres sont vraiment très beaux.",
        date: 'le 8 jan. 2023',
      },
      {
        id: '65165115',
        name: 'Hélène S',
        note: 2,
        text: 'Ces verres sont très fragile et cassent très facilement ! ',
        date: 'le 3 déc. 2022',
      },
      {
        id: '98746889',
        name: 'Liana',
        note: 5,
        text: 'Magnifique verre à pied très classe.',
        date: 'le 25 nov. 2022',
      },
      {
        id: '19849819',
        name: 'Anonyme',
        note: 4.5,
        text: "Premiere commande par internet pour profiter d'une journée VIP. Traitement parfait de ma commande, on me rappelle pour m'indiquer que je peux récupérer celle ci plus tot. Verres à vin magnifiques avec un super prix, vivement Noël pour les offrir à ma belle mère. Merci Zodio.",
        photos: [
          'https://photos-eu.bazaarvoice.com/photo/2/cGhvdG86em9kaW8tZnI/c681c6cc-823f-5fc6-b9ea-da723b4abf38',
          'https://photos-eu.bazaarvoice.com/photo/2/cGhvdG86em9kaW8tZnI/9ef2ce31-4308-5179-b5b0-6ae2be22981f',
        ],
        date: 'le 16 nov. 2022',
      },
    ],
  },

  summary: {
    title: 'Note moyenne des avis',
    notes: [
      { note: 5, percentage: 82, count: 3 },
      { note: 4, percentage: 6, count: 1 },
      { note: 3, percentage: 6, count: 6 },
      { note: 2, percentage: 0, count: 0 },
      { note: 1, percentage: 0, count: 0 },
    ],
  },
};
