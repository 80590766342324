import { Button } from '@mozaic-ds/react';
import Picto from 'components/elements/Picto/Picto';
import React from 'react';

const Infos = ({ onClick, email, firstname, prefix, lastname }) => {
  return (
    <div>
      <h2>Informations personnelles</h2>
      <ul>
        <li>
          <span>Adresse email :</span>
          <strong>{email}</strong>
        </li>
        <li>
          <span>Civilité :</span>
          <strong>{prefix}</strong>
        </li>
        <li>
          <span>Prénom :</span>
          <strong>{lastname}</strong>
        </li>
        <li>
          <span>Nom :</span>
          <strong>{firstname}</strong>
        </li>
        <li>
          <span>Date de naissance :</span>
          <strong>- </strong>
        </li>
        <li>
          <span>Téléphone :</span>
          <strong>- </strong>
        </li>
      </ul>

      <div className="text-center mt-4 max-w-[32rem] mx-auto w-full">
        <Button width="full" onClick={onClick}>
          <Picto icon="pen" className="mr-[4rem]" /> Modifier mes informations
        </Button>
      </div>
    </div>
  );
};

export default Infos;
