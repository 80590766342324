import React from 'react';
import style from './DeliveryMethods.module.scss';

import mock from './DeliveryMethods.mock';

const DeliveryMethods = () => {
  const [activeMethod, setActiveMethod] = React.useState(null);

  React.useEffect(() => {
    setActiveMethod(mock.find(({ defaultSelected }) => defaultSelected).id);
  }, []);

  return (
    <ul className={style.DeliveryMethods}>
      {mock.map((method) => {
        const { id, type, name, image, subtitle, from, price } = method;
        return (
          <li key={id} className={style.DeliveryMethods__item} data-active={activeMethod === id}>
            <label className={style.DeliveryMethods__item__inner} aria-label={`Choisir le mode de Livraison ${name}`}>
              <input
                className={style.DeliveryMethods__item__checkbox}
                type="checkbox"
                checked={activeMethod === id}
                onChange={() => setActiveMethod(id)}
              />

              <div className={style.DeliveryMethods__item__infos}>
                <h2 className={style.DeliveryMethods__item__infos__title}>{name}</h2>

                {image && <img className={style.DeliveryMethods__item__infos__image} src={image} alt="" />}

                <p className={style.DeliveryMethods__item__infos__subtitle}>
                  {subtitle} - à partir de {from}
                </p>
              </div>

              <strong className={style.DeliveryMethods__item__price}>{price === 0 ? 'offert' : `${price.toLocaleString('fr-FR')}€`}</strong>
            </label>

            {type === 'relay' && <div className={style.DeliveryMethods__item__details}>Choisir un point relais</div>}
            {type === 'store' && <div className={style.DeliveryMethods__item__details}>Adresse du magasin</div>}
          </li>
        );
      })}
    </ul>
  );
};

export default DeliveryMethods;
