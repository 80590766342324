import ChoisirZodio from './Personnalisation/ChoisirZodio/ChoisirZodio';
import Faq from './Personnalisation/Faq/Faq';
import ProjetParticulier from './Personnalisation/ProjetParticulier/ProjetParticulier';
import InfosLegalesSecurite from './InfosLegalesSecurite/InfosLegalesSecurite';
import InfosLivraison from './InfosLivraion/InfosLivraison';
import RappelProduits from './RappelProduits/RappelProduits';
import quiSommesNous from './quiSommesNous';
import retoursRemboursements from './retoursRemboursements';
import programmeFidelite from './programmeFidelite';
import SecondLife from './SecondLife/SecondLife';
import inspirations from './inspirations';
import MasterLanding from './MasterLanding/MasterLanding';

export default {
  ChoisirZodio,
  Faq,
  ProjetParticulier,
  InfosLegalesSecurite,
  InfosLivraison,
  RappelProduits,
  SecondLife,
  MasterLanding,
  ...retoursRemboursements,
  ...programmeFidelite,
  ...quiSommesNous,
  ...inspirations,
};
