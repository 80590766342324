import React from 'react';
import style from './HeaderCheckout.module.scss';
import { Button } from '@mozaic-ds/react';
import Picto from 'components/elements/Picto/Picto';
import Link from 'next/link';

const HeaderCheckout = () => {
  return (
    <div className={style.HeaderCheckout}>
      <div className={style.HeaderCheckout__container}>
        <div className={style.HeaderCheckout__button}>
          <Link href="/">
            <Button href="/" size="full" theme="secondary">
              <Picto icon="arrow--left" className="mr-[2rem]" /> continuer mes achats
            </Button>
          </Link>
        </div>

        <Link href="/">
          <a className={style.HeaderCheckout__logo} aria-hidden="true">
            <img
              src="//images.ctfassets.net/cg3jdovg6x56/7pUxytwwqfAJDiIgIKJqyc/76bd722b22bf24473ec25c9f496e4702/logo.svg"
              alt="Retour à l'accueil"
            />

            <span>
              Tout commence
              <br />
              entre vos mains
            </span>
          </a>
        </Link>
      </div>
    </div>
  );
};

export default HeaderCheckout;
