import style from './RSE.module.scss';

import mockedLogo from './logo-rse.png';

const RSE = ({ data }) => {
  const { logo, text, points, image } = data.content;

  return (
    <section className={style.RSE}>
      <div className={style.RSE__container}>
        <img className={style.RSE__logo} src={mockedLogo.src} alt={logo.alt} loading="lazy" />

        <div className={style.RSE__text}>
          <span>{text}</span>
          <ul>
            {points.map((point, i) => (
              <li key={i}>{point}</li>
            ))}
          </ul>
        </div>

        <img className={style.RSE__image} src={image} alt="" loading="lazy" />
      </div>
    </section>
  );
};

export default RSE;
