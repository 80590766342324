import style from './ProductTabReviews.module.scss';
import { RatingStarsResult, Button, Pagination, useModals, Layer, LayerHeader, LayerTitle, LayerBody } from '@mozaic-ds/react';
import Picto from 'components/elements/Picto/Picto';
import ReviewForm from './partials/ReviewForm/ReviewForm';
import mock from './ProductTabReviews.mock';

const { summary, reviews } = mock;

const ProductTabReviews = () => {
  const { open } = useModals();
  return (
    <div className={style.ProductTabReviews}>
      {summary && (
        <div className={style.ProductTabReviews__column}>
          <h2 className={style.ProductTabReviews__title}>{summary.title}</h2>

          <div className={style.ProductTabReviews__content}>
            <ul className={style.ProductTabReviews__summary}>
              {summary.notes.map((item) => {
                return (
                  <li key={item.note}>
                    <RatingStarsResult score={20 * item.note} size="s" />
                    <span className={style.ProductTabReviews__summary__percentage}>{item.percentage}%</span>
                    <span className={style.ProductTabReviews__summary__count}>{item.count} avis</span>
                  </li>
                );
              })}
            </ul>

            <Button theme="secondary" width="full" onClick={() => open('review-layer')}>
              <Picto icon="pen" className="mr-[1rem]" />
              Écrire un avis
            </Button>

            <Layer id="review-layer">
              <LayerHeader id="review-layer">
                <LayerTitle>Rédiger un avis</LayerTitle>
              </LayerHeader>
              <LayerBody id="review-layer">
                <ReviewForm />
              </LayerBody>
            </Layer>
          </div>
        </div>
      )}

      {reviews && (
        <div className={style.ProductTabReviews__column}>
          <h2 className={style.ProductTabReviews__title}>{reviews.title} (10)</h2>

          <div className={style.ProductTabReviews__content}>
            <ul className={style.ProductTabReviews__list}>
              {reviews.list.map((item) => {
                return (
                  <li key={item.id}>
                    <div>
                      <span className={style.ProductTabReviews__list__name}>{item.name}</span>
                      <RatingStarsResult score={20 * item.note} size="s" />
                    </div>

                    <div>
                      <span className={style.ProductTabReviews__list__text}>{item.text}</span>
                      <span className={style.ProductTabReviews__list__date}>{item.date}</span>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      )}

      <div className={style.ProductTabReviews__pagination}>
        <Pagination
          pagesTotal={3}
          currentPage={1}
          options={[
            { label: 'Page 1 of 3', value: 1 },
            { label: 'Page 2 of 3', value: 2 },
            { label: 'Page 3 of 3', value: 3 },
          ]}
        />
      </div>
    </div>
  );
};

export default ProductTabReviews;
