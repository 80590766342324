import React from 'react';
import style from './AnchorNav.module.scss';

const AnchorNav = () => {
  const aside = React.useRef();
  const [anchors, setAnchors] = React.useState([]);
  React.useEffect(findAnchorsInPage, []);
  React.useEffect(scrollOnLoad, [anchors]);

  function scrollOnLoad() {
    const anchor = anchors.find((item) => item.id === window.location.hash.replace('#', ''));
    if (anchor) goToAnchor(anchor);
  }

  function findAnchorsInPage() {
    const foundAnchors = [];
    document.querySelectorAll('#main-container [data-anchor-label][data-anchor-id]').forEach((anchor) => {
      const { anchorId: id, anchorLabel: label } = anchor.dataset;
      if (!foundAnchors.find((item) => item.id === id)) foundAnchors.push({ label, id });
    });

    setAnchors(foundAnchors);
    return () => setAnchors([]);
  }

  function goToAnchor({ label, id }) {
    window.location.hash = id;
    document.querySelector(`#main-container [data-anchor-label="${label}"][data-anchor-id="${id}"]`)?.scrollIntoView({ block: 'start' });
  }

  return anchors.length ? (
    <aside className={style.AnchorNav} ref={aside}>
      <nav>
        <ul>
          {anchors.map((anchor) => (
            <li key={anchor.id}>
              <button onClick={() => goToAnchor(anchor)}>{anchor.label}</button>
            </li>
          ))}
        </ul>
      </nav>
    </aside>
  ) : null;
};

export default AnchorNav;
