import React from 'react';
import style from './ItemTile.module.scss';
import Link from 'next/link';
import { debounce } from 'lodash';

import ItemTileFlag from './partials/ItemTileFlag/ItemTileFlag';
import ItemTileImages from './partials/ItemTileImages/ItemTileImages';
import ItemTilePictos from './partials/ItemTilePictos/ItemTilePictos';
import ItemTileTitle from './partials/ItemTileTitle/ItemTileTitle';
import ItemTileVariants from './partials/ItemTileVariants/ItemTileVariants';

import Price from 'components/elements/Price/Price';

const ItemTile = ({ data }) => {
  const item = React.useRef();

  function calculateWidth() {
    item.current?.toggleAttribute('small', item.current?.offsetWidth <= 230);
  }

  React.useEffect(() => {
    setTimeout(calculateWidth, 50);
    window.addEventListener('resize', debounce(calculateWidth, 50));
    return window.removeEventListener('resize', calculateWidth);
  }, []);

  return (
    <article ref={item} className={style.ItemTile} data-sku={data.sku}>
      <Link href={data.url.replace('fr//', 'fr/')}>
        <a>
          <div className={style.ItemTile__header}>
            <ItemTileFlag {...data} />
            <ItemTileImages {...data} />
            <ItemTilePictos {...data} />
          </div>

          <div className={style.ItemTile__content}>
            <ItemTileVariants {...data} />
            <ItemTileTitle {...data} />
          </div>

          <div className={style.ItemTile__footer}>
            <Price size="s" data={data.price.EUR} />
          </div>
        </a>
      </Link>
    </article>
  );
};

export default ItemTile;
