import React from 'react';
import style from './ConsentOptin.module.scss';
import { CheckBox } from '@mozaic-ds/react';
import Link from 'next/link';

const ConsentOptin = ({ data }) => {
  const { label, id, defaultValue, linkLabel, linkHref } = data.data.dataSource.fields;
  const [checked, toggleCheck] = React.useState(defaultValue);

  const onChange = () => {
    toggleCheck(!checked);

    // Do Something depending on ID
    if (checked) console.log(id, 'active');
    else console.log(id, 'disable');
  };

  try {
    return (
      <div id={id}>
        <CheckBox className={style.ConsentOptin} onChange={onChange} isChecked={checked}>
          {label}

          {linkLabel && linkHref && (
            <Link href={linkHref}>
              <a>{linkLabel}</a>
            </Link>
          )}
        </CheckBox>
      </div>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default ConsentOptin;
