import style from './ParagraphTwoImgs.module.scss';

const ParagraphTwoImgs = ({ data }) => {
  try {
    const { images608x608px, title, subTitle } = data.data.dataSource.fields;
    return (
      <div className={style.ParagraphTwoImgs}>
        <h2 className={style.ParagraphTwoImgs__title}>{title}</h2>
        <p className={style.ParagraphTwoImgs__subtitle}>{subTitle}</p>
        <div className={style.ParagraphTwoImgs__images}>
          {images608x608px.map((image) => (
            <img key={image.sys.id} src={image.fields.file.url} alt={image.fields.title} />
          ))}
        </div>
      </div>
    );
  } catch (e) {
    console.error(e);
    return <></>;
  }
};

export default ParagraphTwoImgs;
