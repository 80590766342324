import { Tabs, TabsButton, TabsList, TabsPanel } from '@mozaic-ds/react';
import WorkshopTabInfos from './partials/WorkshopTabInfos/WorshopTabInfos';
import WorkshopTabFormer from './partials/WorkshopTabFormer/WorkshopTabFormer';
import React from 'react';
import style from './WorkshopTabs.module.scss';

const titles = {
  infos: "Plus d'infos",
  reviews: 'Le formateur',
};

const WorkshopTabs = ({ data }) => {
  const tabs = React.useRef();
  const buttonInfos = React.useRef();
  const buttonReviews = React.useRef();

  React.useEffect(scrollLoad, []);

  function scrollLoad() {
    const tab = window.location.hash.replace('#', '');
    console.log(tab);
    if (tab) setTimeout(() => goToTab(tab), 500);
  }

  React.useEffect(() => {
    window.addEventListener('open-tab', (event) => goToTab(event.detail.val));
    return window.removeEventListener('open-tab', (event) => goToTab(event.detail.val));
  }, []);

  const goToTab = (tab) => {
    window.location.hash = tab;
    window.scrollTo({ top: tabs?.current?.offsetTop });

    switch (tab) {
      case 'infos':
        buttonInfos?.current?.closest('button')?.click();
        break;
      case 'reviews':
        buttonReviews?.current?.closest('button')?.click();
    }
  };
  return (
    <section className={style.WorkshopTabs} ref={tabs}>
      <Tabs defaultTab={titles.infos}>
        <TabsList width="full">
          <TabsButton tab={titles.infos}>
            <span className={style.WorkshopTabs__title} ref={buttonInfos}>
              {titles.infos}
            </span>
          </TabsButton>
          <TabsButton tab={titles.reviews}>
            <span className={style.WorkshopTabs__title} ref={buttonReviews}>
              {titles.reviews}
            </span>
          </TabsButton>
        </TabsList>
        <TabsPanel tab={titles.infos}>
          <div className={style.WorkshopTabs__content}>
            <WorkshopTabInfos />
          </div>
        </TabsPanel>
        <TabsPanel tab={titles.reviews}>
          <div className={style.WorkshopTabs__content}>
            <WorkshopTabFormer />
          </div>
        </TabsPanel>
      </Tabs>
    </section>
  );
};

export default WorkshopTabs;
