import style from './WorkshopsThematics.module.scss';
import Link from 'next/link';

const WorkshopsThematics = ({ data }) => {
  try {
    const { titre, subtitle, blocs } = data?.data?.dataSource?.fields;

    return (
      <section className={style.WorkshopsThematics}>
        <h2 className={style.WorkshopsThematics__title}>{titre}</h2>
        <p className={style.WorkshopsThematics__subtitle}>{subtitle}</p>

        {blocs && (
          <ul className={style.WorkshopsThematics__items}>
            {blocs.map((bloc) => {
              const { id } = bloc?.sys;
              const { titre, subtitle, image, ctaUrl } = bloc?.fields;
              const { url: imageSrc } = image?.fields?.file;

              return (
                <li key={id}>
                  <Link href={ctaUrl}>
                    <a className={style.WorkshopsThematics__card}>
                      <img className={style.WorkshopsThematics__card__image} src={imageSrc} alt="" />
                      <div className={style.WorkshopsThematics__card__text}>
                        <h3>{titre}</h3>
                        <p>{subtitle}</p>
                      </div>
                    </a>
                  </Link>
                </li>
              );
            })}
          </ul>
        )}
      </section>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default WorkshopsThematics;
