import Dev from 'components/dev'; // DEVELOPMENT ONLY (to remove)
import Generic from 'components/generic/'; // GENERIC
import Scopes from 'components/scopes'; // SCOPES
import NotFound from './not-found'; // NOT FOUND

export const tastics = {
  ...Dev,
  ...Generic,
  ...Scopes,

  // NOT FOUND
  default: NotFound,
};
