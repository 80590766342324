import Subtitle from 'components/elements/Subtitle/Subtitle';
import { Button } from '@mozaic-ds/react';
import style from './Steps.module.scss';

const Steps = ({ data }) => {
  const { title, steps } = data.content;

  return (
    <section className={style.Steps}>
      <Subtitle title={title} />

      <ol className={style.Steps__list}>
        {steps.map((step, i) => {
          return (
            <li key={i} className={style.Steps__list__item}>
              <div className={style.Steps__list__item__image} style={{ backgroundImage: `url(https:${step.picto})` }}></div>
              <h4 className={style.Steps__list__item__title}>{step.title}</h4>
              <p className={style.Steps__list__item__text}>{step.text}</p>
            </li>
          );
        })}
      </ol>

      <Button href="/trouver-un-magasin" className={style.Steps__Button}>
        Choisir un magasin
      </Button>
    </section>
  );
};

export default Steps;
