import style from './FideliteHeader.module.scss';
import PipeString from 'components/elements/PipeString/PipeString';

const FideliteHeader = ({ title, titleHousky, subtitle }) => {
  return (
    <div className={style.FideliteHeader}>
      <h2 className={style.FideliteHeader__title}>
        {title && <PipeString string={title} />}
        {titleHousky && <em>{titleHousky}</em>}
      </h2>

      {subtitle && <p className={style.FideliteHeader__subtitle}>{subtitle}</p>}
    </div>
  );
};

export default FideliteHeader;
