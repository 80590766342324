import style from './BannerNotFull.module.scss';
import React from 'react';

const BannerNotFull = ({ data }) => {
  try {
    const { banner1504x810px } = data.data.dataSource.fields;

    const breakpoints = [
      {
        media: '(max-width: 800px) and (-webkit-min-device-pixel-ratio: 1)',
        srcSet: banner1504x810px.fields.file.url + '?w=800',
      },
      {
        media: '(max-width: 800px) and (-webkit-min-device-pixel-ratio: 2)',
        srcSet: banner1504x810px.fields.file.url + '?w=1600',
      },
      {
        media: '(max-width: 1100px) and (-webkit-min-device-pixel-ratio: 1)',
        srcSet: banner1504x810px.fields.file.url + '?w=1100',
      },
      {
        media: '(max-width: 1100px) and (-webkit-min-device-pixel-ratio: 2)',
        srcSet: banner1504x810px.fields.file.url + '?w=2200',
      },
      {
        media: '(max-width: 1500px) and (-webkit-min-device-pixel-ratio: 1)',
        srcSet: banner1504x810px.fields.file.url + '?w=1920',
      },
      {
        media: '(max-width: 1500px) and (-webkit-min-device-pixel-ratio: 2)',
        srcSet: banner1504x810px.fields.file.url + '?w=3840',
      },
    ];

    return (
      <section className={style.BannerNotFull}>
        <picture className={style.BannerNotFull__picture}>
          {breakpoints.map((breakpoint, i) => (
            <source key={i} {...breakpoint} />
          ))}
          <img src={banner1504x810px.fields.file.url + '?w=1920'} alt="" />
        </picture>
      </section>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default BannerNotFull;
