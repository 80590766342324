import React from 'react';
import Picto from 'components/elements/Picto/Picto';
import { Field } from '@mozaic-ds/react';

import { rules } from 'helpers/utils/formRules';
import { useForm } from 'react-hook-form';
import { useRouter } from 'next/router';

const FooterNewsletter = ({ data }) => {
  const router = useRouter();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  function onSubmit(query) {
    router.push({ pathname: '/newsletter', query });
  }

  return (
    <form className="footer__newsletter" onSubmit={handleSubmit(onSubmit)}>
      <label htmlFor="newsletter-input">{data.label}</label>

      <Field className="w-[100%]" error={errors?.email?.message}>
        <div className="input-group">
          <input
            type="text"
            id="newsletter-input"
            placeholder="Votre adresse email"
            autoComplete="off"
            className={errors?.email ? 'has-error' : ''}
            {...register('email', rules.email)}
          />

          <button type="submit" title="Soumettre votre inscription à la newsletter Zodio.fr">
            <Picto icon="paper-plane" />
          </button>
        </div>
      </Field>
    </form>
  );
};

export default FooterNewsletter;
