import { createFocusLoop, destroyFocusLoop } from 'helpers/utils/keyboardNav';
import { getCurrentDevice } from './media-queries';

/**
 * Build a JSON from BS response
 * @param {Array} root Array of main categories IDs
 * @param {*} entries flat Array of all categories
 * @returns
 */
export function buildMenu(root, entries) {
  const index = Object.fromEntries(entries.map((entry) => [entry.id, entry]));

  Object.values(index).forEach((entry) => {
    if (entry.items) entry.items = entry.items?.map((id) => (entry.id === id ? { ...index[id], type: 'main' } : index[id])) || [];
  });
  const menu = root.map((id) => index[id]);

  return menu;
}

/**
 * On mobile, the menu is hidden behind a burger icon
 */
export function showOnMobile() {
  const navBar = document.querySelector('.header__menu__navigation');
  const overlay = document.querySelector('.header__menu__overlay');
  navBar.setAttribute('data-visible', true);
  overlay.setAttribute('data-visible', true);
  createFocusLoop(navBar, ['button', 'a']);
  document.body.classList.add('is-blocked');
  window.addEventListener('keydown', (event) => event.code === 'Escape' && hideOnMobile());
}

export function hideOnMobile() {
  const navBar = document.querySelector('.header__menu__navigation');
  const overlay = document.querySelector('.header__menu__overlay');
  navBar.setAttribute('data-visible', false);
  overlay.setAttribute('data-visible', false);
  destroyFocusLoop(navBar);
  document.body.classList.remove('is-blocked');
  window.removeEventListener('keydown', (event) => event.code === 'Escape' && hideOnMobile());
  closeDrawers();
  closeLayers();
}

/**
 * Open menu drawers (level 2)
 */
export function openDrawer(event) {
  const button = event.target.closest('button');
  const drawer = button.parentNode.querySelector('.header__menu__drawer');
  const drawerIsOpen = drawer?.dataset.visible === 'true';

  closeDrawers();
  closeLayers();

  if (drawer && !drawerIsOpen) {
    const height = drawer.scrollHeight;
    const width = drawer?.clientWidth || 430;
    const left = Math.min(button?.offsetLeft || 0, window.innerWidth - width - 40);

    window.scrollTo({ top: 0, behavior: 'instant' });
    document.body.classList.add('is-blocked');
    drawer.style.setProperty('--drawer--height', `${height}px`);
    drawer.style.setProperty('--drawer--left', `${left}px`);
    button.setAttribute('data-active', true);
    drawer.setAttribute('data-visible', true);

    setTabIndex(drawer);
  }
}

/**
 * Close all menu drawers (level 2)
 */
export function closeDrawers() {
  document.body.classList.remove('is-blocked');

  document.querySelectorAll('.header__menu__drawer').forEach((drawer) => {
    drawer.closest('.header__menu__item').querySelector('.header__menu__item__link').removeAttribute('data-active');
    drawer.style.removeProperty('--drawer--left');
    drawer.style.removeProperty('--drawer--height');
    drawer.setAttribute('data-visible', false);
  });

  resetTabIndex();
}

/**
 * Open menu layers (level 3 and more...)
 */
export function openLayer(event) {
  const button = event.target.closest('button');
  const drawer = button.closest('.header__menu__drawer');
  const thisLayer = button.parentNode.querySelector('.header__menu__layer');
  const thisLayerIsOpen = thisLayer?.dataset.visible === 'true';

  const currentDevice = getCurrentDevice();

  if (thisLayer && !thisLayerIsOpen) {
    const height =
      currentDevice === 'desktop'
        ? thisLayer.querySelector('.header__menu__layer__close').scrollHeight +
          thisLayer.querySelector('.header__menu__layer__items').scrollHeight +
          'px'
        : '100vh';

    drawer.style.setProperty('--drawer--height', height);
    thisLayer.setAttribute('data-visible', true);

    setTabIndex(thisLayer);
  }
}

/**
 * close all menu layers
 */
export function closeLayers() {
  document.querySelectorAll('.header__menu__layer').forEach(closeLayer);
}

/**
 * close a specific layer
 */
export function closeLayer(layer) {
  if (!layer) return;

  const currentDevice = getCurrentDevice();
  const drawer = layer.closest('.header__menu__drawer');
  const parent = layer.parentNode.closest('.header__menu__layer') || drawer;

  let height = '100vh';

  if (parent === drawer) height = drawer.scrollHeight;
  else if (currentDevice === 'desktop')
    height =
      parent.querySelector('.header__menu__layer__close').scrollHeight + parent.querySelector('.header__menu__layer__items').scrollHeight + 'px';

  drawer.style.setProperty('--drawer--height', height);
  layer.setAttribute('data-visible', false);
}

/**
 * Reset all tabindex of menu items to 0
 */
function resetTabIndex() {
  clearTabIndex();
  document.querySelectorAll('.header__menu__navigation .header__menu__item > [tabindex]').forEach((item) => {
    item.setAttribute('tabindex', '0');
  });
}

/**
 * Reset all tabindex of drawer child to -1
 */
function clearTabIndex() {
  document.querySelectorAll('.header__menu__drawer [tabindex]').forEach((item) => {
    item.setAttribute('tabindex', '-1');
  });
}

/**
 * Set all tabindex of container child to 0
 */
function setTabIndex(container) {
  clearTabIndex();

  // if container is a Drawer
  if (container.classList.contains('header__menu__drawer'))
    container.querySelectorAll('& > .header__menu__child > [tabindex]').forEach((item) => {
      item.setAttribute('tabindex', '0');
    });

  // if container is a Layer
  if (container.classList.contains('header__menu__layer'))
    container
      .querySelectorAll('& > .header__menu__layer__items > .header__menu__child > [tabindex], & > .header__menu__layer__close [tabindex]')
      .forEach((item) => {
        item.setAttribute('tabindex', '0');
      });
}
