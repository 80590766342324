export default {
  data: {
    dataSource: {
      fields: {
        contentReferences: [
          // BANNER
          {
            content: {
              title: 'Engagez-vous avec nous et nos associations partenaires',
              imageDesktop: '//images.ctfassets.net/cg3jdovg6x56/6kFhASWrN99QVlmzhm7dWh/6bd1d0b91a733160cee1027189931776/banner-seconde-vie.jpg',
              imageMobile:
                '//images.ctfassets.net/cg3jdovg6x56/51MIMkZd5ruhq3NcMX5379/e3598ccefcec798e385e457b766ee9b6/Banner-second-life-mobile.jpg',
            },
          },

          // BLOCK TEXT
          {
            content: {
              title: "La seconde vie, c'est quoi ?",
              text: 'C’est une initiative responsable et solidaire. Elle vous permet de donner certains objets de votre quotidien en bon état et qui ne vous servent plus, à des personnes dans le besoin.',
            },
          },

          // DETAILS
          {
            content: {},
          },

          // STEPS
          {
            content: {
              title: "Comment participer ? C'est simple !",
              steps: [
                {
                  picto: '//images.ctfassets.net/cg3jdovg6x56/4eW7llX8Li5BADrlUmEUqh/3e8ab3cd56adf1cd6001ab655b7e3f4a/weight.svg',
                  title: 'Je fais peser en magasin',
                  text: 'Assurez-vous que vos dons soient en bon état pour être réutilisables. Emballez bien vos dons et apportez-les dans votre magasin Zôdio pour les faire peser.',
                },
                {
                  picto: '//images.ctfassets.net/cg3jdovg6x56/5D3pGxoCUEJkctCqgDE5f7/7557880d89326a59cf4786b8a94d9386/association.svg',
                  title: 'Une association reçoit mes dons',
                  text: 'Nous nous occupons de transmettre vos dons à une association locale pour en faire profiter à des personnes dans le besoin.',
                },
                {
                  picto: '//images.ctfassets.net/cg3jdovg6x56/VloS2qqVr4rB5HS3cMyP5/16e9b43026ffc85e93fc9999e413073e/discount.svg',
                  title: "J'obtiens mon bon de réduction",
                  text: 'Un conseiller vous remettra un bon d’achat jusqu’à -15 %* en fonction du poids total de vos dons. Profitez de votre bon d’achat le jour même dans le rayon correspondant à vos dons et obtenez 10 points* sur votre compte fidélité.',
                },
              ],
            },
          },

          // CATEGORIES
          {
            content: {
              title: 'Quels objets sont concernés ?',
              legend: 'Bon à valoir le jour même dans le rayon correspondant aux dons(1)',
              categories: [
                {
                  image: '//www.zodio.fr/static/version1679058868/frontend/Zodio/2019/fr_FR/Zodio_Animations/images/cat-chambre.jpg',
                  title: 'Chambre',
                  text: 'Les produits concernés : housses de couette, couettes, taies d’oreiller, oreillers, draps, protections literie et plaids.',
                },
                {
                  image: '//www.zodio.fr/static/version1679058868/frontend/Zodio/2019/fr_FR/Zodio_Animations/images/cat-cuisson.jpg',
                  title: 'Cuisson',
                  text: 'Les produits concernés : poêles, sauteuses, casseroles, cocottes, faitouts, moules à gâteaux, plats à four et cuisine du monde.',
                },
                {
                  image: '//www.zodio.fr/static/version1679058868/frontend/Zodio/2019/fr_FR/Zodio_Animations/images/cat-bain.jpg',
                  title: 'Linge de bain',
                  text: 'Les produits concernés : serviettes, gants de toilette, tapis de bain et peignoirs.',
                },
                {
                  image: '//www.zodio.fr/static/version1679058868/frontend/Zodio/2019/fr_FR/Zodio_Animations/images/cat-table.jpg',
                  title: 'Table',
                  text: 'Les produits concernés : verres, couverts, assiettes, bols, tasses et nappes.',
                },
              ],
            },
          },

          // ASSOCIATIONS
          {
            content: {
              title: 'Les associations que nous soutenons',
              logos: [
                {
                  image: '//www.zodio.fr/media/emmaus.jpg?width=300',
                  alt: 'Emmaüs France',
                },
                {
                  image: '//www.zodio.fr/media/secours-pop.jpg?width=300',
                  alt: 'Secours Populaire Français',
                },
                {
                  image: '//www.zodio.fr/media/saint-vincent.jpg?width=300',
                  alt: 'Société de Saint-Vincent-de-Paul',
                },
              ],
            },
          },

          // RSE
          {
            content: {
              logo: {
                src: '//placehold.co/600x800/jpg',
                alt: 'Bon pour ma planète, bon pour moi. Le pouvoir de faire autrement',
              },
              text: 'Chez Zôdio, nous adoptons des engagements sociaux et environnementaux forts.',
              points: [
                'Le choix des produits positifs',
                'Le bien-être de nos collaborateurs',
                'Des collaborateurs experts',
                'La liberté de mieux consommer',
                'Des actions solidaires toute l’année',
              ],
              image: '//www.zodio.fr/static/version1679058868/frontend/Zodio/2019/fr_FR/Zodio_Animations/images/rse.jpg',
            },
          },

          // LEGEND
          {
            content: {
              text: '(1) À valoir le jour même dans le rayon correspondant aux dons. Offre proportionnelle au poids de vos produits jusqu’à -15 % pour 5 kg et plus. Non cumulable avec toutes autres promotions en cours, soldes et remises fidélité. (2) +10 points lors de l’utilisation du bon de réduction Seconde vie en caisse. Le nombre de participations à l’opération est illimité mais l’ajout de points est limité à 4 par an soit un maximum de 40 points. Les points sont valables 12 mois après le dernier achat. Chaque nouvel ajout de points prolonge la durée de validité de la totalité des points acquis d’un an.',
            },
          },
        ],
      },
    },
  },
};
