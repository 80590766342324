import CtaFaq from './CtaFaq/CtaFaq';
import Mentions from './Mentions/Mentions';
import Modalites from './Modalites/Modalites';
import Retour from './Retour/Retour';
import TitleRetours from './TitleRetours/TitleRetours';
import Fonctionnement from './Fonctionnement/Fonctionnement';

export default {
  CtaFaq,
  Mentions,
  Modalites,
  Retour,
  TitleRetours,
  Fonctionnement,
};
