import React from 'react';
import Link from 'next/link';
import Picto from 'components/elements/Picto/Picto';
import { useRouter } from 'next/router';
import { UserContext } from 'frontastic';

const HeaderPictos = () => {
  const router = useRouter();

  const { user } = React.useContext(UserContext);
  const { store } = React.useContext(UserContext);
  const { cart } = React.useContext(UserContext);

  return (
    <section className="header__pictos">
      {store?.contact?.website ? (
        <Link href={store.contact.website.split('.fr')[1]}>
          <a className="header__pictos__item header__pictos__item--store" aria-label={`Mon magasin de ${store.name}`}>
            <Picto icon="home--bordered" className="header__pictos__item__icon" />
            <span className="header__pictos__item__label">{store.name.toUpperCase()}</span>
          </a>
        </Link>
      ) : (
        <a href="/trouver-un-magasin" className="header__pictos__item header__pictos__item--store" aria-label="Trouver un magasin">
          <Picto icon="home--bordered" className="header__pictos__item__icon" />
          <span className="header__pictos__item__label">Mon magasin</span>
        </a>
      )}

      <Link href={user?.firstname ? '/mon-compte' : { pathname: '/connexion', query: { referer: router?.asPath } }}>
        <a className="header__pictos__item header__pictos__item--user" aria-label={user ? 'Mon compte' : 'Connexion'}>
          <Picto icon="account--bordered" className="header__pictos__item__icon" />
          <span className="header__pictos__item__label">{user?.firstname || 'Connexion'}</span>
        </a>
      </Link>

      <Link href="/checkout/cart">
        <a
          className="header__pictos__item header__pictos__item--cart"
          aria-label={`Mon panier. Il y a ${cart?.length || '0'} produits dans le panier`}
        >
          <Picto icon="shopping-bag" className="header__pictos__item__icon" />

          <span className="header__pictos__item__label" aria-hidden="true">
            Mon panier
          </span>

          {cart?.length > 0 && <span className="header__pictos__item__badge">{cart?.length}</span>}
        </a>
      </Link>
    </section>
  );
};

export default HeaderPictos;
