import Banner from 'components/generic/Banner/Banner';
import HeadBand from 'components/generic/HeadBand/HeadBand';
import Thumbnails from 'components/generic/Thumbnails/Thumbnails';
import SeoBlock from 'components/generic/SeoBlock/SeoBlock';
import Video from 'components/generic/Video/Video';
import Recommendation from 'components/generic/Recommendation/Recommendation';
import MoreIdeas from 'components/generic/MoreIdeas/MoreIdeas';
import { publishItems } from 'helpers/utils/publishItems';
import ContentTiles from 'components/generic/ContentTiles/ContentTiles';

const DynamicLanding = ({ data }) => {
  try {
    const { contentReferences } = data.data.dataSource.fields;

    return (
      <>
        {publishItems(contentReferences).map((content) => {
          const componentGenID = content?.sys?.contentType?.sys?.id;
          const componentData = { data: { dataSource: content } };

          switch (componentGenID) {
            case 'gen_Banner':
            case 'genBannerWoCta':
              return <Banner key={componentGenID} data={componentData} />;

            case 'genHeadband':
              return <HeadBand key={componentGenID} data={componentData} />;

            case 'gen_Thumbnails':
              return <Thumbnails key={componentGenID} data={componentData} />;

            case 'bandeauDoubleEncart':
              return <MoreIdeas key={componentGenID} data={componentData} />;

            case 'genRecommendations':
            case 'genProductRecoWithoutCta':
              return <Recommendation key={componentGenID} data={componentData} />;

            case 'genContentRecoWithoutCta':
              return <ContentTiles key={componentGenID} data={componentData} />;

            case 'genVideo':
              return <Video key={componentGenID} data={componentData} />;

            case 'genSeoText':
              return <SeoBlock key={componentGenID} data={componentData} />;

            default:
              console.warn(`An error occured rendering "${componentGenID}". Please check DynamicContentfull component.`);
              return <></>;
          }
        })}
      </>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default DynamicLanding;
