import Dashboard from './Dashboard/Dashboard';
import InformationsPersonnelles from './InformationsPersonnelles/InformationsPersonnelles';
import Fidelity from './Fidelity/Fidelity';
import Orders from './Orders/Orders';
import Contributions from './Contributions/Contributions';
import GiftsCards from './GiftsCards/GiftsCards';
import Logout from './Logout/Logout';

export default {
  Dashboard,
  InformationsPersonnelles,
  Fidelity,
  Orders,
  Contributions,
  GiftsCards,
  Logout,
};
