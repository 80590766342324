import React from 'react';
import style from './Filters.module.scss';
import Summary from './partials/Summary/Summary';
import RangeSliderFilter from './partials/RangeSliderFilter/RangeSliderFilter';
import CheckboxesFilter from './partials/CheckboxesFilter/CheckboxesFilter';
import MenuFilter from './partials/MenuFilter/MenuFilter';
import { Button } from '@mozaic-ds/react';
import { setFiltersOrder } from './Filters.utils';
import { useCurrentRefinements, useRefinementList } from 'react-instantsearch-hooks-web';

let filterContainer;

export const FiltersButton = () => {
  const { items } = useCurrentRefinements();
  function openFilters() {
    document.body.classList.add('is-blocked');
    filterContainer.setAttribute('data-open', true);
  }

  return (
    <Button className={style.Filters__button} size="s" theme="secondary" width="full" onClick={openFilters}>
      Filtrer {items.length > 0 && <small>{items.length}</small>}
    </Button>
  );
};

const FilterItem = ({ facet }) => {
  const { title, type, attribute } = facet;
  const { canRefine } = useRefinementList({ attribute });

  return !!canRefine ? (
    <div className={style.Filters__item}>
      <h3 className={style.Filters__item__title}>{title}</h3>

      {type === 'range' && <RangeSliderFilter attribute={attribute} />}
      {type === 'checkbox' && <CheckboxesFilter attribute={attribute} />}
      {type === 'list' && <MenuFilter attributes={attribute} />}
    </div>
  ) : null;
};

const Filters = ({ order, type }) => {
  const filters = React.useRef(null);
  const { items } = useCurrentRefinements();

  function closeFilters() {
    document.body.classList.remove('is-blocked');
    filterContainer.setAttribute('data-open', false);
    window.scrollTo(0, filterContainer.getBoundingClientRect().top + window.scrollY);
  }

  React.useEffect(() => {
    filterContainer = filters.current;
  }, []);

  return (
    <div className={style.Filters}>
      <div className={style.Filters__container} ref={filters} data-open={false}>
        <div className={style.Filters__items}>
          {order && order.length > 0 ? (
            <>
              {items.length > 0 && (
                <div className={`${style.Filters__item} ${style['Filters__item--alt']}`}>
                  <h3 className={style.Filters__item__title}>Votre sélection</h3>
                  <Summary />
                </div>
              )}

              {setFiltersOrder(order, type).map((facet) => (
                <FilterItem key={facet.attribute} facet={facet} />
              ))}
            </>
          ) : (
            <h3 className={`${style.Filters__empty}`}>Aucun filtre disponible pour cette catégorie</h3>
          )}
        </div>
      </div>

      <div className={style.Filters__container__close}>
        <Button size="s" theme="primary" width="full" onClick={closeFilters}>
          Voir les résultats
        </Button>
      </div>

      <button className={style.Filters__container__overlay} onClick={closeFilters} aria-label="Voir les résultats" tabIndex="-1" />
    </div>
  );
};

export default Filters;
