import style from './Positionnement.module.scss';
import React from 'react';
import RichText from 'components/elements/contentful/RichText/RichText';

const Positionnement = ({ data }) => {
  try {
    const { title, imageDesktop, paragraph } = data.data.dataSource.fields;

    return (
      <section className={style.Positionnement}>
        <div data-anchor-id="notre-positionnement" data-anchor-label="Notre positionnement" />

        {title && <h2 className={style['Positionnement__title']}>{title}</h2>}

        <div className={style['Positionnement__block']}>
          <div className={style['Positionnement__block__text']}>
            <RichText content={paragraph} />
          </div>

          <div className={style['Positionnement__block__image']}>
            <img src={imageDesktop?.fields?.file?.url} loading="lazy" alt={imageDesktop?.fields?.title} />
          </div>
        </div>
      </section>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default Positionnement;
