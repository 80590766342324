import CheckoutTemplate from '../partials/CheckoutTemplate/CheckoutTemplate';
import AdressList from '../partials/AdressList/AdressList';
import DeliveryMethods from '../partials/DeliveryMethods/DeliveryMethods';
import ValidationButton from '../partials/ValidationButton/ValidationButton';

const Delivery = () => {
  try {
    return (
      <CheckoutTemplate step="delivery">
        <AdressList />
        <DeliveryMethods />
        <ValidationButton label="Suivant" href="/checkout/payment" />
      </CheckoutTemplate>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};
export default Delivery;
