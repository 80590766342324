import style from './EnseigneDeux.module.scss';
import React from 'react';
import RichText from 'components/elements/contentful/RichText/RichText';

const EnseigneDeux = ({ data }) => {
  try {
    const { imageDesktop, paragraph } = data.data.dataSource.fields;

    return (
      <section className={style.EnseigneDeux}>
        <div className={style.EnseigneDeux__block}>
          <div className={style.EnseigneDeux__block__text}>
            <RichText content={paragraph} />
          </div>

          <div className={style.EnseigneDeux__block__image}>
            <img src={imageDesktop?.fields?.file?.url} loading="lazy" alt={imageDesktop?.fields?.title} />
          </div>
        </div>
      </section>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default EnseigneDeux;
