import React from 'react';
import Picto from 'components/elements/Picto/Picto';

// FULLSCREEN BUTTON
export function Close(onClick, disabled) {
  return (
    <button data-action="close" aria-label="Fermer l'affichage en plein écran" onClick={onClick} disabled={!disabled} tabIndex="0">
      <Picto icon="cross" />
    </button>
  );
}

// ARROW LEFT
export function PrevArrow(onClick, disabled) {
  return (
    <button data-action="prev" aria-label="afficher l'image précédente" onClick={onClick} disabled={disabled} tabIndex="0">
      <Picto icon="zodio-arrow--left" />
    </button>
  );
}

// ARROW RIGHT
export function NextArrow(onClick, disabled) {
  return (
    <button data-action="next" aria-label="afficher l'image suivante" onClick={onClick} disabled={disabled} tabIndex="0">
      <Picto icon="zodio-arrow--right" />
    </button>
  );
}
