import style from './TitleWithHousky.module.scss';
import RichText from 'components/elements/contentful/RichText/RichText';

const TitleWithHousky = ({ data }) => {
  try {
    const { title, subTitle, paragraph } = data.data.dataSource.fields;
    return (
      <div className={style.TitleWithHousky}>
        {title && <h1>{title}</h1>}
        {subTitle && <span>{subTitle}</span>}
        {paragraph && <RichText content={paragraph} />}
      </div>
    );
  } catch (e) {
    console.log(e);
    return <></>;
  }
};

export default TitleWithHousky;
