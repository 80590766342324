import React from 'react';
import style from './Thumbnails.module.scss';
import Link from 'next/link';
import { publishItems } from 'helpers/utils/publishItems';

const Thumbnails = ({ data }) => {
  try {
    const { ThumbsStyle = 'default' } = data;
    const { fields } = data.data.dataSource;

    const { title, thumbnails, buttonLabel, buttonHref } = fields;

    return (
      <section className={`${style.Thumbnails}`} data-type={ThumbsStyle}>
        <div className={ThumbsStyle === 'vignette' ? style.Thumbnails__vignette : 'container'}>
          {title && <h2 className={style.Thumbnails__title}>{title}</h2>}

          {thumbnails && (
            <div className={ThumbsStyle === 'vignette' ? style.Thumbnails__thumbVignette : style.Thumbnails__thumbs}>
              {publishItems(thumbnails).map((thumb) => {
                const { url, thumbmail, title } = thumb.fields;
                const imageUrl = thumbmail.fields.file.url;

                const breakpoints = [
                  { media: '(max-width: 800px) and (-webkit-min-device-pixel-ratio: 1)', srcSet: imageUrl + '?w=350' },
                  { media: '(max-width: 800px) and (-webkit-min-device-pixel-ratio: 2)', srcSet: imageUrl + '?w=700' },
                  { media: '(max-width: 1100px) and (-webkit-min-device-pixel-ratio: 1)', srcSet: imageUrl + '?w=335' },
                  { media: '(max-width: 1100px) and (-webkit-min-device-pixel-ratio: 2)', srcSet: imageUrl + '?w=670' },
                  { media: '(max-width: 1500px) and (-webkit-min-device-pixel-ratio: 1)', srcSet: imageUrl + '?w=270' },
                  { media: '(max-width: 1500px) and (-webkit-min-device-pixel-ratio: 2)', srcSet: imageUrl + '?w=540' },
                ];

                const Thumbmail = () => (
                  <article className={style.Thumbnail}>
                    <picture>
                      {breakpoints.map((breakpoint, i) => (
                        <source key={i} {...breakpoint} />
                      ))}
                      <img className={style.Thumbnail__image} src={imageUrl + '?w=540'} alt="" />
                    </picture>
                    <h3 className={style.Thumbnail__title}>{title}</h3>
                  </article>
                );

                return url ? (
                  <Link href={url} key={thumb.sys.id}>
                    <a>
                      <Thumbmail />
                    </a>
                  </Link>
                ) : (
                  <Thumbmail key={thumb.sys.id} />
                );
              })}
            </div>
          )}

          {buttonLabel && buttonHref && (
            <div className={style.Thumbnails__button}>
              <Link href={buttonHref}>
                <a target="_blank">{buttonLabel}</a>
              </Link>
            </div>
          )}
        </div>
      </section>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default Thumbnails;
