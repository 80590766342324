import style from './ImageTop.module.scss';

const ImageTop = ({ data }) => {
  try {
    const { title, imageDesktop, imageMobile } = data.data.dataSource.fields;

    const imageMobileUrl = imageMobile.fields.file.url;
    const imageDesktopUrl = imageDesktop.fields.file.url;

    const breakpoints = [
      { media: '(max-width: 800px) and (-webkit-min-device-pixel-ratio: 1)', srcSet: imageMobileUrl + '?w=850' },
      { media: '(max-width: 800px) and (-webkit-min-device-pixel-ratio: 2)', srcSet: imageMobileUrl + '?w=1700' },
      { media: '(min-width: 800px) and (-webkit-min-device-pixel-ratio: 1)', srcSet: imageDesktopUrl + '?w=1195' },
      { media: '(min-width: 800px) and (-webkit-min-device-pixel-ratio: 2)', srcSet: imageDesktopUrl + '?w=2390' },
    ];

    return (
      <section className={style.ImageTop}>
        <picture>
          {breakpoints.map((breakpoint, i) => (
            <source key={i} {...breakpoint} />
          ))}
          <img className={style.ImageTop__picture} src={imageDesktopUrl + '?w=1195'} alt={title} />
        </picture>
      </section>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default ImageTop;
