import WorkshopBanner from './WorkshopBanner/WorkshopBanner';
import WorkshopCards from './WorkshopCards/WorkshopCards';
import WorkshopGiftcard from './WorkshopGiftcard/WorkshopGiftcard';
import WorkshopLoyalty from './WorkshopLoyalty/WorkshopLoyalty';

export default {
  WorkshopBanner,
  WorkshopCards,
  WorkshopGiftcard,
  WorkshopLoyalty,
};
