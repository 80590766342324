import React, { useContext, useEffect } from 'react';
import style from './Dashboard.module.scss';
import { Button } from '@mozaic-ds/react';
import { useRouter } from 'next/router';
import LoyaltyAccount from '../partials/LoyaltyAccount/LoyaltyAccount';
import BreadcrumbAccount from '../partials/BreadcrumbAccount/BreadcrumbAccount';
import Navigation from '../partials/Navigation/Navigation';
import Picto from 'components/elements/Picto/Picto';
import BlocOrders from './partials/BlocOrders/BlocOrders';
import BlocRetours from './partials/BlocRetours/BlocRetours';
import BlocGiftcards from './partials/BlocGiftcards/BlocGiftcards';
import mocks from '../account-mock';
import { UserContext } from '../../../../frontastic';
import Link from 'next/link';

const Dashboard = ({ data }) => {
  const { default_billing, addresses, extension_attributes, firstname, lastname, email } = mocks;
  const { management_point_of_sale, customer } = extension_attributes?.loyalty_card;
  const defaultAdress = addresses.find((address) => address.id === default_billing);
  const router = useRouter();

  const { user } = useContext(UserContext);

  useEffect(() => {
    if (!user) router.push('/connexion');
  }, []);

  if (!user) return <></>;

  const orders = [
    {
      date: '2005-02-04',
      id: 1007689896,
      price: '13.90€',
      flag: 'annule',
    },
    {
      date: '2005-02-17',
      id: 1007689870,
      price: '24.90€',
      flag: 'en cours',
    },
  ];
  return (
    <>
      <BreadcrumbAccount />
      <div className={style['dashboard']}>
        <div className={style['dashboard__navigation']}>
          <Navigation />
        </div>
        <div className={style['dashboard__personal']}>
          <div className={style['dashboard__personal__header']}>
            <div className={style['dashboard__personal__header__infos']}>
              <h2>Bonjour {user?.firstname}</h2>
              <ul>
                <li>
                  <Picto icon="mail" /> <span>{user?.email}</span>
                </li>
                <li>
                  <Picto icon="phone--bordered" /> <span>06 29 79 34 16</span>
                </li>
                <li>
                  <Picto icon="home--bordered" />{' '}
                  <span>
                    {defaultAdress.postcode} {defaultAdress.city}
                  </span>
                </li>
              </ul>
            </div>
            <div className={style['dashboard__personal__header__link']}>
              <Link href={'/mon-compte/mes-informations'}>
                <Button size="full" theme="secondary" href={'/mon-compte/mes-informations'}>
                  Modifier mes informations
                </Button>
              </Link>
            </div>
          </div>
          <div className={style['dashboard__personal__blocs']}>
            <BlocOrders orders={orders} />
            <BlocRetours />
            <BlocGiftcards />
          </div>
          <div className={style['dashboard__personal__fidelity']}>
            {management_point_of_sale ? <LoyaltyAccount fidelity={management_point_of_sale} id={customer} type="dashboard" /> : <LoyaltyAccount />}
          </div>
        </div>
      </div>
    </>
  );
};
export default Dashboard;
