import style from './AddressForm.module.scss';
import { Button, CheckBox, Field, Radio, RadioGroup, Select, TextInput } from '@mozaic-ds/react';
import React, { useRef, useState } from 'react';
import Picto from 'components/elements/Picto/Picto';
import { useForm } from 'react-hook-form';
import { rules } from 'helpers/utils/formRules';
import alert from 'helpers/utils/alert';

const AddressForm = ({ adrressChange }) => {
  const { firstname, lastname, postcode, city, street } = adrressChange;
  const form = useRef();
  const [hideForm, setHideForm] = useState(false);
  const [validAddress, setValidAddress] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      firstname: firstname,
      lastname: lastname,
      code: postcode,
      road: street,
      city: city,
    },
  });
  const onSubmit = (data) => {
    console.log(data);
    setHideForm(true);
    setValidAddress(true);
  };

  React.useEffect(() => {
    validAddress && alert('success', 'Vos informations ont été modifiées', 5000);
  }, [validAddress]);

  return (
    <>
      {!hideForm ? (
        <form ref={form} onSubmit={handleSubmit(onSubmit)} className={style['addressForm']}>
          <h3>Ajouter une nouvelle adresse</h3>
          <Field className={style['addressForm__gender-form']} htmlFor="gender" label="Civilité*" error={errors?.gender?.message}>
            <RadioGroup name="gender">
              <Radio className="radio-group-item mb-2" {...register('M.')}>
                M.
              </Radio>
              <Radio className="radio-group-item" {...register('Mme')}>
                Mme
              </Radio>
            </RadioGroup>
          </Field>
          <div className={style['addressForm__fields']}>
            <Field htmlFor="firstname" className="mt-[2rem]" label="Prénom*" error={errors?.firstname?.message}>
              <TextInput
                id="firstname"
                {...register('firstname', rules.firstname)}
                isInvalid={errors.firstname}
                size="m"
                isValid={isSubmitted && !errors.firstname}
                placeholder="Prénom"
              />
            </Field>

            <Field className="mt-[2rem]" htmlFor="lastname" label={`Nom${rules.lastname.required ? '*' : ''}`} error={errors?.lastname?.message}>
              <TextInput
                id="lastname"
                {...register('lastname', rules.lastname)}
                isInvalid={errors.lastname}
                size="m"
                isValid={isSubmitted && !errors.lastname}
                placeholder="Nom"
              />
            </Field>
            <Field htmlFor="road" className="mt-[2rem]" label="N° et libellé de la voie*" error={errors?.road?.message}>
              <TextInput
                id="road"
                {...register('road', rules.road)}
                isInvalid={errors.road}
                size="m"
                isValid={isSubmitted && !errors.road}
                placeholder="N° et libellé de la voie*"
              />
            </Field>
            <Field htmlFor="complement" className="mt-[2rem]" label="Complément d'adresse">
              <TextInput id="complement" {...register('complement')} size="m" placeholder="Complément d'adresse" />
            </Field>
            <Field htmlFor="entree" className="mt-[2rem]" label="Entrée-Bât-Escalier">
              <TextInput id="entree" {...register('entree')} size="m" placeholder="Entrée-Bât-Escalier" />
            </Field>
            <Field className="mt-[2rem]" htmlFor="city" label="Ville*" error={errors?.city?.message}>
              <TextInput
                id="city"
                {...register('city', rules.city)}
                isInvalid={errors.city}
                size="m"
                isValid={isSubmitted && !errors.city}
                placeholder="Ville"
              />
            </Field>
            <Field htmlFor="code" label="Code postal*" error={errors?.road?.message}>
              <TextInput
                id="code"
                {...register('code', rules.code)}
                isInvalid={errors.code}
                size="m"
                isValid={isSubmitted && !errors.code}
                placeholder="Code postal*"
              />
            </Field>
            <Field>
              <Select
                className="mt-[2.2rem]"
                onChange={function noRefCheck() {}}
                options={[
                  {
                    label: 'France',
                    value: 'french',
                  },
                  {
                    label: 'Allemagne',
                    value: 'deutsch',
                  },
                ]}
                size="m"
                value="french"
              />
            </Field>
            <div className={style['addressForm__fields__checks']}>
              <CheckBox {...register('billing')}>Utiliser comme adresse de facturation par défaut</CheckBox>
              <CheckBox {...register('delivery')}>Utiliser comme adresse de livraison par défaut</CheckBox>
            </div>
          </div>
          <div className={style['addressForm__button']}>
            <Button width="full" type="submit" disabled={Object.keys(errors).length}>
              <Picto icon="check" className="mr-[4rem]" /> Sauvegarder
            </Button>
          </div>
        </form>
      ) : (
        <></>
      )}
    </>
  );
};

export default AddressForm;
