import React from 'react';
import style from './Univers.module.scss';
import Slider from 'components/elements/Slider/Slider';
import Link from 'next/link';
import { Button } from '@mozaic-ds/react';
import { publishItems } from 'helpers/utils/publishItems';

const Univers = ({ data }) => {
  try {
    const { fields } = data.data.dataSource;
    const { univers } = fields;

    return (
      <section className={style.Univers}>
        <div className={`${style.Univers__container}`}>
          <h2 className={style.Univers__title}>{fields.title}</h2>
          <div className={style.Univers__subtitle}>{fields.subtitle}</div>

          <div className={style.Univers__slider}>
            <Slider
              breakpoints={{
                320: { slidesPerView: 1 },
                581: { slidesPerView: 2 },
                801: { slidesPerView: 3 },
                1100: { slidesPerView: 4 },
              }}
            >
              {publishItems(univers).map((item, i) => {
                const { thumbmail, title, url } = item.fields;
                const { id } = item.sys;

                return (
                  <Link href={url} key={id}>
                    <a className={style.Univers__slider__item} data-is-last={i === univers.length - 1}>
                      <img className={style.Univers__slider__item__image} loading="lazy" src={thumbmail.fields.file.url} alt={title} />
                      <div className={style.Univers__slider__item__button}>
                        <Button theme="invert" width="full">
                          {title}
                        </Button>
                      </div>
                    </a>
                  </Link>
                );
              })}
            </Slider>
          </div>
        </div>
      </section>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default Univers;
