import React from 'react';
import style from './TutoSteps.module.scss';
import RichText from 'components/elements/contentful/RichText/RichText';
import { publishItems } from 'helpers/utils/publishItems';

const TutoSteps = ({ data }) => {
  const { editoType, reciepeTitle, contentReferences } = data.data.dataSource.fields;
  const edito = editoType.toString();

  return (
    <section className={style.TutoSteps}>
      <h2 className={style.TutoSteps__title}>{`Étapes de réalisation ${edito === 'Recette' ? 'de la recette' : 'du tuto'}  ${reciepeTitle}`}</h2>

      {publishItems(contentReferences).length > 0 && (
        <ul className={style.TutoSteps__list}>
          {publishItems(contentReferences).map((content, i) => (
            <li key={i} className={style.TutoSteps__list__item}>
              <h3 className={style.TutoSteps__list__item__title}>{content.fields.step}</h3>
              <div className={style.TutoSteps__list__item__bloc}>
                {content.fields.image && <img src={content.fields.image.fields.file.url} alt="" />}
                <RichText content={content.fields.paragraph} />
              </div>
            </li>
          ))}
        </ul>
      )}
    </section>
  );
};

export default TutoSteps;
