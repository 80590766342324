import style from './Navigation.module.scss';
import Link from 'next/link';
import { useRouter } from 'next/router';
import Picto from 'components/elements/Picto/Picto';
import { UserContext, useZodioAccount } from '../../../../../frontastic';
import { useContext } from 'react';

const Navigation = () => {
  const router = useRouter();

  const { logout } = useZodioAccount();
  const { deleteUser } = useContext(UserContext);

  const handleLogout = async () => {
    await logout();
    deleteUser();
    router.push('/mon-compte/logout');
  };

  const links = [
    {
      url: '/mon-compte',
      name: 'Mon tableau de bord',
      picto: 'home--bordered',
    },
    {
      url: '/mon-compte/mes-informations',
      name: 'Mes informations',
      picto: 'account',
    },
    {
      url: '/mon-compte/mes-commandes',
      name: 'Mes commandes',
      picto: 'cart',
    },
    /* {
      url: '/mon-compte/mes-contributions',
      name: 'Mes contributions',
      picto: 'chat',
    },*/
    {
      url: '/mon-compte/mon-programme-fidelite',
      name: 'Mon programme de fidélité',
      picto: 'star--bordered',
    },
    {
      url: '/mon-compte/mes-cartes-cadeaux',
      name: 'Mes cartes cadeaux',
      picto: 'gift',
    },
  ];

  return (
    <div className={style['Navigation']}>
      <div className={style['Navigation__title']}>
        <h1>Mon compte</h1>
        <div className={style['Navigation__title__logout']} onClick={handleLogout}>
          Me déconnecter
        </div>
      </div>
      <nav className={style['Navigation__links']}>
        <ul>
          {links.map((link, index) => {
            const { url, picto, name } = link;
            return (
              <li key={index} className={router.asPath.split('?')[0] === url ? style['Navigation__links__active'] : ''}>
                <Link href={url}>
                  <a>
                    <Picto className={style['Navigation__links__icon']} icon={picto} /> <span>{name}</span>
                  </a>
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
};

export default Navigation;
