import style from './WorkshopsBanner.module.scss';
import RichText from 'components/elements/contentful/RichText/RichText';

const WorkshopsBanner = ({ data }) => {
  try {
    const { title, paragraph, banner } = data?.data?.dataSource?.fields || {};
    const { url } = banner.fields.file;

    return (
      <section className={style.WorkshopsBanner}>
        <div className={style.WorkshopsBanner__text}>
          <h1 className={style.WorkshopsBanner__text__title}>{title}</h1>
          <RichText content={paragraph} />
        </div>

        <img className={style.WorkshopsBanner__image} src={url + '?h=320&w=470'} alt="" />
      </section>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default WorkshopsBanner;
