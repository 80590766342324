export default function calculateStrength(password = '') {
  if (!password.length) return [0, 'Pas de mot de passe'];

  let points = 0;
  if (password.match(/[a-z]/gi)) points++; // Password has at least one lowercase
  if (password.match(/[A-Z]/gi)) points++; // Password has at least one uppercase
  if (password.match(/[0-9]/gi)) points++; // Password has at least one number
  if (password.match(/[#?!@$%^&*-]/gi)) points++; // Password has at least one special caracter

  if (points < 4 || password.length < 8) return [1, 'Faible'];
  if (points >= 4 && password.length >= 8 && password.length < 12) return [2, 'Moyen'];
  if (points >= 4 && password.length >= 12 && password.length < 15) return [3, 'Sécurisé'];
  if (points >= 4 && password.length >= 15) return [4, 'Très sécurisé'];
}
