import style from './Steps.module.scss';
import Picto from '../Picto/Picto';
import { ProgressBar } from '@mozaic-ds/react';
import React from 'react';

const Steps = (props) => {
  const { status, val } = props;
  return (
    <div className={style.Steps}>
      <h3>Suivi de votre commande</h3>
      <div className={style.Steps__check}>
        {status.map((item, index) => {
          return (
            <div key={index}>
              {item.state ? (
                <Picto className={style.Steps__check__checked} icon="check" />
              ) : (
                <Picto className={style.Steps__check__notChecked} icon="cross" />
              )}
              <p>{item.label}</p>
            </div>
          );
        })}
      </div>
      <div>
        <ProgressBar value={val} size="m" theme="default" />
      </div>
    </div>
  );
};

export default Steps;
