import FooterBlock from './FooterBlock/FooterBlock';
import FooterMenu from './FooterMenu/FooterMenu';
import FooterNewsletter from './FooterNewsletter/FooterNewsletter';
import FooterSocial from './FooterSocial/FooterSocial';
import { publishItems } from 'helpers/utils/publishItems';

const Footer = ({ data }) => {
  try {
    const footerData = data?.data?.dataSource?.fields;
    return (
      <footer className="footer">
        <section className="footer__columns container" role="navigation">
          <div className="footer__column footer__column--social">
            <FooterBlock title={footerData.socialLinks.fields.title}>
              <FooterSocial data={footerData.socialLinks.fields} />
            </FooterBlock>

            <FooterBlock title={footerData.newsletter.fields.title}>
              <FooterNewsletter data={footerData.newsletter.fields} />
            </FooterBlock>
          </div>

          {publishItems(footerData.menuFooter).map((menuColumn) => {
            const { title, linksColonnes } = menuColumn?.fields;
            return (
              <div className="footer__column" key={`footer-column--${title}`}>
                <FooterBlock title={title} isAccordion>
                  <FooterMenu links={linksColonnes} />
                </FooterBlock>
              </div>
            );
          })}
        </section>

        <section className="footer__copyright">
          <div className="container">{footerData.copyright}</div>
        </section>
      </footer>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default Footer;
