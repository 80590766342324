import style from './ProductVariants.module.scss';
import Link from 'next/link';

const valueMock = [
  { value: '32cl', type: 'value', href: '/test' },
  { value: '37cl', type: 'value', href: '/test' },
  { value: '40cl', type: 'value', href: '/test' },
  { value: '47cl', type: 'value', href: '/test' },
  { value: '55cl', type: 'value', href: '/test' },
  { value: '32cl', type: 'value', href: '/test' },
];

// const colorMock = [
//   {
//     value: 'Vert',
//     background: 'https://www.zodio.fr/media/attribute/swatch/swatch_thumb/110x90/v/a/variant-vert.png',
//     type: 'color',
//     href: '/test',
//   },
//   {
//     value: 'Bleu',
//     background: 'https://www.zodio.fr/media/attribute/swatch/swatch_thumb/110x90/p/r/primaire_bleu.png',
//     type: 'color',
//     href: '/test',
//   },
//   {
//     value: 'Gris',
//     background: 'https://www.zodio.fr/media/attribute/swatch/swatch_thumb/110x90/v/a/variant-gris.png',
//     type: 'color',
//     href: '/test',
//   },
//   {
//     value: 'Jaune',
//     background: 'https://www.zodio.fr/media/attribute/swatch/swatch_thumb/110x90/v/a/variant-jaune.png',
//     type: 'color',
//     href: '/test',
//   },
// ];

const ProductVariants = () => {
  return (
    <>
      {/* Variant type value */}
      <ul className={style.ProductVariants} data-type="value">
        {valueMock.map((item, i) => (
          <li key={`value--${i}`}>
            <Link href={item.href}>
              <a disabled={i === 0} aria-label={`voir le variant`}>
                {item.value}
              </a>
            </Link>
          </li>
        ))}
      </ul>

      {/* Variant type color */}
      {/* <ul className={style.ProductVariants} data-type="color">
        {colorMock.map((item, i) => (
          <li key={`color--${i}`}>
            <Link href={item.href}>
              <a disabled={i === 1} aria-label={`voir le variant`}>
                <span style={{ backgroundImage: `url(${item.background})` }} />
                <span>{item.value}</span>
              </a>
            </Link>
          </li>
        ))}
      </ul> */}
    </>
  );
};

export default ProductVariants;
