import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import Price from '../../../../elements/Price/Price';
import { Button } from '@mozaic-ds/react';
import Picto from '../../../../elements/Picto/Picto';
import style from './WorkshopStickyBanner.module.scss';

const WorkshopStickyBanner = () => {
  const { register, handleSubmit } = useForm();
  const [bannerVisible, setBannerVisible] = useState(false);
  const stick = useRef();
  const bannerTop = stick.current?.offsetTop || 500;
  useEffect(() => {
    window.addEventListener('scroll', () => setBannerVisible(document.documentElement.scrollTop > bannerTop));
    return () => window.removeEventListener('scroll', () => setBannerVisible(false));
  }, [bannerTop]);
  const onSubmit = (data) => {
    console.log(data);
  };
  return bannerVisible ? (
    <form className={style.WorkshopStickyBanner} ref={stick} onSubmit={handleSubmit(onSubmit)}>
      <div className={style.WorkshopStickyBanner__bloc}>
        <div className={style.WorkshopStickyBanner__bloc__title}>
          <img
            src="https://photos.zodio.fr/zodio-magento/catalog/products/source/0/0/0026102293355_1_A.jpg?height=70&width=70&fit=bounds&bg-color=fff"
            alt=""
          />
          <p>Couronne Design</p>
          <Price size="m" data={{ default_original_formated: '79,95 €', default_formated: '39,90 €' }} />
        </div>
        <input type="hidden" {...register('qty')} />
        <input type="hidden" value="511515656161" {...register('sku')} />
        <input type="hidden" value="76590" {...register('atelier')} />
        <input type="hidden" value="76590" {...register('item')} />
        <Button type="submit" theme="primary">
          <span>Ajouter au panier</span> <Picto icon="cart" />
        </Button>
      </div>
    </form>
  ) : (
    <></>
  );
};

export default WorkshopStickyBanner;
