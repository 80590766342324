import style from './BlockInformation.module.scss';
import Infos from '../cardInfos/Infos/Infos';
import InfosForm from '../cardInfos/InfosForm/InfosForm';
import Password from '../cardPassword/Password/Password';
import PasswordForm from '../cardPassword/PasswordForm/PasswordForm';
import Communication from '../cardCommunication/Communication/Communication';
import Blog from '../cardBlog/Blog/Blog';
import BlogForm from '../cardBlog/BlogForm/BlogForm';
import Address from '../cardAddress/Address/Address';
import { useState } from 'react';

const BlockInformation = ({ type, firstname, lastname, email, address, prefix }) => {
  const [showForm, setShowForm] = useState(false);
  return (
    <div className={style['blockInformation']}>
      {type === 'infos' && (
        <>
          {!showForm ? (
            <Infos firstname={firstname} lastname={lastname} email={email} prefix={prefix} onClick={() => setShowForm(true)} />
          ) : (
            <InfosForm />
          )}
        </>
      )}
      {type === 'password' && <>{!showForm ? <Password onClick={() => setShowForm(true)} /> : <PasswordForm />}</>}
      {type === 'communication' && (
        <>
          <Communication />
        </>
      )}
      {type === 'blog' && <>{!showForm ? <Blog onClick={() => setShowForm(true)} /> : <BlogForm />}</>}
      {type === 'address' && (
        <>
          <Address addresses={address} />
        </>
      )}
    </div>
  );
};

export default BlockInformation;
