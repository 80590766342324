import React from 'react';
import Picto from 'components/elements/Picto/Picto';
import { publishItems } from 'helpers/utils/publishItems';

const FooterSocial = ({ data }) => {
  try {
    return (
      <div className="footer__social">
        <span className="footer__social__baseline">{data.subtitle}</span>

        <ul className="footer__social__pictos">
          {publishItems(data.links).map((link) => {
            const { idIconSocial, url } = link?.fields;
            return (
              <li key={`social-link--${idIconSocial}`}>
                <a href={url} target="_blank" rel="noreferrer noopener" aria-label={`suivre nous sur ${idIconSocial}`}>
                  <Picto icon={idIconSocial} />
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default FooterSocial;
