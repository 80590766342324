export default [
  {
    id: 351,
    type: 'relay',
    defaultSelected: false,
    name: 'Livraison en point relais',
    subtitle: 'Livraison Mondial Relay',
    from: '25 Juin 2023',
    price: 0,
  },
  {
    id: 687,
    type: 'home',
    defaultSelected: true,
    name: 'Livraison standard à domicile',
    subtitle: 'Livraison Colissimo à votre domicile',
    from: '22 Juin 2023',
    price: 0,
  },
  {
    id: 198,
    type: 'home',
    defaultSelected: false,
    name: 'Livraison rapide à domicile',
    subtitle: 'Livraison rapide à domicile',
    image: 'https://www.zodio.fr/static/version1687249785/frontend/Zodio/2019/fr_FR/Chronopost_Chronorelais/images/chronopost.png',
    from: '22 Juin 2023',
    price: 9.99,
  },
  {
    id: 889,
    type: 'store',
    defaultSelected: false,
    name: 'Retrait en magasin',
    subtitle: 'Retrait en magasins',
    from: '22 Juin 2023',
    price: 0,
  },
];
