import React from 'react';
import style from './CheckoutSummary.module.scss';
import Picto from 'components/elements/Picto/Picto';
import Link from 'next/link';
import { useForm } from 'react-hook-form';
import { Button, Field, TextInput, Accordion, AccordionHeader, AccordionHeading, AccordionContent } from '@mozaic-ds/react';
import { rules } from 'helpers/utils/formRules';

const CheckoutSummary = ({ step }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onSubmit', resetOptions: 'onSubmit' });

  const onSubmit = (data) => {
    console.log('promotion', data);
  };

  try {
    return (
      <div className={style.CheckoutSummary}>
        <h2 className={style.CheckoutSummary__title}>Détails de la commande</h2>
        <p className={style.CheckoutSummary__line}>
          <span>
            <strong>Sous-total</strong>
          </span>
          <span>550,75€</span>
        </p>

        {step === 'cart' ? (
          <div className={style.CheckoutSummary__delivery}>
            <p className={style.CheckoutSummary__line}>
              <span>
                Livraison <small>Ou livraison express à domicile pour 9€90</small>
              </span>

              <span>OFFERT</span>
            </p>

            <p className={style.CheckoutSummary__line}>
              <span>
                Plus que <b>34,00 €</b> pour bénéficier de la <b>livraison offerte</b>
              </span>
            </p>

            <p className={style.CheckoutSummary__line}>
              <span>
                <div className={style.CheckoutSummary__delivery__progress}>
                  <Picto icon="truck" />
                  <div className={style.CheckoutSummary__delivery__progress__bar}>
                    <span style={{ width: '20%' }}>20%</span>
                  </div>
                </div>
              </span>
            </p>

            <hr />

            <p className={`${style.CheckoutSummary__line} ${style['CheckoutSummary__line--total']}`}>
              <span>
                <strong>Total</strong>
              </span>
              <span>
                <strong>550,75€</strong>
              </span>
            </p>

            <Link href="/checkout/delivery">
              <Button href="/checkout/delivery" className="mt-[1.6rem]" width="full" theme="primary">
                Choisir ma livraison
              </Button>
            </Link>

            <form onSubmit={handleSubmit(onSubmit)} className={style.CheckoutSummary__delivery__promo}>
              <label htmlFor="code-promo" className="sr-only">
                Votre code promo
              </label>
              <Field error={errors?.promo?.message}>
                <TextInput
                  id="code-promo"
                  type="text"
                  placeholder="Votre code promo"
                  autoComplete="off"
                  {...register('promo', rules.promo)}
                  isInvalid={errors.promo}
                />

                <Button type="submit" title="Soumettre votre code de promotion">
                  <Picto icon="arrow--right" />
                </Button>
              </Field>
            </form>
          </div>
        ) : (
          <>
            <div className={style.CheckoutSummary__items}>
              <Accordion size="s">
                <AccordionHeader>
                  <AccordionHeading>Détail des articles</AccordionHeading>
                </AccordionHeader>
                <AccordionContent>
                  <ul className={style.CheckoutSummary__items__list}>
                    {[...Array(8)].map((_, i) => (
                      <li key={i} className={style.CheckoutSummary__items__list__item}>
                        <img className={style.CheckoutSummary__items__list__item__image} src="https://placehold.co/50x50/png" alt="" />

                        <div className={style.CheckoutSummary__items__list__item__details}>
                          <strong>Mon super produit</strong>
                          <span>quantité: 1 </span>
                        </div>

                        <strong className={style.CheckoutSummary__items__list__item__price}>27,50€</strong>
                      </li>
                    ))}
                  </ul>
                </AccordionContent>
              </Accordion>
            </div>

            <hr />

            <p className={style.CheckoutSummary__line}>
              <Link href="/checkout/cart">
                <a>Afficher et modifier le panier</a>
              </Link>
            </p>

            <p className={style.CheckoutSummary__line}>
              <span>Frais de livraison</span>
              <span>
                <strong>9,90€</strong>
              </span>
            </p>

            <hr />

            <p className={`${style.CheckoutSummary__line} ${style['CheckoutSummary__line--total']}`}>
              <span>
                <strong>Total</strong>
              </span>
              <span>
                <strong>550,75€</strong>
              </span>
            </p>
          </>
        )}
      </div>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default CheckoutSummary;
