import { publishItems } from 'helpers/utils/publishItems';
import Recommendation from 'components/generic/Recommendation/Recommendation';
import SeoBlock from 'components/generic/SeoBlock/SeoBlock';
import BrandBanner from './tastics/BrandBanner/BrandBanner';
import BrandContent from './tastics/BrandContent/BrandContent';
import ContentTiles from 'components/generic/ContentTiles/ContentTiles';

const DynamicBrand = ({ data }) => {
  try {
    const { contentReferences } = data.data.dataSource.fields;

    return (
      <>
        {publishItems(contentReferences).map((content) => {
          const componentGenID = content?.sys?.contentType?.sys?.id;
          const componentData = { data: { dataSource: content } };

          switch (componentGenID) {
            case 'gen_Banner':
            case 'genBannerWoCta':
              return <BrandBanner key={componentGenID} data={componentData} />;

            case 'brandPremiumPageBrandContent':
              return <BrandContent key={componentGenID} data={componentData} />;

            case 'genRecommendations':
            case 'genProductRecoWithoutCta':
              return <Recommendation key={componentGenID} data={componentData} />;

            case 'genContentRecoWithoutCta':
              return <ContentTiles key={componentGenID} data={componentData} />;

            case 'genSeoText':
              return <SeoBlock key={componentGenID} data={componentData} />;

            default:
              console.warn(`An error occured rendering "${componentGenID}". Please check DynamicContentfull component.`);
              return <></>;
          }
        })}
      </>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default DynamicBrand;
