import style from './MarqueUne.module.scss';
import { Button } from '@mozaic-ds/react';

const MarqueUne = ({ data }) => {
  try {
    const { title, image, cta } = data.data.dataSource.fields;

    return (
      <div className={style.MarqueUne}>
        <div className={style.MarqueUne__container}>
          <h3 className={style.MarqueUne__title}>{title}</h3>

          <img className={style.MarqueUne__image} alt={image.fields.title} src={image.fields.file.url} />

          <div className={style.MarqueUne__button}>
            <Button theme="primary" width="full" href={cta.fields.url} className={style.MarqueUne__button}>
              {title}
            </Button>
          </div>
        </div>
      </div>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default MarqueUne;
