import WorkshopIcons from './WorkshopIcons/WorkshopIcons';
import WorkshopInfos from './WorkshopInfos/WorkshopInfos';
import WorkshopStorePlace from './WorkshopStorePlace/WorkshopStorePlace';
import WorkshopTabs from './WorkshopTabs/WorkshopTabs';
import WorkshopATC from './WorkshopATC/WorkshopATC';
import WorkshopStickyBanner from './WorkshopStickyBanner/WorkshopStickyBanner';

export default {
  WorkshopIcons,
  WorkshopInfos,
  WorkshopStorePlace,
  WorkshopTabs,
  WorkshopATC,
  WorkshopStickyBanner,
};
