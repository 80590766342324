import React from 'react';
import Picto from 'components/elements/Picto/Picto';
import { useRouter } from 'next/router';
import { buildMenu, showOnMobile, hideOnMobile } from 'helpers/utils/menu';
import HeaderMenuItem from './partials/HeaderMenuItem/HeaderMenuItem';

// MOCKED MENU (Waiting for BS to merge algolia and contentfull)
import mockedResponseFromBS from '../BS.mock';

const HeaderMenu = ({ data = mockedResponseFromBS }) => {
  const router = useRouter();
  const { root, entries } = JSON.parse(JSON.stringify(data));
  const menu = React.useMemo(() => buildMenu(root, entries));

  // Reset Nav after page change
  const [key, setKey] = React.useState(Date.now());
  React.useEffect(() => setKey(Date.now()), [router.query.slug]);

  try {
    return (
      <section className="header__menu">
        <button className="header__menu__burger" onClick={showOnMobile}>
          <Picto icon="burger" aria-label="ouvrir le menu principal" />
        </button>

        <div className="header__menu__overlay" data-visible="false" onClick={hideOnMobile} />

        {menu && (
          <nav key={key} className="header__menu__navigation" data-visible="false" aria-label="Menu principal">
            <div className="header__menu__navigation__close">
              <button onClick={hideOnMobile} aria-label="fermer le menu principal">
                <Picto icon="cross" />
              </button>
            </div>

            <ul className="header__menu__navigation__items">
              {menu.map((item) => (
                <HeaderMenuItem key={item.id} item={item} />
              ))}
            </ul>
          </nav>
        )}
      </section>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default HeaderMenu;
