import style from './Legend.module.scss';

const Legend = ({ data }) => {
  const { text } = data.content;

  return (
    <section className={style.Legend}>
      <div className="container">{text}</div>
    </section>
  );
};

export default Legend;
