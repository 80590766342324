import style from './StoreServices.module.scss';
import Link from 'next/link';

import EReservationPicto from './images/e-reservation.svg';
import ReturnPurchasePicto from './images/return-purchase.svg';
import SecondLifePicto from './images/second-life.svg';

const StoreServices = () => {
  return (
    <section className={style.StoreServices}>
      <div className={style.StoreServices__content}>
        <h2 className={style.StoreServices__content__title}>
          Les services <span>de votre magasin</span>
        </h2>

        <ul className={style.StoreServices__content__list}>
          <li className={style.StoreServices__content__list__item}>
            <Link href="/retrait-magasin">
              <a className={style.StoreServices__service}>
                <EReservationPicto />
                <strong>e-réservation</strong>
                <p>Faites votre choix sur le site et retirez vos articles 2h plus tard</p>
              </a>
            </Link>
          </li>

          <li className={style.StoreServices__content__list__item}>
            <Link href="/retour">
              <a className={style.StoreServices__service}>
                <ReturnPurchasePicto />
                <strong>Retours achats</strong>
                <p>Commandez en ligne sans crainte, et retournez vos produits en magasin</p>
              </a>
            </Link>
          </li>

          <li className={style.StoreServices__content__list__item}>
            <Link href="/seconde-vie">
              <a className={style.StoreServices__service}>
                <SecondLifePicto />
                <strong>Seconde vie</strong>
                <p>Apportez les objets qui ne vous servent plus et recevez des réductions</p>
              </a>
            </Link>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default StoreServices;
