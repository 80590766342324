import style from './BlocOrders.module.scss';
import { Button, Flag } from '@mozaic-ds/react';
import React from 'react';
import Picto from '../../../../../elements/Picto/Picto';
import Link from 'next/link';

const mock = {
  title: 'Mes dernieres commandes',
  titleHousky: 'Mince',
  subtitle: "Il semblerait que vous n'ayez pas encore trouvé votre bonheur",
  url: 'zodio.fr',
  cta: 'passez une commande',
};
const BlocOrders = ({ orders }) => {
  return (
    <div className={style.BlocOrders}>
      <div>
        <h2>{mock.title}</h2>
        {orders?.length ? (
          <>
            <div className={style.BlocOrders__head}>
              <p>Date</p>
              <p className="ml-8">Commande</p>
              <p>Prix total</p>
              <p>Detail</p>
            </div>
            {orders.map((order) => (
              <div key={order.id} className={style.BlocOrders__body}>
                <div>
                  <p className="font-light mb-1">{new Date(order.date).toLocaleDateString('fr-FR')}</p>
                  <Flag theme="light">{order.flag}</Flag>
                </div>
                <p className="font-bold">{order.id}</p>
                <p className="font-light">{order.price}</p>
                <div className={style.BlocOrders__body__plus}>
                  <Link
                    href={{
                      pathname: '/mon-compte/mes-commandes',
                      query: { orderId: order.id },
                    }}
                  >
                    <Button theme="secondary">
                      <Picto icon="more" />
                    </Button>
                  </Link>
                </div>
              </div>
            ))}
          </>
        ) : (
          <>
            <p className={style.BlocOrders__housky}>{mock.titleHousky}</p>
            <p className={style.BlocOrders__text}>{mock.subtitle}</p>
          </>
        )}
      </div>
      <div className={style.BlocOrders__button}>
        <Button href={orders ? '/mon-compte/mes-commandes' : mock.url}>{orders ? 'Voir mes commandes' : mock.cta}</Button>
      </div>
    </div>
  );
};

export default BlocOrders;
