import Router from 'next/router';

const WoosmapKey = process.env.NEXT_PUBLIC_WOOSMAP_KEY;

/**
 * Save favorite store in local storage
 * @param {number} storeId
 * @param {boolean} goToStorePage if true, we redirect to store page, else we reload the page
 */
export async function setStoreAsfavorite(storeId, goToStorePage = true) {
  const request = `https://api.woosmap.com/stores/search/?query=idstore:${storeId}&key=${WoosmapKey}`;

  const data = await fetch(request)
    .then((response) => response.json())
    .catch(console.error);

  const storeProperties = data.features[0].properties;

  localStorage.setItem('store', JSON.stringify(storeProperties));
  goToStorePage ? Router.push(storeProperties.contact.website.split('.fr')[1]) : Router.reload();
}
