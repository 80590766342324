import style from './TutoTips.module.scss';
import Form from './partials/Form';
import { useModals, Button, Layer, LayerHeader, LayerTitle, LayerBody } from '@mozaic-ds/react';

const urls = {
  pdf: 'https://www.zodio.fr/sheets/sheet/viewpdf/id/7131',
  image: 'https://photos.zodio.fr/zodio-magento/admin_user/1/1610030342_laulau-300x300.jpg',
};

const TutoTips = ({ data }) => {
  const { open } = useModals();
  const { astuce, chiefName } = data.data.dataSource.fields;

  return (
    <section className={style.TutoTips}>
      <div className={style.TutoTips__chief}>
        <img src={urls.image} alt={`Portrait du chef ${chiefName}`} />
      </div>

      {astuce && (
        <div className={style.TutoTips__tip}>
          <h2>Astuce !</h2>
          <p>{astuce}</p>
        </div>
      )}

      <div className={style.TutoTips__buttons}>
        <Button width="full" onClick={() => open('chief-message-layer')}>
          Envoyer un message à {chiefName}
        </Button>

        <Button width="full" theme="secondary" href={urls.pdf} rel="noopener noreferrer" target="_blank">
          Télecharger la fiche tuto
        </Button>
      </div>

      <Layer id="chief-message-layer">
        <LayerHeader id="chief-message-layer">
          <LayerTitle>Contacter {chiefName}</LayerTitle>
        </LayerHeader>
        <LayerBody id="chief-message-layer">
          <Form />
        </LayerBody>
      </Layer>
    </section>
  );
};

export default TutoTips;
