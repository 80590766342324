import style from './Avantages.module.scss';
import FideliteHeader from '../partials/FideliteHeader/FideliteHeader';
import LoyaltyProgramCard from 'components/elements/LoyaltyProgramCard/LoyaltyProgramCard';
import { publishItems } from 'helpers/utils/publishItems';

const Avantages = ({ data }) => {
  try {
    const { content, title, subtitle, titleHousky } = data.data.dataSource.fields;
    return (
      <section className={style.Avantages}>
        <FideliteHeader title={title} subtitle={subtitle} titleHousky={titleHousky} />

        <div className={style.Avantages__items}>
          {publishItems(content).map((item) => (
            <LoyaltyProgramCard key={item.sys.id} data={item} bloc />
          ))}
        </div>
      </section>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default Avantages;
