import React from 'react';
import Script from 'next/script';
import style from './SelectStore.module.scss';
import { setStoreAsfavorite } from 'helpers/utils/woosmap';
import { Button, TextInput } from '@mozaic-ds/react';
import Picto from '../Picto/Picto';

const SelectStore = ({ productID }) => {
  const WoosmapKey = process.env.NEXT_PUBLIC_WOOSMAP_KEY;
  const input = React.useRef();
  const [ready, setReady] = React.useState(false);
  const [service, setService] = React.useState(false);
  const [searching, setSearching] = React.useState(false);
  const [localities, setLocalities] = React.useState([]);
  const [stores, setStores] = React.useState([]);
  React.useEffect(initForm, [ready]);

  // WHEN USER TYPE TEXT IN SEARCH INPUT
  function onSearchInputChange() {
    setSearching(false);
    setStores([]);

    service.autocomplete(
      { input: input.current.value, components: { country: ['fr'] } },
      (response) => setLocalities(response.localities),
      (errorCode, errorText) => console.error(`error ${errorCode}:${errorText}`),
      200,
    );
  }

  // WHEN USER CLICKS ON A CITY SUGGESTION
  async function selectLocality(locality) {
    setSearching(true);
    setLocalities([]);

    input.current.value = locality.description;

    await service.getDetails(locality.public_id, async (details) => {
      const { lat, lng } = details.geometry.location;

      const url = 'https://api.woosmap.com/stores/search/';
      url += '?key=' + WoosmapKey;
      url += '&country=FR';
      url += '&lat=' + lat;
      url += '&lng=' + lng;
      url += '&radius=' + 20000;
      url += '&stores_by_page=' + 10;

      const response = await fetch(url)
        .then((r) => r.json())
        .catch(console.error);

      setStores(response.features);
    });
  }

  function clearSearch() {
    input.current.value = '';
    setSearching(false);
    setLocalities([]);
    setStores([]);
  }

  function highlightWords(string, coordinates) {
    coordinates?.forEach(({ offset, length }) => {
      if (length < 3) return;
      const term = string.substring(offset, length);
      string = string.replace(new RegExp(term, 'gi'), (match) => `<b>${match}</b>`);
    });

    return string;
  }

  function initForm() {
    if (!ready) return;

    try {
      setService(new woosmap.localities.AutocompleteService(WoosmapKey));
    } catch (error) {
      throw new Error('An error occured with Woosmap : ' + error);
    }
  }

  return (
    <>
      <Script src="https://sdk.woosmap.com/localities/localities.2.0.js" onReady={() => setReady(true)} />

      <div className={style.SelectStore} id="select-store">
        <div className={style.SelectStore__input}>
          <TextInput
            size="m"
            ref={input}
            type="text"
            id="select-store-input"
            placeholder="Chercher par ville ou par code postal..."
            autoComplete="off"
            onChange={onSearchInputChange}
          />

          {input.current?.value ? (
            <button className={style.SelectStore__input__button} aria-label="effacer la recherche" onClick={clearSearch}>
              <Picto icon="cross" />
            </button>
          ) : (
            <button
              className={style.SelectStore__input__button}
              aria-label="rechercher les magasins proches de chez vous"
              onClick={onSearchInputChange}
            >
              <Picto icon="search" />
            </button>
          )}

          <ul className={style.SelectStore__input__suggestions}>
            {localities.map((locality) => {
              return (
                <li key={locality.public_id} className={style.SelectStore__input__suggestions__item}>
                  <button onClick={() => selectLocality(locality)}>
                    <Picto icon="pin" />
                    <span
                      dangerouslySetInnerHTML={{
                        __html: highlightWords(locality.description, locality.matched_substrings?.description),
                      }}
                    />
                  </button>
                </li>
              );
            })}
          </ul>
        </div>

        {searching && (
          <div className={style.SelectStore__count}>
            {stores.length === 0 && "Nous n'avons trouvé aucun magasin proche de chez vous"}
            {stores.length === 1 && 'Nous avons trouvé 1 magasin proche de chez vous'}
            {stores.length > 1 && `Nous avons trouvé ${stores.length} magasins proche de chez vous`}
          </div>
        )}

        {stores.length > 0 && (
          <ul className={style.SelectStore__stores}>
            {stores.map((store) => {
              const { store_id, name, open, distance } = store.properties;

              return (
                <li key={store_id} className={style.SelectStore__stores__item}>
                  <h3 className={style.SelectStore__stores__item__name}>
                    {name} <span>({(distance / 1000).toFixed(2)}kms)</span>
                  </h3>

                  {productID && (
                    <>
                      <span className={style.SelectStore__stores__item__stock}>30 en stock - retrait 2h gratuit</span>
                      <span className={style.SelectStore__stores__item__delivery}>retrait 2h gratuit</span>
                    </>
                  )}

                  <span className={style.SelectStore__stores__item__opening}>
                    {open.open_now ? `Magasin ouvert de ${open.current_slice.start} à ${open.current_slice.end} ce jour` : `Magasin fermé ce jour`}
                  </span>

                  <Button width="full" theme="accent" onClick={() => setStoreAsfavorite(store_id, false)}>
                    Choisir ce magasin
                  </Button>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </>
  );
};

export default SelectStore;
