import React from 'react';
import style from './Form.module.scss';
import { rules } from 'helpers/utils/formRules';
import { useForm } from 'react-hook-form';
import { useRouter } from 'next/router';
import {
  Button,
  Field,
  TextInput,
  QuantitySelector,
  Radio,
  TextArea,
  Modal,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
  useModals,
} from '@mozaic-ds/react';

// MOCK
const prices = [30, 50, 100];
const visuals = [
  {
    name: 'Furoshiki',
    image: 'https://www.zodio.fr/media/giftcard/background/carte_cadeau1-resize.png?height=186&width=295&fit=bounds&bg-color=fff',
  },
  {
    name: 'Blé',
    image: 'https://www.zodio.fr/media/giftcard/background/carte_cadeau_2-resize.png?height=186&width=295&fit=bounds&bg-color=fff',
  },
  {
    name: 'Teracotta',
    image: 'https://www.zodio.fr/media/giftcard/background/carte_cadeau3-resize.png?height=186&width=295&fit=bounds&bg-color=fff',
  },
  {
    name: 'Gateau',
    image: 'https://www.zodio.fr/media/giftcard/background/carte_cadeau4-resize.png?height=186&width=295&fit=bounds&bg-color=fff',
  },
];

const Form = ({ onFormChange, scope }) => {
  const router = useRouter();
  const { open } = useModals();

  const {
    register,
    unregister,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      visual: visuals[0].image,
    },
  });

  const form = React.useRef();
  const priceInput = React.useRef();
  const inputFile = React.useRef();

  const [quantity, setQuantity] = React.useState(1);
  const [multiEmails, setMultiEmails] = React.useState(false);
  const [priceInputType, setPriceInputType] = React.useState('prefilled');
  const [giftcardVisual, setGiftcardVisual] = React.useState(visuals[0].image);

  const formData = watch();

  React.useEffect(() => setTimeout(() => onFormChange(formData), 0), []);

  React.useEffect(() => {
    onFormChange(formData);
  }, [formData]);

  try {
    function onSubmit(data) {
      console.log('onSubmit', data);

      // Send ajax and if success open modal
      open('giftcard-confirm-modal');
      // else open error modal
      // open('giftcard-error-modal');
    }

    function setManualPrice(event) {
      setPriceInputType('manual');
      setValue('price', +event.target.value, { shouldValidate: true });
    }

    function setPrefilledPrice(price) {
      setPriceInputType('prefilled');
      setValue('price', price, { shouldValidate: true });
      priceInput.current.value = '';
    }

    function changeQuantity(value) {
      setQuantity(value);
      setValue('quantity', value);
    }

    function setPreffiledVisual(visual) {
      setGiftcardVisual(visual.image);
      setValue('visual', visual.image, { shouldValidate: true });
    }

    function setManualVisual() {
      inputFile.current.click();
    }

    function onUploadImage(event) {
      const newVisual = event.target.files.length ? URL.createObjectURL(event.target.files[0]) : visuals[0].image;
      setGiftcardVisual(newVisual);
      setValue('visual', newVisual);
    }

    function changeMultiEmails(boolean) {
      setMultiEmails(boolean);
      unregister('emails');
    }

    return (
      <form ref={form} className={style.Form} noValidate onSubmit={handleSubmit(onSubmit)}>
        <input type="hidden" value="ecarte cadeau" {...register('name')} />
        <input type="hidden" value="zodio" {...register('brand')} />
        <input type="hidden" value="Apparel" {...register('category')} />
        <input type="hidden" value="SYSTEM-GIFTCARD-ECARD" {...register('id')} />

        <fieldset className={style.Form__step}>
          <legend className={style.Form__step__title}>Je choisis un montant</legend>

          <div className={style.Form__step__fields}>
            {prices.map((price) => (
              <Button
                key={price}
                type="button"
                className={style.Form__step__fields__priceButton}
                theme={errors?.price && priceInputType === 'prefilled' ? 'secondary-danger' : 'secondary'}
                isDisabled={getValues('price') === price}
                width="full"
                onClick={() => setPrefilledPrice(price)}
              >
                {price} €
              </Button>
            ))}

            <Field htmlFor="manuel-price" label="Autre" className={style.Form__step__fields__priceInput}>
              <TextInput
                ref={priceInput}
                type="tel"
                id="manuel-price"
                placeholder="Votre montant en €"
                onChange={setManualPrice}
                isInvalid={errors?.price && priceInputType === 'manual'}
                onKeyPress={(event) => !/[0-9]/.test(event.key) && event.preventDefault()}
              />
            </Field>
          </div>
        </fieldset>

        <Field className="w-[100%] -mt-[0.8rem]" error={errors?.price?.message}>
          <input type="hidden" {...register('price', rules.giftcard.price)} />
        </Field>

        <fieldset className={style.Form__step}>
          <legend className={style.Form__step__title}>Je choisis la quantité</legend>

          <div className={style.Form__step__fields}>
            <QuantitySelector
              className={style.Form__step__fields__quantitySelector}
              theme="secondary"
              minValue={1}
              maxValue={100}
              value={quantity}
              onChange={changeQuantity}
            />
            <input type="hidden" {...register('quantity')} value={quantity} />
          </div>
        </fieldset>
        <fieldset className={style.Form__step}>
          <legend className={style.Form__step__title}>Je choisis un visuel</legend>

          <div className={style.Form__step__fields}>
            {visuals.map((visual, i) => (
              <label
                key={visual.name}
                htmlFor={`choose-visual-${i}`}
                className={style.Form__step__fields__visual}
                data-active={giftcardVisual === visual.image}
              >
                <Radio id={`choose-visual-${i}`} isChecked={giftcardVisual === visual.image} onChange={() => setPreffiledVisual(visual)} />
                <img src={visual.image} alt={visual.name} />
              </label>
            ))}

            <label
              htmlFor="upload-visual"
              className={`${style.Form__step__fields__visual} ${style['Form__step__fields__visual--upload']}`}
              data-active={!visuals.find((visual) => visual.image === giftcardVisual)}
            >
              <Radio id="upload-visual" isChecked={!visuals.find((visual) => visual.image === giftcardVisual)} onChange={setManualVisual}>
                <span>Je télécharge une photo</span>
              </Radio>

              <input ref={inputFile} type="file" accept="image/*" hidden onChange={onUploadImage} />

              <div className={style.Form__step__fields__visual__upload}>
                <Button type="button" theme="secondary" width="fit" onClick={setManualVisual}>
                  Télécharger votre photo
                </Button>
              </div>
            </label>
          </div>

          <input type="hidden" {...register('visual')} value={giftcardVisual} />
        </fieldset>

        <fieldset className={style.Form__step}>
          <legend className={style.Form__step__title}>Et je personnalise mon message&nbsp;!</legend>

          <div className={style.Form__step__fields}>
            <Field htmlFor="firstname" label="De*" className="w-[100%]" error={errors?.firstname?.message}>
              <TextInput
                id="firstname"
                placeholder="Prénom de l'expéditeur"
                {...register('firstname', rules.firstname)}
                isInvalid={errors.firstname}
              />
            </Field>
            <Field htmlFor="giftcardMessage" label="Message*" className="w-[100%]" error={errors?.message?.message}>
              <TextArea
                id="giftcardMessage"
                placeholder="Votre message personnalisé"
                rows="10"
                style={{ resize: 'vertical' }}
                {...register('message', rules.giftcard.message)}
                isInvalid={errors.message}
              />
            </Field>

            {scope === 'private' && getValues('quantity') > 1 ? (
              <>
                <Field htmlFor="email" label="Envoyer à*" className="w-[100%]">
                  <Radio onChange={() => changeMultiEmails(false)} name="recipient" isChecked={!multiEmails}>
                    Un destinataire
                  </Radio>
                  <Radio onChange={() => changeMultiEmails(true)} name="recipient" isChecked={multiEmails}>
                    Plusieurs destinataires
                  </Radio>
                </Field>

                {[...Array(multiEmails ? getValues('quantity') : 1)].map((_, i) => (
                  <Field key={i} className="w-[100%]" error={errors?.emails?.[i]?.message}>
                    <TextInput placeholder="Adresse e-mail" {...register(`emails.${i}`, rules.email)} isInvalid={errors.emails?.[i]} />
                  </Field>
                ))}
              </>
            ) : (
              <Field htmlFor="email" label="Envoyer à*" className="w-[100%]" error={errors?.emails?.[0]?.message}>
                <TextInput id="email" placeholder="Adresse e-mail" {...register('emails.0', rules.email)} isInvalid={errors.emails?.[0]} />
              </Field>
            )}
            <div className={style.Form__step__fields__submit}>
              <Button type="submit" size="l" width="full">
                Je valide ma création
              </Button>
            </div>
          </div>
        </fieldset>

        <Modal id="giftcard-confirm-modal">
          <ModalHeader id="giftcard-confirm-modal">
            <ModalTitle>Article ajouté au panier</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <div className={style.Form__modal}>
              <img className={style.Form__modal__preview} src={getValues('visual')} alt="" />
              <div className={style.Form__modal__details}>
                <span className={style.Form__modal__details__name}>E-Carte Cadeau</span>
                <span className={style.Form__modal__details__quantity}>Quantité: {getValues('quantity')}</span>
              </div>
              <div className={style.Form__modal__price}>{getValues('price')} €</div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button theme="secondary" width="full" onClick={() => router.reload(window.location.pathname)}>
              Commander une nouvele carte cadeau
            </Button>

            <Button theme="primary" width="full" onClick={() => router.push('/cart')}>
              Voir mon panier
            </Button>
          </ModalFooter>
        </Modal>

        <Modal id="giftcard-error-modal">
          <ModalHeader id="giftcard-error-modal">
            <ModalTitle>Une erreur s&apos;est produite</ModalTitle>
          </ModalHeader>
          <ModalBody>
            La carte cadeau n&apos;a pas été ajoutée au panier, merci de réessayer ou de contacter le
            <a href="https://contactzodio.zendesk.com/hc/fr/requests/new" target="_blank" rel="noopener, noreferrer">
              service client
            </a>
            .
          </ModalBody>
        </Modal>
      </form>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default React.memo(Form);
