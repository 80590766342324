import Breadcrumb from './Breadcrumb/Breadcrumb';
import Footer from './Footer/Footer';
import Header from './Header/Header';
import HeaderCheckout from './HeaderCheckout/HeaderCheckout';
import Reinsurance from './Reinsurance/Reinsurance';

import Meta from './Meta/Meta';
import Banner from './Banner/Banner';
import Recommendation from './Recommendation/Recommendation';
import HeadBand from './HeadBand/HeadBand';
import SeoBlock from './SeoBlock/SeoBlock';
import FootNotes from './FootNotes/FootNotes';
import Thumbnails from './Thumbnails/Thumbnails';
import MoreIdeas from './MoreIdeas/MoreIdeas';
import Error404 from './Error404/Error404';
import Paragraph from './Paragraph/Paragraph';
import Video from './Video/Video';
import Title from './Title/Title';
import HiddenH1 from './HiddenH1/HiddenH1';
import AnchorNav from './AnchorNav/AnchorNav';
import ConsentOptin from './ConsentOptin/ConsentOptin';
import MediasGallery from './MediasGallery/MediasGallery';
import Spacer from './Spacer/Spacer';
import History from './History/History';
import TitleWithHousky from './TitleWithHousky/TitleWithHousky';
import ContentTiles from './ContentTiles/ContentTiles';
import BannerCatalog from './BannerCatalog/BannerCatalog';
import Contact from './Contact/Contact';
import OurUnivers from './OurUnivers/OurUnivers';
import BannerInspiHouse from './BannerInspiHouse/BannerInspiHouse';
import ImagesTargets from './ImagesTargets/ImagesTargets';
import ImagesTargetWithText from './ImagesTargetWithText/ImagesTargetWithText';
import BannerNotFull from './BannerNotFull/BannnerNotFull';
import ParagraphTwoImgs from './ParagraphTwoImgs/ParagraphTwoImgs';
import TextImage from './TextImage/TextImage';
import TextTwoParagraph from './TextTwoParagraph/TextTwoParagraph';
import SimpleTitle from './SimpleTitle/SimpleTitle';
import VignettesText from './VignettesText/VignettesText';
import ParagraphFull from './ParagraphFull/ParagraphFull';

export default {
  Breadcrumb,
  Footer,
  Header,
  HeaderCheckout,
  Reinsurance,
  Meta,
  Banner,
  Recommendation,
  HeadBand,
  SeoBlock,
  FootNotes,
  Thumbnails,
  MoreIdeas,
  Error404,
  Paragraph,
  Video,
  Title,
  HiddenH1,
  AnchorNav,
  ConsentOptin,
  MediasGallery,
  Spacer,
  History,
  TitleWithHousky,
  ContentTiles,
  BannerCatalog,
  Contact,
  OurUnivers,
  BannerInspiHouse,
  ImagesTargets,
  ImagesTargetWithText,
  BannerNotFull,
  ParagraphTwoImgs,
  TextImage,
  TextTwoParagraph,
  SimpleTitle,
  VignettesText,
  ParagraphFull,
};
