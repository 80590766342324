import style from './Intro.module.scss';
import RichText from 'components/elements/contentful/RichText/RichText';
import React from 'react';

const Intro = ({ data }) => {
  try {
    const { title, imageDesktop, paragraph } = data.data.dataSource.fields;
    return (
      <section className={style.Intro}>
        {title && <h1 className={style.Intro__title}>{title}</h1>}

        <div className={style.Intro__block}>
          <div className={style.Intro__block__text}>
            <RichText content={paragraph} />
          </div>

          <div className={style.Intro__block__image}>
            <img src={imageDesktop?.fields?.file?.url} loading="lazy" alt={imageDesktop?.fields?.title} />
          </div>
        </div>

        <div data-anchor-id="enseigne-francaise" data-anchor-label="Une enseigne française" />
      </section>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default Intro;
