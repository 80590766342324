import style from './CheckoutAdressForm.module.scss';
import { useForm } from 'react-hook-form';
import { rules } from 'helpers/utils/formRules';
import { Field, Select, TextInput, CheckBox, Button } from '@mozaic-ds/react';

const CheckoutAdressForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const onSubmit = (data) => {
    console.log('onSubmit form adress', data);
  };

  try {
    return (
      <form noValidate className={style.CheckoutAdressForm} onSubmit={handleSubmit(onSubmit)}>
        <Field htmlFor="gender" label="Civilité*">
          <Select
            id="gender"
            {...register('gender')}
            options={[
              { label: 'M.', value: 'M' },
              { label: 'Mme', value: 'F' },
            ]}
            size="m"
          />
        </Field>

        <Field htmlFor="firstname" label={`Prénom${rules.firstname.required ? '*' : ''}`} error={errors?.firstname?.message}>
          <TextInput id="firstname" placeholder="Votre prénom" {...register('firstname', rules.firstname)} isInvalid={errors.firstname} />
        </Field>

        <Field htmlFor="lastname" label={`Nom${rules.lastname.required ? '*' : ''}`} error={errors?.lastname?.message}>
          <TextInput id="lastname" placeholder="Votre nom" {...register('lastname', rules.lastname)} isInvalid={errors.lastname} />
        </Field>

        <Field htmlFor="company" label={`Société${rules.company.required ? '*' : ''}`} error={errors?.company?.message}>
          <TextInput id="company" placeholder="Vous êtes un professionel" {...register('company', rules.company)} isInvalid={errors.company} />
        </Field>

        <Field htmlFor="adress" label={`Adresse${rules.adress.required ? '*' : ''}`} error={errors?.adress?.message}>
          <TextInput id="adress" placeholder="Numéro, libélé et voie" {...register('adress', rules.adress)} isInvalid={errors.adress} />
          <TextInput className="mt-[0.8rem]" placeholder="Entrée batiment" {...register('adress-2')} />
          <TextInput className="mt-[0.8rem]" placeholder="Mentions spéciales" {...register('adress-3')} />
        </Field>

        <Field htmlFor="zipcode" label={`Code postal${rules.zipcode.required ? '*' : ''}`} error={errors?.zipcode?.message}>
          <TextInput id="zipcode" placeholder="Votre code postal" {...register('zipcode', rules.zipcode)} isInvalid={errors.zipcode} />
        </Field>

        <Field htmlFor="city" label={`Ville${rules.city.required ? '*' : ''}`} error={errors?.city?.message}>
          <TextInput id="city" placeholder="Votre ville" {...register('city', rules.city)} isInvalid={errors.city} />
        </Field>

        <Field htmlFor="country" label={`Pays${rules.country.required ? '*' : ''}`} error={errors?.country?.message}>
          <TextInput id="country" placeholder="Votre pays" {...register('country', rules.country)} isInvalid={errors.country} />
        </Field>

        <Field htmlFor="phone" label={`Téléphone${rules.phone.required ? '*' : ''}`} error={errors?.phone?.message}>
          <TextInput id="phone" placeholder="Votre numéro de téléphone" {...register('phone', rules.phone)} isInvalid={errors.phone} />
        </Field>

        <CheckBox {...register('save')}>Enregistrer dans le carnet d&apos;adresses</CheckBox>

        <div className="my-[1.6rem]">
          <Button type="submit">Sauvegarder</Button>
        </div>
      </form>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default CheckoutAdressForm;
