import { Fragment } from 'react';
import style from './Points.module.scss';
import Fonctionnement from './Fonctionnement/Fonctionnement';
import CollectePoints from './CollectePoints/CollectePoints';
import { publishItems } from 'helpers/utils/publishItems';

const Points = ({ data }) => {
  try {
    const { points } = data.data.dataSource.fields;

    return (
      <div className={style.Points}>
        {publishItems(points).map((point) => {
          return (
            <Fragment key={point.sys.id}>{point.fields.titleHousky ? <Fonctionnement point={point} /> : <CollectePoints point={point} />}</Fragment>
          );
        })}
      </div>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default Points;
