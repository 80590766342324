import PLP from 'components/elements/PLP/PLP';

const ProductList = () => {
  return (
    <PLP
      type="search"
      index={process.env.NEXT_PUBLIC_ALGOLIA_INDEX_SEARCH}
      config={{ hitsPerPage: 24, filters: 'NOT type_id: "workshop" AND visibility_search: "1"' }}
    />
  );
};

export default ProductList;
