import style from './Newsletter.module.scss';
import Form from './partials/Form';
import image from './image/newsletter.webp';
import { useRouter } from 'next/router';

const Newsletter = () => {
  const router = useRouter();
  const { email } = router.query;

  return (
    <section className={style.Newsletter}>
      <div className={style.Newsletter__content}>
        <h1 className={style.Newsletter__content__title}>
          <span>Faites le plein d&apos;idées cuisine,</span>
          <span>déco et créa!</span>
        </h1>

        <p className={style.Newsletter__content__text}>
          Inscrivez-vous à notre newsletter et profitez de{' '}
          <strong>-10% dès 35€ d&apos;achat sur votre prochaine commande en livraison ou e-réservation.</strong> Un code unique vous sera envoyé par
          email.
        </p>

        <Form defaultEmail={email} />

        <p className={style.Newsletter__content__mentions}>
          En indiquant votre adresse email ci-dessus vous acceptez notre politique de données personnelles et consentez à recevoir nos propositions
          commerciales par voie électronique. Vous pouvez vous désinscrire ou modifier vos choix d&apos;abonnement à tout moment à partir de votre
          compte client ou des liens de désinscription disponibles dans toutes nos communications envoyées par voie électronique.
        </p>
      </div>

      <img className={style.Newsletter__image} src={image.src} alt="" />
    </section>
  );
};

export default Newsletter;
