import React from 'react';
import style from './Banner.module.scss';
import Link from 'next/link';
import Picto from 'components/elements/Picto/Picto';
import { Button } from '@mozaic-ds/react';

const Banner = ({ data }) => {
  try {
    const { title, url, imageDesktop, imageMobile, mobileButtonLabel, desktopButtonLabel } = data.data.dataSource.fields;

    const imageMobileUrl = imageMobile.fields.file.url;
    const imageDesktopUrl = imageDesktop.fields.file.url;

    const breakpoints = [
      { media: '(max-width: 800px) and (-webkit-min-device-pixel-ratio: 1)', srcSet: imageMobileUrl + '?w=800' },
      { media: '(max-width: 800px) and (-webkit-min-device-pixel-ratio: 2)', srcSet: imageMobileUrl + '?w=1600' },
      { media: '(max-width: 1100px) and (-webkit-min-device-pixel-ratio: 1)', srcSet: imageDesktopUrl + '?w=1100' },
      { media: '(max-width: 1100px) and (-webkit-min-device-pixel-ratio: 2)', srcSet: imageDesktopUrl + '?w=2200' },
      { media: '(max-width: 1500px) and (-webkit-min-device-pixel-ratio: 1)', srcSet: imageDesktopUrl + '?w=1920' },
      { media: '(max-width: 1500px) and (-webkit-min-device-pixel-ratio: 2)', srcSet: imageDesktopUrl + '?w=3840' },
    ];

    const BannerInner = () => (
      <>
        <picture>
          {breakpoints.map((breakpoint, i) => (
            <source key={i} {...breakpoint} />
          ))}
          <img className={style.Banner__picture} src={imageDesktopUrl + '?w=1920'} alt={title} />
        </picture>

        {url && mobileButtonLabel && (
          <div className={`${style.Banner__button} ${style['Banner__button--mobile']}`}>
            <Button theme="accent" width="full">
              {mobileButtonLabel} <Picto icon="arrow--up" className="ml-[1rem]" />
            </Button>
          </div>
        )}

        {url && desktopButtonLabel && (
          <div className={`${style.Banner__button} ${style['Banner__button--desktop']}`}>
            <Button theme="accent" width="full">
              {desktopButtonLabel} <Picto icon="arrow--right" className="ml-[1rem]" />
            </Button>
          </div>
        )}
      </>
    );

    return (
      <section className={style.Banner}>
        {url ? (
          <Link href={url}>
            <a>
              <BannerInner />
            </a>
          </Link>
        ) : (
          <>
            <BannerInner />
          </>
        )}
      </section>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default Banner;
