import React from 'react';
import style from './GiftcardPayment.module.scss';
import { useForm } from 'react-hook-form';
import { Button, CheckBox, Field, TextInput } from '@mozaic-ds/react';

const GiftcardPayment = () => {
  const [formIsVisible, setFormIsVisible] = React.useState(false);

  const { register, handleSubmit } = useForm({ mode: 'onSubmit' });

  function onSubmit(data) {
    console.log('onSubmit giftcard', data);
  }

  return (
    <div className={style.GiftcardPayment}>
      <h2 className={style.GiftcardPayment__title}>Ma carte cadeau</h2>

      <CheckBox onChange={(e) => setFormIsVisible(e.target.checked)}>Utiliser une carte cadeau Zodio</CheckBox>

      {formIsVisible && (
        <form className={style.GiftcardPayment__form} onSubmit={handleSubmit(onSubmit)}>
          <Field className="w-[100%]" htmlFor="giftcardNumber" label="Numéro de carte cadeau">
            <TextInput id="giftcardNumber" placeholder="13 ou 20 chiffres" {...register('giftcardNumber')} />
          </Field>

          <Field className="w-[100%]" htmlFor="giftcardPin" label="Code PIN">
            <TextInput id="giftcardPin" placeholder="40 chiffres" {...register('giftcardPin')} />
          </Field>

          <a
            className={style.GiftcardPayment__form__mentions}
            href="https://service-client.zodio.fr/hc/fr/categories/13820606778769-Paiement-et-Carte-cadeau"
            target="_blank"
            rel="noreferrer noopener"
          >
            Ces informations se trouvent au dos de la carte ou sur le document reçu par mail.
          </a>

          <Button theme="accent" type="submit">
            Utiliser cette carte
          </Button>
        </form>
      )}
    </div>
  );
};

export default GiftcardPayment;
