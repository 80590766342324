/**
 * remove accent and replace all special caracters and space by hyphens
 * @param {String} string
 * @returns {String}
 */
export function flattenString(string) {
  return string
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '') // replace accents
    .replace(/[^\w\s-]/g, '-') // replace special caracters by hyphens
    .replace(/\s+/g, '-') // replace spaces caracters by hyphens
    .replace(/-+/g, '-'); // avoid multi hyphens side by side
}
