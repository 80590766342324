import style from './WorkshopsPictos.module.scss';
import Link from 'next/link';
import Picto from 'components/elements/Picto/Picto';

const WorkshopsPictos = ({ data }) => {
  try {
    const { iconsUnivers: pictos } = data?.data?.dataSource?.fields;

    return (
      <ul className={style.WorkshopsPictos}>
        {pictos.map((picto) => {
          const { text, id, url } = picto?.fields;

          return (
            <li key={id} className={style.WorkshopsPictos__item}>
              <Link href={url}>
                <a>
                  <div className={style.WorkshopsPictos__item__icon}>
                    <Picto icon={id} />
                  </div>
                  {text}
                </a>
              </Link>
            </li>
          );
        })}
      </ul>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default WorkshopsPictos;
