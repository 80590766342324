import style from './Summary.module.scss';
import { useCurrentRefinements, useClearRefinements } from 'react-instantsearch-hooks-web';
import { Button } from '@mozaic-ds/react';
import Picto from 'components/elements/Picto/Picto';

const Summary = () => {
  const { refine: clearThisFilter, items } = useCurrentRefinements();
  const { refine: clearAllFilters } = useClearRefinements();

  return (
    <div className={style.Summary}>
      <div className={style.Summary__clearOne}>
        {items.map(({ refinements }) =>
          refinements.map((refinement) => (
            <Button key={refinement.label} size="s" width="fit" theme="secondary" onClick={() => clearThisFilter(refinement)}>
              {refinement.label}
              <Picto icon="cross" className="ml-[1em]" />
            </Button>
          )),
        )}
      </div>

      <button className={style.Summary__clearAll} onClick={clearAllFilters}>
        <Picto icon="cross" className="mr-[1em]" />
        Supprimer tous les filtres
      </button>
    </div>
  );
};

export default Summary;
