import style from './SimpleTitle.module.scss';

const SimpleTitle = () => {
  return (
    <div className={style.SimpleTitle}>
      <h2 className={style.SimpleTitle__title}>Sous-sous titre</h2>
      <p className={style.SimpleTitle__text}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
      </p>
    </div>
  );
};

export default SimpleTitle;
