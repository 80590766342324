import style from './OrderSummary.module.scss';
import React from 'react';

const OrderSummary = () => {
  return (
    <ul className={style.OrderSummary}>
      <li className={style.OrderSummary__subtotal}>
        Sous-total
        <span className="font-bold">99,67&nbsp;€</span>
      </li>
      {/*if loyaltycard, number loyalty card*/}
      <li className={style.OrderSummary__loyalty}>
        Remise (Carte cadeau 94402101528530363160, Carte cadeau 94402101923119723738)
        <span className="font-bold">-124&nbsp;€</span>
      </li>
      {/*if reductions order*/}
      <li className={style.OrderSummary__reductions}>
        <p>
          10% remise supplémentaires soldes
          <span className="font-light">-12,45&nbsp;€</span>
        </p>
        <p>
          Remise Collaborateur
          <span className="font-light">-11.21&nbsp;€</span>
        </p>
        <p>
          Livraison Mondial Relay offerte si panier sup 35€
          <span className="font-light">-3.97&nbsp;€</span>
        </p>
        <p>
          Chèque plaisir de 5€ contre 150 points
          <span className="font-light">-5.00&nbsp;€</span>
        </p>
      </li>
      {/*delivery date*/}
      <li className={style.OrderSummary__delivery}>
        Livraison en point relais avec Mondial relay à partir du 30 janv. 2023
        <span className="font-bold">4.08&nbsp;€</span>
      </li>
      <li className={style.OrderSummary__total}>
        TOTAL HT
        <span className="font-bold">0.00&nbsp;€</span>
      </li>
    </ul>
  );
};

export default OrderSummary;
