import React from 'react';
import { rules } from 'helpers/utils/formRules';
import { useForm } from 'react-hook-form';
import { Button, Field, TextInput, Radio } from '@mozaic-ds/react';
import alert from 'helpers/utils/alert';

const Form = ({ defaultEmail }) => {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  function onSubmit(data) {
    console.log('onSubmit', data);

    const success = false; // response ajax

    if (success) alert('success', 'Souscription à la newsletter réussie', 4000);
    else alert('error', "Une erreur s'est produite. Merci de réessayer ou de contacter le service client", 4000);
  }

  return (
    <form className="max-w-[45rem] mx-auto my-[3.2rem]" noValidate onSubmit={handleSubmit(onSubmit)}>
      <Field className="w-[100%] my-[1.6rem]" htmlFor="gender" label={`Civilité${rules.gender.required ? '*' : ''}`} error={errors?.gender?.message}>
        <Radio
          value="F"
          isChecked={getValues('gender') === 'F'}
          onChange={(e) => setValue('gender', e.target.value, { shouldValidate: true })}
          isInvalid={errors.gender}
        >
          Madame
        </Radio>

        <Radio
          value="M"
          isChecked={getValues('gender') === 'M'}
          onChange={(e) => setValue('gender', e.target.value, { shouldValidate: true })}
          isInvalid={errors.gender}
        >
          Monsieur
        </Radio>

        <input type="hidden" {...register('gender', rules.gender)} />
      </Field>

      <Field htmlFor="lastname" label="Nom*" className="w-[100%] my-[1.6rem]" error={errors?.lastname?.message}>
        <TextInput id="lastname" placeholder="Votre nom" {...register('lastname', rules.lastname)} isInvalid={errors.lastname} />
      </Field>

      <Field htmlFor="firstname" label="Prénom*" className="w-[100%] my-[1.6rem]" error={errors?.firstname?.message}>
        <TextInput id="firstname" placeholder="Votre prénom" {...register('firstname', rules.firstname)} isInvalid={errors.firstname} />
      </Field>

      <Field htmlFor="email" label="Email*" className="w-[100%] my-[1.6rem]" error={errors?.email?.message}>
        <TextInput id="email" placeholder="Votre email" defaultValue={defaultEmail} {...register('email', rules.email)} isInvalid={errors.email} />
      </Field>

      <Button type="submit" width="full" className="my-[3.2rem]">
        Envoyer
      </Button>
    </form>
  );
};

export default Form;
