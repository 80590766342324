import React from 'react';
import style from './Icons.module.scss';
import { Tooltip } from '@mozaic-ds/react';

const names = [];

const Icons = () => {
  const [icons, setIcons] = React.useState([]);
  const [selectedIcon, setSelectedIcon] = React.useState(undefined);

  const selectIcon = async (name) => {
    try {
      await navigator.clipboard.writeText(name);
      setSelectedIcon(name);
    } catch (error) {
      alert("Impossible de copier l'icone, merci de réessayer");
    }
  };

  const importedIcons = [];

  const importAll = (r) => {
    names = [...r.keys()];
    return r.keys().map(r);
  };

  React.useEffect(() => setIcons(importedIcons), [importedIcons]);

  importAll(require.context('/public/icons/')).map((icon) => importedIcons.push(icon.default()));

  return (
    <section className={style.Icons}>
      {selectedIcon ? (
        <h1 className={style.Icons__title}>
          Vous avez copié l&apos;icone <b>{selectedIcon}</b> !
        </h1>
      ) : (
        <h1 className={style.Icons__title}>Cliquez sur une icone pour la copier.</h1>
      )}

      <div className={style.Icons__blocks}>
        {icons.map((icon, i) => {
          const name = names[i].split('.svg')[0].split('./')[1];

          return (
            <Tooltip key={i} label={selectedIcon === name ? 'Copié !' : 'Clickez pour copier'} placement="top">
              <button className={style.Icons__blocks__item} onClick={() => selectIcon(name)}>
                {React.createElement(icon.type, icon.props, icon.props.children)}
                <h2 className={style.Icons__blocks__item__title}>{name}</h2>
              </button>
            </Tooltip>
          );
        })}
      </div>
    </section>
  );
};

export default Icons;
