import Link from 'next/link';
import { Fragment } from 'react';

const LinkBreadcrumb = ({ node, currentPage = false }) => {
  const parent = node?.fields?.parent || node?.parent;
  const hasParent = !!parent;
  const cssClass = currentPage ? 'Breadcrumbs__list__item Breadcrumbs__list__item--current' : 'Breadcrumbs__list__item';

  return (
    <Fragment>
      {hasParent && <LinkBreadcrumb node={parent} />}
      <li className={cssClass}>
        {!currentPage ? (
          <Link href={`${node?.fields?.urlKey || node?.urlKey}`}>
            <a>{node?.fields?.breadcrumb || node?.breadcrumb}</a>
          </Link>
        ) : (
          <span aria-current="page">{node?.fields?.breadcrumb || node?.breadcrumb}</span>
        )}
      </li>
    </Fragment>
  );
};

const Breadcrumb = ({ data }) => {
  try {
    const items = data.data ? data.data.dataSource : [];

    return (
      <nav aria-label="Breadcrumb" className="Breadcrumbs">
        <ol className="Breadcrumbs__list">
          <li className="Breadcrumbs__list__item">
            <Link href="/">
              <a>Accueil</a>
            </Link>
          </li>
          {items && <LinkBreadcrumb node={items} currentPage={true} />}
        </ol>
      </nav>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default Breadcrumb;
