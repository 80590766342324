import Subtitle from 'components/elements/Subtitle/Subtitle';
import style from './Text.module.scss';

const Text = ({ data }) => {
  const { title, text } = data.content;

  return (
    <section className={style.Text}>
      <Subtitle title={title} />

      <p>{text}</p>
    </section>
  );
};

export default Text;
