import CheckoutTemplate from '../partials/CheckoutTemplate/CheckoutTemplate';
import ValidationButton from '../partials/ValidationButton/ValidationButton';
import GiftcardPayment from '../partials/GiftcardPayment/GiftcardPayment';
import StandardPayment from '../partials/StandardPayment/StandardPayment';
import { useRouter } from 'next/router';
import alert from 'helpers/utils/alert';

const Payment = () => {
  const router = useRouter();

  function validPayment() {
    const error = false; // Mock payment response

    if (error) {
      alert('error', "Une erreur s'est produite avec votre paiement");
    } else {
      router.push('/checkout/confirmation');
    }
  }

  try {
    return (
      <CheckoutTemplate step="payment">
        <GiftcardPayment />
        <StandardPayment />
        <ValidationButton label="Payer ma commande" onClick={validPayment} />
      </CheckoutTemplate>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};
export default Payment;
