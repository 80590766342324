import Subtitle from 'components/elements/Subtitle/Subtitle';
import style from './Associations.module.scss';

const Associations = ({ data }) => {
  const { title, logos } = data.content;

  return (
    <section className={style.Associations}>
      <Subtitle title={title} />

      {logos && (
        <ul className={style.Associations__logos}>
          {logos.map((logo, i) => {
            return (
              <li key={i} className={style.Associations__logos__item}>
                <img src={logo.image} alt={logo.alt} loading="lazy" />
              </li>
            );
          })}
        </ul>
      )}

      <div className={style.Associations__text}>
        <img src="https://www.zodio.fr/media/sv_illus.jpg?width=850" alt="" loading="lazy" />
        <div>
          <strong>
            Cela fait maintenant plusieurs années que la Seconde Vie Zôdio est organisée. Aujourd&apos;hui plus que jamais, nous renforçons nos
            engagements pour une consommation qui a du sens.
          </strong>

          <span>
            En faisant un simple tri de vos placards, vous aidez notre réseau d&apos;associations locales qui distribuera vos dons pour accompagner
            des personnes dans le besoin. Vos dons serviront à des personnes hébergées en urgence, à des personnes qui redémarrent dans la vie et qui
            n&apos;ont plus rien, ou tout simplement pour aider les familles qui n&apos;ont pas les moyens de bien s&apos;équiper.
          </span>
        </div>
      </div>
    </section>
  );
};

export default Associations;
