export default {
  id: 1021199,
  group_id: 1,
  default_billing: 'fe3c864bb4a6ccfcd05b815a1c1a1ded9fb7232d.b1a9f487a310e1002f506212f9dec5fcee720aa6',
  default_shipping: 'fe3c864bb4a6ccfcd05b815a1c1a1ded9fb7232d.b1a9f487a310e1002f506212f9dec5fcee720aa6',
  created_at: '2023-03-06 10:49:37',
  updated_at: '2023-05-09 12:59:41',
  created_in: 'Zodio2019',
  dob: '2005-02-04',
  email: 'mgasmi@atecna.fr',
  firstname: 'mgasmi',
  lastname: 'aaaaa bb',
  prefix: 'M.',
  store_id: 1,
  website_id: 1,
  addresses: [
    {
      id: 'fe3c864bb4a6ccfcd05b815a1c1a1ded9fb7232d.b1a9f487a310e1002f506212f9dec5fcee720aa6',
      customer_id: 1021199,
      region: {
        region_code: null,
        region: null,
        region_id: 0,
      },
      country_id: 'FR',
      street: ['street'],
      postcode: '59000',
      city: 'lille',
      firstname: 'mouheb',
      lastname: 'gasmi',
      prefix: 'M.',
      default_shipping: true,
      default_billing: true,
      extension_attributes: {
        id_mdm: 'fe3c864bb4a6ccfcd05b815a1c1a1ded9fb7232d.b1a9f487a310e1002f506212f9dec5fcee720aa6',
      },
    },
    {
      id: '551893f5d85bf53d23a0e6c437fabcf414e24ed3.b1a9f487a310e1002f506212f9dec5fcee720aa6',
      customer_id: 1021199,
      region: {
        region_code: null,
        region: null,
        region_id: 0,
      },
      country_id: 'FR',
      street: ['avenue de flandre'],
      postcode: '59000',
      city: 'lille',
      firstname: 'mouheb',
      lastname: 'gasmi',
      prefix: 'M.',
      extension_attributes: {
        id_mdm: '551893f5d85bf53d23a0e6c437fabcf414e24ed3.b1a9f487a310e1002f506212f9dec5fcee720aa6',
      },
    },
  ],
  disable_auto_group_change: 0,
  extension_attributes: {
    is_subscribed: false,
    related_store_code: '900',
    phone_number: '1254789544',
    optins: [
      {
        label: 'Offres Marketing & Newsletter',
        finality: 'marketing_offers',
        communication_types: {
          s_m_s: {
            translation: 'sms',
            state: 'DNA',
          },
          e_m_a_i_l: {
            translation: 'email',
            state: 'REFUSED',
          },
        },
        global_state: 'REFUSED',
      },
    ],
    loyalty_card: {
      id: '63e3aab9c2478f4fc7d1c162',
      customer: 2103000612,
      management_point_of_sale: 900,
      channel: 'WEB',
      bu_id: 21,
      type: '1',
      type_label: 'Programme fidélité',
      status: 'VALID',
      membership_fee_to_pay: 0,
      validity_start_date: {
        date: '2023-02-08 14:59:21.000000',
        timezone_type: 1,
        timezone: '+01:00',
      },
      creation_date: {
        date: '2023-02-08 14:59:21.000000',
        timezone_type: 1,
        timezone: '+01:00',
      },
      update_date: {
        date: '2023-05-09 14:59:08.000000',
        timezone_type: 1,
        timezone: '+02:00',
      },
      migration_state: 'NATIVE',
      supports: [
        {
          customer: 2103000612,
          number: '93010218000008988',
          type: '8',
          type_label: 'Carte dématérialisée',
          tag: 'DEMATERIALIZED',
          status: 'VALID',
          validity_start_date: {
            date: '2023-02-08 14:59:21.000000',
            timezone_type: 1,
            timezone: '+01:00',
          },
        },
      ],
      source: 'LYSE',
      counters: [
        {
          id: '63e3aabcc5c90455ded09162',
          update_date: {
            date: '2023-05-11 11:23:42.000000',
            timezone_type: 1,
            timezone: '+02:00',
          },
          creation_date: {
            date: '2023-02-08 14:59:24.000000',
            timezone_type: 1,
            timezone: '+01:00',
          },
          type: {
            value: 'P',
            label: 'Points fidélité',
          },
          value: 140,
          earn_value: 140,
        },
      ],
      aggregated_counters: [
        {
          counter_type: {
            value: 'P',
            label: 'Points fidélité',
          },
          aggregates_by_period_unit: [],
        },
        {
          counter_type: {
            value: 'G',
            label: "Chiffre d'affaires",
          },
          aggregates_by_period_unit: {
            m_o_n_t_h: [
              {
                start_date: {
                  date: '2023-06-01 00:00:00.000000',
                  timezone_type: 1,
                  timezone: '+02:00',
                },
                end_date: {
                  date: '2023-06-30 23:59:59.000000',
                  timezone_type: 1,
                  timezone: '+02:00',
                },
                value: 0,
              },
              {
                start_date: {
                  date: '2023-05-01 00:00:00.000000',
                  timezone_type: 1,
                  timezone: '+02:00',
                },
                end_date: {
                  date: '2023-05-31 23:59:59.000000',
                  timezone_type: 1,
                  timezone: '+02:00',
                },
                value: 0,
              },
            ],
          },
        },
      ],
      rewards: [
        {
          id: '643d0955aa66af30ab048d4e',
          update_date: {
            date: '2023-04-17 10:54:45.000000',
            timezone_type: 1,
            timezone: '+02:00',
          },
          creation_date: {
            date: '2023-04-17 10:54:45.000000',
            timezone_type: 1,
            timezone: '+02:00',
          },
          model_code: 'LOYALTY_VOUCHER_ZDFR',
          model_label: 'Coupon',
          status: 'AVAILABLE',
          cancelable: true,
          additionnal_data: {
            description: 'Livraison offerte sur Zodio.fr',
            short_label: 'Livraison offerte sur Zodio.fr',
            advantage_id: '23100',
            offer_code: 'PG_ECHANGE_DE_POINTS',
            advantage_code: 'TRANSFO_FID_FREE_DELIVERY',
          },
          attributes: {
            validity_start_date: '2023-04-17T10:54:45+02:00',
            validity_end_date: '2023-07-17T23:59:59+02:00',
            use_count: 0,
          },
          calc_mode: 'COMPUTED',
        },
        {
          id: '643d12bdaa66af30ab048d50',
          update_date: {
            date: '2023-04-17 11:34:53.000000',
            timezone_type: 1,
            timezone: '+02:00',
          },
          creation_date: {
            date: '2023-04-17 11:34:53.000000',
            timezone_type: 1,
            timezone: '+02:00',
          },
          model_code: 'LOYALTY_VOUCHER_ZDFR',
          model_label: 'Coupon',
          status: 'AVAILABLE',
          cancelable: true,
          additionnal_data: {
            description: 'Livraison offerte sur Zodio.fr',
            short_label: 'Livraison offerte sur Zodio.fr',
            advantage_id: '23100',
            offer_code: 'PG_ECHANGE_DE_POINTS',
            advantage_code: 'TRANSFO_FID_FREE_DELIVERY',
          },
          attributes: {
            validity_start_date: '2023-04-17T11:34:53+02:00',
            validity_end_date: '2023-07-17T23:59:59+02:00',
            use_count: 0,
          },
          calc_mode: 'COMPUTED',
        },
        {
          id: '643d134cd2f9984283d5c9cb',
          update_date: {
            date: '2023-04-17 11:37:16.000000',
            timezone_type: 1,
            timezone: '+02:00',
          },
          creation_date: {
            date: '2023-04-17 11:37:16.000000',
            timezone_type: 1,
            timezone: '+02:00',
          },
          model_code: 'LOYALTY_VOUCHER_ZDFR',
          model_label: 'Coupon',
          status: 'AVAILABLE',
          cancelable: true,
          additionnal_data: {
            description: 'Livraison offerte sur Zodio.fr',
            short_label: 'Livraison offerte sur Zodio.fr',
            advantage_id: '23100',
            offer_code: 'PG_ECHANGE_DE_POINTS',
            advantage_code: 'TRANSFO_FID_FREE_DELIVERY',
          },
          attributes: {
            validity_start_date: '2023-04-17T11:37:16+02:00',
            validity_end_date: '2023-07-17T23:59:59+02:00',
            use_count: 0,
          },
          calc_mode: 'COMPUTED',
        },
        {
          id: '643d13e5d2f9984283d5c9ce',
          update_date: {
            date: '2023-04-17 11:39:49.000000',
            timezone_type: 1,
            timezone: '+02:00',
          },
          creation_date: {
            date: '2023-04-17 11:39:49.000000',
            timezone_type: 1,
            timezone: '+02:00',
          },
          model_code: 'LOYALTY_CHECK_ZDFR',
          model_label: 'Chèque fidélité',
          status: 'AVAILABLE',
          cancelable: true,
          additionnal_data: {
            description: 'Chèque plaisir de 10€ contre 300 points',
            short_label: 'Chèque plaisir de 10€ contre 300 points',
            advantage_id: '18600',
            offer_code: 'PG_LOYALTY_CHECK',
            advantage_code: 'CHEQUE_FID_300_PTS',
          },
          attributes: {
            validity_start_date: '2023-04-17T11:39:49+02:00',
            validity_end_date: '2023-07-17T23:59:59+02:00',
            value: 10,
            use_count: 0,
          },
          calc_mode: 'FIXED',
        },
        {
          id: '643d159dd2f9984283d5c9d1',
          update_date: {
            date: '2023-04-17 11:47:09.000000',
            timezone_type: 1,
            timezone: '+02:00',
          },
          creation_date: {
            date: '2023-04-17 11:47:09.000000',
            timezone_type: 1,
            timezone: '+02:00',
          },
          model_code: 'LOYALTY_VOUCHER_ZDFR',
          model_label: 'Coupon',
          status: 'AVAILABLE',
          cancelable: true,
          additionnal_data: {
            description: 'Une personnalisation sur le produit de votre choix',
            short_label: 'Une personnalisation sur le produit de votre choix',
            advantage_id: '17605',
            offer_code: 'PG_ECHANGE_DE_POINTS',
            advantage_code: 'TRANSFO_PERSO',
          },
          attributes: {
            validity_start_date: '2023-04-17T11:47:09+02:00',
            validity_end_date: '2023-07-17T23:59:59+02:00',
            use_count: 0,
          },
          calc_mode: 'COMPUTED',
        },
        {
          id: '643d161cd2f9984283d5c9d4',
          update_date: {
            date: '2023-04-17 11:49:16.000000',
            timezone_type: 1,
            timezone: '+02:00',
          },
          creation_date: {
            date: '2023-04-17 11:49:16.000000',
            timezone_type: 1,
            timezone: '+02:00',
          },
          model_code: 'LOYALTY_CHECK_ZDFR',
          model_label: 'Chèque fidélité',
          status: 'AVAILABLE',
          cancelable: true,
          additionnal_data: {
            description: 'Chèque plaisir de 25€ contre 750 points',
            short_label: 'Chèque plaisir de 25€ contre 750 points',
            advantage_id: '18603',
            offer_code: 'PG_LOYALTY_CHECK',
            advantage_code: 'CHEQUE_FID_750_PTS',
          },
          attributes: {
            validity_start_date: '2023-04-17T11:49:16+02:00',
            validity_end_date: '2023-07-17T23:59:59+02:00',
            value: 25,
            use_count: 0,
          },
          calc_mode: 'FIXED',
        },
        {
          id: '643d1675aa66af30ab048d53',
          update_date: {
            date: '2023-04-17 11:50:45.000000',
            timezone_type: 1,
            timezone: '+02:00',
          },
          creation_date: {
            date: '2023-04-17 11:50:45.000000',
            timezone_type: 1,
            timezone: '+02:00',
          },
          model_code: 'LOYALTY_CHECK_ZDFR',
          model_label: 'Chèque fidélité',
          status: 'AVAILABLE',
          cancelable: true,
          additionnal_data: {
            description: 'Chèque plaisir de 15€ contre 450 points',
            short_label: 'Chèque plaisir de 15€ contre 450 points',
            advantage_id: '18601',
            offer_code: 'PG_LOYALTY_CHECK',
            advantage_code: 'CHEQUE_FID_450_PTS',
          },
          attributes: {
            validity_start_date: '2023-04-17T11:50:45+02:00',
            validity_end_date: '2023-07-17T23:59:59+02:00',
            value: 15,
            use_count: 0,
          },
          calc_mode: 'FIXED',
        },
        {
          id: '643d16b2aa66af30ab048d56',
          update_date: {
            date: '2023-04-17 11:51:46.000000',
            timezone_type: 1,
            timezone: '+02:00',
          },
          creation_date: {
            date: '2023-04-17 11:51:46.000000',
            timezone_type: 1,
            timezone: '+02:00',
          },
          model_code: 'LOYALTY_CHECK_ZDFR',
          model_label: 'Chèque fidélité',
          status: 'AVAILABLE',
          cancelable: true,
          additionnal_data: {
            description: 'Chèque plaisir de 5€ contre 150 points',
            short_label: 'Chèque plaisir de 5€ contre 150 points',
            advantage_id: '18152',
            offer_code: 'PG_LOYALTY_CHECK',
            advantage_code: 'CHEQUE_FID_150_PTS',
          },
          attributes: {
            validity_start_date: '2023-04-17T11:51:46+02:00',
            validity_end_date: '2023-07-17T23:59:59+02:00',
            value: 5,
            use_count: 0,
          },
          calc_mode: 'FIXED',
        },
        {
          id: '643d1705aa66af30ab048d59',
          update_date: {
            date: '2023-04-17 11:53:09.000000',
            timezone_type: 1,
            timezone: '+02:00',
          },
          creation_date: {
            date: '2023-04-17 11:53:09.000000',
            timezone_type: 1,
            timezone: '+02:00',
          },
          model_code: 'LOYALTY_VOUCHER_ZDFR',
          model_label: 'Coupon',
          status: 'AVAILABLE',
          cancelable: true,
          additionnal_data: {
            description: 'Un atelier cuisine ou créatif',
            short_label: 'Un atelier cuisine ou créatif',
            advantage_id: '18202',
            offer_code: 'PG_ECHANGE_DE_POINTS',
            advantage_code: 'TRANSFO_ATELIER',
          },
          attributes: {
            validity_start_date: '2023-04-17T11:53:09+02:00',
            validity_end_date: '2023-07-17T23:59:59+02:00',
            use_count: 0,
          },
          calc_mode: 'COMPUTED',
        },
        {
          id: '643d1777d2f9984283d5c9d7',
          update_date: {
            date: '2023-04-17 11:55:03.000000',
            timezone_type: 1,
            timezone: '+02:00',
          },
          creation_date: {
            date: '2023-04-17 11:55:03.000000',
            timezone_type: 1,
            timezone: '+02:00',
          },
          model_code: 'LOYALTY_VOUCHER_ZDFR',
          model_label: 'Coupon',
          status: 'AVAILABLE',
          cancelable: true,
          additionnal_data: {
            description: 'Un grand cabas en coton',
            short_label: 'Un grand cabas en coton',
            advantage_id: '17603',
            offer_code: 'PG_ECHANGE_DE_POINTS',
            advantage_code: 'TRANSFO_SAC_ZODIO',
          },
          attributes: {
            validity_start_date: '2023-04-17T11:55:03+02:00',
            validity_end_date: '2023-07-17T23:59:59+02:00',
            use_count: 0,
          },
          calc_mode: 'COMPUTED',
        },
        {
          id: '643d17d9d2f9984283d5c9da',
          update_date: {
            date: '2023-04-17 11:56:41.000000',
            timezone_type: 1,
            timezone: '+02:00',
          },
          creation_date: {
            date: '2023-04-17 11:56:41.000000',
            timezone_type: 1,
            timezone: '+02:00',
          },
          model_code: 'LOYALTY_CHECK_ZDFR',
          model_label: 'Chèque fidélité',
          status: 'AVAILABLE',
          cancelable: true,
          additionnal_data: {
            description: 'Chèque plaisir de 20€ contre 600 points',
            short_label: 'Chèque plaisir de 20€ contre 600 points',
            advantage_id: '18602',
            offer_code: 'PG_LOYALTY_CHECK',
            advantage_code: 'CHEQUE_FID_600_PTS',
          },
          attributes: {
            validity_start_date: '2023-04-17T11:56:41+02:00',
            validity_end_date: '2023-07-17T23:59:59+02:00',
            value: 20,
            use_count: 0,
          },
          calc_mode: 'FIXED',
        },
        {
          id: '643d1899d2f9984283d5c9de',
          update_date: {
            date: '2023-04-17 11:59:53.000000',
            timezone_type: 1,
            timezone: '+02:00',
          },
          creation_date: {
            date: '2023-04-17 11:59:53.000000',
            timezone_type: 1,
            timezone: '+02:00',
          },
          model_code: 'LOYALTY_DISCOUNT_DAY_15_ZDFR',
          model_label: 'Journée fidélité 15 %',
          status: 'AVAILABLE',
          cancelable: false,
          additionnal_data: {
            description: 'Une journée pour soi à moins 15%',
            short_label: 'Une journée pour soi à moins 15%',
            advantage_id: '18204',
            offer_code: 'PG_ECHANGE_JOURNEE_FIDELITE',
            advantage_code: 'TRANSFO_JOURNEEFIDELITE',
          },
          attributes: {
            validity_start_date: '2023-04-17T11:59:53+02:00',
            validity_end_date: '2023-07-17T23:59:59+02:00',
            value: 15,
            use_count: 0,
            numeric_id: '12',
          },
          calc_mode: 'PERCENT',
        },
        {
          id: '643d190baa66af30ab048d5c',
          update_date: {
            date: '2023-04-17 12:01:47.000000',
            timezone_type: 1,
            timezone: '+02:00',
          },
          creation_date: {
            date: '2023-04-17 12:01:47.000000',
            timezone_type: 1,
            timezone: '+02:00',
          },
          model_code: 'LOYALTY_CHECK_ZDFR',
          model_label: 'Chèque fidélité',
          status: 'AVAILABLE',
          cancelable: true,
          additionnal_data: {
            description: 'Chèque plaisir de 30€ contre 900 points',
            short_label: 'Chèque plaisir de 30€ contre 900 points',
            advantage_id: '18604',
            offer_code: 'PG_LOYALTY_CHECK',
            advantage_code: 'CHEQUE_FID_900_PTS',
          },
          attributes: {
            validity_start_date: '2023-04-17T12:01:47+02:00',
            validity_end_date: '2023-07-17T23:59:59+02:00',
            value: 30,
            use_count: 0,
          },
          calc_mode: 'FIXED',
        },
        {
          id: '6441045c4d54746f4b63e0e2',
          update_date: {
            date: '2023-04-20 11:22:36.000000',
            timezone_type: 1,
            timezone: '+02:00',
          },
          creation_date: {
            date: '2023-04-20 11:22:36.000000',
            timezone_type: 1,
            timezone: '+02:00',
          },
          model_code: 'LOYALTY_VOUCHER_ZDFR',
          model_label: 'Coupon',
          status: 'AVAILABLE',
          cancelable: true,
          additionnal_data: {
            description: 'Une personnalisation sur le produit de votre choix',
            short_label: 'Une personnalisation sur le produit de votre choix',
            advantage_id: '17605',
            offer_code: 'PG_ECHANGE_DE_POINTS',
            advantage_code: 'TRANSFO_PERSO',
          },
          attributes: {
            validity_start_date: '2023-04-20T11:22:36+02:00',
            validity_end_date: '2023-07-20T23:59:59+02:00',
            use_count: 0,
          },
          calc_mode: 'COMPUTED',
        },
        {
          id: '644106b784b8474e43ff3b0c',
          update_date: {
            date: '2023-04-20 11:32:39.000000',
            timezone_type: 1,
            timezone: '+02:00',
          },
          creation_date: {
            date: '2023-04-20 11:32:39.000000',
            timezone_type: 1,
            timezone: '+02:00',
          },
          model_code: 'LOYALTY_VOUCHER_ZDFR',
          model_label: 'Coupon',
          status: 'AVAILABLE',
          cancelable: true,
          additionnal_data: {
            description: 'Une personnalisation sur le produit de votre choix',
            short_label: 'Une personnalisation sur le produit de votre choix',
            advantage_id: '17605',
            offer_code: 'PG_ECHANGE_DE_POINTS',
            advantage_code: 'TRANSFO_PERSO',
          },
          attributes: {
            validity_start_date: '2023-04-20T11:32:39+02:00',
            validity_end_date: '2023-07-20T23:59:59+02:00',
            use_count: 0,
          },
          calc_mode: 'COMPUTED',
        },
        {
          id: '64413a7784b8474e43ff3b0f',
          update_date: {
            date: '2023-04-20 15:13:27.000000',
            timezone_type: 1,
            timezone: '+02:00',
          },
          creation_date: {
            date: '2023-04-20 15:13:27.000000',
            timezone_type: 1,
            timezone: '+02:00',
          },
          model_code: 'LOYALTY_VOUCHER_ZDFR',
          model_label: 'Coupon',
          status: 'AVAILABLE',
          cancelable: true,
          additionnal_data: {
            description: 'Une personnalisation sur le produit de votre choix',
            short_label: 'Une personnalisation sur le produit de votre choix',
            advantage_id: '17605',
            offer_code: 'PG_ECHANGE_DE_POINTS',
            advantage_code: 'TRANSFO_PERSO',
          },
          attributes: {
            validity_start_date: '2023-04-20T15:13:27+02:00',
            validity_end_date: '2023-07-20T23:59:59+02:00',
            use_count: 0,
          },
          calc_mode: 'COMPUTED',
        },
        {
          id: '645cb313218134004ff53b08',
          update_date: {
            date: '2023-05-11 11:19:15.000000',
            timezone_type: 1,
            timezone: '+02:00',
          },
          creation_date: {
            date: '2023-05-11 11:19:15.000000',
            timezone_type: 1,
            timezone: '+02:00',
          },
          model_code: 'LOYALTY_DISCOUNT_DAY_15_ZDFR',
          model_label: 'Journée fidélité 15 %',
          status: 'AVAILABLE',
          cancelable: false,
          additionnal_data: {
            description: 'Une journée pour soi à moins 15%',
            short_label: 'Une journée pour soi à moins 15%',
            advantage_id: '18204',
            offer_code: 'PG_ECHANGE_JOURNEE_FIDELITE',
            advantage_code: 'TRANSFO_JOURNEEFIDELITE',
          },
          attributes: {
            validity_start_date: '2023-05-11T11:19:15+02:00',
            validity_end_date: '2023-08-11T23:59:59+02:00',
            value: 15,
            use_count: 0,
            numeric_id: '17',
          },
          calc_mode: 'PERCENT',
        },
        {
          id: '645cb3aae6ad120125c266b6',
          update_date: {
            date: '2023-05-11 11:21:46.000000',
            timezone_type: 1,
            timezone: '+02:00',
          },
          creation_date: {
            date: '2023-05-11 11:21:46.000000',
            timezone_type: 1,
            timezone: '+02:00',
          },
          model_code: 'LOYALTY_VOUCHER_ZDFR',
          model_label: 'Coupon',
          status: 'AVAILABLE',
          cancelable: true,
          additionnal_data: {
            description: 'Une personnalisation sur le produit de votre choix',
            short_label: 'Une personnalisation sur le produit de votre choix',
            advantage_id: '17605',
            offer_code: 'PG_ECHANGE_DE_POINTS',
            advantage_code: 'TRANSFO_PERSO',
          },
          attributes: {
            validity_start_date: '2023-05-11T11:21:46+02:00',
            validity_end_date: '2023-08-11T23:59:59+02:00',
            use_count: 0,
          },
          calc_mode: 'COMPUTED',
        },
        {
          id: '645cb41e218134004ff53b0b',
          update_date: {
            date: '2023-05-11 11:23:42.000000',
            timezone_type: 1,
            timezone: '+02:00',
          },
          creation_date: {
            date: '2023-05-11 11:23:42.000000',
            timezone_type: 1,
            timezone: '+02:00',
          },
          model_code: 'LOYALTY_VOUCHER_ZDFR',
          model_label: 'Coupon',
          status: 'AVAILABLE',
          cancelable: true,
          additionnal_data: {
            description: 'Une personnalisation sur le produit de votre choix',
            short_label: 'Une personnalisation sur le produit de votre choix',
            advantage_id: '17605',
            offer_code: 'PG_ECHANGE_DE_POINTS',
            advantage_code: 'TRANSFO_PERSO',
          },
          attributes: {
            validity_start_date: '2023-05-11T11:23:42+02:00',
            validity_end_date: '2023-08-11T23:59:59+02:00',
            use_count: 0,
          },
          calc_mode: 'COMPUTED',
        },
      ],
      badges: [],
    },
    client_type: 'inhabitant',
  },
  custom_attributes: [
    {
      attribute_code: 'from_shopping_feed',
      value: '0',
    },
    {
      attribute_code: 'client_id',
      value: 2103000612,
    },
    {
      attribute_code: 'zo_alias',
      value: 'pseudo value mgasmi126441551209887',
    },
    {
      attribute_code: 'zo_picture_url',
      value: '1576858867368.jpeg',
    },
    {
      attribute_code: 'zo_website',
      value: 'Mon web à moi value xxxx',
    },
    {
      attribute_code: 'a_propos_de_moi',
      value: 'A propos value xxxx',
    },
    {
      attribute_code: 'loyalty_card_number',
      value: '93010218000008988',
    },
    {
      attribute_code: 'mon_facebook',
      value: 'xxxxxx',
    },
    {
      attribute_code: 'mon_instagram',
      value: 'xxxxxx',
    },
    {
      attribute_code: 'mon_pinterest',
      value: 'xxxxxx',
    },
    {
      attribute_code: 'mon_youtube',
      value: 'xxxxxx',
    },
  ],
};
