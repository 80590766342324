import React from 'react';
import Link from 'next/link';
import { hideOnMobile, openLayer } from 'helpers/utils/menu';

import HeaderMenuLayer from '../HeaderMenuLayer/HeaderMenuLayer';

const HeaderMenuChildInner = ({ data }) => {
  const { name, description, image } = data;

  return (
    <>
      {image && <img className="header__menu__child__inner__image" src={image} alt="" />}
      <span className="header__menu__child__inner__name">
        {description && <span>{description}</span>}
        {name && <span>{name}</span>}
      </span>
    </>
  );
};

const HeaderMenuChild = ({ data }) => {
  const [layerIsVisible, setLayerVisible] = React.useState(false);
  const { name, url, items, type } = data;

  return (
    <li className="header__menu__child">
      {type === 'list' ? (
        <>
          <button
            className="header__menu__child__inner"
            data-type={type}
            onClick={(event) => {
              setLayerVisible(true);
              setTimeout(() => openLayer(event), 50);
            }}
            tabIndex="-1"
          >
            <HeaderMenuChildInner data={data} />
          </button>

          {layerIsVisible && items?.length > 0 && <HeaderMenuLayer items={items} />}
        </>
      ) : (
        <Link href={url}>
          <a className="header__menu__child__inner" data-type={name.toUpperCase() === name ? 'main' : type} onClick={hideOnMobile} tabIndex="-1">
            <HeaderMenuChildInner data={data} />
          </a>
        </Link>
      )}
    </li>
  );
};

export default HeaderMenuChild;
