import { useRouter } from 'next/router';
import React from 'react';
import Picto from 'components/elements/Picto/Picto';
import { useSearchBox } from 'react-instantsearch-hooks-web';
import { debounce } from 'lodash';

const HeaderSearch = ({ props }) => {
  const slug = '/search';
  const input = React.useRef(null);
  const [picto, setPicto] = React.useState('search');
  const router = useRouter();
  const { query, refine } = useSearchBox(props);

  React.useEffect(onInit, [router]);

  function onInit() {
    if (query && router.asPath.split('?')[0] === slug) {
      input.current.value = query;
      setPicto('cross');
    } else {
      input.current.value = '';
      setPicto('search');
    }
  }

  function onInputChange() {
    const { value } = input.current;

    setPicto(value.length === 0 ? 'search' : 'cross');

    if (value.trim().length >= 3) {
      router.asPath.split('?')[0] !== slug ? router.push({ pathname: slug, query: { query: value } }).then(() => refine(value)) : refine(value);
    }
  }

  function onPictoClick() {
    if (picto === 'search') input.current.focus();
    else {
      input.current.value = '';
      setPicto('search');
      router.push({ pathname: slug, query: { query: '' } }).then(() => refine(''));
    }
  }

  return (
    <section className="header__search">
      <label htmlFor="header-search-input" className="sr-only">
        Rechercher
      </label>

      <input
        type="search"
        id="header-search-input"
        className="header__search__input"
        placeholder="Rechercher..."
        onKeyUp={debounce(onInputChange, 250)}
        ref={input}
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
        maxLength="512"
      />

      <button
        className="header__search__button"
        onClick={onPictoClick}
        aria-label={picto === 'search' ? 'saisissez votre recherche' : 'effacer la recherche'}
      >
        <Picto icon={picto} data-icon={picto} className="header__search__button__picto" />
      </button>
    </section>
  );
};

export default HeaderSearch;
