import IdeaMoments from './IdeaMoments/IdeaMoments';
import Univers from './Univers/Univers';
import About from './About/About';
import MarqueUne from './MarqueUne/MarqueUne';
import FooterUnivers from './FooterUnivers/FooterUnivers';

export default {
  IdeaMoments,
  Univers,
  About,
  MarqueUne,
  FooterUnivers,
};
