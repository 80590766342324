import React from 'react';
import Link from 'next/link';

import HeaderMenuDrawer from '../HeaderMenuDrawer/HeaderMenuDrawer';
import { openDrawer } from 'helpers/utils/menu';

const HeaderMenuItem = ({ item }) => {
  const { type, name, items, url, color } = item;

  let itemClass = 'header__menu__item';
  if (color) itemClass += ' header__menu__item--' + color;

  return (
    <li className={itemClass}>
      {type === 'list' ? (
        <>
          <button className="header__menu__item__link" onClick={openDrawer} tabIndex="0">
            {name}
          </button>

          {items?.length > 0 && <HeaderMenuDrawer items={items} />}
        </>
      ) : (
        <Link href={url}>
          <a className="header__menu__item__link" tabIndex="0">
            {name}
          </a>
        </Link>
      )}
    </li>
  );
};

export default HeaderMenuItem;
