import React from 'react';
import style from './SocialFlow.module.scss';
import Slider from 'components/elements/Slider/Slider';
import { publishItems } from 'helpers/utils/publishItems';

const SocialFlow = ({ data }) => {
  try {
    const { thumbnails, title, subtitle } = data.data.dataSource.fields;

    return (
      <section className={style.SocialFlow}>
        <h2 className={style.SocialFlow__title}>{title}</h2>
        <p className={style.SocialFlow__subtitle}>{subtitle}</p>

        <Slider
          breakpoints={{
            320: { slidesPerView: 1 },
            581: { slidesPerView: 2 },
            801: { slidesPerView: 2.5 },
            980: { slidesPerView: 3.5 },
            1101: { slidesPerView: 4.25 },
          }}
          arrows={false}
        >
          {publishItems(thumbnails).map((thumb) => {
            return (
              <a key={thumb.sys.id} href={thumb.fields.ctaUrl} target="_blank" rel="noreferrer noopener">
                <img src={thumb.fields.image.fields.file.url} alt={thumb.fields.image.fields.title} />
              </a>
            );
          })}
        </Slider>
      </section>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default SocialFlow;
