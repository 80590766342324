import CommentCaMarche from './CommentCaMarche/CommentCaMarche';
import Recompenses from './Recompenses/Recompenses';
import Avantages from './Avantages/Avantages';
import Points from './Points/Points';
import Inscriptions from './Inscriptions/Inscriptions';
import BasDePage from './BasDePage/BasDePage';

export default {
  CommentCaMarche,
  Recompenses,
  Avantages,
  Points,
  Inscriptions,
  BasDePage,
};
