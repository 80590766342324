import style from './ValidationButton.module.scss';
import Link from 'next/link';
import { Button } from '@mozaic-ds/react';

const ValidationButton = ({ label, href, onClick }) => {
  return (
    <div className={style.ValidationButton}>
      {href && (
        <Link href={href}>
          <Button href={href} width="full">
            {label}
          </Button>
        </Link>
      )}

      {onClick && (
        <Button onClick={onClick} width="full">
          {label}
        </Button>
      )}
    </div>
  );
};

export default ValidationButton;
