/**
 *
 * @param {Array} order Array of ordered attributes
 * @returns
 */
export function setFiltersOrder(order, type) {
  const facets = [];

  order.map((attribute) => {
    if (facets.find((facet) => facet.attribute === attribute)) return;

    switch (attribute) {
      // INDEX PRODUCT / SEARCH
      case 'couleur_consolidee':
        return facets.push({ attribute, title: 'Couleurs', type: 'checkbox' });

      case 'V2_TYPE_PRODUIT_MATIERE':
        return facets.push({ attribute, title: 'Matières', type: 'checkbox' });

      case 'MARQUE':
        return facets.push({ attribute, title: 'Marques', type: 'checkbox' });

      case 'MADE_IN_FRANCE':
        return facets.push({ attribute, title: 'Fabriqué en France', type: 'checkbox' });

      case 'price.EUR.default':
        return facets.push({ attribute, title: 'Prix', type: 'range' });

      case 'availability_cc':
        return facets.push({ attribute, title: 'Disponibilité', type: 'checkbox' });

      case 'categories_without_path':
        return facets.push({ attribute, title: 'Catégorie', type: 'checkbox' });

      case 'categoryIds':
        return facets.push({ attribute, title: 'ID de catégorie', type: 'checkbox' });

      case 'available_at':
        return facets.push({ attribute, title: 'Disponible dans les magasins', type: 'checkbox' });

      // INDEX CONTENTFUL
      case 'fields.category.en-US':
        return facets.push({ attribute, title: 'Catégorie', type: 'checkbox' });

      case 'fields.chiefName.en-US':
        if (type === 'tendances') break;
        if (type === 'tutorials') return facets.push({ attribute, title: 'Maker', type: 'checkbox' });
        if (type === 'recipes') return facets.push({ attribute, title: 'Chef', type: 'checkbox' });

      case 'fields.qtyOfPersonn.en-US':
        if (type === 'tendances') break;
        if (type === 'tutorials') break;
        if (type === 'recipes') return facets.push({ attribute, title: 'Nombre de personnes', type: 'checkbox' });

      case 'fields.budget.en-US':
        if (type === 'tendances') break;
        if (type === 'tutorials') return facets.push({ attribute, title: 'Budget', type: 'checkbox' });
        if (type === 'recipes') break;

      case 'fields.timing.en-US':
        if (type === 'tendances') break;
        if (type === 'tutorials') return facets.push({ attribute, title: 'Durée', type: 'checkbox' });
        if (type === 'recipes') return facets.push({ attribute, title: 'Durée', type: 'checkbox' });

      case 'fields.difficulty.en-US':
        if (type === 'tendances') break;
        if (type === 'tutorials') return facets.push({ attribute, title: 'Difficulté', type: 'checkbox' });
        if (type === 'recipes') return facets.push({ attribute, title: 'Difficulté', type: 'checkbox' });
        if (type === 'recipes') return facets.push({ attribute, title: 'Durée', type: 'checkbox' });

      case 'fields.reciepeType.en-US':
        if (type === 'tendances') break;
        if (type === 'tutorials') break;
        if (type === 'recipes') return facets.push({ attribute, title: 'Type de plats', type: 'checkbok' });

      default: {
        console.log(`The attribute ${attribute} is not present in Filters.utils.js file !`);
        return facets.push({ attribute, title: attribute, type: 'checkbox' });
      }
    }
  });

  return facets;
}
