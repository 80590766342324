import React from 'react';
import css from './RichText.module.scss';
import Link from 'next/link';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';

const RichText = ({ content, style, className }) => {
  try {
    const options = {
      renderNode: {
        [BLOCKS.HEADING_1]: (node, children) => <h2 className="heading-1">{children}</h2>,
        [BLOCKS.HEADING_2]: (node, children) => <h2>{children}</h2>,
        [BLOCKS.HEADING_3]: (node, children) => <h3>{children}</h3>,
        [BLOCKS.HEADING_4]: (node, children) => <h4>{children}</h4>,
        [BLOCKS.HEADING_5]: (node, children) => <h5>{children}</h5>,
        [BLOCKS.HEADING_6]: (node, children) => <h6>{children}</h6>,
        [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
        [BLOCKS.TABLE]: (node, children) => <table>{children}</table>,
        [BLOCKS.TABLE_ROW]: (node, children) => {
          if (children.every((node) => node.nodeType === BLOCKS.TABLE_HEADER_CELL)) {
            return (
              <thead>
                <tr>{children}</tr>
              </thead>
            );
          } else {
            return (
              <tbody>
                <tr>{children}</tr>
              </tbody>
            );
          }
        },
        [BLOCKS.TABLE_CELL]: (node, children) => <td>{children}</td>,
        [BLOCKS.EMBEDDED_ENTRY]: (node, children) => <div>{children}</div>,
        [BLOCKS.EMBEDDED_ASSET]: (node) => (
          <div className="flex justify-center">
            <img src={node.data.target.fields.file.url} alt={node.data.target.fields.title} />
          </div>
        ),
        [BLOCKS.UL_LIST]: (node, children) => <ul>{children}</ul>,
        [BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,
        [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
        [BLOCKS.QUOTE]: (node, children) => <blockquote>{children}</blockquote>,
        [BLOCKS.HR]: () => <hr />,

        [INLINES.ASSET_HYPERLINK]: (node, children) => {
          try {
            const { url } = node.data.target.fields.file;
            return (
              <Link href={url}>
                <a target="_blank" rel="noreferrer">
                  {children}
                </a>
              </Link>
            );
          } catch (error) {
            console.error(error);
            return <>{children}</>;
          }
        },
        [INLINES.HYPERLINK]: (node, children) => {
          try {
            const { uri } = node.data;
            return (
              <Link href={uri}>
                <a>{children}</a>
              </Link>
            );
          } catch (error) {
            console.error(error);
            return <>{children}</>;
          }
        },
      },

      renderMark: {
        [MARKS.BOLD]: (text) => <b className={css.RichText__bold}>{text}</b>,
        [MARKS.ITALIC]: (text) => <span className={css.RichText__italic}>{text}</span>,
        [MARKS.UNDERLINE]: (text) => <span className={css.RichText__underline}>{text}</span>,
        [MARKS.CODE]: (text) => <code>{text}</code>,
      },

      renderText: (text) => text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
    };
    return (
      <div className={css.RichText + ' ' + className} style={style}>
        {documentToReactComponents(content, options)}
      </div>
    );
  } catch (error) {
    console.log(error);
    return <></>;
  }
};

export default RichText;
