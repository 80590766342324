import Picto from '../../../../elements/Picto/Picto';
import style from './WorkshopIcons.module.scss';

const WorkshopIcons = () => {
  const icons = [
    {
      icon: 'account--bordered',
      name: 'Adulte plus de 16 ans',
    },
    {
      icon: 'toque--bordered',
      name: 'Débutant',
    },
    {
      icon: 'clock',
      name: '1h',
    },
  ];
  return (
    <section className={style.WorkshopIcons}>
      {icons.map((ic, i) => (
        <div key={i} className={style.WorkshopIcons__bloc}>
          <div className={style.WorkshopIcons__bloc__icon}>
            <Picto className={style.WorkshopIcons__bloc__icon__picto} icon={ic.icon} />
          </div>
          <div className={style.WorkshopIcons__bloc__name}>
            <p>{ic.name}</p>
          </div>
        </div>
      ))}
    </section>
  );
};

export default WorkshopIcons;
