import RichText from 'components/elements/contentful/RichText/RichText';
import style from './HeadBand.module.scss';

const HeadBand = ({ data }) => {
  try {
    const { fields } = data.data.dataSource;
    const { imageLeft, imageRight, text, title } = fields;

    return (
      <section className={style.HeadBand}>
        <h1 className={style.HeadBand__title}>
          <span>{title}</span>
        </h1>

        <div className={style.HeadBand__text}>
          <RichText content={text} />
        </div>

        <img className={style.HeadBand__image} data-position="left" src={imageLeft.fields.file.url} alt="" />
        <img className={style.HeadBand__image} data-position="right" src={imageRight.fields.file.url} alt="" />
      </section>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default HeadBand;
