import React, { useEffect, useRef } from 'react';
import style from './Order.module.scss';

import Picto from 'components/elements/Picto/Picto';
import OrderAddress from './partials/OrderAddress/OrderAddress';
import Steps from 'components/elements/Steps/Steps';
import OrderItem from './partials/OrderItem/OrderItem';
import OrderSummary from './partials/OrderSummary/OrderSummary';
import { useRouter } from 'next/router';

const Order = (props) => {
  const router = useRouter();
  const slugId = router?.query?.orderId;
  const { status, val, id, price, etat } = props;
  const [showId, setShowId] = React.useState(slugId && JSON.parse(slugId) === id);

  const openDetails = (id) => {
    setShowId(id);
  };
  const closeDetails = () => setShowId(false);

  return (
    <div className={style.Order}>
      <div className={style.Order__line}>
        <p className={style.Order__icon}>
          <Picto icon="shopping-bag" />
        </p>
        <div className={style.Order__bloc}>
          <div className={style.Order__detail}>
            <p className={style.Order__detail__date}>26/01/2023</p>
            <p className={style.Order__detail__number}>{id}</p>
            <p className={style.Order__detail__price}>{price}</p>
            <p className={style.Order__detail__validate}>
              <span>{etat}</span>
            </p>
          </div>
          {!showId ? (
            <p onClick={() => openDetails(id)} className={style.Order__detail__plus}>
              <Picto icon="more" />
            </p>
          ) : (
            <p onClick={closeDetails} className={style.Order__detail__cross}>
              <Picto icon="cross" />
            </p>
          )}
        </div>
      </div>
      {showId && (
        <div className={style.Order__body}>
          <OrderAddress />
          <Steps status={status} val={val} />
          <OrderItem />
          <OrderSummary />
        </div>
      )}
    </div>
  );
};

export default Order;
