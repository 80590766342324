import style from './WorkshopsListHeader.module.scss';
import { Layer, LayerBody, LayerHeader, LayerTitle, useModals } from '@mozaic-ds/react';
import WorkshopsPictos from 'components/scopes/workshops/elements/WorkshopsPictos/WorkshopsPictos';
import SelectStore from 'components/elements/SelectStore/SelectStore';

import { pictos } from './WorkshopListHeader.mock';

const WorkshopsListHeader = () => {
  const { open } = useModals();

  try {
    return (
      <section className={style.WorkshopsListHeader}>
        <WorkshopsPictos data={pictos} />

        <h1 className={style.WorkshopsListHeader__title}>{`Tous nos ateliers à VILLENEUVE D'ASCQ`}</h1>

        <button className={style.WorkshopsListHeader__button} onClick={() => open('change-store')}>
          Changer de magasin
        </button>

        <Layer id="change-store">
          <LayerHeader id="change-store">
            <LayerTitle>Je cherche le magasin le plus proche de chez moi</LayerTitle>
          </LayerHeader>

          <LayerBody>
            <SelectStore />
          </LayerBody>
        </Layer>
      </section>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default WorkshopsListHeader;
