import React from 'react';
import { Button } from '@mozaic-ds/react';
import Picto from 'components/elements/Picto/Picto';

const Password = ({ onClick }) => {
  return (
    <div>
      <h2>Mon mot de passe</h2>
      <ul>
        <li>
          <span>Mon mot de passe :</span>
          <strong>*****</strong>
        </li>
      </ul>

      <div className="text-center mt-4 max-w-[32rem] mx-auto w-full">
        <Button width="full" onClick={onClick}>
          <Picto icon="pen" className="mr-[2rem]" /> Modifier mon mot de passe
        </Button>
      </div>
    </div>
  );
};

export default Password;
