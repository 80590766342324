import React from 'react';
import style from './Recommendation.module.scss';
import Subtitle from 'components/elements/Subtitle/Subtitle';
import Slider from 'components/elements/Slider/Slider';
import ItemTile from 'components/elements/ItemTile/ItemTile';
import WorkshopTile from 'components/elements/WorkshopTile/WorkshopTile';
import { Button } from '@mozaic-ds/react';
import Link from 'next/link';

import algoliasearch from 'algoliasearch';

const appId = process.env.NEXT_PUBLIC_ALGOLIA_APP_ID;
const apiKey = process.env.NEXT_PUBLIC_ALGOLIA_API_KEY;
const indexName = process.env.NEXT_PUBLIC_ALGOLIA_INDEX_PRODUCT;

const Recommendation = ({ data }) => {
  const [isMounted, setIsMounted] = React.useState(true);
  const [items, setItems] = React.useState(null);

  const { fields } = data.data.dataSource;
  const recommandations = fields?.productRecommandation;

  const { products, title, ctaLabel, ctaUrl } = recommandations ? recommandations[0].fields : fields;

  async function fetchData() {
    const result = await algoliasearch(appId, apiKey)
      .initIndex(indexName)
      .getObjects(Object.keys(products))
      .then(({ results }) => results.filter((item) => item?.type_id && item.visibility_catalog === 1 && item.in_stock === 1).slice(0, 4))
      .catch(console.error);

    isMounted && setItems(result);
  }

  React.useEffect(() => {
    fetchData();
    return () => setIsMounted(false);
  }, []);

  try {
    return (
      <section className={style.Recommendation}>
        <Subtitle title={title} />

        {items && (
          <Slider
            breakpoints={{
              320: { slidesPerView: 1 },
              581: { slidesPerView: 2 },
              801: { slidesPerView: 3 },
              1024: { slidesPerView: 4 },
            }}
            alignArrowsOn='article [class*="ItemTile__header"], article [class*="WorkshopTile__header"]'
          >
            {items
              ? Object.values(items).map((item) => {
                  return (
                    <React.Fragment key={item.objectID}>
                      {item.type_id === 'simple' && <ItemTile key={item.sku} data={item} />}
                      {item.type_id === 'worskhop' && <WorkshopTile key={item.sku} data={item} />}
                    </React.Fragment>
                  );
                })
              : null}
          </Slider>
        )}

        {ctaLabel && ctaUrl && (
          <div className={`${style.Recommendation__cta}`}>
            <Link href={ctaUrl}>
              <Button>{ctaLabel}</Button>
            </Link>
          </div>
        )}
      </section>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default Recommendation;
