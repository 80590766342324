const Spacer = ({ data }) => {
  try {
    const { height } = data;
    return <div style={{ height }}></div>;
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default Spacer;
