import React from 'react';
import style from './BannerCatalog.module.scss';
import RichText from 'components/elements/contentful/RichText/RichText';

const BannerCatalog = ({ data }) => {
  try {
    const { banner, imageSecondaire, cta, ctaUrl, paragraph, backgroundColor } = data.data.dataSource.fields;
    const bannerURL = banner.fields.file.url;

    const breakpoints = [
      { media: '(max-width: 800px) and (-webkit-min-device-pixel-ratio: 1)', srcSet: bannerURL + '?w=800' },
      { media: '(max-width: 800px) and (-webkit-min-device-pixel-ratio: 2)', srcSet: bannerURL + '?w=1600' },
      { media: '(max-width: 1100px) and (-webkit-min-device-pixel-ratio: 1)', srcSet: bannerURL + '?w=1100' },
      { media: '(max-width: 1100px) and (-webkit-min-device-pixel-ratio: 2)', srcSet: bannerURL + '?w=2200' },
      { media: '(max-width: 1500px) and (-webkit-min-device-pixel-ratio: 1)', srcSet: bannerURL + '?w=750' },
      { media: '(max-width: 1500px) and (-webkit-min-device-pixel-ratio: 2)', srcSet: bannerURL + '?w=1500' },
    ];

    return (
      <section className={style.BannerCatalog}>
        <div className={style.BannerCatalog__image}>
          <picture>
            {breakpoints.map((breakpoint, i) => (
              <source key={i} {...breakpoint} />
            ))}
            <img src={bannerURL} alt="" loading="lazy" />
          </picture>
        </div>

        <div className={style.BannerCatalog__content} data-background={backgroundColor}>
          <div className={style.BannerCatalog__content__text}>
            <RichText content={paragraph} />
          </div>

          {cta && ctaUrl && (
            <a className={style.BannerCatalog__content__button} href={ctaUrl} target="_blank" rel="noreferrer noopener">
              {cta}
            </a>
          )}

          <a className={style.BannerCatalog__content__button} href={'#'} target="_blank" rel="noreferrer noopener">
            Feuilleter tous nos cahiers
          </a>
        </div>

        <img className={style.BannerCatalog__visual} src={imageSecondaire.fields.file.url} alt="" loading="lazy" />
      </section>
    );
  } catch (e) {
    console.error(e);
    return <></>;
  }
};

export default BannerCatalog;
