import style from './ItemTileVariants.module.scss';

const getVariantColorImage = (color) =>
  `url(https://images.ctfassets.net/cg3jdovg6x56/3T9U91mRoR1y1gMnqUR7Jg/82a82e3aa7394c0117aea66e8dfe2ac4/variant-terracotta.png)`;

const ItemTileVariants = (data) => {
  const { productsVariations, attributesVariations, magentoId } = data;
  if (!productsVariations || !attributesVariations) return <></>;

  const varationType = Object.values(attributesVariations)[0] === 'COULEUR_PRIMAIRE' ? 'color' : 'value';
  const variants = Object.values(productsVariations);

  return (
    <div className={style.ItemTileVariants}>
      <div className={style.ItemTileVariants__list}>
        {varationType === 'color' ? (
          variants
            .sort((variant) => (variant.couleur_consolidee_value === data.couleur_consolidee ? -1 : 0))
            .slice(0, 4)
            .map((variant) => (
              <span
                data-type="color"
                key={variant.COULEUR_PRIMAIRE_value}
                className={style.ItemTileVariants__list__item}
                title={variant.couleur_consolidee_value}
                style={{ backgroundImage: getVariantColorImage(variant.couleur_consolidee_variations_value) }}
              />
            ))
        ) : (
          <span data-type="value" className={style.ItemTileVariants__list__item} title={Object.values(productsVariations[magentoId])[0]}>
            {Object.values(productsVariations[magentoId])[0]}
          </span>
        )}

        <div className={style.ItemTileVariants__choices}>{variants.length} choix</div>
      </div>
    </div>
  );
};

export default ItemTileVariants;
