import style from './Modalites.module.scss';
import Encart from '../Encart/Encart';
import { publishItems } from 'helpers/utils/publishItems';
const Modalites = ({ data }) => {
  try {
    const { fields } = data.data.dataSource;
    return (
      <div className={style.modalites}>
        <div data-anchor-id="modalites-de-remboursement" data-anchor-label="Modalités de remboursement" />

        <h2>{fields.title}</h2>

        <div className={style.modalites__items}>
          {publishItems(fields.content).map((encart) => (
            <Encart paragraph={encart.fields.paragraph} title={encart.fields.title} type="modalite" key={encart.sys.id} />
          ))}
        </div>
      </div>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default Modalites;
