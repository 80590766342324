import PLP from 'components/elements/PLP/PLP';
import { publishItems } from 'helpers/utils/publishItems';

import Categories from 'components/scopes/products/PLP/Categories/Categories';
import SeoBlock from 'components/generic/SeoBlock/SeoBlock';

const TutoList = ({ data }) => {
  const { algoliaQueryId, contentReferences, title, urlKey } = data.data.dataSource.fields;

  let type;

  if (urlKey.includes('recettes')) type = 'recipes';
  if (urlKey.includes('tutoriels')) type = 'tutorials';
  if (urlKey.includes('tendance')) type = 'tendances';

  try {
    return (
      <>
        {type && (
          <PLP
            type={type}
            title={title}
            index={process.env.NEXT_PUBLIC_ALGOLIA_INDEX_CONTENTFUL}
            config={{ hitsPerPage: 24, ruleContexts: [algoliaQueryId] }}
          />
        )}

        {contentReferences &&
          publishItems(contentReferences).map((content) => {
            const componentID = content?.sys?.id;
            const componentGenID = content?.sys?.contentType?.sys?.id;
            const componentData = { data: { dataSource: content } };

            switch (componentGenID) {
              case 'genLinksContainer':
                return <Categories key={componentID} data={componentData} />;

              case 'genSeoText':
                return <SeoBlock key={componentID} data={componentData} />;

              default:
                console.warn(`An error occured rendering "${componentGenID}". Please check DynamicContentfull component.`);
                return <></>;
            }
          })}
      </>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default TutoList;
