import Link from 'next/link';
import Picto from '../Picto/Picto';
import style from './ContentTile.module.scss';
import { Flag } from '@mozaic-ds/react';

const ContentTile = ({ tile }) => {
  try {
    const { fields, sys } = tile;
    const { id } = sys?.contentType?.sys;
    const { title, difficulty, qtyOfPersonn, editoType, timing, budget, reciepeTitle, reciepePictures, shortDescription, urlKey } = fields;

    const BudgetPictos = ({ data }) => {
      const values = { '1€ à 14€': 1, '15€ à 30€': 2, 'Plus de 30€': 3 };
      const value = values[data];
      return [...Array(3)].map((_, i) => <Picto key={i} icon="cost" data-active={i + 1 <= value} />);
    };

    const DificultyPictos = ({ data }) => {
      const values = { Facile: 1, Intermédiaire: 2, Difficile: 3 };
      const value = values[data];
      return [...Array(3)].map((_, i) => (
        <Picto
          key={i}
          icon={(Object.values(editoType).toString() || editoType.toString()) === 'Recette' ? 'toque--bordered' : 'hammer'}
          data-active={i + 1 <= value}
        />
      ));
    };

    const convertItem = (item) => {
      return typeof item !== 'object' ? item : Object.values(item);
    };

    const onError = (image) => {
      image.target.src = 'https://placehold.co/300x240/f5f5f5/333?text=Image+manquante';
    };

    return (
      <article className={style.ContentTile}>
        <Link href={typeof urlKey !== 'object' ? `${urlKey}` : `${Object.values(urlKey)}`}>
          <a>
            {reciepePictures ? (
              <img
                onError={onError}
                className={style.ContentTile__image}
                src={
                  typeof reciepePictures !== 'object'
                    ? reciepePictures[0].fields.file.url
                    : Object.values(reciepePictures)[0]?.fields
                    ? Object.values(reciepePictures)[0]?.fields?.file.url
                    : 'https://placehold.co/300x240/f5f5f5/333?text=Image+manquante'
                }
                alt=""
              />
            ) : (
              <img className={style.ContentTile__image} src="" alt="" onError={onError} />
            )}

            {editoType ? (
              <Flag theme="primary-02" variant="solid">
                {typeof editoType !== 'object' ? editoType.toString() : Object.values(editoType).toString()}
              </Flag>
            ) : (
              <Flag theme="primary-02" variant="solid">
                Tendance
              </Flag>
            )}

            <h3 className={style.ContentTile__title}>{id !== 'ficheTendance' ? convertItem(reciepeTitle) : convertItem(title)}</h3>

            <p className={style.ContentTile__description}>{shortDescription && convertItem(shortDescription)}</p>

            {editoType ? (
              <div className={style.ContentTile__infos}>
                {timing && (
                  <p className={style.ContentTile__infos__item} aria-label={`Durée: ${convertItem(timing)}`} role="tooltip">
                    <Picto icon="clock" /> {convertItem(timing)}
                  </p>
                )}

                {difficulty && (
                  <p className={style.ContentTile__infos__item} aria-label={`difficulté : ${convertItem(difficulty)}`} role="tooltip">
                    <DificultyPictos data={convertItem(difficulty)} />
                  </p>
                )}
                {(editoType.toString() === 'Tutoriel' || Object.values(editoType).toString() === 'Tutoriel') && (budget || Object.values(budget)) && (
                  <p className={style.ContentTile__infos__item} aria-label={`budget: ${convertItem(budget)}`} role="tooltip">
                    <BudgetPictos data={convertItem(budget)} />
                  </p>
                )}

                {(editoType.toString() === 'Recette' || Object.values(editoType).toString() === 'Recette') && (
                  <p className={style.ContentTile__infos__item} aria-label={`pour ${convertItem(qtyOfPersonn)} personnes`} role="tooltip">
                    {qtyOfPersonn && (
                      <>
                        <Picto icon="account" /> {convertItem(qtyOfPersonn)}
                      </>
                    )}
                  </p>
                )}
              </div>
            ) : (
              <div className={style.ContentTile__link}>
                Lien vers le contenu
                <Picto icon="arrow--long" />
              </div>
            )}
          </a>
        </Link>
      </article>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default ContentTile;
