import React from 'react';
import style from './About.module.scss';

const About = ({ data }) => {
  try {
    const { title, subtitle, textHousky, image, description } = data.data.dataSource.fields;

    return (
      <section className={style.About}>
        <h2 className={style.About__title}>{title}</h2>
        <p className={style.About__text}>{description}</p>
        <span className={style.About__baseline}>{textHousky}</span>

        <div className={style.About__image}>
          <img src={image.fields.file.url} alt="" />
          <span>{subtitle}</span>
        </div>
      </section>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default About;
