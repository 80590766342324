import React from 'react';
import style from './ImagesTargets.module.scss';
import { publishItems } from 'helpers/utils/publishItems';
import Targets from 'components/elements/Targets/Targets';

const ImagesTargets = ({ data }) => {
  try {
    const { title, subtitle, imagesTarget } = data.data.dataSource.fields;

    return (
      <div className={style.imagesTargets}>
        <h2 className={style.imagesTargets__title}>{title}</h2>

        <p className={style.imagesTargets__subtitle}>{subtitle}</p>

        <div className={style.imagesTargets__blocs}>
          {publishItems(imagesTarget).map((targ) => {
            const { image736x736px, targets } = targ.fields;

            return (
              <div key={targ.sys.id} className={style.imagesTargets__blocs__item}>
                <img src={image736x736px.fields.file.url} alt={image736x736px.fields.title} />
                <Targets targets={targets} />
              </div>
            );
          })}
        </div>
      </div>
    );
  } catch (e) {
    console.error(e);
    return <></>;
  }
};

export default ImagesTargets;
