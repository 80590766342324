import React from 'react';
import style from './WorkshopTile.module.scss';
import Link from 'next/link';
import { debounce } from 'lodash';

import WorkshopTileImage from './partials/WorkshopTileImage/WorkshopTileImage';
import WorkshopTileFlag from './partials/WorkshopTileFlag/WorkshopTileFlag';
import WorkshopTileTitle from './partials/WorkshopTileTitle/WorkshopTileTitle';
import WorkshopTileDetails from './partials/WorkshopTileDetails/WorkshopTileDetails';

import Price from 'components/elements/Price/Price';

const WorkshopTile = ({ data }) => {
  const item = React.useRef();

  function calculateWidth() {
    item.current?.toggleAttribute('small', item.current?.offsetWidth <= 230);
  }

  React.useEffect(() => {
    setTimeout(calculateWidth, 50);
    window.addEventListener('resize', debounce(calculateWidth, 50));
    return window.removeEventListener('resize', calculateWidth);
  }, []);

  return (
    <article ref={item} className={style.WorkshopTile}>
      <Link href={data.url.replace('fr//', 'fr/')}>
        <a>
          <div className={style.WorkshopTile__header}>
            <WorkshopTileFlag {...data} />
            <WorkshopTileImage {...data} />
          </div>

          <div className={style.WorkshopTile__content}>
            <WorkshopTileTitle {...data} />
            <Price size="s" data={data.price.EUR} unity={'pers'} />
            <WorkshopTileDetails {...data} />
          </div>
        </a>
      </Link>
    </article>
  );
};

export default WorkshopTile;
