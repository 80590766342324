import React, { useEffect, useRef, useState } from 'react';
import style from './PasswordForm.module.scss';
import { Button, Field, PasswordInput, TextInput } from '@mozaic-ds/react';
import { rules } from 'helpers/utils/formRules';
import { useForm } from 'react-hook-form';
import PasswordStrengh from 'components/elements/PasswordStrengh/PasswordStrengh';
import Picto from 'components/elements/Picto/Picto';
import alert from 'helpers/utils/alert';

const PasswordForm = () => {
  const [errorShow, setErrorShow] = useState(false);
  const [validShow, setValidShow] = useState(false);
  const [password, setPassword] = useState('');
  const form = useRef();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, touchedFields },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  console.log(errors);

  const watchPassword = watch('passwordCurrent');

  useEffect(() => {
    watchPassword;
  }, [watchPassword]);

  const onSubmit = (data) => {
    console.log('datas', data);
    if (watchPassword !== 'Zouber@123') {
      setErrorShow(true);
    } else {
      setValidShow(true);
    }
  };

  React.useEffect(() => {
    validShow && alert('success', 'Votre mot de passe a été modifié', 5000);
  }, [validShow]);

  React.useEffect(() => {
    errorShow && alert('error', 'Votre mot de passe actuelle nest pas juste', 5000);
  }, [errorShow]);

  return (
    <form ref={form} onSubmit={handleSubmit(onSubmit)} className={style['passwordForm']}>
      <h2>Mon mot de passe</h2>
      <div>
        <Field htmlFor="password" label="Ancien mot de passe">
          <TextInput type="password" id="password" size="s" {...register('passwordCurrent')} />
        </Field>
        <Field htmlFor="password" label="Nouveau mot de passe" error={errors?.password?.message} className="mt-[2rem]">
          <PasswordStrengh password={password} />

          <PasswordInput
            id="password"
            size="s"
            placeholder="Nouveau mot de passe"
            labelHide={<span className="w-[4.5rem] block">Cacher</span>}
            labelShow={<span className="w-[4.5rem] block">Voir</span>}
            {...register('password', rules.password)}
            isInvalid={errors.password}
            isValid={touchedFields.password && !errors.password}
            onChange={(event) => {
              setPassword(event.target.value);
            }}
          />
        </Field>
        <Field htmlFor="passwordConfirm" label="Nouveau mot de passe" className="text-left mt-[2rem]" error={errors?.passwordConfirm?.message}>
          <PasswordInput
            id="passwordConfirm"
            size="s"
            labelHide={<span className="w-[4.5rem] block">Cacher</span>}
            labelShow={<span className="w-[4.5rem] block">Voir</span>}
            placeholder="Confirmer le mot de passe"
            {...register('passwordConfirm', rules.passwordConfirm)}
            isInvalid={errors.passwordConfirm}
            isValid={touchedFields.passwordConfirm && !errors.passwordConfirm}
          />
        </Field>
      </div>
      <div className={style['passwordForm__button']}>
        <Button width="full" type="submit" disabled={Object.keys(errors).length}>
          <Picto icon="check" className="mr-[4rem]" /> Sauvegarder
        </Button>
      </div>
    </form>
  );
};

export default PasswordForm;
