import React from 'react';
import style from './ProductUGC.module.scss';
import Subtitle from 'components/elements/Subtitle/Subtitle';
import Picto from 'components/elements/Picto/Picto';
import Link from 'next/link';
import Slider from 'components/elements/Slider/Slider';

import mock from './ProductUGC.mock';

const ProductUGC = () => {
  try {
    return (
      <section className={style.ProductUGC}>
        <Subtitle title="Les passionnés en parlent !" />

        <div className={style.ProductUGC__content}>
          <div className={style.ProductUGC__content__list}>
            <Slider
              breakpoints={{
                0: { slidesPerView: 1 },
                581: { slidesPerView: 2 },
                801: { slidesPerView: 3 },
                1101: { slidesPerView: 4 },
              }}
            >
              {mock.map((item) => {
                return item.type === 'image' ? (
                  <div key={item.image} className={style.ProductUGC__content__list__item} data-type="image" tabIndex="0">
                    <img src={item.img} alt="" />
                    <div className={style.ProductUGC__content__list__item__mask}>
                      <Picto icon="circle--account" />
                    </div>
                  </div>
                ) : item.type === 'tuto' ? (
                  <Link key={item.image} href="/">
                    <a className={style.ProductUGC__content__list__item} data-type="tuto">
                      <img src={item.img} alt="" />
                      <div className={style.ProductUGC__content__list__item__mask} dangerouslySetInnerHTML={{ __html: item.text }} />
                    </a>
                  </Link>
                ) : (
                  <></>
                );
              })}
            </Slider>
          </div>
        </div>
      </section>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default ProductUGC;
