import React, { useState } from 'react';
import style from './Address.module.scss';

import { Button, Modal, ModalBody, ModalFooter, useModals } from '@mozaic-ds/react';
import AddressForm from './partials/AddressForm/AddressForm';
import Picto from 'components/elements/Picto/Picto';

const Address = ({ addresses }) => {
  const [showFormAddress, setShowFormAddress] = React.useState(false);
  const [changeAdrress, setChangeAddress] = useState('');
  const { open, register, unregister } = useModals();
  const modalAccount = 'account';

  React.useEffect(() => {
    register('modalAccount');
    return () => unregister('modalAccount');
  }, [register]);

  const removeAddress = () => {
    window.localStorage.removeItem('dataForm');
    window.location.reload();
  };
  const myAddress = (data) => {
    setChangeAddress(data);
    setShowFormAddress(true);
  };

  return (
    <>
      <h2>Mon carnet d&apos;adresse</h2>
      {addresses && (
        <div className={style['address']}>
          {addresses &&
            addresses.map((address) => (
              <div key={address.id} className={style['address__item']}>
                <p>
                  {address.firstname} {address.lastname} {address.street.toString()} {address.postcode} {address.city} {address.country_id}
                </p>
                <div>
                  <Button theme="secondary" className="mr-2" onClick={() => myAddress(address)}>
                    <Picto icon="pen" />
                  </Button>{' '}
                  <Button onClick={() => open(modalAccount)}>
                    <Picto icon="cross" />
                  </Button>
                </div>
              </div>
            ))}
          <Modal id={modalAccount}>
            <ModalBody>
              <p className="text-center mt-5">Êtes-vous sûr(e) de vouloir effacer cette adresse ?</p>
            </ModalBody>
            <ModalFooter>
              <Button onClick={removeAddress}>Ok</Button>
            </ModalFooter>
          </Modal>
        </div>
      )}
      {showFormAddress && <AddressForm adrressChange={changeAdrress} />}
      {!showFormAddress && (
        <div className="text-center mt-4 max-w-[32rem] mx-auto w-full">
          <Button width="full" onClick={() => setShowFormAddress(true)}>
            <Picto icon="pen" className="mr-[4rem]" /> Ajouter une nouvelle adresse
          </Button>
        </div>
      )}
    </>
  );
};

export default Address;
