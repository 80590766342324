import React from 'react';
import style from './PLP.module.scss';
import { eventProductView, eventSearchDisplay } from 'helpers/utils/dataLayer';
import { applyUserData } from 'helpers/utils/algolia';
import { Index, useConfigure, useHits, usePagination, useSortBy } from 'react-instantsearch-hooks-web';
import { Field, Select } from '@mozaic-ds/react';

import Filters, { FiltersButton } from './partials/Filters/Filters';
import Items from './partials/Items/Items';
import Pagination from './partials/Pagination/Pagination';
import Banner from './partials/Banner/Banner';

const PLPInner = ({ data }) => {
  const { type, index, config, title } = data;

  const { results } = useHits(); // Get results from Alolia query
  if (results?.userData) results = applyUserData(results, results.userData); // Apply userData to the results

  // GET ITEMS FROM useHits HOOK
  const { hits, nbHits, query, renderingContent, banner } = results;

  // GET ITEMS FROM usePagination HOOK
  const { currentRefinement: currentPage, nbPages } = usePagination();

  // GET ITEMS FROM useSortBy HOOK
  const {
    currentRefinement: currentSort,
    options: sortOptions,
    canRefine: canSort,
    refine: sort,
  } = useSortBy({
    items: [
      { label: 'Pertinence', value: index },
      { label: 'Prix le moins cher', value: `${index}_price_default_asc` },
      { label: 'Prix le plus cher', value: `${index}_price_default_desc` },
      { label: 'Nouveau', value: `${index}_created_at_desc` },
    ],
  });

  useConfigure({ ...config, index });

  React.useEffect(() => {
    const hitsEvents = hits.slice(0, 3);
    eventSearchDisplay({
      cdl_search: {
        number_result: nbHits || 0,
        keyword: query,
        cdl_page_number: currentPage + 1,
      },
    });
    eventProductView({
      cdl_products_list: hitsEvents,
    });
  }, [hits, nbPages]);

  return (
    <section className={style.PLP}>
      <div className={style.PLP__header}>
        {type === 'search' && (
          <div className={style.PLP__header__column}>
            <h2 className={style.PLP__title}>
              {nbHits === 0 && "Nous n'avons trouvé aucun résultat"}
              {nbHits === 1 && 'Nous avons trouvé 1 résultat'}
              {nbHits > 1 && `Nous avons trouvé ${nbHits} résultats`}
            </h2>
          </div>
        )}

        {type === 'products' && title && (
          <div className={style.PLP__header__column}>
            <h2 className={style.PLP__title}>
              {title}&nbsp;&nbsp;<small>({nbHits} produits)</small>
            </h2>
          </div>
        )}

        {type === 'tutorials' && title && (
          <div className={style.PLP__header__column}>
            <h2 className={style.PLP__title}>
              {title}&nbsp;&nbsp;<small>({nbHits} tutoriels)</small>
            </h2>
          </div>
        )}
        {type === 'tendances' && title && (
          <div className={style.PLP__header__column}>
            <h2 className={style.PLP__title}>
              {title}&nbsp;&nbsp;<small>({nbHits} tendances)</small>
            </h2>
          </div>
        )}

        {type === 'recipes' && title && (
          <div className={style.PLP__header__column}>
            <h2 className={style.PLP__title}>
              {title}&nbsp;&nbsp;<small>({nbHits} recettes)</small>
            </h2>
          </div>
        )}

        <div className={style.PLP__header__column}>
          <div className={style.PLP__header__buttons}>
            <div className={style.PLP__header__buttons__filter}>
              <FiltersButton />
            </div>
            {['search', 'category', 'workshops'].includes(type) && nbHits > 0 && canSort && (
              <div className={style.PLP__header__buttons__sort}>
                <Field label="Trier par">
                  <Select size="s" value={currentSort} options={sortOptions.map((option) => option)} onChange={(e) => sort(e.target.value)} />
                </Field>
              </div>
            )}
          </div>
        </div>
      </div>

      {nbHits > 0 ? (
        <div className={style.PLP__content}>
          <div className={style.PLP__content__column}>
            <Filters order={renderingContent?.facetOrdering?.facets?.order} type={type} />
          </div>

          <div className={style.PLP__content__column}>
            {banner && <Banner data={banner} />}
            <Items hits={hits} type={type} />
            <Pagination />
          </div>
        </div>
      ) : (
        <p className={style.PLP__empty}>
          {type === 'search' ? (
            <>
              {query ? <strong>Aucun résultat pour &quot;{query}&quot;</strong> : <strong>Aucun résultat</strong>}

              <span>Vous pouvez essayer ces recherches populaires :</span>
              <span>
                <button onClick={() => search('seviette')}>seviette</button>, <button onClick={() => search('moule')}>moule</button>,{' '}
                <button onClick={() => search('cadre')}>cadre</button>, <button onClick={() => search('miroir')}>miroir</button>
              </span>
              <span>
                <button onClick={() => search()}>ou voir tous les produits</button>
              </span>
            </>
          ) : (
            <strong>Votre recherche n&apos;a donné aucun résultats</strong>
          )}
        </p>
      )}
    </section>
  );
};

const PLP = ({ type, index = process.env.NEXT_PUBLIC_ALGOLIA_INDEX_SEARCH, config = {}, title = 'Catégorie' }) => (
  <Index indexName={index}>
    <PLPInner data={{ type, index, config, title }} />
  </Index>
);

export default PLP;
