import React from 'react';
import style from './StoreEvents.module.scss';
import Slider from 'components/elements/Slider/Slider';
import WorkshopTile from 'components/elements/WorkshopTile/WorkshopTile';

import algoliasearch from 'algoliasearch';

const appId = process.env.NEXT_PUBLIC_ALGOLIA_APP_ID;
const apiKey = process.env.NEXT_PUBLIC_ALGOLIA_API_KEY;
const indexName = process.env.NEXT_PUBLIC_ALGOLIA_INDEX_PRODUCT;

const storeCode = 102; // A dynamiser

const StoreEvents = () => {
  const [isMounted, setIsMounted] = React.useState(true);
  const [items, setItems] = React.useState(false);

  async function fetchData() {
    const result = await algoliasearch(appId, apiKey)
      .initIndex(indexName)
      .search('', {
        hitsPerPage: 100,
        filters: `type_id: "workshop" AND visibility_catalog: 1 AND workshop.merchant_store_code: "${storeCode}"`,
      })
      .then(({ hits }) => hits)
      .then((hits) => hits.sort((a, b) => new Date(b.workshop.time_slot) - new Date(a.workshop.time_slot)))
      .then((hits) => hits.slice(0, 4))
      .catch(console.error);

    isMounted && setItems(result);
  }

  React.useEffect(() => {
    fetchData();
    return () => setIsMounted(false);
  }, []);

  try {
    return items ? (
      <section className={style.StoreEvents}>
        <h2 className={style.StoreEvents__title}>
          Les prochains évènements <span>de votre magasin</span>
        </h2>

        <div className={style.StoreEvents__content}>
          <Slider
            breakpoints={{
              320: { slidesPerView: 1 },
              581: { slidesPerView: 2 },
              801: { slidesPerView: 3 },
              1024: { slidesPerView: 4 },
            }}
            alignArrowsOn='article [class*="ItemTile__header"], article [class*="WorkshopTile__header"]'
          >
            {items.map((item) => (
              <WorkshopTile key={item.objectID} data={item} />
            ))}
          </Slider>
        </div>
      </section>
    ) : null;
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default StoreEvents;
