import React from 'react';
import style from './OurUnivers.module.scss';
import Slider from 'components/elements/Slider/Slider';
import { publishItems } from 'helpers/utils/publishItems';
import Link from 'next/link';
import { Button } from '@mozaic-ds/react';

const OurUnivers = ({ data }) => {
  try {
    const { title, subtitle, univers } = data.data.dataSource.fields;

    return (
      <section className={style.OurUnivers}>
        <h2 className={style.OurUnivers__title}>{title}</h2>
        <div className={style.OurUnivers__subtitle}>{subtitle}</div>

        <div className={style.OurUnivers__slider}>
          <Slider
            breakpoints={{
              320: { slidesPerView: 1 },
              581: { slidesPerView: 2 },
              801: { slidesPerView: 3 },
              1100: { slidesPerView: 4 },
            }}
          >
            {publishItems(univers).map((item) => {
              const { thumbmail, title, url } = item.fields;
              const { id } = item.sys;

              return (
                <Link href={url} key={id}>
                  <a className={style.OurUnivers__slider__item}>
                    <img className={style.OurUnivers__slider__item__image} loading="lazy" src={thumbmail.fields.file.url} alt={title} />
                    <div className={style.OurUnivers__slider__item__button}>
                      <Button theme="invert" width="full">
                        {title}
                      </Button>
                    </div>
                  </a>
                </Link>
              );
            })}
          </Slider>
        </div>
      </section>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default OurUnivers;
