import Subtitle from 'components/elements/Subtitle/Subtitle';
import Link from 'next/link';
import { Button } from '@mozaic-ds/react';
import style from './IdeaMoments.module.scss';
import { publishItems } from 'helpers/utils/publishItems';

const IdeaMoments = ({ data }) => {
  try {
    const { blocs, titre } = data.data.dataSource.fields;

    return (
      <>
        <Subtitle title={titre} />

        <section className={style.Ideas}>
          {publishItems(blocs).map((idea) => {
            const { id } = idea.sys;
            const { title, desktopImage, mobileImage, url } = idea.fields;

            const mobileImageUrl = `https:${mobileImage.fields.file.url}`;
            const desktopImageUrl = `https:${desktopImage.fields.file.url}`;

            const breakpoints = [
              {
                media: '(max-width: 500px) and (-webkit-min-device-pixel-ratio: 1)',
                srcSet: `${mobileImageUrl}?w=500`,
              },
              {
                media: '(max-width: 500px) and (-webkit-min-device-pixel-ratio: 2)',
                srcSet: `${mobileImageUrl}?w=1000`,
              },
              {
                media: '(max-width: 1400px) and (-webkit-min-device-pixel-ratio: 1)',
                srcSet: `${desktopImageUrl}?w=690`,
              },
              {
                media: '(max-width: 1400px) and (-webkit-min-device-pixel-ratio: 2)',
                srcSet: `${desktopImageUrl}?w=1800`,
              },
            ];

            return (
              <Link href={url} key={id}>
                <a className={style.Ideas__block}>
                  <picture>
                    {breakpoints.map((breakpoint, i) => (
                      <source key={i} {...breakpoint} />
                    ))}
                    <img className={style.Ideas__block__image} src={`${desktopImageUrl}?w=1800`} alt={title} />
                  </picture>

                  <div className={style.Ideas__block__button}>
                    <Button theme="invert" width="full" tabIndex="-1">
                      {title}
                    </Button>
                  </div>
                </a>
              </Link>
            );
          })}
        </section>
      </>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default IdeaMoments;
