import { Button, Field, TextArea, TextInput } from '@mozaic-ds/react';

import React from 'react';
import { rules } from 'helpers/utils/formRules';
import style from './Form.module.scss';
import { useForm } from 'react-hook-form';

const Form = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const form = React.useRef();

  function onSubmit(data) {
    console.log('onSubmit', data);
    reset();
  }

  return (
    <form ref={form} noValidate className={style.Form} onSubmit={handleSubmit(onSubmit)}>
      <Field htmlFor="lastname" label={rules.lastname.required ? 'Nom*' : 'Nom'} className={style.Form__field} error={errors?.lastname?.message}>
        <TextInput id="lastname" placeholder="Nom de l'expéditeur" {...register('lastname', rules.lastname)} isInvalid={errors.lastname} />
      </Field>

      <Field
        htmlFor="firstname"
        label={rules.firstname.required ? 'Prénom*' : 'Prénom'}
        className={style.Form__field}
        error={errors?.firstname?.message}
      >
        <TextInput id="firstname" placeholder="Prénom de l'expéditeur" {...register('firstname', rules.firstname)} isInvalid={errors.firstname} />
      </Field>

      <Field
        htmlFor="tutorialMessage"
        label={rules.tuto.message.required ? 'Votre message*' : 'Votre message'}
        className={style.Form__field}
        error={errors?.message?.message}
      >
        <TextArea
          id="tutorialMessage"
          placeholder="Votre message"
          rows="10"
          style={{ resize: 'vertical' }}
          {...register('message', rules.tuto.message)}
        />
      </Field>

      <div className={style.Form__submit}>
        <Button type="submit" width="full">
          Envoyer mon message
        </Button>
      </div>
    </form>
  );
};

export default Form;
