import style from './Encart.module.scss';
import RichText from 'components/elements/contentful/RichText/RichText';

const Encart = ({ paragraph, title, type }) => {
  try {
    return (
      <div className={style[`Encart__${type}`]}>
        {title && <h3>{title}</h3>}
        <RichText content={paragraph} />
      </div>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default Encart;
