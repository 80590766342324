import style from './Fonctionnement.module.scss';
import { Fragment } from 'react';
import RichText from 'components/elements/contentful/RichText/RichText';
import FideliteHeader from '../../partials/FideliteHeader/FideliteHeader';
import { publishItems } from 'helpers/utils/publishItems';

const Fonctionnement = ({ point }) => {
  try {
    const { title, titleHousky, content } = point.fields;

    return (
      <div className={style.Fonctionnement}>
        <FideliteHeader title={title} titleHousky={titleHousky} />

        <div className={style.Fonctionnement__items}>
          {publishItems(content).map((item) => {
            const { paragraph, illustration, title, titleHousky, subtitle } = item.fields;

            return (
              <Fragment key={item.sys.id}>
                {item.sys.contentType.sys.id === 'genRichText' && (
                  <div className={style.Fonctionnement__items__text}>
                    <RichText content={paragraph} />
                  </div>
                )}

                {item.sys.contentType.sys.id === 'loyaltyGiftThumbnails' && (
                  <div className={style.Fonctionnement__items__loyalty}>
                    <img className={style.Fonctionnement__items__loyalty__illustration} src={illustration.fields.file.url} alt="" />

                    <h3 className={style.Fonctionnement__items__loyalty__title}>
                      {title} <span>{titleHousky}</span> <em>{subtitle}</em>
                    </h3>
                  </div>
                )}
              </Fragment>
            );
          })}
        </div>
      </div>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default Fonctionnement;
