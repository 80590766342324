import style from './LoyaltyCard.module.scss';

const classNames = (...classes) => {
  return classes.join(' ');
};

const LoyaltyCard = ({ children, ...data }) => {
  try {
    const { userID, userFidelity, type } = data;

    return (
      <figure className={classNames(style.LoyaltyCard, type === 'programme' && style.LoyaltyCard__fidelity)}>
        <div className={style.LoyaltyCard__text}>
          <div className={style.LoyaltyCard__text__baseline}>Entre nous. Le programme de fidélité Zôdio qui vous ressemble</div>

          {userID && type !== 'programme' && (
            <div className={style.LoyaltyCard__text__userId}>
              Mon identifiant{' '}
              <span>
                {userID} {userFidelity}
              </span>
            </div>
          )}
        </div>

        <div className={classNames(style.LoyaltyCard__content, type === 'programme' && style.LoyaltyCard__contentFidelity)}>{children}</div>
      </figure>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default LoyaltyCard;
