import React from 'react';
import style from 'components/scopes/login/Login.module.scss';
import Link from 'next/link';
import PipeString from 'components/elements/PipeString/PipeString';
import { rules } from 'helpers/utils/formRules';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import { Button, Field, TextInput } from '@mozaic-ds/react';
import { useZodioAccount } from 'frontastic';
import alert from 'helpers/utils/alert';

const SignIn = () => {
  const form = React.useRef();
  const router = useRouter();

  const { email, referer } = router.query;

  const { resetPassword } = useZodioAccount();

  const {
    getValues,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { email },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  async function onSubmit({ email }) {
    try {
      await resetPassword(email);
      alert(
        'success',
        `S'il y a un compte associé à l'adresse [${email}](), vous recevrez un email avec un lien pour réinitialiser votre mot de passe.`,
        7000,
      );
      router.push({ pathname: '/connexion', query: { referer, email } });
    } catch (error) {
      console.error(error);
      alert('error', "Une erreur s'est produite. Veuillez contacter le service client.", 7000);
    }
  }

  try {
    return (
      <section className={style.Login}>
        <div className={style.Login__card}>
          <h2 className={style.Login__card__title}>Mot de passe oublié</h2>

          <p className={style.Login__card__text}>
            <PipeString string="Oups... Vous avez oublié votre mot de passe ?|Pas de panique, on s'en occupe !" />
          </p>

          <form className={style.Login__card__form} noValidate ref={form} onSubmit={handleSubmit(onSubmit)}>
            <Field htmlFor="email" label={rules.email.required ? 'Adresse email*' : 'Adresse email'} error={errors?.email?.message}>
              <TextInput id="email" {...register('email', rules.email)} isValid={getValues('email') && !errors.email} isInvalid={errors.email} />
            </Field>

            <div className={style.Login__card__form__button}>
              <Button type="submit" width="full">
                Valider
              </Button>

              <Link href={{ pathname: '/connexion', query: { email: getValues('email') } }}>
                <a className="block mt-[1.6rem]">Retour à la page de connexion</a>
              </Link>
            </div>
          </form>
        </div>
      </section>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default SignIn;
