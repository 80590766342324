import { publishItems } from 'helpers/utils/publishItems';
import ContentTiles from 'components/generic/ContentTiles/ContentTiles';
import SeoBlock from 'components/generic/SeoBlock/SeoBlock';
import TitleWithHousky from 'components/generic/TitleWithHousky/TitleWithHousky';
import OurUnivers from 'components/generic/OurUnivers/OurUnivers';
import BannerInspiHouse from 'components/generic/BannerInspiHouse/BannerInspiHouse';
import ImagesTargets from 'components/generic/ImagesTargets/ImagesTargets';

const DynamicTendance = ({ data }) => {
  try {
    const { contentReferences } = data.data.dataSource.fields;

    return (
      <>
        {publishItems(contentReferences).map((content) => {
          const componentGenID = content?.sys?.contentType?.sys?.id;
          const componentData = { data: { dataSource: content } };

          switch (componentGenID) {
            case 'genTitleSubTitleRichText':
              return <TitleWithHousky key={componentGenID} data={componentData} />;
            case 'bannerTitleRichText2Cta':
              return <BannerInspiHouse key={componentGenID} data={componentData} />;
            case 'targetBloc':
              return <ImagesTargets key={componentGenID} data={componentData} />;
            case 'genContentRecoWithoutCta':
              return <ContentTiles key={componentGenID} data={componentData} />;
            case 'homepageUnivers':
              return <OurUnivers key={componentGenID} data={componentData} />;
            case 'genSeoText':
              return <SeoBlock key={componentGenID} data={componentData} />;

            default:
              console.warn(`An error occured rendering "${componentGenID}". Please check DynamicContentfull component.`);
              return <></>;
          }
        })}
      </>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default DynamicTendance;
