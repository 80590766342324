import style from './ChoisirZodio.module.scss';
import { Fragment } from 'react';
import RichText from 'components/elements/contentful/RichText/RichText';
import { publishItems } from 'helpers/utils/publishItems';

const ChoisirZodio = ({ data }) => {
  try {
    const { content } = data.data.dataSource.fields;

    return (
      <section className={style.ChoisirZodio}>
        {publishItems(content).map((item) => {
          const { title, imageFlow, imageDesktop, paragraph } = item.fields;

          return (
            <Fragment key={item.sys.id}>
              {title && <h2 className={style.ChoisirZodio__title}>{title}</h2>}

              <div
                className={`
                ${style['ChoisirZodio__block']}
                ${style[`ChoisirZodio__block--${imageFlow.toString()}`]}
              `}
              >
                <div className={style.ChoisirZodio__block__text}>
                  <RichText content={paragraph} />
                </div>

                <div className={style.ChoisirZodio__block__image}>
                  <img src={imageDesktop.fields.file.url} loading="lazy" alt={imageDesktop?.fields?.title} />
                </div>
              </div>
            </Fragment>
          );
        })}
      </section>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default ChoisirZodio;
