import React from 'react';
import style from './Colors.module.scss';
import MosaicToken from 'node_modules/@mozaic-ds/tokens/src/tokens/zodio/color/base.json';
import { Tooltip } from '@mozaic-ds/react';

const Colors = () => {
  try {
    const [selectedColor, setSelectedColor] = React.useState(undefined);

    const selectColor = async (name) => {
      try {
        await navigator.clipboard.writeText(name);
        setSelectedColor(name);
      } catch (error) {
        alert('Impossible de copier la couleur, merci de réessayer');
      }
    };

    return (
      <section className={style.Colors}>
        {selectedColor ? (
          <h1 className={style.Colors__title}>
            Vous avez copié la couleur <b>{selectedColor}</b> !
          </h1>
        ) : (
          <h1 className={style.Colors__title}>Cliquez sur une couleur pour la copier.</h1>
        )}

        <div className={style.Colors__blocks}>
          {Object.entries(MosaicToken.color).map((color) => {
            const [title, values] = color;

            return (
              <div key={title} className={style.Colors__blocks__item}>
                <h2 className={style.Colors__blocks__item__title}>{title}</h2>

                <ul className={style.Colors__list}>
                  {Object.entries(values).map((value) => {
                    const [code, hexa] = value;

                    const scssVarName = `$color-${title}-${code}`;

                    return (
                      <li key={`${title}-${code}`} className={style.Colors__list__item}>
                        <Tooltip label={selectedColor === scssVarName ? 'Copié !' : hexa.value} placement="right">
                          <i onClick={() => selectColor(scssVarName)} style={{ backgroundColor: hexa.value }} />
                        </Tooltip>

                        <h3>{scssVarName}</h3>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </div>
      </section>
    );
  } catch (error) {
    console.log(error);
    return <></>;
  }
};

export default Colors;
