import React from 'react';
import style from './StoreMap.module.scss';
import config from './StoreMap.config';
import Loader from 'components/elements/Loader/Loader';
import Script from 'next/script';
import { useRouter } from 'next/router';
import { UserContext } from 'frontastic';

const WoosmapKey = process.env.NEXT_PUBLIC_WOOSMAP_KEY;
const StoreMap = () => {
  const router = useRouter();
  const [ready, setReady] = React.useState(false);
  const { setStore } = React.useContext(UserContext);

  React.useEffect(initMap, [ready]);

  const setStoreAsfavorite = async (storeId) => {
    const request = `https://api.woosmap.com/stores/search/?query=idstore:${storeId}&key=${WoosmapKey}`;

    const data = await fetch(request)
      .then((response) => response.json())
      .then((response) => response.features[0].properties)
      .catch(console.error);

    setStore(data);
    window.localStorage.setItem('store', JSON.stringify(data));
    router.push(data.contact.website.split('.fr')[1]);
  };

  function initMap() {
    if (!ready) return;

    try {
      const map = new WebApp('store-locator-container', WoosmapKey);

      map.listenOn(map.HANDLED_EVENT.FAVORITED, setStoreAsfavorite);
      map.setConf(config);
      map.render(window.innerWidth < 450);
    } catch (error) {
      throw new Error('An error occured with Woosmap : ' + error);
    }
  }

  return (
    <section className={style.StoreMap}>
      <h2 className={style.StoreMap__title}>
        <span>Trouver</span> mon magasin
      </h2>

      <Script src="https://webapp.woosmap.com/webapp.js" onLoad={() => setReady(true)} />

      <div className={style.StoreMap__map} id="store-locator-container">
        <Loader className={style.StoreMap__map__loader} />
      </div>
    </section>
  );
};

export default StoreMap;
