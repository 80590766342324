export default {
  description: {
    title: 'Description du produit',
    content: `
      Optez pour ce set de 6 verres à pied transparents Open'Up en cristallin de 32 cl de chez Chef &amp; Sommelier
      pour déguster vos bouteilles de vin favorites ! Le design moderne et la jambe fine de ces verres en font de
      véritables pièces d’exception à avoir chez soi.
      <br />
      <br />
      Le verre à pied Open’Up de 32 cl est parfait pour déguster des vins jeunes, qu'ils soient blancs (1 à 3 ans)
      ou rouges (1 à 5 ans). Chaque verre a une forme convexe, permettant d'oxygéner le vin correctement. La
      paraison arrondie favorise l'oxygénation et la concentration des arômes dans le buvant, tout en révélant les
      subtilités et ainsi assurer un parfait équilibre.
      <br />
      <br />
      Contrairement à un verre classique, le verre à vin est conçu pour vous permettre de découvrir tous les arômes
      présents dans votre vin. Un produit indispensable pour savourer et apprécier votre boisson. <br />
      <br />
      La marque Chef &amp; Sommelier est une véritable référence en matière de verrerie, que ce soit pour les
      professionnels ou les particuliers. Avec cette collection Open'Up, l’entreprise signe la création de produits
      au design révolutionnaire, conçus pour faire vivre la meilleure expérience possible.
      <br />
      <br />
      Ces verres sont conçus en Krysta, un cristal sans plomb spécialement développé par Chef &amp; Sommelier, qui
      donne à ces verres une transparence éblouissante et une pureté exceptionnelle. C’est un véritable plaisir en
      main mais aussi au visuel...
      <br />
      <br />
      Les verres sont également disponibles dans une contenance de 37 cl, 40 cl, 47 cl et 55 cl. Alors n'hésitez
      plus et adoptez vous aussi ces verres d'exceptions !<br />
    `,
  },

  caracteristics: {
    title: 'Caractéristiques 6 Verres à Pied transparents Open Up en Cristallin 32cl',
    list: [
      { label: 'Ref', value: '10193639' },
      { label: 'Type de produit table', value: 'verre à pied' },
      { label: 'Matière', value: 'cristallin' },
      { label: 'Contenance en cl', value: '32cl' },
      { label: 'Type de boisson', value: 'vin' },
      { label: 'Entretien lave-vaisselle', value: 'oui' },
      { label: 'Couleur', value: 'transparent' },
      { label: 'Les points forts', value: "Ce verre Open Up s'adapte à tous les types de vins" },
      { label: 'Made in France', value: 'Oui' },
      { label: 'Marque', value: 'CHEF & SOMMELIER' },
      { label: 'Longueur (cm)', value: '8,70' },
      { label: 'Largeur (cm)', value: '8,70' },
      { label: 'Hauteur (cm)', value: '18' },
      { label: 'Poids net (kg)', value: '0,25' },
      { label: 'Garantie', value: 'ans' },
      { label: 'Disponibilité', value: 'Vendu en ligne / Retrait 2 h' },
      { label: 'Nombre de produits', value: '6' },
      { label: 'Essentiel du faire', value: 'Non' },
      { label: 'Empilable', value: 'Non' },
    ],
  },
};
