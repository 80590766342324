import style from './WorkshopTileDetails.module.scss';
import Picto from 'components/elements/Picto/Picto';

const WorkshopTileDetails = (data) => {
  const { remaining_slots, time_slot, end_time_slot } = data?.workshop || {};

  const start = new Date(time_slot);
  const end = new Date(end_time_slot);

  const startHours = formatNumber(start.getUTCHours(), 2);
  const startMinutes = formatNumber(start.getUTCMinutes(), 2);
  const startDay = formatNumber(start.getUTCDate(), 2);
  const startMonth = formatNumber(start.getUTCMonth(), 2);
  const startYear = formatNumber(start.getUTCFullYear(), 4);
  const endHours = formatNumber(end.getUTCHours(), 2);
  const endMinutes = formatNumber(end.getUTCMinutes(), 2);

  function formatNumber(number, length) {
    return ('0' + number).slice(-1 * length);
  }

  return (
    <ul className={style.WorkshopTileDetails}>
      {time_slot && (
        <li className={style.WorkshopTileDetails__item}>
          <Picto icon="calendar" />
          <span>
            le {startDay}/{startMonth}/{startYear}
          </span>
        </li>
      )}

      {end_time_slot && (
        <li className={style.WorkshopTileDetails__item}>
          <Picto icon="clock" />
          <span>
            De {startHours}h{startMinutes} à {endHours}h{endMinutes}
          </span>
        </li>
      )}

      {remaining_slots && (
        <li className={style.WorkshopTileDetails__item}>
          <Picto icon="account--bordered" />
          <span>
            Intermédiaire <em>{remaining_slots} places disponibles</em>
          </span>
        </li>
      )}
    </ul>
  );
};

export default WorkshopTileDetails;
