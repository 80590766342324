import React from 'react';
import style from './WorkshopBanner.module.scss';
import Link from 'next/link';

const WorkshopBanner = ({ data }) => {
  try {
    const { title, subtitle, url, imageDesktop, picto } = data.data.dataSource.fields;

    const imageUrl = imageDesktop.fields.file.url;
    const pictoUrl = picto.fields.file.url;

    const WorkshopBannerInner = () => (
      <>
        <picture>
          {[
            { media: '(max-width: 800px) and (-webkit-min-device-pixel-ratio: 1)', srcSet: imageUrl + '?w=1600' },
            { media: '(max-width: 800px) and (-webkit-min-device-pixel-ratio: 2)', srcSet: imageUrl + '?w=3200' },
            { media: '(max-width: 1100px) and (-webkit-min-device-pixel-ratio: 1)', srcSet: imageUrl + '?w=1100' },
            { media: '(max-width: 1100px) and (-webkit-min-device-pixel-ratio: 2)', srcSet: imageUrl + '?w=2200' },
            { media: '(max-width: 1500px) and (-webkit-min-device-pixel-ratio: 1)', srcSet: imageUrl + '?w=1920' },
            { media: '(max-width: 1500px) and (-webkit-min-device-pixel-ratio: 2)', srcSet: imageUrl + '?w=3840' },
          ].map((breakpoint, i) => (
            <source key={i} {...breakpoint} />
          ))}
          <img className={style.WorkshopBanner__picture} src={imageUrl + '?w=1920'} alt={title} />
        </picture>

        <div className={style.WorkshopBanner__content}>
          <img className={style.WorkshopBanner__content__picto} src={pictoUrl} alt="" />
          <h1 className={style.WorkshopBanner__content__title}>
            {title}
            <span>{subtitle}</span>
          </h1>
        </div>
      </>
    );

    return (
      <section className={style.WorkshopBanner}>
        {url ? (
          <Link href={url}>
            <a>
              <WorkshopBannerInner />
            </a>
          </Link>
        ) : (
          <WorkshopBannerInner />
        )}
      </section>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default WorkshopBanner;
