import style from './FootNotes.module.scss';
import RichText from 'components/elements/contentful/RichText/RichText';

const FootNotes = ({ data }) => {
  try {
    const { size, text } = data.data.dataSource.fields;

    return (
      <section className={style.FootNotes} data-size={size.toLowerCase()}>
        <RichText content={text} />
      </section>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default FootNotes;
