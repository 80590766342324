import style from './CtaFaq.module.scss';
import { Button } from '@mozaic-ds/react';
import Link from 'next/link';

const CtaFaq = ({ data }) => {
  try {
    const { url, title } = data.data.dataSource.fields;

    return (
      <div className={style.CtaFaq}>
        <Link href={url}>
          <Button width="full">{title}</Button>
        </Link>
      </div>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default CtaFaq;
