import style from './InfosLivraison.module.scss';
import RichText from 'components/elements/contentful/RichText/RichText';
import { publishItems } from 'helpers/utils/publishItems';

const InfosLivraison = ({ data }) => {
  try {
    const { contentReferences, title } = data.data.dataSource.fields;

    return (
      <div className={style.infosLivraison}>
        <h1 className={style.infosLivraison__title}>{title}</h1>

        {publishItems(contentReferences).map((content) => {
          return (
            <div className={style.infosLivraison__items} key={content.sys.id}>
              {content.fields.paragraph && <RichText content={content.fields.paragraph} />}

              {content.fields.deliveries && (
                <ul className={style.infosLivraison__list}>
                  {content.fields.deliveries?.map((delivery) => {
                    const { picto, title, text } = delivery.fields;

                    return (
                      <div key={delivery.sys.id} className={style.infosLivraison__list__item}>
                        <img src={picto.fields.file.url} alt={title} />
                        <span>{title}</span>
                        <RichText content={text} />
                      </div>
                    );
                  })}
                </ul>
              )}
            </div>
          );
        })}
      </div>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default InfosLivraison;
