import React from 'react';
import style from './CheckoutSteps.module.scss';
import Link from 'next/link';

const CheckoutSteps = ({ step = 'cart' }) => {
  const steps = ['cart', 'delivery', 'payment'];

  try {
    return (
      <nav className={style.CheckoutSteps} role="navigation">
        {steps.map((_step, index) => (
          <Link href={`/checkout/${_step}`} key={_step}>
            <a className={style.CheckoutSteps__link} data-active={step === _step} data-disabled={steps.indexOf(step) < steps.indexOf(_step)}>
              {['Panier', 'Livraison', 'Paiement'][index]}
            </a>
          </Link>
        ))}
      </nav>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default CheckoutSteps;
