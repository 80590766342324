import style from './RappelProduits.module.scss';
import RichText from 'components/elements/contentful/RichText/RichText';
import { publishItems } from 'helpers/utils/publishItems';

const RappelProduits = ({ data }) => {
  try {
    const { contentReferences, title } = data.data.dataSource.fields;

    return (
      <section className={style.RappelProduits}>
        <h1 className={style.RappelProduits__title}>{title}</h1>

        {publishItems(contentReferences).map((content) => {
          const { imageDesktop, paragraph } = content.fields;
          return (
            <div className={style.RappelProduits__item} key={content?.sys?.id}>
              <img className={style.RappelProduits__item__image} src={imageDesktop.fields.file.url} loading="lazy" alt={imageDesktop.fields.title} />
              <div className={style.RappelProduits__item__text}>
                <RichText content={paragraph} />
              </div>
            </div>
          );
        })}
      </section>
    );
  } catch (e) {
    console.error(e);
    return <></>;
  }
};

export default RappelProduits;
