const getMedia = (type, media = null, imageWithZoom) => {
  switch (type) {
    case 'none':
      return {
        thumbnail: media.fields.file.url + '?w=90&h=90',
        original: media.fields.file.url + '?w=600&h=600',
        fullscreen: media.fields.file.url + '?w=1080&h=1080',
        fullscreenZoomed: media.fields.file.url + '?w=1600&h=1600',
        originalAlt: '',
        thumbnailAlt: '',
        renderItem: imageWithZoom,
      };
    case 'contentful':
      return {
        thumbnail: media.fields.file.url + '?w=90&h=90',
        original: media.fields.file.url + '?w=600&h=600',
        fullscreen: media.fields.file.url + '?w=1080&h=1080',
        fullscreenZoomed: media.fields.file.url + '?w=1600&h=1600',
        originalAlt: '',
        thumbnailAlt: '',
        renderItem: imageWithZoom,
      };
    case 'magento':
      return Array.from({ length: 8 }, (_, i) => {
        return {
          thumbnail: `https://picsum.photos/id/${185 + i}/90`,
          original: `https://picsum.photos/id/${185 + i}/600`,
          fullscreen: `https://picsum.photos/id/${185 + i}/1080`,
          fullscreenZoomed: `https://picsum.photos/id/${185 + i}/1600`,
          thumbnailHeight: 90,
          thumbnailWidth: 90,
          originalAlt: '',
          thumbnailAlt: '',
          renderItem: imageWithZoom,
        };
      });
  }
};

export default getMedia;
