import React from 'react';
import style from './ProjetParticulier.module.scss';
import RichText from 'components/elements/contentful/RichText/RichText';
import { Button } from '@mozaic-ds/react';

const classNames = (...classes) => {
  return classes.join(' ');
};

const ProjetParticulier = ({ data }) => {
  try {
    const content = data.data.dataSource.fields;
    return (
      <section className={classNames(style.projet, 'container')}>
        <div
          className={classNames(
            content?.imageFlow?.toString() === 'right' && style.projet__imageRight,
            content?.imageFlow?.toString() === 'left' && style.projet__imageLeft,
          )}
        >
          <div className={style.projetParagrah}>
            <h2>{content.title && content.title}</h2>
            <RichText content={content.paragraph} />
            <div className={style.projet__button}>
              <Button size="l" width="full">
                Contactez votre magasin
              </Button>
            </div>
          </div>
          <div className={style.projetImage}>
            <img src={content?.imageDesktop?.fields.file.url} alt={content.imageDesktop?.fields?.title} />
          </div>
        </div>
      </section>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default ProjetParticulier;
