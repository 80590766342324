import style from './ContentTiles.module.scss';
import React from 'react';
import { Button } from '@mozaic-ds/react';
import { publishItems } from 'helpers/utils/publishItems';
import ContentTile from 'components/elements/ContentTile/ContentTile';
import Slider from 'components/elements/Slider/Slider';

const ContentTiles = ({ data }) => {
  try {
    const { ctaLabel, ctaUrl, contentTiles, title, subtitle } = data.data.dataSource.fields;

    return (
      <section className={style.ContentTiles}>
        <h2>{title}</h2>
        {subtitle && <p className={style.ContentTiles__subtitle}>{subtitle}</p>}
        <div className={style.ContentTiles__slider}>
          <Slider
            breakpoints={{
              320: { slidesPerView: 1 },
              580: { slidesPerView: 2 },
              850: { slidesPerView: 3 },
            }}
          >
            {contentTiles && publishItems(contentTiles).map((tile) => <ContentTile key={tile.sys.id} tile={tile} />)}
          </Slider>
        </div>
        {ctaLabel && ctaUrl && (
          <div className={style.ContentTiles__button}>
            <Button size="l" width="full" theme="primary" href={ctaUrl}>
              {ctaLabel}
            </Button>
          </div>
        )}
      </section>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default ContentTiles;
