import style from './VignettesText.module.scss';
import SimpleTitle from '../SimpleTitle/SimpleTitle';
import { publishItems } from 'helpers/utils/publishItems';
import RichText from 'components/elements/contentful/RichText/RichText';

const VignettesText = ({ data }) => {
  const { title, subtitle, content } = data.data.dataSource.fields;
  return (
    <div className={style.VignettesText}>
      <SimpleTitle title={title} subtitle={subtitle} />
      {publishItems(content).map((item) => {
        const { paragraph, image } = item.fields;
        return (
          <div key={item.sys.id} className={style.VignettesText__content}>
            <img src={image.fields.file.url} alt={image.fields.title} />
            <div className={style.VignettesText__content__text}>
              <RichText content={paragraph} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default VignettesText;
