import React from 'react';
import style from './Summary.module.scss';

import useGetCurrentDevice from 'helpers/utils/media-queries';

const Summary = ({ data }) => {
  const currentDevice = useGetCurrentDevice();
  const [previewIsVisible, setPreviewIsVisible] = React.useState(false);
  const [detailsIsVisible, setDetailsIsVisible] = React.useState(false);

  React.useEffect(() => {
    setPreviewIsVisible(currentDevice === 'desktop');
    setDetailsIsVisible(currentDevice === 'desktop');
  }, [currentDevice]);

  try {
    const { price, quantity, visual } = data;

    function toggleAccordion(event) {
      event.target.closest('button').classList.toggle('open');
      event.target.closest('[data-type="preview"]') && setPreviewIsVisible(!previewIsVisible);
      event.target.closest('[data-type="details"]') && setDetailsIsVisible(!detailsIsVisible);
    }

    return (
      <div className={style.Summary}>
        <div className={style.Summary__block} data-type="preview">
          {currentDevice === 'desktop' ? (
            <h2 className={style.Summary__title}>Aperçu de ma carte</h2>
          ) : (
            <button className={style.Summary__title} onClick={toggleAccordion}>
              <h2>Aperçu de ma carte</h2>
            </button>
          )}

          {previewIsVisible && <img src={visual} alt="" className={style.Summary__card} />}
        </div>

        <div className={style.Summary__block} data-type="details">
          {currentDevice === 'desktop' ? (
            <h2 className={style.Summary__title}>Details de la carte cadeau</h2>
          ) : (
            <button className={style.Summary__title} onClick={toggleAccordion}>
              <h2>Details de la carte cadeau</h2>
            </button>
          )}

          {price > 0 && detailsIsVisible && (
            <div className={style.Summary__details}>
              <p className={style.Summary__details__line}>
                Montant de la carte cadeau: <strong>{price} €</strong>
              </p>
              <p className={style.Summary__details__line}>
                Quantité: <strong>{quantity}</strong>
              </p>
              <p className={style.Summary__details__total}>
                Total: <strong>{quantity * price} €</strong>
              </p>
            </div>
          )}
        </div>
      </div>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default Summary;
