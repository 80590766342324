import style from './BlogForm.module.scss';
import { Button, Field, Select, TextArea, TextInput } from '@mozaic-ds/react';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { rules } from 'helpers/utils/formRules';
import Picto from 'components/elements/Picto/Picto';
import alert from 'helpers/utils/alert';

const BlogForm = () => {
  const form = React.useRef();
  const [selectedFile, setSelectedFile] = React.useState();
  const [showPlaceholder, setShowPlaceholder] = React.useState('facebook');
  const [validBlogForm, setValidBlogForm] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitted },
  } = useForm({ mode: 'onChange', reValidateMode: 'onChange' });

  const onSubmit = (data) => {
    console.log('blog', data);
    setValidBlogForm(true);
  };

  const onSelectFile = (e) => {
    console.log(e);
    if (e.target.files.length) {
      setSelectedFile(URL.createObjectURL(e.target.files[0]));
    }
  };

  const classNames = (...classes) => {
    return classes.join(' ');
  };

  React.useEffect(() => {
    validBlogForm && alert('success', 'Vos informations ont été modifiées', 5000);
  }, [validBlogForm]);

  const icons = [{ name: 'facebook' }, { name: 'instagram' }, { name: 'pinterest' }, { name: 'youtube' }];

  return (
    <form ref={form} onSubmit={handleSubmit(onSubmit)} className={style['blogForm']}>
      <h2>Informations Zôdiosphère (blog)</h2>

      <label htmlFor="upload" className={style.blogForm__file}>
        <span className={style['blogForm__file__icon']}>
          {selectedFile ? <img src={selectedFile} alt="" /> : <Picto icon="account--bordered" className={style['blogForm__file__icon__blog']} />}
        </span>
        <span>Changer la photo de profil</span>
      </label>

      <input type="file" id="upload" accept="image/*" hidden {...register('file', { onChange: onSelectFile })} />

      <Field htmlFor="pseudo" className="mt-[2rem]" label="Pseudo">
        <TextInput id="pseudo" defaultValue="Fb34542378" size="m" />
      </Field>

      <Field className="mt-[2rem]" htmlFor="propos" label="A propos">
        <TextArea id="propos" {...register('propos')} placeholder="A propos" />
      </Field>

      <Field htmlFor="web" className="mt-[2rem]" label="Mon web a moi" error={errors?.web?.message}>
        <TextInput id="web" {...register('web', rules.web)} size="m" isInvalid={errors.web} placeholder="L'adresse web de votre site ou blog" />
      </Field>

      <Field
        htmlFor="social"
        className="mt-[2rem]"
        error={errors?.socialNetwork?.message}
        label="Sélectionner les réseaux sociaux sur lesquels vous êtes présents"
      >
        <div className={style['blogForm__social']}>
          {icons.map((icon, index) => (
            <span
              key={index}
              className={classNames(
                style['blogForm__social__icon'],
                icon.name === 'facebook' && !showPlaceholder && style['blogForm__social__icon__active'],
                showPlaceholder === icon.name && style['blogForm__social__icon__active'],
              )}
              onClick={() => setShowPlaceholder(icon.name)}
            >
              <Picto icon={icon.name} className={style['blogForm__social__icon__blog']} />
            </span>
          ))}
        </div>

        <TextInput
          id="social"
          {...register('social', rules.socialNetwork)}
          size="m"
          isInvalid={errors.socialNetwork}
          isValid={isSubmitted && !errors.socialNetwork}
          placeholder={`Votre adresse ${showPlaceholder} (exemple : ${showPlaceholder}.fr/mon_compte)`}
        />
      </Field>
      <Field>
        <Select
          className="mt-[2.2rem]"
          {...register('store')}
          options={[
            {
              label: 'lille',
              value: 'lille',
            },
            {
              label: 'paris',
              value: 'paris',
            },
          ]}
          size="m"
          placeholder="Mon magasin préféré ou le plus proche"
        />
      </Field>
      <div className={style['blogForm__button']}>
        <Button width="full" type="submit" disabled={Object.keys(errors).length}>
          <Picto icon="check" className="mr-[4rem]" /> Sauvegarder
        </Button>
      </div>
    </form>
  );
};

export default BlogForm;
