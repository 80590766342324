import style from './Fonctionnement.module.scss';
import Encart from '../Encart/Encart';
import { publishItems } from 'helpers/utils/publishItems';

const Fonctionnement = ({ data }) => {
  try {
    const { fields } = data.data.dataSource;

    return (
      <div className={style.Fonctionnement}>
        <div data-anchor-id="achat-en-ligne" data-anchor-label="Achat effectué sur zodio.fr" />
        <div data-anchor-id="achat-en-magasin" data-anchor-label="Achat effectué en magasin" />

        <h2>{fields.title}</h2>

        <div className={style.Fonctionnement__items}>
          {publishItems(fields.content).map((encart) => (
            <Encart paragraph={encart.fields.paragraph} title={encart.fields.title} type="modalite" key={encart.sys.id} />
          ))}
        </div>
      </div>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default Fonctionnement;
