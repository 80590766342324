import style from './Banner.module.scss';
import Link from 'next/link';

const Banner = ({ data }) => {
  const { banner_alt, banner_link, banner_src, banner_title } = data;

  return (
    <div className={style.Banner}>
      <Link href={banner_link}>
        <a title={banner_title}>
          <img src={banner_src} alt={banner_alt} />
        </a>
      </Link>
    </div>
  );
};

export default Banner;
