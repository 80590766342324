import style from './Contact.module.scss';
import { Fragment } from 'react';
import Link from 'next/link';
import RichText from 'components/elements/contentful/RichText/RichText';
import Markdown from 'components/elements/contentful/MarkDown/MarkDown';
import Picto from 'components/elements/Picto/Picto';
import { Button } from '@mozaic-ds/react';
import { publishItems } from 'helpers/utils/publishItems';

const classNames = (...classes) => {
  return classes.join(' ');
};

const Contact = ({ data, type }) => {
  try {
    const { content, title, subtitle } = data.data.dataSource.fields;
    const imageData = publishItems(content).find((item) => item.sys.contentType.sys.id === 'genBannerWoCta');
    const { imageMobile, imageDesktop } = imageData.fields;

    const imageMobileUrl = imageMobile.fields.file.url;
    const imageDesktopUrl = imageDesktop.fields.file.url;

    const breakpoints = [
      { media: '(max-width: 800px) and (-webkit-min-device-pixel-ratio: 1)', srcSet: imageMobileUrl + '?w=800' },
      { media: '(max-width: 800px) and (-webkit-min-device-pixel-ratio: 2)', srcSet: imageMobileUrl + '?w=1600' },
      { media: '(max-width: 1100px) and (-webkit-min-device-pixel-ratio: 1)', srcSet: imageDesktopUrl + '?w=1100' },
      { media: '(max-width: 1100px) and (-webkit-min-device-pixel-ratio: 2)', srcSet: imageDesktopUrl + '?w=2200' },
      { media: '(max-width: 1500px) and (-webkit-min-device-pixel-ratio: 1)', srcSet: imageDesktopUrl + '?w=1920' },
      { media: '(max-width: 1500px) and (-webkit-min-device-pixel-ratio: 2)', srcSet: imageDesktopUrl + '?w=3840' },
    ];

    return (
      <section className={classNames(style.Contact, type === 'account' && style.Contact__account)}>
        <picture>
          {breakpoints.map((breakpoint, i) => (
            <source key={i} {...breakpoint} />
          ))}
          <img className={style.Contact__image} alt="" loading="lazy" src={imageDesktop.fields.file.url + '?h=1920'} />
        </picture>

        <div className={style.Contact__content}>
          <h3 className={style.Contact__title}>{title}</h3>
          <p className={style.Contact__subtitle}>{subtitle}</p>

          {publishItems(content).map((item) => {
            const { paragraph, savEncart } = item.fields;

            return (
              <Fragment key={item.sys.id}>
                {paragraph && <RichText content={paragraph} />}

                {savEncart && (
                  <div className={style.Contact__blocks}>
                    {savEncart.map((encart) => {
                      if (encart.sys.contentType.sys.id === 'genPictoSvgParagraphWithCta') {
                        const { paragraph, svgId, cta, url } = encart.fields;

                        return (
                          <div key={encart.sys.id} className={style.Contact__block}>
                            <Picto icon={svgId} className={style.Contact__block__picto} />

                            <RichText content={paragraph} />
                            <div className={style.Contact__block__cta}>
                              <Link href={url}>
                                <Button size="l" width="full">
                                  {cta}
                                </Button>
                              </Link>
                            </div>
                          </div>
                        );
                      }

                      if (encart.sys.contentType.sys.id === 'loyaltyPictoCcm') {
                        const { pictoSvg, subtitle } = encart.fields;

                        return (
                          <div key={encart.sys.id} className={style.Contact__block}>
                            <Picto icon={pictoSvg} className={style.Contact__block__picto} />
                            <Markdown content={subtitle} />
                          </div>
                        );
                      }
                    })}
                  </div>
                )}
              </Fragment>
            );
          })}
        </div>
      </section>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default Contact;
