import style from './FooterUnivers.module.scss';
import { publishItems } from 'helpers/utils/publishItems';

const FooterUnivers = ({ data }) => {
  try {
    const { iconsUnivers } = data.data.dataSource.fields;

    return (
      iconsUnivers && (
        <ul className={style.FooterUnivers}>
          {publishItems(iconsUnivers).map((item) => {
            const { image, text } = item.fields;
            return (
              <li key={item.sys.id} className={style.FooterUnivers__item}>
                <img className={style.FooterUnivers__item__picto} src={image.fields.file.url} alt="" />
                <span className={style.FooterUnivers__item__text}>{text}</span>
              </li>
            );
          })}
        </ul>
      )
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default FooterUnivers;
