import React from 'react';
import Link from 'next/link';

const HeaderLogo = ({ logo, baseline }) => {
  try {
    return (
      <Link href="/">
        <a className="header__logo">
          <img className="header__logo__image" src={logo.fields.file.url} alt={logo.fields.description} />

          {baseline && (
            <span
              className="header__logo__baseline"
              dangerouslySetInnerHTML={{ __html: baseline.replace(/(<([^>]+)>)/gi, ' ').replace(/\|/gi, '<br>') }}
            />
          )}
        </a>
      </Link>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default HeaderLogo;
