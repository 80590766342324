const HiddenH1 = ({ data }) => {
  try {
    const { title } = data.data.dataSource.fields;
    return title && <h1 className="sr-only">{title}</h1>;
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default HiddenH1;
