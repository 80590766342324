import style from './BasDePage.module.scss';
import RichText from 'components/elements/contentful/RichText/RichText';

const BasDePage = ({ data }) => {
  try {
    const { paragraph } = data.data.dataSource.fields;

    return (
      <div className={style.Basdepage}>
        <RichText content={paragraph} />
      </div>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default BasDePage;
