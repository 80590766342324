import React from 'react';
import calculateStrength from './PasswordStrengh.utils';
import style from './PasswordStrengh.module.scss';

const PasswordStrengh = ({ password, className }) => {
  const [strength, value] = calculateStrength(password);

  const classNameValue = style.PasswordStrengh;

  if (className) classNameValue += ` ${className}`;

  return (
    <div className={classNameValue} data-strength={strength}>
      <span className={style.PasswordStrengh__text}>{value}</span>
      <span className={style.PasswordStrengh__pill}></span>
      <span className={style.PasswordStrengh__pill}></span>
      <span className={style.PasswordStrengh__pill}></span>
      <span className={style.PasswordStrengh__pill}></span>
    </div>
  );
};

export default PasswordStrengh;
