import style from './BrandBanner.module.scss';
import mock from './BrandBanner.mock';

const BrandBanner = ({ data }) => {
  try {
    const { title, imageDesktop, imageMobile } = data.data ? data.data.dataSource.fields : mock;

    const imageMobileUrl = imageMobile.fields.file.url;
    const imageDesktopUrl = imageDesktop.fields.file.url;

    const breakpoints = [
      { media: '(max-width: 800px) and (-webkit-min-device-pixel-ratio: 1)', srcSet: imageMobileUrl + '?w=800' },
      { media: '(max-width: 800px) and (-webkit-min-device-pixel-ratio: 2)', srcSet: imageMobileUrl + '?w=1600' },
      { media: '(max-width: 1100px) and (-webkit-min-device-pixel-ratio: 1)', srcSet: imageDesktopUrl + '?w=1100' },
      { media: '(max-width: 1100px) and (-webkit-min-device-pixel-ratio: 2)', srcSet: imageDesktopUrl + '?w=2200' },
      { media: '(max-width: 1500px) and (-webkit-min-device-pixel-ratio: 1)', srcSet: imageDesktopUrl + '?w=1920' },
      { media: '(max-width: 1500px) and (-webkit-min-device-pixel-ratio: 2)', srcSet: imageDesktopUrl + '?w=3840' },
    ];

    return (
      <section className={style.BrandBanner}>
        <h2 className={style.BrandBanner__title}>{title}</h2>

        <picture>
          {breakpoints.map((breakpoint, i) => (
            <source key={i} {...breakpoint} />
          ))}
          <img className={style.BrandBanner__picture} src={imageDesktopUrl + '?w=1920'} alt={title} />
        </picture>
      </section>
    );
  } catch (e) {
    console.log(e);
    return <></>;
  }
};

export default BrandBanner;
