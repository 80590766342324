import home from './home';
import cms from './cms';
import store from './store';
import login from './login';
import workshops from './workshops';
import giftcard from './giftcard';
import account from './account';
import checkout from './checkout';
import products from './products';
import tutos from './tutos';
import brand from './brand';
import landing from './landing';
import tendance from './tendance';

export default {
  ...home,
  ...cms,
  ...store,
  ...login,
  ...workshops,
  ...giftcard,
  ...account,
  ...checkout,
  ...products,
  ...tutos,
  ...brand,
  ...landing,
  ...tendance,
};
