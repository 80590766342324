import React, { useContext } from 'react';
import style from 'components/scopes/login/Login.module.scss';
import Link from 'next/link';
import Loader from 'components/elements/Loader/Loader';
import PipeString from 'components/elements/PipeString/PipeString';
import { rules } from 'helpers/utils/formRules';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import { UserContext, useZodioAccount } from 'frontastic';
import alert from 'helpers/utils/alert';
import { Button, Field, TextInput, PasswordInput } from '@mozaic-ds/react';

const SignIn = () => {
  const form = React.useRef();
  const router = useRouter();
  const [loading, setLoading] = React.useState(false);
  const [step, setStep] = React.useState('email');

  const { email, referer } = router.query;

  const { doesAccountExist, login } = useZodioAccount();
  const { setUser } = useContext(UserContext);

  const {
    getValues,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { email },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  async function onSubmit({ email, password }) {
    if (Object.keys(errors).length) return;

    setLoading(true);

    if (step === 'email') {
      try {
        const response = await doesAccountExist(email);
        switch (response) {
          case 'ACCOUNT_DOES_NOT_EXIST':
            router.push({ pathname: '/creation-compte', query: { email, referer } });
            break;
          case 'ACCOUNT_EXIST_BUT_REQUIRES_ACTIVATION':
            alert('success', 'Un email vous a été envoyé pour activer votre compte (Pensez à vérifier vos spams).', 7000);
            break;
          case 'ACCOUNT_CONFLICTS':
            alert('error', 'Votre compte a été trouvé mais il y a un problème avec celui-ci. Veuillez contacter le service client.', 7000);
            break;
          case 'ACCOUNT_EXISTS':
            setStep('password');
            break;
          default: // Un autre status est reçu ou une erreur s'est produite
            console.log(response);
            alert('error', "Une erreur s'est produite. Veuillez contacter le service client.", 7000);
            break;
        }
      } catch (error) {
        console.error(error);
        alert('error', "Une erreur s'est produite. Veuillez contacter le service client.", 7000);
      } finally {
        setLoading(false);
      }
    }

    // Validate Password
    if (step === 'password') {
      try {
        await login(email, password).then((res) => setUser(res));
        router.push(referer || '/');
      } catch (error) {
        console.error(error);
        alert('error', "Une erreur s'est produite. Veuillez contacter le service client.", 7000);
      } finally {
        setLoading(false);
      }
    }
  }

  try {
    return (
      <section className={style.Login}>
        <div className={style.Login__card}>
          <h2 className={style.Login__card__title}>Mon compte</h2>

          <p className={style.Login__card__text}>
            {step === 'email' && <PipeString string="Renseigner votre adresse email|pour créer un compte ou vous connecter" />}
            {step === 'password' && 'Veuillez saisir votre mot de passe'}
          </p>

          <form className={style.Login__card__form} noValidate ref={form} onSubmit={handleSubmit(onSubmit)}>
            {step === 'email' && (
              <Field htmlFor="email" label={rules.email.required ? 'Adresse email*' : 'Adresse email'} error={errors?.email?.message}>
                <TextInput id="email" {...register('email', rules.email)} isValid={getValues('email') && !errors.email} isInvalid={errors.email} />
              </Field>
            )}

            {step === 'password' && (
              <>
                <div className={style.Login__card__form__help}>
                  <span className={style.Login__card__form__help__label}>Adresse email</span>
                  <button
                    type="button"
                    className={style.Login__card__form__help__button}
                    onClick={() => setStep('email')}
                    disabled={step !== 'password'}
                  >
                    Modifier
                  </button>
                  <span className={style.Login__card__form__help__value}>{getValues('email')}</span>
                  <input type="hidden" {...register('email')} />
                </div>

                <Field
                  htmlFor="password"
                  help="8 caractères, 1 majuscule et 1 minuscule minimum, 1 chiffre, 1 caractère spécial."
                  label={rules.password.required ? 'Mot de passe*' : 'Mot de passe'}
                  error={errors?.password?.message}
                >
                  <PasswordInput
                    id="password"
                    labelShow={<span className="w-[4.5rem] block">Voir</span>}
                    labelHide={<span className="w-[4.5rem] block">Cacher</span>}
                    isValid={getValues('password') && !errors.password}
                    isInvalid={errors.password}
                    {...register('password', rules.password)}
                  />
                </Field>
              </>
            )}

            <div className={style.Login__card__form__button}>
              <Button type="submit" width="full">
                Valider
              </Button>
              <Link href={{ pathname: '/connexion/mot-de-passe-oublie', query: { email: getValues('email'), referer } }}>
                <a className="block mt-[1.6rem]">Mot de passe oublié</a>
              </Link>
            </div>
          </form>
        </div>

        {loading && (
          <div className={style.Login__loader}>
            <Loader className={style.Login__loader__picto} />
          </div>
        )}
      </section>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default SignIn;
