import React, { useEffect, useRef, useState } from 'react';
import style from './InfosForm.module.scss';
import { Field, TextInput, Radio, Button, DateInput, RadioGroup } from '@mozaic-ds/react';
import { rules } from 'helpers/utils/formRules';
import { useForm } from 'react-hook-form';
import Picto from 'components/elements/Picto/Picto';
import alert from 'helpers/utils/alert';

const InfosForm = () => {
  const form = useRef();
  const [validInfos, setValidInfos] = useState(false);
  const {
    handleSubmit,
    setValue,
    getValues,
    register,
    formState: { errors, isSubmitted },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    // TODO: ne plus passer par le local storage !
    setValue('email', window.localStorage.getItem('login-email'));
    setValue('firstname', window.localStorage.getItem('firstname'));
  }, [setValue]);

  const onSubmit = (data) => {
    console.log('submit', data);
    setValidInfos(true);
  };

  React.useEffect(() => {
    validInfos && alert('success', 'Vos informations ont été modifiées', 5000);
  }, [validInfos]);

  return (
    <form ref={form} onSubmit={handleSubmit(onSubmit)} className={style['formInfos']}>
      <h2>Informations personnelles</h2>
      <Field htmlFor="email" label="Adresse email*">
        <TextInput id="email" size="s" value={getValues('email')} isDisabled={true} />
      </Field>
      <Field
        className={style['formInfos__gender-form']}
        htmlFor="gender"
        label={`Civilité${rules.gender.required ? '*' : ''}`}
        error={errors?.gender?.message}
      >
        <RadioGroup name="gender">
          <Radio className="radio-group-item" {...register('M.')}>
            M.
          </Radio>
          <Radio className="radio-group-item" {...register('Mme')}>
            Mme
          </Radio>
        </RadioGroup>
      </Field>
      <Field htmlFor="firstname" className="mt-[2rem]" label={`Prénom${rules.firstname.required ? '*' : ''}`} error={errors?.firstname?.message}>
        <div>
          <TextInput
            id="firstname"
            {...register('firstname', rules.firstname)}
            isInvalid={errors.firstname}
            value={getValues('firstname')}
            size="m"
            isValid={isSubmitted && !errors.firstname}
          />
        </div>
      </Field>

      <Field className="mt-[2rem]" htmlFor="lastname" label={`Nom${rules.lastname.required ? '*' : ''}`} error={errors?.lastname?.message}>
        <div>
          <TextInput
            id="lastname"
            {...register('lastname', rules.lastname)}
            isInvalid={errors.lastname}
            size="m"
            isValid={isSubmitted && !errors.lastname}
          />
        </div>
      </Field>
      <Field className="mt-[2rem]" htmlFor="birthday" label="Date de naissance">
        <div>
          <DateInput id="birthday" {...register('date')} size="m" />
        </div>
      </Field>
      <Field htmlFor="phone" className="mt-[2rem]" label={`Numéro de téléphone${rules.phone.required ? '*' : ''}`} error={errors?.phone?.message}>
        <div>
          <TextInput
            type="tel"
            id="phone"
            {...register('phone', rules.phone)}
            isInvalid={errors.phone}
            size="m"
            placeholder="Numéro de téléphone"
            isValid={isSubmitted && !errors.phone}
          />
        </div>
      </Field>
      <div className={style['formInfos__button']}>
        <Button width="full" type="submit" disabled={Object.keys(errors).length}>
          <Picto icon="check" className="mr-[4rem]" /> Sauvegarder
        </Button>
      </div>
    </form>
  );
};

export default InfosForm;
