import style from './OrderItem.module.scss';
import React from 'react';

const OrderItem = () => {
  return (
    <div className={style.OrderItem}>
      <h3>Mes articles</h3>
      <div className={style.OrderItem__head}>
        <p>Produit</p>
        <p>Prix unitaire</p>
        <p>Quantité</p>
        <p>Prix total</p>
      </div>
      <div className={style.OrderItem__product}>
        <div className={style.OrderItem__product__text}>
          <img
            src="https://photos.zodio.fr/zodio-magento/catalog/products/source/8/7/8710364042883_2_A.png?height=150&width=150&canvas=150,150&fit=crop&bg-color=fff"
            alt=""
          />
          <div className={style.OrderItem__product__text__description}>
            <p className="font-bold">Dremel S545 Disque diamant EZ SpeedClic ø38mm materiaux durs</p>
            <p className="text-[1rem] text-[#7f7f7f]">Référence : 10205591 </p>
            <p className="font-bold">Dremel S545 Disque diamant EZ SpeedClic ø38mm materiaux durs </p>
            <p>10205591 29,90 € </p>
            <p>Commandé 4</p>
            <p>119,60 €</p>
          </div>
        </div>
        <p className={style.OrderItem__product__text__hidden}>29.90€</p>
        <p className={style.OrderItem__product__text__hidden}>4</p>
        <p className="font-bold">119.60€</p>
      </div>
    </div>
  );
};

export default OrderItem;
