import style from './InfosLegalesSecurite.module.scss';
import RichText from 'components/elements/contentful/RichText/RichText';
import { publishItems } from 'helpers/utils/publishItems';

const InfosLegalesSecurite = ({ data }) => {
  try {
    const { contentReferences, title } = data.data.dataSource.fields;

    return (
      <div className={style.InfosLegalesSecurite}>
        <h1 className={style.InfosLegalesSecurite__title}>{title}</h1>

        <div className={style.InfosLegalesSecurite__content}>
          {publishItems(contentReferences).map((content) => (
            <RichText content={content.fields.paragraph} key={content.sys.id} />
          ))}
        </div>
      </div>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default InfosLegalesSecurite;
