import Cart from './Cart/Cart';
import Delivery from './Delivery/Delivery';
import Payment from './Payment/Payment';
import Confirmation from './Confirmation/Confirmation';

export default {
  Cart,
  Delivery,
  Payment,
  Confirmation,
};
