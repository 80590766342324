import React from 'react';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { debounce } from 'lodash';
import style from './Slider.module.scss';

const defaultBreakpoints = {
  0: { slidesPerView: 1 },
  581: { slidesPerView: 2 },
  801: { slidesPerView: 3 },
  1101: { slidesPerView: 4 },
};

const Slider = ({ children: slides, alignArrowsOn, dots = true, arrows = true, ...data }) => {
  const slider = React.useRef(null);
  const [swiper, setSwiper] = React.useState(null);
  React.useEffect(setSwiper, [dots, arrows, alignArrowsOn, slides, setSwiper]);

  // Align navigation arrows on a specific element of the slides
  React.useEffect(() => {
    if (alignArrowsOn && typeof alignArrowsOn === 'string') {
      setArrowPosition();
      window.addEventListener('resize', debounce(setArrowPosition, 50));
      return () => window.removeEventListener('resize', setArrowPosition);
    }
  }, []);

  let setArrowTimeout = null;
  function setArrowPosition() {
    clearTimeout(setArrowTimeout);

    setArrowTimeout = setTimeout(() => {
      let top = '50%';
      const elementToAlign = slider.current?.querySelector(alignArrowsOn);

      if (elementToAlign) {
        top = 0;
        top += elementToAlign.offsetTop;
        top += elementToAlign.offsetParent.offsetTop;
        top += elementToAlign.clientHeight / 2;
        top += 'px';
      }

      slider.current?.style?.setProperty('--arrow-offset-top', top);
    }, 50);
  }

  const modules = [];
  if (arrows) modules.push(Navigation);
  if (dots) modules.push(Pagination);

  try {
    return slides ? (
      <div className={style.Slider} ref={slider}>
        <Swiper
          ref={swiper}
          onSwiper={setSwiper}
          className={style.Slider__swiper}
          breakpoints={defaultBreakpoints}
          spaceBetween={20}
          autoHeight={true}
          navigation={!!arrows && true}
          pagination={!!dots && { type: 'bullets', clickable: true, dynamicBullets: true }}
          modules={modules}
          data-arrows={arrows}
          data-dots={dots}
          {...data}
        >
          {slides.map((content, i) => {
            return (
              <SwiperSlide key={i} className={style.Slider__swiper__item}>
                <div className={style.Slider__swiper__item__inner}>{content}</div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    ) : null;
  } catch (error) {
    console.error(error);
    return <></>;
  }
};
export default Slider;
