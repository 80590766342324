export default {
  title: 'De Buyer',
  imageDesktop: {
    fields: {
      file: {
        url: '//picsum.photos/1920/530',
      },
    },
  },
  imageMobile: {
    fields: {
      file: {
        url: '//picsum.photos/800/800',
      },
    },
  },
};
