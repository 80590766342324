import { Button } from '@mozaic-ds/react';
import Picto from 'components/elements/Picto/Picto';
import style from './TutoHeader.module.scss';

const urls = {
  url: 'https://www.zodio.fr/sheets/sheet/viewpdf/id/7131',
};

const TutoHeader = ({ data }) => {
  const { reciepeTitle, shortDescription } = data.data.dataSource.fields;
  return (
    <section className={style.TutoHeader}>
      <div className={style.TutoHeader__content}>
        <h1 className={style.TutoHeader__content__title}>{reciepeTitle}</h1>
        <div className={style.TutoHeader__content__subtitle}>{shortDescription}</div>
      </div>
      <div className={style.TutoHeader__button}>
        <Button theme="secondary" size="l" aria-label="Télécharger la fiche" href={urls.url} rel="noopener noreferrer" target="_blank">
          <Picto icon="download" className={style.TutoHeader__button__picto} />
        </Button>
      </div>
    </section>
  );
};

export default TutoHeader;
