import React from 'react';
import style from './Targets.module.scss';
import targetPicto from 'components/generic/ImagesTargets/targetPicto.jpg';
import ProductsTargets from '../ProductsTargets/ProductsTargets';

const Targets = ({ targets }) => {
  const [toggle, setToggle] = React.useState(null);

  function showInfos(id, event) {
    setToggle(id);
    setTimeout(() => calculatePosition(event), 0);
  }

  function calculatePosition(event) {
    event.stopPropagation();
    const { target } = event;

    const infosBlock = target?.querySelector('a');
    if (!infosBlock) return;

    const container = target?.parentNode?.closest('[class*="imagesTargets__blocs__item"]');
    if (!container) return;

    const left = target.offsetLeft + 0.35 * target.clientWidth + infosBlock.clientWidth + 8 - container.clientWidth;
    container.style.setProperty('--offsetLeft', Math.min(0, -left) + 'px');

    const top = target.offsetTop + 1.1 * target.clientHeight + infosBlock.clientHeight + 8 - container.clientHeight;
    container.style.setProperty('--offsetTop', Math.min(0, -top) + 'px');
  }

  return (
    <div className={style.Targets}>
      {targets &&
        targets.map((t) => {
          const { targetPositionWidth: top, targetPositionHeight: left, product } = t.fields;

          return (
            <React.Fragment key={t.sys.id}>
              <div
                onMouseEnter={(event) => showInfos(t.sys.id, event)}
                onMouseOut={(event) => calculatePosition(event)}
                className={style.Targets__cible}
                style={{
                  top: `${top}%`,
                  left: `${left}%`,
                }}
              >
                <img className={style.Targets__cible__picto} src={targetPicto.src} alt="" />
                <ProductsTargets product={product} target={toggle} id={t.sys.id} />
              </div>
            </React.Fragment>
          );
        })}
    </div>
  );
};

export default Targets;
