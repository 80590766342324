import style from './Item.module.scss';
import Picto from 'components/elements/Picto/Picto';

const Item = ({ data }) => {
  return (
    <article className={style.Item}>
      <button className={style.Item__delete} onClick={console.log} aria-label="retirer ce produit du panier">
        <Picto icon="cross" />
      </button>

      <img className={style.Item__image} src="https://placehold.co/70x70/png" alt="" />

      <div className={style.Item__details}>
        <h2 className={style.Item__title}>Titre de mon produit</h2>
        <ul className={style.Item__delivery}>
          <li className={style.Item__delivery__method} data-available="true">
            <Picto icon="checkbox--on" /> Disponible à la livraison
          </li>
          <li className={style.Item__delivery__method} data-available="false">
            <Picto icon="cross" /> Stock momentanément indisponible au magasin de : VILLENEUVE D&apos;ASCQ
          </li>
        </ul>
      </div>
    </article>
  );
};

export default Item;
