import style from './WorkshopInfos.module.scss';
import Price from '../../../../elements/Price/Price';
import React from 'react';

const WorkshopInfos = () => {
  const goToTab = () => window.dispatchEvent(new CustomEvent('open-tab', { detail: { val: 'infos' } }));
  try {
    return (
      <section className={style.WorkshopInfos}>
        <div className={style.WorkshopInfos__bloc}>
          <h1 className={style.WorkshopInfos__title}>Cake aux maroiles</h1>
        </div>

        <div className={style.WorkshopInfos__price}>
          <Price size="m" data={{ default_formated: '39,90 €' }} />
        </div>
        <div className={style.WorkshopInfos__reviews}>
          <button onClick={goToTab}>+ de details</button>
        </div>
      </section>
    );
  } catch (e) {
    return <></>;
  }
};

export default WorkshopInfos;
