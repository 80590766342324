import style from './Inscriptions.module.scss';
import RichText from 'components/elements/contentful/RichText/RichText';
import { Button } from '@mozaic-ds/react';
import { publishItems } from 'helpers/utils/publishItems';
import Link from 'next/link';

import FideliteHeader from '../partials/FideliteHeader/FideliteHeader';

const Inscriptions = ({ data }) => {
  try {
    const { content, title, subtitle } = data.data.dataSource.fields;

    return (
      <div className={style.Inscriptions}>
        <FideliteHeader title={title} titleHousky={subtitle} />

        <div className={style.Inscriptions__blocks}>
          {publishItems(content).map((item, index) => {
            const { image, pictoLegend, paragraph, url, cta } = item.fields;

            return (
              <div className={style.Inscriptions__block} key={item.sys.id}>
                <img className={style.Inscriptions__block__icon} src={image.fields.file.url} alt="" />
                <h3 className={style.Inscriptions__block__title}>{pictoLegend}</h3>
                <div className={style.Inscriptions__block__text}>
                  <RichText content={paragraph} />
                </div>

                {cta && url && (
                  <Link href={url}>
                    <a className={style.Inscriptions__block__cta}>
                      <Button theme={index % 2 === 0 ? 'primary' : 'secondary'} width="full" size="l">
                        {cta}
                      </Button>
                    </a>
                  </Link>
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default Inscriptions;
