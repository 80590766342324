import style from './ProductTabInfos.module.scss';

import mock from './ProductTabInfos.mock';

const ProductTabInfos = () => {
  const { description, caracteristics } = mock;

  return (
    <div className={style.ProductTabInfos}>
      {description && (
        <div className={style.ProductTabInfos__column}>
          <h2 className={style.ProductTabInfos__title}>{description.title}</h2>
          <div className={style.ProductTabInfos__content}>
            <p dangerouslySetInnerHTML={{ __html: description.content }}></p>
          </div>
        </div>
      )}

      {caracteristics && (
        <div className={style.ProductTabInfos__column}>
          <h2 className={style.ProductTabInfos__title}>{caracteristics.title}</h2>

          <div className={style.ProductTabInfos__content}>
            <ul>
              {caracteristics.list.map((item) => {
                return (
                  <li key={item.label}>
                    <strong>{item.label}</strong> : {item.value}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductTabInfos;
