import style from './ProductBusinessSolutions.module.scss';

const ProductBusinessSolutions = () => {
  return (
    <div className={style.ProductBusinessSolutions}>
      <div className={style.ProductBusinessSolutions__logo}>
        <img src="/images/logo-zodio.svg" alt="Zôdio" />
        <span>Business Solutions</span>
      </div>

      <div className={style.ProductBusinessSolutions__content}>
        <span>Professionnels, besoin d’une plus grande quantité ?</span>
        <a
          href="https://contact-pro.zodio.fr/hc/fr/requests/new"
          target="_blank"
          rel="noreferrer noopener"
          title="Professionnels, besoin d’une plus grande quantité ?"
        >
          Faites une demande de devis
        </a>
      </div>
    </div>
  );
};

export default ProductBusinessSolutions;
