import React from 'react';
import { publishItems } from 'helpers/utils/publishItems';
import { useRouter } from 'next/router';

import StoreList from 'components/scopes/store/StoreList/StoreList';
import Contact from 'components/generic/Contact/Contact';
import SeoBlock from 'components/generic/SeoBlock/SeoBlock';

import WorkshopReinsurrance from 'components/scopes/workshops/common/WorkshopReinsurrance/WorkshopReinsurrance';
import WorkshopsPictos from 'components/scopes/workshops/elements/WorkshopsPictos/WorkshopsPictos';

import WorkshopsBanner from './tastics/WorkshopsBanner/WorkshopsBanner';
import WorkshopsThematics from './tastics/WorkshopsThematics/WorkshopsThematics';
import WorkshopsSelectStore from './tastics/WorkshopsSelectStore/WorkshopsSelectStore';

const DynamicWorshops = ({ data }) => {
  const router = useRouter();
  const [currentURL, setCurrentUrl] = React.useState('');

  React.useEffect(() => {
    setCurrentUrl(window.location.pathname);
  }, [router]);

  try {
    const { contentReferences } = data?.data?.dataSource?.fields;

    function getDataFromContentReferences(block) {
      const dataSource = contentReferences
        ? publishItems(contentReferences)?.find(({ fields }) => fields.contentTitle?.includes(block) || fields.contentType?.includes(block))
        : {};
      return { data: { dataSource } };
    }

    return (
      <>
        <WorkshopsBanner data={getDataFromContentReferences('Banner')} />
        <WorkshopsPictos data={getDataFromContentReferences('Bloc Pictos')} />
        <WorkshopsSelectStore />
        <WorkshopsThematics data={getDataFromContentReferences('Bloc Thématiques')} />
        <StoreList title="Retrouvez nos ateliers par ville" prefix="cours de cuisine" href={`${currentURL}/%STORE%`} />
        <WorkshopReinsurrance data={getDataFromContentReferences('Bloc réassurance')} />
        <Contact data={getDataFromContentReferences('Bloc info service client')} />
        <SeoBlock data={getDataFromContentReferences('Bloc SEO')} />
      </>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default DynamicWorshops;
