import React from 'react';
import Picto from 'components/elements/Picto/Picto';
import { useRouter } from 'next/router';
import { debounce } from 'lodash';

const FooterBlock = ({ isAccordion, title, children }) => {
  const router = useRouter();
  const accordionContent = React.useRef();

  // Close footer block after redirect
  React.useEffect(() => {
    accordionContent.current.classList.remove('is-open');
  }, [router.query.slug]);

  React.useEffect(() => {
    const accordion = accordionContent.current;
    window.addEventListener(
      'resize',
      debounce(() => accordion.classList.remove('is-open'), 50),
    );
    return () => window.removeEventListener('resize', () => accordion.classList.remove('is-open'));
  }, []);

  function toggleAccordion() {
    accordionContent.current.classList.toggle('is-open');
  }

  return (
    <div className="footer__block">
      <h3 className="footer__block__title">{title}</h3>

      {isAccordion && (
        <button className="footer__block__toggle" onClick={toggleAccordion} aria-hidden="true">
          <Picto icon="arrow--right" />
        </button>
      )}

      <div className="footer__block__content" ref={accordionContent}>
        {children}
      </div>
    </div>
  );
};

export default FooterBlock;
