export default {
  theme: {
    primary_color: '#000',
    storeWebsiteInSameWindow: true,
  },
  datasource: {
    max_responses: 5,
    max_distance: 50000,
  },
  internationalization: {
    lang: 'fr',
    unitSystem: 0,
    customTranslations: {
      fr: {
        storeview: {
          visitStorePage: "Voir l'actualité du magasin",
        },
        favoritebuttonview: {
          setAsFavorite: 'Je choisis ce magasin',
        },
      },
    },
  },
  maps: {
    provider: 'google',
    api_key: 'AIzaSyAogtvSQJ8e1LO6WH6GzSI-QtwaRPCV5C8',
    places: {
      types: ['geocode'],
      componentRestrictions: {
        country: ['fr'],
      },
    },
  },
  woosmapview: {
    initialCenter: {
      lat: 46.8806952,
      lng: 2.2233118,
    },
    initialZoom: 6,
    tileStyle: {
      color: '#000',
      size: 11,
      minSize: 5,
    },
    baseMapStyle: [
      {
        featureType: 'administrative',
        elementType: 'geometry',
        stylers: [
          {
            color: '#a7a7a7',
          },
        ],
      },
      {
        featureType: 'administrative',
        elementType: 'labels.text.fill',
        stylers: [
          {
            visibility: 'on',
          },
          {
            color: '#737373',
          },
        ],
      },
      {
        featureType: 'landscape',
        elementType: 'geometry.fill',
        stylers: [
          {
            visibility: 'on',
          },
          {
            color: '#efefef',
          },
        ],
      },
      {
        featureType: 'poi',
        elementType: 'geometry.fill',
        stylers: [
          {
            visibility: 'on',
          },
          {
            color: '#dadada',
          },
        ],
      },
      {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'poi',
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'road',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#696969',
          },
        ],
      },
      {
        featureType: 'road',
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry.fill',
        stylers: [
          {
            color: '#ffffff',
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [
          {
            visibility: 'simplified',
          },
          {
            color: '#b3b3b3',
          },
        ],
      },
      {
        featureType: 'road.arterial',
        elementType: 'geometry.fill',
        stylers: [
          {
            color: '#ffffff',
          },
        ],
      },
      {
        featureType: 'road.arterial',
        elementType: 'geometry.stroke',
        stylers: [
          {
            color: '#d6d6d6',
          },
        ],
      },
      {
        featureType: 'road.local',
        elementType: 'geometry.fill',
        stylers: [
          {
            visibility: 'on',
          },
          {
            color: '#ffffff',
          },
          {
            weight: 1.8,
          },
        ],
      },
      {
        featureType: 'road.local',
        elementType: 'geometry.stroke',
        stylers: [
          {
            color: '#d7d7d7',
          },
        ],
      },
      {
        featureType: 'transit',
        elementType: 'all',
        stylers: [
          {
            color: '#808080',
          },
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'water',
        elementType: 'geometry.fill',
        stylers: [
          {
            color: '#d3d3d3',
          },
        ],
      },
    ],
    breakPoint: 5,
    style: {
      default: {
        icon: {
          url: 'icons/home.svg',
          scaledSize: {
            width: 24,
            height: 24,
          },
          anchor: {
            x: 12,
            y: 12,
          },
        },
        selectedIcon: {
          url: 'icons/home.svg',
          scaledSize: {
            width: 32,
            height: 32,
          },
          anchor: {
            x: 16,
            y: 16,
          },
        },
      },
    },
  },
};
