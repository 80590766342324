import style from './Details.module.scss';
import image from './entre-nous-points.png';

const Details = () => {
  return (
    <section className={style.Details}>
      <div className={style.Details__content}>
        <ul className={style.Details__list}>
          <li className={style.Details__list__item}>
            <h3>Donner une « seconde vie » à vos objets</h3>
            <p>
              à travers le don à des associations ou avec de l&apos;upcycling à faire soi-même ou ensemble en atelier Zôdio, vous aidez les gens,
              évitez le gâchis et limitez la surconsommation. Vos dons sont récupérés toute l&apos;année dans nos magasins par nos équipes qui ont
              tissé des liens avec des associations locales et nationales.
            </p>
          </li>

          <li className={style.Details__list__item}>
            <h3>4 univers de produits à donner toute l&apos;année</h3>
            <p>
              Nous reprenons les produits des univers de <strong>la chambre, la cuisson, le linge de bain et la table.</strong>
            </p>
          </li>

          <li className={style.Details__list__item}>
            <h3>Donner une « seconde vie » à vos objets</h3>
            <p>
              à travers le don à des associations ou avec de l&apos;upcycling à faire soi-même ou ensemble en atelier Zôdio, vous aidez les gens,
              évitez le gâchis et limitez la surconsommation. Vos dons sont récupérés toute l&apos;année dans nos magasins par nos équipes qui ont
              tissé des liens avec des associations locales et nationales.
            </p>
          </li>

          <li className={style.Details__list__item}>
            <h3>+10 points sur votre compte fidélité</h3>
            <p>
              Lors de votre passage en caisse avec votre bon Seconde vie, vous obtiendrez 10 points de fidélité Zôdio. L’ajout de points est limité à
              4 par an soit un maximum de 40 points
            </p>
          </li>
        </ul>

        <div className={style.Details__discount}>
          <strong>1 à 5kg : -10%</strong>
          <strong>5 à 10kg : -15%</strong>
          <p>
            Bon à valoir le jour même dans le rayon correspondant aux dons<sup>(1)</sup>
          </p>
          <img src={image.src} alt="Entre nous, +10 points(2). Je donne une Seconde vie." loading="lazy" />
        </div>
      </div>
    </section>
  );
};

export default Details;
