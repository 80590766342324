import { Button } from '@mozaic-ds/react';
import Link from 'next/link';
import style from './Confirmation.module.scss';

const Confirmation = () => {
  try {
    return (
      <section className={style.Confirmation}>
        <h1 className={style.Confirmation__title}>Confirmation de votre commande</h1>

        <p className="mb-[1.6rem]">Zôdio vous remercie de votre confiance.</p>

        <p className="mb-[3.2rem]">
          Votre commande n°<strong>{'100802918'}</strong> est enregistrée <br />
          Un mail de confirmation viens de vous être envoyé à l&apos;adresse suivante :<strong>{'mon-email@zodio.fr'}</strong>
        </p>

        <Link href="/account/orders">
          <Button href="/account/orders">Suivre mes commandes</Button>
        </Link>

        <div className={style.Confirmation__details}>
          <h2 className={style.Confirmation__details__title}>Détail de la commande</h2>

          <ul className={style.Confirmation__details__list}>
            <li className={style.Confirmation__details__list__item}>
              <img className={style.Confirmation__details__list__item__image} src="https://placehold.co/70x70/png" alt="" />
              <h3 className={style.Confirmation__details__list__item__name}>Mon super produit</h3>
              <div className={style.Confirmation__details__list__item__quantity}>Quantité: 1</div>
              <div className={style.Confirmation__details__list__item__price}>24,99€</div>
            </li>

            <li className={style.Confirmation__details__list__item}>
              <img className={style.Confirmation__details__list__item__image} src="https://placehold.co/70x70/png" alt="" />
              <h3 className={style.Confirmation__details__list__item__name}>Mon autre super produit</h3>
              <div className={style.Confirmation__details__list__item__quantity}>Quantité: 2</div>
              <div className={style.Confirmation__details__list__item__price}>16,49€</div>
            </li>

            <li className={style.Confirmation__details__list__item}>
              <img className={style.Confirmation__details__list__item__image} src="https://placehold.co/70x70/png" alt="" />
              <h3 className={style.Confirmation__details__list__item__name}>Mon troisième super produit</h3>
              <div className={style.Confirmation__details__list__item__quantity}>Quantité: 1</div>
              <div className={style.Confirmation__details__list__item__price}>99,99€</div>
            </li>
          </ul>
        </div>

        <div className={style.Confirmation__summary}>
          <div className={style.Confirmation__summary__line}>
            <span>Sous-total</span>
            <span>{'70,95€'}</span>
          </div>

          <div className={style.Confirmation__summary__line}>
            <span>Frais de port</span>
            <span>{'OFFERT'}</span>
          </div>

          <div className={style.Confirmation__summary__line}>
            <strong>Total (HT)</strong>
            <strong>{'64.38€'}</strong>
          </div>

          <div className={style.Confirmation__summary__line}>
            <strong>Total (TTC)</strong>
            <strong>{'70,95€'}</strong>
          </div>
        </div>
      </section>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default Confirmation;
