import React from 'react';
import style from './StoreInfos.module.scss';
import Picto from 'components/elements/Picto/Picto';
import { Button } from '@mozaic-ds/react';
import { useRouter } from 'next/router';

import Breadcrumb from 'components/generic/Breadcrumb/Breadcrumb';
import Meta from 'components/generic/Meta/Meta';

const StoreInfos = () => {
  const router = useRouter();
  const [isMounted, setIsMounted] = React.useState(true);
  const [breadcrumbData, setBreadcrumbData] = React.useState(null);
  const [metaData, setMetaData] = React.useState(null);
  const [storeInfos, setStoreInfos] = React.useState(null);
  const days = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];

  const WoosmapKey = process.env.NEXT_PUBLIC_WOOSMAP_KEY;

  React.useEffect(() => {
    fetchData();
    return () => setIsMounted(false);
  }, []);

  async function fetchData() {
    const request = `https://api.woosmap.com/stores/search/?key=${WoosmapKey}`;

    const data = await fetch(request)
      .then((response) => response.json())
      .then((json) => json.features.find((i) => i.properties.contact.website.includes(router.asPath)))
      .then((store) => store.properties)
      .catch(console.error);

    if (!isMounted) return;

    setStoreInfos(data);
    setBreadcrumbData({
      data: {
        dataSource: {
          fields: {
            parent: {
              fields: { breadcrumb: 'Trouver un magasin', urlKey: '/trouver-un-magasin' },
            },
            breadcrumb: `Votre magasin de décoration et cuisine Zôdio à ${data.name || ''}`,
          },
        },
      },
    });
    setMetaData({
      data: {
        dataSource: {
          fields: {
            metaTitle: `Votre magasin de décoration et cuisine Zôdio à ${data.name || ''}`,
            metaDescription: `Trouvez votre magasin de décoration et de cuisine Zôdio à ${
              data.name || ''
            } : Horaires d’ouverture, adresse, informations de contact, actualités, dates des ateliers et cours de cuisine`,
          },
        },
      },
    });
  }

  return storeInfos ? (
    <>
      {breadcrumbData && <Breadcrumb data={breadcrumbData} />}
      {metaData && <Meta data={metaData} />}

      <section className={style.StoreInfos}>
        <header className={style.StoreInfos__top}>
          <h1 className={style.StoreInfos__top__title}>Votre magasin de décoration et cuisine Zôdio à {storeInfos.name}</h1>

          <div className={style.StoreInfos__top__button}>
            <Button width="full" href="/trouver-un-magasin">
              Je change de magasin
            </Button>
          </div>
        </header>

        {/* BLOCK ADRESSE */}
        <div className={style.StoreInfos__content}>
          <div className={style.StoreInfos__block}>
            <h2 className={style.StoreInfos__block__title}>Adresse</h2>

            <p className={style.StoreInfos__block__line}>
              <Picto icon="home--bordered" />
              <span>
                {storeInfos.address.lines.map((line) => (
                  <span key={line} dangerouslySetInnerHTML={{ __html: line.split('\n').join('<br />') }} />
                ))}
                <br />
                {`${storeInfos.address.zipcode} ${storeInfos.address.city}`}
              </span>
            </p>

            <div className="mt-[1.6rem]" />

            <p className={style.StoreInfos__block__line}>
              <Picto icon="phone--bordered" />
              <a href={`tel:${storeInfos.contact.phone}`}>{storeInfos.contact.phone}</a>
            </p>
          </div>
          {/* END BLOCK ADRESSE */}

          {/* BLOCK HORAIRES */}
          <div className={style.StoreInfos__block}>
            <h2 className={style.StoreInfos__block__title}>Horaires</h2>

            {Object.entries(storeInfos.opening_hours.usual)
              .filter((day) => !day.includes('default'))
              .map(([index, day]) => {
                const [morning, afternoon] = day;

                return (
                  <p key={index} className={style.StoreInfos__block__line}>
                    <span>
                      <strong>{days[index - 1]} : </strong>
                      <span>
                        {morning && `${morning.start} - ${morning.end}`}
                        {afternoon && ` / ${afternoon.start} - ${afternoon.end}`}
                        {!morning && !afternoon && 'MAGASIN FERMÉ'}
                      </span>
                    </span>
                  </p>
                );
              })}
          </div>
          {/* END BLOCK HORAIRES */}

          {/* BLOCK HORAIRES EXCEPTIONELS */}
          <div className={style.StoreInfos__block}>
            <h2 className={style.StoreInfos__block__title}>Horaires exceptionnels</h2>

            <p className={style.StoreInfos__block__line}>
              <span>
                <strong>Mercredi 5 avril 2023 :</strong> 10:00 - 21:00
              </span>
            </p>

            <p className={style.StoreInfos__block__line}>
              <span>
                <strong>Lundi 10 avril 2023 :</strong> 10:00 - 19:00
              </span>
            </p>

            <p className={style.StoreInfos__block__line}>
              <span>
                <strong>Lundi 1er mai 2023 :</strong> FERMÉ
              </span>
            </p>

            <p className={style.StoreInfos__block__line}>
              <span>
                <strong>Lundi 8 mai 2023 :</strong> 10:00 - 19:00
              </span>
            </p>

            <p className={style.StoreInfos__block__line}>
              <span>
                <strong>Jeudi 18 mai 2023 :</strong> 10:00 - 19:00
              </span>
            </p>

            <p className={style.StoreInfos__block__line}>
              <span>
                <strong>Lundi 29 mai 2023 :</strong> 10:00 - 19:00
              </span>
            </p>

            <p className={style.StoreInfos__block__line}>
              <span>
                <strong>Vendredi 14 juillet 2023 :</strong> 10:00 - 19:00
              </span>
            </p>

            <p className={style.StoreInfos__block__line}>
              <span>
                <strong>Mardi 15 août 2023 :</strong> 10:00 - 19:00
              </span>
            </p>

            <div className="mt-[3.2rem]" />
            {/* END BLOCK HORAIRES EXCEPTIONELS */}

            {/* BLOCK INFOS COMPLEMENTAIRES */}
            <h2 className={style.StoreInfos__block__title}>Informations Complémentaires</h2>

            <p className={style.StoreInfos__block__line}>
              <span>
                <strong>E-réservation :</strong>
                <br />
                <strong>Possibilités de retrait de votre commande :</strong> En magasin
              </span>
            </p>
            {/* END BLOCK INFOS COMPLEMENTAIRES */}
          </div>
        </div>
      </section>
    </>
  ) : (
    <></>
  );
};

export default StoreInfos;
