import { Button } from '@mozaic-ds/react';
import Picto from 'components/elements/Picto/Picto';
import React from 'react';

const Blog = ({ onClick }) => {
  return (
    <div>
      <h2>Informations Zodiosphère(blog)</h2>
      <ul>
        <li>
          <span>Pseudo :</span>
          <strong>fbeyhknskjskdk</strong>
        </li>
        <li>
          <span>Mon magasin préféré :</span>
          <strong></strong>
        </li>
      </ul>
      <div className="text-center mt-4 max-w-[32rem] mx-auto w-full">
        <Button width="full" onClick={onClick}>
          <Picto icon="pen" className="mr-[4rem]" /> Modifier mes informations
        </Button>
      </div>
    </div>
  );
};

export default Blog;
