const regex = {
  email:
    // Comply with RFC 5322 Official Standard
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
  password: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/g,
  phone: /^((\+)33|0|0033)[1-9](\d{2}){4}$/g,
  date: /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/g,
  web: /^((https?):\/\/)?([w|W]{3}\.)+[a-zA-Z0-9\-\.]{3,}\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/g,
  zipcode: /^(?:0[1-9]|[1-8]\d|9[0-8])\d{3}$/g,
};

export const rules = {
  email: {
    required: { value: true, message: 'Ce champ est obligatoire' },
    minLength: { value: 5, message: "L'email que vous avez renseigné est trop court. 5 caractères minimum." },
    maxLength: { value: 80, message: "L'email que vous avez renseigné est trop long. 80 caractères maximum." },
    pattern: {
      value: regex.email,
      message: "L'email que vous avez renseigné ne respecte pas le format. Ex: micheldupont@mail.fr",
    },
  },

  password: {
    required: { value: true, message: 'Ce champ est obligatoire' },
    minLength: { value: 8, message: 'Le mot de passe que vous avez renseigné est trop court. 8 caractères minimum.' },
    maxLength: { value: 24, message: 'Le mot de passe que vous avez renseigné est trop long. 24 caractères maximum.' },
    pattern: {
      value: regex.password,
      message: 'Le mot de passe que vous avez renseigné ne respecte pas le format.',
    },
  },
  passwordConfirm: {
    required: { value: true, message: 'Ce champ est obligatoire' },
    validate: (value, formValues) => {
      return value === formValues.password || 'Le mot de passe de confirmation que vous avez renseigné ne correspond pas.';
    },
  },

  gender: {
    required: { value: true, message: 'Ce champ est obligatoire' },
  },

  company: {
    minLength: { value: 2, message: 'Le nom de société que vous avez renseigné est trop court. 2 caractères minimum.' },
    maxLength: {
      value: 60,
      message: 'Le nom de société que vous avez renseigné est trop long. 60 caractères maximum.',
    },
  },

  firstname: {
    required: { value: true, message: 'Ce champ est obligatoire' },
    minLength: { value: 2, message: 'Le prénom que vous avez renseigné est trop court. 2 caractères minimum.' },
    maxLength: { value: 30, message: 'Le prénom que vous avez renseigné est trop long. 30 caractères maximum.' },
  },

  lastname: {
    required: { value: true, message: 'Ce champ est obligatoire' },
    minLength: { value: 2, message: 'Le nom que vous avez renseigné est trop court. 2 caractères minimum.' },
    maxLength: { value: 30, message: 'Le nom que vous avez renseigné est trop long. 30 caractères maximum.' },
  },

  adress: {
    required: { value: true, message: 'Ce champ est obligatoire' },
    minLength: { value: 2, message: "L'adresse que vous avez renseigné est trop courte. 2 caractères minimum." },
    maxLength: { value: 100, message: "L'adresse que vous avez renseigné est trop longue. 100 caractères maximum." },
  },

  zipcode: {
    required: { value: true, message: 'Ce champ est obligatoire' },
    pattern: {
      value: regex.zipcode,
      message: 'Le code postal que vous avez renseigné est incorrect',
    },
  },

  road: {
    required: { value: true, message: 'Ce champ est obligatoire' },
  },

  city: {
    required: { value: true, message: 'Ce champ est obligatoire' },
  },

  country: {
    required: { value: true, message: 'Ce champ est obligatoire' },
  },

  code: {
    required: { value: true, message: 'Ce champ est obligatoire' },
  },

  phone: {
    pattern: {
      value: regex.phone,
      message: 'Le numéro de téléphone que vous avez renseigné est invalide',
    },
  },

  birthdate: {
    required: {
      value: true,
      message: 'Vous devez renseigner votre date de naissance pour adhérer au programme de fidélité Zôdio',
    },
    pattern: {
      value: regex.date,
      message: 'La date que vous avez renseigné est invalide',
    },
    validate: (value) =>
      Math.abs(new Date(Date.now() - new Date(value).getTime()).getUTCFullYear() - 1970) >= 18 ||
      'Vous devez avoir au minimum 18 ans pour adhérer à notre programme de fidélité',
  },

  web: {
    pattern: {
      value: regex.web,
      message: "l'adresse du site que vous avez renseigné est invalide",
    },
  },

  socialNetwork: {
    pattern: {
      value: regex.web,
      message: 'le reseau social que vous avez renseigné est invalide',
    },
  },

  giftcard: {
    message: {
      required: { value: true, message: 'Ce champ est obligatoire' },
      minLength: { value: 5, message: 'Le message que vous avez renseigné est trop court. 5 caractères minimum.' },
      maxLength: { value: 215, message: 'Le message que vous avez renseigné est trop long. 215 caractères maximum.' },
    },

    price: {
      required: { value: true, message: 'Vous devez renseigner un montant pour votre carte cadeau' },
      min: { value: 5, message: 'Le montant que vous avez renseigné est trop faible. 5€ minimum' },
      max: { value: 9999, message: 'Le montant que vous avez renseigné est trop élevé. 9999€ maximum.' },
    },
  },

  tuto: {
    message: {
      required: { value: true, message: 'Ce champ est obligatoire' },
      minLength: { value: 5, message: 'Le message que vous avez renseigné est trop court. 5 caractères minimum.' },
      maxLength: { value: 1000, message: 'Le message que vous avez renseigné est trop long. 1000 caractères maximum.' },
    },
  },

  promo: {
    minLength: { value: 3, message: 'Le code promo que vous avez renseigné est trop court. 3 caractères minimum.' },
    maxLength: { value: 20, message: 'Le code promo que vous avez renseigné est trop long. 20 caractères maximum.' },
    validate: (value) => {
      // test code promo via AJAX
      return value ? `Le code promo "${value}" ne fonctionne pas.` : true;
    },
  },

  reviews: {
    score: {
      required: { value: true, message: 'Vous devez choisir une note pour votre avis.' },
    },

    title: {
      required: { value: true, message: 'Vous devez saisir un titre pour votre avis.' },
      minLength: { value: 3, message: 'Le titre que vous avez renseigné est trop court. 3 caractères minimum.' },
      maxLength: { value: 50, message: 'Le titre que vous avez renseigné est trop long. 50 caractères maximum.' },
    },

    message: {
      required: { value: true, message: 'Vous devez saisir un texte pour votre avis.' },
      minLength: { value: 10, message: 'Le texte que vous avez renseigné est trop court. 10 caractères minimum.' },
      maxLength: { value: 2500, message: 'Le texte que vous avez renseigné est trop long. 2500 caractères maximum.' },
    },

    images: {
      validate: (files) => {
        let error;

        files.forEach((image) => {
          const { name, size, type } = image;
          if (size && size > 3e6) error = `Votre image "${name}" ne doit pas depasser 3Mo.`;
          if (type && !['image/jpg', 'image/jpeg', 'image/png'].includes(type))
            error = `Le format de votre image "${name}" est incorrect, les images doivent être au format .jpeg ou .png`;
        });

        return error || true;
      },
    },
  },
};
