export default {
  description: {
    title: 'Description du produit',
    content: `
      Optez pour ce set de 6 verres à pied transparents Open'Up en cristallin de 32 cl de chez Chef &amp; Sommelier
      pour déguster vos bouteilles de vin favorites ! Le design moderne et la jambe fine de ces verres en font de
      véritables pièces d’exception à avoir chez soi.
    `,
  },

  caracteristics: {
    title: 'Caractéristiques 6 Verres à Pied transparents Open Up en Cristallin 32cl',
    list: [
      { label: 'Ref', value: '10193639' },
      { label: 'Type de produit table', value: 'verre à pied' },
      { label: 'Matière', value: 'cristallin' },
      { label: 'Contenance en cl', value: '32cl' },
      { label: 'Type de boisson', value: 'vin' },
      { label: 'Entretien lave-vaisselle', value: 'oui' },
      { label: 'Couleur', value: 'transparent' },
      { label: 'Les points forts', value: "Ce verre Open Up s'adapte à tous les types de vins" },
      { label: 'Made in France', value: 'Oui' },
      { label: 'Marque', value: 'CHEF & SOMMELIER' },
      { label: 'Longueur (cm)', value: '8,70' },
      { label: 'Largeur (cm)', value: '8,70' },
      { label: 'Hauteur (cm)', value: '18' },
      { label: 'Poids net (kg)', value: '0,25' },
      { label: 'Garantie', value: 'ans' },
      { label: 'Disponibilité', value: 'Vendu en ligne / Retrait 2 h' },
      { label: 'Nombre de produits', value: '6' },
      { label: 'Essentiel du faire', value: 'Non' },
      { label: 'Empilable', value: 'Non' },
    ],
  },
};
