import React from 'react';
import style from './Banner.module.scss';
import Logo from './logo.svg';

const Banner = ({ data }) => {
  try {
    const { title, imageDesktop, imageMobile } = data.content;

    const breakpoints = [
      { media: '(max-width: 800px) and (-webkit-min-device-pixel-ratio: 1)', srcSet: imageMobile + '?w=800' },
      { media: '(max-width: 800px) and (-webkit-min-device-pixel-ratio: 2)', srcSet: imageMobile + '?w=1600' },
      { media: '(max-width: 1100px) and (-webkit-min-device-pixel-ratio: 1)', srcSet: imageDesktop + '?w=1100' },
      { media: '(max-width: 1100px) and (-webkit-min-device-pixel-ratio: 2)', srcSet: imageDesktop + '?w=2200' },
      { media: '(max-width: 1500px) and (-webkit-min-device-pixel-ratio: 1)', srcSet: imageDesktop + '?w=1920' },
      { media: '(max-width: 1500px) and (-webkit-min-device-pixel-ratio: 2)', srcSet: imageDesktop + '?w=3840' },
    ];

    return (
      <section className={style.Banner}>
        <picture>
          {breakpoints.map((breakpoint, i) => (
            <source key={i} {...breakpoint} />
          ))}
          <img className={style.Banner__picture} src={imageDesktop + '?w=1920'} alt="" />
        </picture>

        <h1 className={style.Banner__content}>
          <Logo />
          <span>{title}</span>
        </h1>
      </section>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default Banner;
