import React from 'react';
import style from './History.module.scss';
import Subtitle from 'components/elements/Subtitle/Subtitle';
import Slider from 'components/elements/Slider/Slider';
import ItemTile from 'components/elements/ItemTile/ItemTile';
import WorkshopTile from 'components/elements/WorkshopTile/WorkshopTile';

import algoliasearch from 'algoliasearch';

const appId = process.env.NEXT_PUBLIC_ALGOLIA_APP_ID;
const apiKey = process.env.NEXT_PUBLIC_ALGOLIA_API_KEY;
const indexName = process.env.NEXT_PUBLIC_ALGOLIA_INDEX_PRODUCT;

import { history } from './History.mock';

const History = ({ data }) => {
  const [isMounted, setIsMounted] = React.useState(true);
  const [items, setItems] = React.useState([]);
  const { max, min, type } = data;

  async function fetchData() {
    if (history.length < min) return;

    let objectsToGet;

    // 1. get history from local Storage
    if (type === 'mix') objectsToGet = history;
    if (type === 'product') objectsToGet = history.filter(({ type }) => type === 'product');
    if (type === 'workshop') objectsToGet = history.filter(({ type }) => type === 'workshop');

    // 2. sort history by date and get only items id
    objectsToGet = objectsToGet.sort((a, b) => new Date(b.date) - new Date(a.date)).map((item) => item.id);

    // 3. call algolia to get items in history
    const result = await algoliasearch(appId, apiKey)
      .initIndex(indexName)
      .getObjects(objectsToGet)
      .then(({ results }) => results.filter((item) => item?.type_id && item.visibility_catalog === 1))
      .catch(console.error);

    // 4. Display result in the page
    isMounted && setItems(result.slice(0, max));
  }

  React.useEffect(() => {
    fetchData();
    return () => setIsMounted(false);
  }, []);

  try {
    return items.length ? (
      <section className={style.History}>
        {type === 'product' && <Subtitle title="Les derniers produits que vous avez consultés" />}
        {type === 'workshop' && <Subtitle title="Les derniers ateliers que vous avez consultés" />}
        {type === 'mix' && <Subtitle title="Les derniers produits et ateliers que vous avez consultés" />}
        <Slider
          breakpoints={{
            320: { slidesPerView: 1 },
            581: { slidesPerView: 2 },
            801: { slidesPerView: 3 },
            1024: { slidesPerView: 4 },
          }}
          alignArrowsOn='article [class*="ItemTile__header"], article [class*="WorkshopTile__header"]'
        >
          {items.map((product) => {
            return (
              <React.Fragment key={product.sku}>
                {product.type_id === 'simple' && <ItemTile data={product} />}
                {product.type_id === 'workshop' && <WorkshopTile data={product} />}
              </React.Fragment>
            );
          })}
        </Slider>
      </section>
    ) : null;
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default History;
