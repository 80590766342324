import Subtitle from 'components/elements/Subtitle/Subtitle';
import style from './Categories.module.scss';

const Categories = ({ data }) => {
  const { title, categories, legend } = data.content;

  return (
    <section className={style.Categories}>
      <Subtitle title={title} />

      <ul className={style.Categories__list}>
        {categories.map((category, i) => {
          return (
            <li key={i} className={style.Categories__list__item}>
              <h4 className={style.Categories__list__item__title}>{category.title}</h4>
              <p className={style.Categories__list__item__text}>{category.text}</p>
              <img className={style.Categories__list__item__image} src={`https:${category.image}`} alt="" loading="lazy" />
            </li>
          );
        })}
      </ul>

      {legend && <p className={style.Categories__legend}>{legend}</p>}
    </section>
  );
};

export default Categories;
