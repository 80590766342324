import React from 'react';

import HeaderLogo from './HeaderLogo/HeaderLogo';
import HeaderPictos from './HeaderPictos/HeaderPictos';
import HeaderSearch from './HeaderSearch/HeaderSearch';
import HeaderMenu from './HeaderMenu/HeaderMenu';

const Header = ({ data }) => {
  try {
    const { logo, baseline } = data.contentfulData.dataSource.fields;

    console.log(data);

    return (
      <header className="header container">
        <HeaderLogo logo={logo} baseline={baseline} />
        <HeaderSearch />
        <HeaderPictos />
        <HeaderMenu data={data.navigationItems?.dataSource} />
      </header>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default Header;
