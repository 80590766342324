import style from './ProductsTargets.module.scss';
import React from 'react';
import Picto from '../Picto/Picto';
import Link from 'next/link';

const ProductsTargets = ({ product, id, target }) => {
  return (
    <Link href="#">
      <a data-visible={target === id} className={style.ProductsTargets}>
        {product &&
          Object.keys(product).map((item) => {
            return (
              <React.Fragment key={item}>
                <strong>{product[item].name}</strong>

                <div className="flex justify-between items-center">
                  <strong>{product[item].price['EUR'].default_formated}</strong>
                  <Picto icon="arrow--right" />
                </div>
              </React.Fragment>
            );
          })}
      </a>
    </Link>
  );
};

export default ProductsTargets;
