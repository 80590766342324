import PDP_ProductInfos from './ProductInfos/ProductInfos';
import PDP_ProductATC from './ProductATC/ProductATC';
import PDP_ProductMoreLink from './ProductMoreLink/ProductMoreLink';
import PDP_ProductBusinessSolutions from './ProductBusinessSolutions/ProductBusinessSolutions';
import PDP_ProductDelivery from './ProductDelivery/ProductDelivery';
import PDP_ProductVariants from './ProductVariants/ProductVariants';
import PDP_ProductStickyBanner from './ProductStickyBanner/ProductStickyBanner';
import PDP_ProductPlus from './ProductPlus/ProductPlus';
import PDP_ProductTabs from './ProductTabs/ProductTabs';
import PDP_ProductUGC from './ProductUGC/ProductUGC';

export default {
  PDP_ProductInfos,
  PDP_ProductATC,
  PDP_ProductMoreLink,
  PDP_ProductBusinessSolutions,
  PDP_ProductDelivery,
  PDP_ProductVariants,
  PDP_ProductStickyBanner,
  PDP_ProductPlus,
  PDP_ProductTabs,
  PDP_ProductUGC,
};
