import React from 'react';
import style from './ProductTabs.module.scss';
import { Tabs, TabsList, TabsButton, TabsPanel } from '@mozaic-ds/react';

import ProductTabInfos from './partials/ProductTabInfos/ProductTabInfos';
import ProductTabReviews from './partials/ProductTabReviews/ProductTabReviews';

const titles = {
  infos: "Plus d'infos",
  reviews: 'Avis client',
};

const ProductTabs = () => {
  const tabs = React.useRef();
  const btnInfos = React.useRef();
  const btnReviews = React.useRef();

  React.useEffect(scrollOnLoad, []);

  function scrollOnLoad() {
    const tab = window.location.hash.replace('#', '');
    if (tab) setTimeout(() => goToTab(tab), 500);
  }

  React.useEffect(() => {
    window.addEventListener('open-tab', (event) => goToTab(event.detail.tab));
    return window.removeEventListener('open-tab', (event) => goToTab(event.detail.tab));
  }, []);

  function goToTab(tab) {
    window.location.hash = tab;
    window.scrollTo({ top: tabs.current?.offsetTop });

    switch (tab) {
      case 'infos':
        btnInfos.current?.closest('button')?.click();
        break;
      case 'reviews':
        btnReviews.current?.closest('button')?.click();
        break;
    }
  }

  return (
    <section className={style.ProductTabs} ref={tabs}>
      <Tabs defaultTab={titles.infos}>
        <TabsList width="full">
          <TabsButton tab={titles.infos}>
            <span className={style.ProductTabs__title} ref={btnInfos}>
              {titles.infos}
            </span>
          </TabsButton>

          <TabsButton tab={titles.reviews}>
            <span className={style.ProductTabs__title} ref={btnReviews}>
              {titles.reviews}
            </span>
          </TabsButton>
        </TabsList>

        <TabsPanel tab={titles.infos}>
          <div className={style.ProductTabs__content}>
            <ProductTabInfos />
          </div>
        </TabsPanel>

        <TabsPanel tab={titles.reviews}>
          <div className={style.ProductTabs__content}>
            <ProductTabReviews />
          </div>
        </TabsPanel>
      </Tabs>
    </section>
  );
};
export default ProductTabs;
