import style from './MenuFilter.module.scss';
import { useHierarchicalMenu } from 'react-instantsearch-hooks-web';

const MenuFilter = ({ attributes }) => {
  const { items, refine } = useHierarchicalMenu({ attributes });
  return <MenuFilterItem items={items} refine={refine} />;
};

const MenuFilterItem = ({ items, refine }) => {
  return (
    <ul className={style.MenuFilter}>
      {items.map((item) => (
        <li key={item.label} className={style.MenuFilter__item}>
          <button className={style.MenuFilter__item__button} data-active={item.isRefined} onClick={() => refine(item.value)}>
            {item.label} <small>({item.count})</small>
          </button>

          {item.data && <MenuFilterItem items={item.data} refine={refine} />}
        </li>
      ))}
    </ul>
  );
};

export default MenuFilter;
