import style from './GiftsCards.module.scss';
import Navigation from '../partials/Navigation/Navigation';
import React, { Fragment, useContext, useEffect } from 'react';
import BreadcrumbAccount from '../partials/BreadcrumbAccount/BreadcrumbAccount';
import { mockGifts } from './GiftsCards.mock';
import { Button, CheckBox } from '@mozaic-ds/react';
import { UserContext } from '../../../../frontastic';
import { useRouter } from 'next/router';

const GiftsCards = ({ data }) => {
  const router = useRouter();

  const { user } = useContext(UserContext);

  useEffect(() => {
    if (!user) router.push('/connexion');
  }, []);

  if (!user) return <></>;

  return (
    <>
      <BreadcrumbAccount name="Mes cartes cadeaux" />
      <div className={style.giftsCards}>
        <div>
          <Navigation />
        </div>
        <div>
          <div className={style.giftsCards__title}>
            <h2>Mes cartes cadeaux</h2>
          </div>
          <p>Retrouvez l’ensemble des cartes cadeaux achetées en ligne. Vous pouvez télécharger la ou les carte(s) que vous souhaitez.</p>
          <div className={style.giftsCards__getFile}>
            <CheckBox>Séléctionner tous</CheckBox>
            <div className={style.giftsCards__download}>
              <Button width="full" theme="secondary" type="submit" onclick="window.location.href='file.doc'">
                Télécharger
              </Button>
            </div>
          </div>
          <div className={style.giftsCards__tableGiftcards}>
            <div className={style.giftsCards__tableGiftcards__head}>
              <div></div>
              <div>Description</div>
              <div>Date d expiration</div>
              <div>Prix</div>
              <div>Télécharger</div>
            </div>
            <div className={style.giftsCards__tableGiftcards__body}>
              {mockGifts.map((gitft) => (
                <Fragment key={gitft.date}>
                  <div>
                    <CheckBox />
                  </div>
                  <div>{gitft.description}</div>
                  <div>{new Date(gitft.date).toLocaleDateString()}</div>
                  <div>{gitft.prix}</div>
                  <div>Télécharger</div>
                </Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GiftsCards;
