import React from 'react';
import style from './StoreList.module.scss';
import Subtitle from 'components/elements/Subtitle/Subtitle';
import Link from 'next/link';
import { Button } from '@mozaic-ds/react';
import { flattenString } from 'helpers/utils/string';

// prefix : s'affiche avant le nom du magasin dans chaque boutton
// suffix : s'affiche après le nom du magasin dans chaque boutton
// href : Si renseignée, chaque bouton redirige vers l'url ou %STORE% est remplacé par le nom du magasin

const StoreList = ({ title = 'Trouvez un magasin Zôdio à proximité', prefix = '', suffix = '', href }) => {
  const WoosmapKey = process.env.NEXT_PUBLIC_WOOSMAP_KEY;
  const [storeList, setStoreList] = React.useState(null);

  React.useEffect(async () => {
    let isMounted = true;

    const data =
      isMounted &&
      (await fetch(`https://api.woosmap.com/stores/search/?stores_by_page=100&key=${WoosmapKey}`)
        .then((response) => response.json())
        .then((json) => json.features)
        .catch(console.error));

    setStoreList(data);

    return () => {
      isMounted = false;
    };
  }, []);

  return storeList ? (
    <section className={style.StoreList}>
      <Subtitle title={title} />

      <ul className={style.StoreList__items}>
        {storeList.map((store) => {
          const { store_id, name, contact } = store.properties;

          const url = href ? href.replace(/%STORE%*/gi, flattenString(name.toLowerCase())) : contact.website.split('.fr')[1];

          return (
            <li key={store_id}>
              <Link href={url}>
                <Button href={url} width="full" theme="secondary">
                  {`${prefix} ${name} ${suffix}`.toUpperCase()}
                </Button>
              </Link>
            </li>
          );
        })}
      </ul>
    </section>
  ) : (
    <></>
  );
};

export default StoreList;
