import style from './Recompenses.module.scss';
import { Button } from '@mozaic-ds/react';
import Link from 'next/link';
import LoyaltyProgramCard from 'components/elements/LoyaltyProgramCard/LoyaltyProgramCard';
import FideliteHeader from '../partials/FideliteHeader/FideliteHeader';
import useGetCurrentDevice from 'helpers/utils/media-queries';

import Slider from 'components/elements/Slider/Slider';
import { publishItems } from 'helpers/utils/publishItems';

const Recompenses = ({ data }) => {
  try {
    const currentDevice = useGetCurrentDevice();
    const { content, title, titleHousky, subtitle } = data.data.dataSource.fields;

    return (
      <section className={style.Recompenses}>
        <div className={style.Recompenses__container}>
          <FideliteHeader title={title} titleHousky={titleHousky} subtitle={subtitle} />

          {currentDevice !== 'desktop' ? (
            <Slider
              sameHeight={true}
              breakpoints={{
                320: { slidesPerView: 1 },
                581: { slidesPerView: 2 },
                900: { slidesPerView: 3 },
              }}
            >
              {publishItems(content).map((item) => {
                const id = item.sys.contentType.sys.id;
                return id === 'loyaltyGiftThumbnails' && <LoyaltyProgramCard key={item.sys.id} data={item} />;
              })}
            </Slider>
          ) : (
            <div className={style.Recompenses__items}>
              {publishItems(content).map((item) => {
                const id = item.sys.contentType.sys.id;
                return id === 'loyaltyGiftThumbnails' && <LoyaltyProgramCard key={item.sys.id} data={item} />;
              })}
            </div>
          )}

          {publishItems(content).map((item) => {
            item.sys.contentType.sys.id === 'links' && (
              <Link key={item.sys.id} href={item.fields.url}>
                <a className={style.Recompenses__link}>
                  <Button size="l" width="full">
                    {item.fields.title}
                  </Button>
                </a>
              </Link>
            );
          })}
        </div>
      </section>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default Recompenses;
