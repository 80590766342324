import style from './ProductInfos.module.scss';
import { Flag, RatingStarsResult } from '@mozaic-ds/react';
import Price from 'components/elements/Price/Price';

const ProductInfos = () => {
  const goToTab = () => window.dispatchEvent(new CustomEvent('open-tab', { detail: { tab: 'reviews' } }));

  return (
    <section className={style.ProductInfos}>
      <div className={style.ProductInfos__block}>
        <h1 className={style.ProductInfos__title}>6 verres à pied transparents Open Up en cristallin 32cl</h1>

        <Flag className={style.ProductInfos__flag} theme="primary" variant="bordered">
          Série Limitée
        </Flag>
      </div>

      <div className={style.ProductInfos__block}>
        <Price
          className={style.ProductInfos__price}
          size="l"
          discount={50}
          data={{ default_original_formated: '79,95 €', default_formated: '39,90 €' }}
        />

        <div className={style.ProductInfos__reviews}>
          <RatingStarsResult score={70} size="s" />
          <button onClick={goToTab}>Voir les 36 avis</button>
        </div>
      </div>
    </section>
  );
};

export default ProductInfos;
