import PropTypes from 'prop-types';
import style from './Price.module.scss';

const sizeClass = {
  xs: 'Price--xsmall',
  s: 'Price--small',
  m: 'Price--regular',
  l: 'Price--big',
};

const Price = (props) => {
  const { data, size = 'm', className, discount, unity, ecopart, ...rest } = props;

  const { default_formated, default_original_formated } = data;
  const priceClass = [style.Price, style[sizeClass[size]], className].join(' ');

  return (
    <div className={priceClass} {...rest}>
      <div className={style.Price__details}>
        {discount && (
          <span className={style.Price__discount} aria-label={`Ce produit dispose d'une réduction de ${discount}%`}>
            -{discount}%
          </span>
        )}

        {default_original_formated && (
          <span className={style.Price__original}>
            {default_original_formated}
            {unity && <small>/{unity}</small>}
          </span>
        )}

        {default_formated && (
          <span className={style.Price__final}>
            {default_formated}
            {unity && <small>/{unity}</small>}
          </span>
        )}
      </div>

      {ecopart && <span className={style.Price__ecopart}>dont {ecopart.toLocaleString('fr-FR')}€ d&apos;éco-part.</span>}
    </div>
  );
};

Price.propTypes = {
  size: PropTypes.oneOf(['s', 'm', 'l']),
  discount: PropTypes.number,
  unity: PropTypes.string,
  ecopart: PropTypes.number,
  data: PropTypes.shape({
    default: PropTypes.number,
    default_formated: PropTypes.string.isRequired,
  }),
};

export default Price;
