import style from './TitleRetours.module.scss';

const TitleRetours = ({ data }) => {
  try {
    const { title } = data.data.dataSource.fields;
    return <h1 className={style.TitleRetours}>{title}</h1>;
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default TitleRetours;
