import style from './StoreTeam.module.scss';

const StoreTeam = () => {
  return (
    <section className={style.StoreTeam}>
      <h2 className={style.StoreTeam__title}>Notre équipe</h2>

      <div className={style.StoreTeam__content}>
        <div className={`${style.StoreTeam__block} ${style['StoreTeam__block--text']}`}>
          <h3 className={style.StoreTeam__block__title}>Le mot du magasin</h3>

          <p>
            Zôdio est un magasin de décoration et de cuisine situé dans la zone commerciale de V2 à Villeneuve-d&apos;Ascq. Dans notre magasin, la
            décoration d&apos;intérieur est mise à l&apos;honneur. Venez trouver de l&apos;inspiration pour créer une déco tendance qui vous
            ressemble. En plus d&apos;être un magasin de décoration pour toutes les pièces de la maison, votre magasin Zôdio à Villeneuve-d&apos;Ascq
            vous propose du matériel pour cuisiner et pâtisser comme des professionnels.
          </p>
          <p>
            Dans nos rayons, retrouvez une large sélection de moules à gâteaux, de poêles, d&apos;ustensiles de cuisine et bien d&apos;autres produits
            pour régaler toute la petite famille. Nos conseillers seront ravis de vous accueillir et de partager leur expertise sur la décoration, la
            cuisine, la pâtisserie et les loisirs créatifs.
          </p>
          <p>
            Un pôle de personnalisation est également à votre disposition afin que vous puissiez personnaliser de nombreux objets du quotidien tels
            que des planches à découper ou votre linge de maison. Notre magasin est aussi un lieu de vie où vous pourrez participer à des ateliers
            créatifs et de cuisine. Avec nos chefs, vous aurez l&apos;opportunité de réaliser des tutos et de vivre de véritables moments de
            convivialité.
          </p>
          <p>
            Tous nos produits sont disponibles sur notre site internet mais également au sein de nos 20 magasins dans toute la France. Venez nous
            rendre visite au sein de notre magasin de décoration et cuisine de Villeneuve-d&apos;Ascq.
          </p>
        </div>

        <img
          className={`${style.StoreTeam__block} ${style['StoreTeam__block--image']}`}
          src="https://www.zodio.fr/static/version1683104084/frontend/Zodio/2019/fr_FR/images/temp/store-example.jpg"
          alt=""
        />

        <img
          className={`${style.StoreTeam__block} ${style['StoreTeam__block--image']}`}
          src="https://www.zodio.fr/static/version1683104084/frontend/Zodio/2019/fr_FR/images/temp/store-example2.jpg"
          alt=""
        />
      </div>
    </section>
  );
};

export default StoreTeam;
