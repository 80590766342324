export const mockGifts = [
  {
    description: 'coupon de réduction',
    date: '2023-06-04',
    prix: 19.45,
  },
  {
    description: 'carte cadeau',
    date: '2023-12-25',
    prix: 19.45,
  },
];
