import React from 'react';
import style from './ProductStickyBanner.module.scss';
import Price from 'components/elements/Price/Price';
import Picto from 'components/elements/Picto/Picto';
import { Button } from '@mozaic-ds/react';
import { debounce } from 'lodash';

const ProductStickyBanner = () => {
  const banner = React.useRef();
  const [bannerIsVisible, setBannerVisible] = React.useState(false);
  const bannerOffsetTop = banner.current?.offsetTop || 500;

  React.useEffect(() => {
    window.addEventListener(
      'scroll',
      debounce(() => setBannerVisible(document.documentElement.scrollTop > bannerOffsetTop), 50),
    );
    return window.removeEventListener('scroll', () => setBannerVisible(false));
  }, [bannerOffsetTop]);

  return bannerIsVisible ? (
    <div className={style.ProductStickyBanner} aria-hidden="true" ref={banner}>
      <div className={style.ProductStickyBanner__content}>
        <img
          className={style.ProductStickyBanner__image}
          src="https://photos.zodio.fr/zodio-magento/catalog/products/source/0/0/0026102293355_1_A.jpg?height=70&width=70&fit=bounds&bg-color=fff"
          alt=""
        />

        <span className={style.ProductStickyBanner__title}>6 verres à pied transparents Open Up en cristallin 32cl</span>

        <Price size="m" className={style.ProductStickyBanner__price} data={{ default_original_formated: '79,95 €', default_formated: '39,90 €' }} />

        <Button className={style.ProductStickyBanner__button} size="m" theme="primary" width="fit">
          Ajouter au panier <Picto icon="cart" />
        </Button>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default ProductStickyBanner;
