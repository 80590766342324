import StoreInfos from './StoreInfos/StoreInfos';
import StoreTeam from './StoreTeam/StoreTeam';
import StoreExperts from './StoreExperts/StoreExperts';
import StoreEvents from './StoreEvents/StoreEvents';
import StoreServices from './StoreServices/StoreServices';
import StoreMap from './StoreMap/StoreMap';
import StoreList from './StoreList/StoreList';

export default {
  StoreInfos,
  StoreTeam,
  StoreExperts,
  StoreEvents,
  StoreServices,
  StoreMap,
  StoreList,
};
