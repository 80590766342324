import React from 'react';
import Picto from 'components/elements/Picto/Picto';
import style from './ItemTilePictos.module.scss';

/* 
  # On affiche le picto "Retrait 2H" si :
    Le paramètre status_ereservation vaux "1") 
    ET 
    ET (
      Le client est contextualisé ET store_stock à un stock > 0 pour le magasin préféré
      OU
      Le client n'est pas contextualisé ET store_stock à au moins 15 magasin avec un stock > 0
    ) 
    
  # On affiche le picto "Ventre en Ligne" si :
    (le paramètre VAD vaux "1") ET (le paramètre salable_qtity est > 0)
*/

const ItemTilePictos = (data) => {
  const [store, setStore] = React.useState(null);

  React.useEffect(() => {
    setStore(JSON.parse(localStorage.getItem('store')));
  }, []);

  try {
    const { status_ereservation, store_stock, VAD, salable_qtity } = data;

    return (
      <>
        {status_ereservation === 1 &&
          ((store && store_stock.find((item) => +item.id === +store.store_id).stock > 0) || available_at.length >= 15) && (
            <span className={style.ItemTilePictos} data-type="eresa" title="Ce produit est disponible à la e-reservation en magasin.">
              <Picto icon="shop" />
            </span>
          )}

        {VAD === 1 && salable_qtity > 0 && (
          <span className={style.ItemTilePictos} data-type="online" title="Ce produit est disponible à la vente en ligne.">
            <Picto icon="cart" />
          </span>
        )}
      </>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default ItemTilePictos;
