import style from './CheckboxesFilter.module.scss';
import { useRefinementList } from 'react-instantsearch-hooks-web';
import { CheckBox, TextInput } from '@mozaic-ds/react';

const CheckboxesFilter = ({ attribute }) => {
  const { canRefine, items, isFromSearch, searchForItems, refine } = useRefinementList({ attribute });

  return items.length || isFromSearch ? (
    <div className={style.CheckboxesFilter}>
      {(isFromSearch || items.length >= 10) && (
        <TextInput className={style.CheckboxesFilter__search} size="s" placeholder="Rechercher..." onChange={(e) => searchForItems(e.target.value)} />
      )}

      {canRefine ? (
        <ul className={style.CheckboxesFilter__list}>
          {items.map((item) => (
            <li key={item.value} className={style.CheckboxesFilter__list__item} data-active={item.isRefined}>
              <CheckBox className={style.CheckboxesFilter__list__item__checkbox} isChecked={item.isRefined} onChange={() => refine(item.value)}>
                {item.label} <small>({item.count})</small>
              </CheckBox>
            </li>
          ))}
        </ul>
      ) : (
        <span className={style.CheckboxesFilter__empty}>Aucun résultats</span>
      )}
    </div>
  ) : null;
};

export default CheckboxesFilter;
