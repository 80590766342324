import style from './CommentCaMarche.module.scss';
import FideliteHeader from '../partials/FideliteHeader/FideliteHeader';
import { Button } from '@mozaic-ds/react';
import { publishItems } from 'helpers/utils/publishItems';
import Link from 'next/link';

const CommentCaMarche = ({ data }) => {
  try {
    const { title, content } = data.data.dataSource.fields;
    return (
      <section className={style.Comment}>
        <FideliteHeader titleHousky={title} />

        <div className={style.Comment__items}>
          {publishItems(content).map((item, index) => {
            const { title, titleHousky, subtitle, illustration } = item.fields;

            return (
              item.sys.contentType.sys.id === 'loyaltyPictoCcm' && (
                <div key={item.sys.id} className={style.Comment__item}>
                  <span className={style.Comment__item__index}>{index + 1}</span>
                  <div className={style.Comment__item__inner}>
                    <h3 className={style.Comment__item__title}>
                      {title}
                      <span>{titleHousky}</span>
                      <em>{subtitle}</em>
                    </h3>

                    <img alt="" className={style.Comment__item__illustration} src={illustration.fields.file.url} />
                  </div>
                </div>
              )
            );
          })}
        </div>

        <div className={style.Comment__link}>
          {publishItems(content).map((item) => {
            item.sys.contentType.sys.id === 'links' && (
              <Link key={item.sys.id} href={item.fields.url}>
                <Button size="l" width="full">
                  {item.fields.title}
                </Button>
              </Link>
            );
          })}
        </div>
      </section>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default CommentCaMarche;
