import SignIn from './SignIn/SignIn';
import SignUp from './SignUp/SignUp';
import ForgotPassword from './ForgotPassword/ForgotPassword';
import Newsletter from './Newsletter/Newsletter';

export default {
  SignIn,
  SignUp,
  ForgotPassword,
  Newsletter,
};
