import style from './WorkshopsSelectStore.module.scss';
import Picto from 'components/elements/Picto/Picto';
import { Button, Layer, LayerBody, LayerHeader, LayerTitle, useModals } from '@mozaic-ds/react';
import SelectStore from 'components/elements/SelectStore/SelectStore';

const WorkshopsSelectStore = () => {
  const { open } = useModals();

  try {
    return (
      <section className={style.WorkshopsSelectStore}>
        <div className={style.WorkshopsSelectStore__inner}>
          <h2 className={style.WorkshopsSelectStore__inner__title}>
            <Picto icon="pin--bordered" />
            Trouvez nos ateliers près de chez vous
          </h2>

          <div className={style.WorkshopsSelectStore__inner__button}>
            <Button width="full" onClick={() => open('change-store')}>
              Trouver un magasin
            </Button>
          </div>
        </div>

        <Layer id="change-store">
          <LayerHeader id="change-store">
            <LayerTitle>Je cherche le magasin le plus proche de chez moi</LayerTitle>
          </LayerHeader>

          <LayerBody>
            <SelectStore />
          </LayerBody>
        </Layer>
      </section>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default WorkshopsSelectStore;
