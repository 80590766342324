import ImageTop from './ImageTop/ImageTop';
import Intro from './Intro/Intro';
import Positionnement from './Positionnnement/Positionnement';
import FaqSommesNous from './FaqSommesNous/FaqSommesNous';
import EnseigneDeux from './EnseigneDeux/EnseigneDeux';

export default {
  ImageTop,
  Intro,
  Positionnement,
  FaqSommesNous,
  EnseigneDeux,
};
