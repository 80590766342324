import Picto from 'components/elements/Picto/Picto';
import style from './Subtitle.module.scss';

const Subtitle = ({ title }) => {
  return (
    <h2 className={style.subtitle}>
      {title}
      <Picto icon="underline" />
    </h2>
  );
};

export default Subtitle;
