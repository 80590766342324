import style from './WorkshopGiftcard.module.scss';
import Subtitle from 'components/elements/Subtitle/Subtitle';
import { Button } from '@mozaic-ds/react';
import Picto from 'components/elements/Picto/Picto';
import { publishItems } from 'helpers/utils/publishItems';

const WorkshopGiftcard = ({ data }) => {
  try {
    const { title, subtitle, encarts } = data.data.dataSource.fields;

    return (
      <section className={style.WorkshopGiftcard}>
        <Subtitle title={title} />

        <p className={style.WorkshopGiftcard__text}>{subtitle}</p>

        {encarts.length && (
          <div className={style.WorkshopGiftcard__content}>
            {publishItems(encarts).map((item) => {
              const { titre, subtitle, image, cta, ctaUrl } = item.fields;

              return (
                <article key={item.sys.id} className={style.WorkshopGiftcard__item}>
                  <img className={style.WorkshopGiftcard__item__image} src={image.fields.file.url} alt="" />

                  <h3 className={style.WorkshopGiftcard__item__title}>{titre}</h3>

                  <p className={style.WorkshopGiftcard__item__subtitle}>{subtitle}</p>

                  <Button theme="primary" width="full" href={ctaUrl}>
                    {cta} <Picto icon="arrow--right" className="ml-[1rem]" />
                  </Button>
                </article>
              );
            })}
          </div>
        )}
      </section>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default WorkshopGiftcard;
