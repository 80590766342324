import React from 'react';
import style from './CollectePoints.module.scss';
import { Button } from '@mozaic-ds/react';
import Link from 'next/link';
import Slider from 'components/elements/Slider/Slider';
import LoyaltyProgramCard from 'components/elements/LoyaltyProgramCard/LoyaltyProgramCard';
import { publishItems } from 'helpers/utils/publishItems';
import useGetCurrentDevice from 'helpers/utils/media-queries';

const CollectePoints = ({ point }) => {
  try {
    const currentDevice = useGetCurrentDevice();
    const { title, subtitle, content } = point.fields;

    return (
      <section className={style.CollectePoints}>
        <div className={style.CollectePoints__container}>
          <div className={style.CollectePoints__title}>
            {title}
            <span>{subtitle}</span>
          </div>

          {currentDevice !== 'desktop' ? (
            <Slider
              sameHeight={true}
              breakpoints={{
                320: { slidesPerView: 1 },
                581: { slidesPerView: 2 },
                900: { slidesPerView: 3 },
              }}
            >
              {publishItems(content).map((item) => {
                const id = item.sys.contentType.sys.id;
                return id === 'loyaltyGiftThumbnails' && <LoyaltyProgramCard key={item.sys.id} data={item} />;
              })}
            </Slider>
          ) : (
            <div className={style.CollectePoints__items}>
              {publishItems(content).map((item) => {
                const id = item.sys.contentType.sys.id;
                return id === 'loyaltyGiftThumbnails' && <LoyaltyProgramCard key={item.sys.id} data={item} />;
              })}
            </div>
          )}

          {publishItems(content).map(
            (item) =>
              item.sys.contentType.sys.id === 'links' && (
                <Link key={item.sys.id} href={item.fields.url}>
                  <a className={style.CollectePoints__link}>
                    <Button size="l" width="full">
                      {item.fields.title}
                    </Button>
                  </a>
                </Link>
              ),
          )}
        </div>
      </section>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default CollectePoints;
