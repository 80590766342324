import style from './BannerInspiHouse.module.scss';
import { Button } from '@mozaic-ds/react';
import Picto from 'components/elements/Picto/Picto';
import Link from 'next/link';
import React from 'react';
import RichText from 'components/elements/contentful/RichText/RichText';

const BannerInspiHouse = ({ data }) => {
  try {
    const { cta1, ctaUrl1, bannerImage, bannerImageMobile, cta2, ctaUrl2, title, shortDescription } = data.data.dataSource.fields;

    const bannerImageUrl = bannerImage.fields.file.url;
    const bannerImageMobileUrl = bannerImageMobile.fields.file.url;

    const breakpoints = [
      { media: '(max-width: 800px) and (-webkit-min-device-pixel-ratio: 1)', srcSet: bannerImageMobileUrl + '?w=800' },
      { media: '(max-width: 800px) and (-webkit-min-device-pixel-ratio: 2)', srcSet: bannerImageMobileUrl + '?w=1600' },
      { media: '(max-width: 1100px) and (-webkit-min-device-pixel-ratio: 1)', srcSet: bannerImageUrl + '?w=1100' },
      { media: '(max-width: 1100px) and (-webkit-min-device-pixel-ratio: 2)', srcSet: bannerImageUrl + '?w=2200' },
      { media: '(max-width: 1500px) and (-webkit-min-device-pixel-ratio: 1)', srcSet: bannerImageUrl + '?w=1920' },
      { media: '(max-width: 1500px) and (-webkit-min-device-pixel-ratio: 2)', srcSet: bannerImageUrl + '?w=3840' },
    ];

    return (
      <section className={style.BannerInspiHouse}>
        <picture className={style.BannerInspiHouse__picture}>
          {breakpoints.map((breakpoint, i) => (
            <source key={i} {...breakpoint} />
          ))}
          <img src={bannerImageUrl + '?w=1920'} alt={title} />
        </picture>
        <div className={style.BannerInspiHouse__bloc}>
          <div className={style.BannerInspiHouse__bloc__text}>
            <h1>{title}</h1>
            <RichText content={shortDescription} />
          </div>
          <Link href={ctaUrl1}>
            <a>
              {cta1} <Picto icon="arrow--long" />
            </a>
          </Link>
        </div>
        <div className={style.BannerInspiHouse__action}>
          <Link href={ctaUrl2}>
            <Button size="full" href={ctaUrl2}>
              {cta2}
            </Button>
          </Link>
        </div>
      </section>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default BannerInspiHouse;
