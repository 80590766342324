import React from 'react';
import Link from 'next/link';
import style from './Categories.module.scss';

const Categories = ({ data }) => {
  const list = React.useRef(null);
  const { links } = data.data.dataSource.fields;

  function handleFocus(event) {
    const left = event.target.offsetLeft - 25;
    list.current.scrollTo({ left, behavior: 'smooth' });
  }

  return links ? (
    <section className={`${style.categories} container`}>
      <h2>Nos catégories</h2>

      <ul ref={list}>
        {links.map((category) => {
          return (
            <li key={`category--${category.fields.title}`}>
              <Link href={`${category.fields.url}`}>
                <a onFocus={handleFocus}>{category.fields.title}</a>
              </Link>
            </li>
          );
        })}
      </ul>
    </section>
  ) : null;
};

export default Categories;
