import RichText from 'components/elements/contentful/RichText/RichText';
import style from './BrandContent.module.scss';
import { Button } from '@mozaic-ds/react';

const BrandContent = (data) => {
  try {
    const { fields } = data.data.data.dataSource;
    return (
      <div className={style.BrandContent}>
        <div className={style.BrandContent__rich}>
          <RichText content={fields.capo} />
        </div>
        <div className={style.BrandContent__content}>
          <div className={style.BrandContent__content__text}>
            <RichText content={fields.mainTextWithHeadings} />
            <Button theme="primary" href={fields.url}>
              {fields.cta}
            </Button>
          </div>
          {fields.media && <img src={fields.media.fields.file.url} alt={fields.media.fields.title} />}
        </div>
      </div>
    );
  } catch (e) {
    console.log(e);
    return <></>;
  }
};

export default BrandContent;
