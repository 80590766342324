import Picto from '../../../../elements/Picto/Picto';
import { Select } from '@mozaic-ds/react';
import style from './WorkshopStorePlace.module.scss';

const WorkshopStorePlace = () => {
  return (
    <section className={style.WorkshopStorePlace}>
      <div className={style.WorkshopStorePlace__store}>
        <Picto className={style.WorkshopStorePlace__store__icon} icon="pointer" />
        <span className="font-bold">Villeneuve d&apos; ascq</span>
      </div>
      <div>
        <Select
          defaultValue="ruby"
          name="language"
          options={[
            {
              label: '1ere date',
              value: '15/06/2023',
            },
            {
              label: '2eme date',
              value: '18/06/2023',
            },
          ]}
          placeholder="Choisissez votre date"
          size="m"
        />
      </div>
    </section>
  );
};

export default WorkshopStorePlace;
