import style from './ProductMoreLink.module.scss';

const ProductMoreLink = () => {
  const goToTab = () => window.dispatchEvent(new CustomEvent('open-tab', { detail: { tab: 'infos' } }));

  return (
    <button className={style.ProductMoreLink} onClick={goToTab}>
      + de détails
    </button>
  );
};

export default ProductMoreLink;
