import React from 'react';
import style from './ImagesTargetWithText.module.scss';
import RichText from 'components/elements/contentful/RichText/RichText';
import Targets from 'components/elements/Targets/Targets';

const ImagesTargetWithText = ({ data }) => {
  try {
    const { title, subtitle, paragraphRight, paragraphLeft, imagesTarget, imagesTargetRightColumn } = data.data.dataSource.fields;
    return (
      <div className={style.ImagesTargetWithText}>
        <h2 className={style.ImagesTargetWithText__title}>{title}</h2>

        <p className={style.ImagesTargetWithText__subtitle}>{subtitle}</p>

        <div className={style.ImagesTargetWithText__bloc}>
          <div className={style.ImagesTargetWithText__bloc__first}>
            <div className={style.ImagesTargetWithText__bloc__first__description}>
              <RichText content={paragraphLeft} />
            </div>

            {imagesTarget.map((targ) => {
              const { image736x736px, targets, id } = targ.fields;

              return (
                <div key={id} className={style.ImagesTargetWithText__bloc__item}>
                  <img src={image736x736px.fields.file.url} alt="" />
                  <Targets targets={targets} />
                </div>
              );
            })}
          </div>
          <div className={style.ImagesTargetWithText__bloc__second}>
            <div className={style.ImagesTargetWithText__bloc__second__description}>
              <RichText content={paragraphRight} />
            </div>

            {imagesTargetRightColumn.map((targ) => {
              const { image736x736px, targets, id } = targ.fields;

              return (
                <div key={id} className={style.ImagesTargetWithText__bloc__item}>
                  <img src={image736x736px.fields.file.url} alt="" />
                  <Targets targets={targets} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  } catch (e) {
    console.error(e);
    return <></>;
  }
};

export default ImagesTargetWithText;
