import style from './BlocGiftcards.module.scss';
import { Button } from '@mozaic-ds/react';

const text = {
  title: 'Mes cartes cadeaux',
  subtitle: 'Vous n&apos; avez pas encore acheté de cartes cadeaux',
  url: 'zodio.fr',
  cta: 'achetez une carte cadeau',
};
const BlocGiftcards = (gifts) => {
  return (
    <div className={style.BlocGiftcards}>
      <div>
        <h2>{text.title}</h2>
        {gifts?.length ? <></> : <p className={style.BlocGiftcards__text}>{text.subtitle}</p>}
        <div className={style.BlocGiftcards__button}>
          <Button href={text.url}>{text.cta}</Button>
        </div>
      </div>
    </div>
  );
};

export default BlocGiftcards;
