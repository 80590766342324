import SeoBlock from 'components/generic/SeoBlock/SeoBlock';
import Video from 'components/generic/Video/Video';
import Recommendation from 'components/generic/Recommendation/Recommendation';
import ContentTiles from 'components/generic/ContentTiles/ContentTiles';
import Title from 'components/generic/Title/Title';
import BannerNotFull from 'components/generic/BannerNotFull/BannnerNotFull';
import ParagraphTwoImgs from 'components/generic/ParagraphTwoImgs/ParagraphTwoImgs';
import TextImage from 'components/generic/TextImage/TextImage';
import TextTwoParagraph from 'components/generic/TextTwoParagraph/TextTwoParagraph';
import ImagesTargetWithText from 'components/generic/ImagesTargetWithText/ImagesTargetWithText';
import VignettesText from 'components/generic/VignettesText/VignettesText';
import ParagraphFull from 'components/generic/ParagraphFull/ParagraphFull';
import { publishItems } from 'helpers/utils/publishItems';
const DynamicArticleTendance = ({ data }) => {
  try {
    console.log(data);
    const { contentReferences } = data?.data?.dataSource?.fields;

    return (
      <>
        {publishItems(contentReferences)?.map((content) => {
          const componentGenID = content?.sys?.contentType?.sys?.id;
          const componentData = { data: { dataSource: content } };

          switch (componentGenID) {
            case 'genTitleSubTitleRichText':
              return <Title key={componentGenID} data={componentData} />;

            case 'simpleBanner':
              return <BannerNotFull key={componentGenID} data={componentData} />;

            case 'targetBloc2Paragraph':
              return <ImagesTargetWithText key={componentGenID} data={componentData} />;

            case 'imgLrTitleRichText':
              return <TextImage key={componentGenID} data={componentData} />;

            case 'genContentRecoWithoutCta':
              return <ContentTiles key={componentGenID} data={componentData} />;

            case 'referenceContentInspiSteps':
              return <VignettesText key={componentGenID} data={componentData} />;

            case 'imgLrProductRichText':
              return <ParagraphFull key={componentGenID} data={componentData} />;

            case 'videoTitleSubtitle':
              return <Video key={componentGenID} data={componentData} />;

            case 'title2Paragraph':
              return <TextTwoParagraph key={componentGenID} data={componentData} />;

            case 'titleSubtitle2Img':
              return <ParagraphTwoImgs key={componentGenID} data={componentData} />;

            case 'genProductRecoWithoutCta':
              return <Recommendation key={componentGenID} data={componentData} />;

            case 'genSeoText':
              return <SeoBlock key={componentGenID} data={componentData} />;

            default:
              console.warn(`An error occured rendering "${componentGenID}". Please check DynamicContentfull component.`);
              return <></>;
          }
        })}
      </>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default DynamicArticleTendance;
