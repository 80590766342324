import style from './Title.module.scss';
import RichText from 'components/elements/contentful/RichText/RichText';
import Picto from '../../elements/Picto/Picto';
import { Button } from '@mozaic-ds/react';
import React from 'react';

const Title = ({ data }) => {
  try {
    const { title, subTitle, cta, publish, paragraph } = data.data.dataSource.fields;

    return (
      <section className={style.Title}>
        <h1>{title}</h1>

        {subTitle && <p className={style.Title__subtitle}>{subTitle}</p>}
        {publish && (
          <div className="font-bold flex items-center justify-center mb-[6rem]">
            <Picto icon="clock" className="mr-[0.8rem]" /> <span>{publish}</span>
          </div>
        )}
        {cta && (
          <div className="text-center mt-4 max-w-[32rem] mx-auto w-full">
            <Button width="full" theme="secondary">
              <Picto icon="paper-plane" className="mr-[4rem]" /> Partager
            </Button>
          </div>
        )}
        {paragraph && (
          <div className={style.Title__description}>
            <RichText content={paragraph} />
          </div>
        )}
      </section>
    );
  } catch (e) {
    console.error(e);
    return <></>;
  }
};

export default Title;
