import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, QuantitySelector } from '@mozaic-ds/react';
import style from './WorkshopATC.module.scss';

const onSubmit = (data) => {
  console.log(data);
};

const WorkshopATC = () => {
  const [quantity, setQuantity] = useState(1);
  const [atcFixed, setAtcFixed] = useState(false);
  const atc = useRef();
  const fixedAdd = atc?.current?.offsetTop || 0;
  useEffect(() => {
    window.addEventListener('scroll', () => fixedAdd > 0 && setAtcFixed(document.documentElement.scrollTop > fixedAdd));
    return () => window.removeEventListener('scroll', () => setAtcFixed(false));
  }, [fixedAdd]);
  const { register, handleSubmit, setValue } = useForm();
  return (
    <>
      <form ref={atc} onSubmit={handleSubmit(onSubmit)} data-fixed={atcFixed} className={style.WorkshopATC}>
        <QuantitySelector
          minValue={1}
          value={quantity}
          className={style.WorkshopATC__quantity}
          theme="primary"
          onChange={(value) => {
            setQuantity(value);
            setValue('qty', value);
          }}
        />
        <input type="hidden" {...register('qty')} />
        <input type="hidden" value="511515656161" {...register('sku')} />
        <input type="hidden" value="76590" {...register('atelier')} />
        <input type="hidden" value="76590" {...register('item')} />
        <Button type="submit" theme="primary" width="full">
          Ajouter au panier
        </Button>
      </form>
      <div className={style.WorkshopATC__ghost} style={{ height: atc.current?.offsetHeight || 50 }} />
    </>
  );
};

export default WorkshopATC;
