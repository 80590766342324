import style from './WorkshopTileFlag.module.scss';
import { Flag } from '@mozaic-ds/react';

const WorkshopTileFlag = (data) => {
  if (!data.flap) return <></>;

  return (
    <Flag variant="bordered" theme="primary-02" className={style.WorkshopTileFlag}>
      {data.flap}
    </Flag>
  );
};

export default WorkshopTileFlag;
