import style from './Items.module.scss';

import ItemTile from 'components/elements/ItemTile/ItemTile';
import WorkshopTile from 'components/elements/WorkshopTile/WorkshopTile';
import ContentTile from 'components/elements/ContentTile/ContentTile';

const Items = ({ hits, type }) => {
  return hits ? (
    <ol className={style.Items} data-type={type}>
      {hits?.map((item) => (
        <li key={item.objectID}>
          {['search', 'products'].includes(type) && <ItemTile data={item} />}
          {['workshops'].includes(type) && <WorkshopTile data={item} />}
          {['tutorials', 'recipes', 'tendances'].includes(type) && <ContentTile tile={item} />}
        </li>
      ))}
    </ol>
  ) : (
    <></>
  );
};

export default Items;
