import style from './ProductDelivery.module.scss';
import Picto from 'components/elements/Picto/Picto';
import SelectStore from 'components/elements/SelectStore/SelectStore';
import { Layer, LayerBody, LayerHeader, LayerTitle, useModals } from '@mozaic-ds/react';

const ProductDelivery = () => {
  const { open } = useModals();

  // MOCK
  const store = {
    name: "Villeneuve d'ascq",
    stock: 112,
  };
  const online = {
    stock: 564,
  };

  return (
    <>
      <ul className={style.ProductDelivery}>
        {/* ONLINE */}
        {online.stock > 0 ? (
          <li className={style.ProductDelivery__method}>
            <Picto icon="truck" className={style.ProductDelivery__method__picto} />

            <div>
              <span className={style.ProductDelivery__method__label}>
                Livraison sous 2 à 4 jours ouvrés <em>({online.stock} en stock)</em>
              </span>

              <button className={style.ProductDelivery__method__button} onClick={() => open('delivery-infos')}>
                Détails de la livraison
              </button>

              <Layer id="delivery-infos">
                <LayerHeader id="delivery-infos">
                  <LayerTitle>Informations livraison</LayerTitle>
                </LayerHeader>
                <LayerBody>
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit. Consequuntur, tenetur maiores natus fugit animi culpa ipsam deleniti
                  corporis quaerat molestiae obcaecati pariatur beatae libero reiciendis et nesciunt doloribus nihil praesentium deserunt inventore?
                  Tempora dignissimos perspiciatis neque qui dicta ex iusto perferendis, ipsam, vero sed quis nesciunt reprehenderit tenetur
                  doloremque similique ab, cupiditate nihil culpa velit soluta beatae? Placeat ipsam voluptatibus nam, laborum voluptatum cumque
                  cupiditate rerum eius, asperiores sunt quasi, facere delectus dolor neque aspernatur in ullam sed dolorum libero autem quibusdam
                  vitae labore commodi! Perspiciatis accusamus harum dignissimos optio. Ipsum praesentium blanditiis tempore doloremque, distinctio
                  exercitationem aspernatur quod ut odio itaque eos ducimus eum cupiditate sequi? Vero rerum accusamus ad deserunt cum, illo esse in
                  asperiores assumenda explicabo debitis corrupti rem sunt et. Officiis sint ipsum cumque! A accusantium quisquam ut magnam, fugiat
                  corporis sunt nam aliquid reiciendis tempore veniam, assumenda sint, atque architecto veritatis tempora numquam. Dicta eveniet hic
                  cumque in qui cum, dignissimos aliquid, cupiditate mollitia iure ex vero laborum harum accusantium et provident modi voluptatibus
                  blanditiis officiis nemo tempore voluptate. Impedit facilis, ullam eaque mollitia quae distinctio, enim iste officia ab tenetur,
                  atque ad laudantium ratione. Exercitationem voluptatum aperiam omnis modi ratione distinctio, nam molestias deleniti illum officiis
                  quaerat ipsam dolorum debitis quae magni rem ea! Autem, rem! Hic incidunt eum magnam. Nisi quia at sit sequi error laudantium velit
                  magni officia deserunt! Eveniet ipsam ut, dolore iusto quam suscipit? Assumenda possimus provident id non harum illum error, aut
                  optio quisquam consequuntur, nulla quasi accusamus dolor.
                </LayerBody>
              </Layer>
            </div>
          </li>
        ) : (
          <li className={style.ProductDelivery__method}>
            <span className={style.ProductDelivery__method__cross} />

            <div>
              <span className={style.ProductDelivery__method__label}>Stock momentanément indisponible en livraison</span>
            </div>
          </li>
        )}

        {/* INSTORE */}
        <li className={style.ProductDelivery__method}>
          {store.stock > 0 ? (
            <Picto icon="clock" className={style.ProductDelivery__method__picto} />
          ) : (
            <span className={style.ProductDelivery__method__cross} />
          )}

          <div>
            <span className={style.ProductDelivery__method__label}>
              {store.stock > 0 ? 'Disponible au retrait 2h gratuit au magasin de :' : 'Stock momentanément indisponible au magasin de :'}

              <span>{store.name}</span>
              {store.stock > 0 && <em>({store.stock} en stock)</em>}
            </span>

            <button className={style.ProductDelivery__method__button} onClick={() => open('change-store')}>
              Choisir un autre magasin
            </button>

            <Layer id="change-store">
              <LayerHeader id="change-store">
                <LayerTitle>Je cherche le magasin le plus proche de chez moi</LayerTitle>
              </LayerHeader>

              <LayerBody>
                <SelectStore />
              </LayerBody>
            </Layer>
          </div>
        </li>
      </ul>
    </>
  );
};

export default ProductDelivery;
