export const history = [
  { date: 'Mon, 1 Jun 2023 14:49:3 GMT', type: 'product', id: '10129984' },
  { date: 'Mon, 1 Jun 2023 14:49:3 GMT', type: 'product', id: '10115559' },
  { date: 'Mon, 1 Jun 2023 14:49:3 GMT', type: 'product', id: '10041500' },
  { date: 'Mon, 1 Jun 2023 14:49:3 GMT', type: 'product', id: '10102500' },
  { date: 'Mon, 1 Jun 2023 14:49:3 GMT', type: 'product', id: '10186651' },
  { date: 'Mon, 1 Jun 2023 14:49:3 GMT', type: 'product', id: '10172958' },
  { date: 'Mon, 1 Jun 2023 14:49:3 GMT', type: 'product', id: '10178028' },
  { date: 'Mon, 3 Jun 2023 14:49:3 GMT', type: 'product', id: '10059801' },
  { date: 'Mon, 3 Jun 2023 14:49:3 GMT', type: 'product', id: '10176647' },
  { date: 'Mon, 3 Jun 2023 14:49:3 GMT', type: 'product', id: '10110348' },
  { date: 'Mon, 1 Jun 2023 14:49:3 GMT', type: 'workshop', id: 'workshop-43624' },
  { date: 'Mon, 3 Jun 2023 14:49:3 GMT', type: 'product', id: '10178429' },
  { date: 'Mon, 3 Jun 2023 14:49:3 GMT', type: 'product', id: '10183921' },
  { date: 'Mon, 1 Jun 2023 14:49:3 GMT', type: 'workshop', id: 'workshop-9798' },
  { date: 'Mon, 3 Jun 2023 14:49:3 GMT', type: 'product', id: '10194291' },
  { date: 'Mon, 1 Jun 2023 14:49:3 GMT', type: 'workshop', id: 'workshop-43614' },
  { date: 'Mon, 1 Jun 2023 14:49:3 GMT', type: 'workshop', id: 'workshop-42870' },
  { date: 'Mon, 1 Jun 2023 14:49:3 GMT', type: 'workshop', id: 'workshop-42839' },
  { date: 'Mon, 3 Jun 2023 14:49:3 GMT', type: 'product', id: '10124652' },
  { date: 'Mon, 4 Jun 2023 14:49:3 GMT', type: 'workshop', id: 'workshop-42838' },
];
