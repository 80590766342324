import PLP from 'components/elements/PLP/PLP';
import { flattenString } from 'helpers/utils/string';
import { publishItems } from 'helpers/utils/publishItems';

import Categories from 'components/scopes/products/PLP/Categories/Categories';
import SeoBlock from 'components/generic/SeoBlock/SeoBlock';

// Cette page doit etre dynamysé en récupérant les infos de categorie de MAGENTO a partir du categoryID dans contentful
const defaultCategoryForTest = 'Cuisine';
// const defaultCategoryForTest = 'Poêle inox';

const ProductList = ({ contentReferences, category = defaultCategoryForTest }) => {
  return (
    <>
      <PLP
        type="products"
        title={category}
        index={process.env.NEXT_PUBLIC_ALGOLIA_INDEX_PRODUCT}
        config={{
          hitsPerPage: 24,
          filters: `NOT type_id: "workshop" AND visibility_catalog: 1 AND categories_without_path: "${category}"`,
          ruleContexts: [`ais-categories_without_path-${flattenString(category)}`],
        }}
      />

      {contentReferences &&
        publishItems(contentReferences).map((content) => {
          const componentID = content?.sys?.id;
          const componentGenID = content?.sys?.contentType?.sys?.id;
          const componentData = { data: { dataSource: content } };

          switch (componentGenID) {
            case 'genLinksContainer':
              return <Categories key={componentID} data={componentData} />;

            case 'genSeoText':
              return <SeoBlock key={componentID} data={componentData} />;

            default:
              console.warn(`An error occured rendering "${componentGenID}". Please check DynamicContentfull component.`);
              return <></>;
          }
        })}
    </>
  );
};

export default ProductList;
