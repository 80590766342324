import style from './ItemTileFlag.module.scss';
import { Flag } from '@mozaic-ds/react';

const ItemTileFlag = (data) => {
  if (!data.flap) return <></>;

  if (data.discount_rate) {
    return (
      <Flag variant="solid" theme="danger" className={style.ItemTileFlag}>
        - {data.flap}
      </Flag>
    );
  }
  return (
    <Flag variant="bordered" theme="primary-02" className={style.ItemTileFlag}>
      {data.flap}
    </Flag>
  );
};

export default ItemTileFlag;
