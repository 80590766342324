import { Button } from '@mozaic-ds/react';
import Picto from '../../../../../../elements/Picto/Picto';
import style from './WorkshopTabFormer.module.scss';

const WorkshopTabFormer = () => {
  return (
    <div className={style.WorkshopTabFormer}>
      <img className={style.WorkshopTabFormer__image} src="" alt="" />
      <p className={style.WorkshopTabFormer__title}>Emmy Delfosse</p>
      <span className={style.WorkshopTabFormer__descriptif}>Atelier Créatif</span>
      <div className={style.WorkshopTabFormer__actions}>
        <Button theme="secondary">Je Découvre ses réalisations</Button>
        <Button theme="secondary">
          <Picto icon="mail" /> <span>Je Découvre ses réalisations</span>
        </Button>
      </div>
    </div>
  );
};

export default WorkshopTabFormer;
