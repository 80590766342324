import style from './Faq.module.scss';
import { Fragment } from 'react';
import { Accordion, AccordionContent, AccordionHeader, AccordionHeading } from '@mozaic-ds/react';
import { publishItems } from 'helpers/utils/publishItems';
import RichText from 'components/elements/contentful/RichText/RichText';

const classNames = (...classes) => {
  return classes.join(' ');
};
const Faq = ({ data }) => {
  try {
    const { title, questions } = data.data.dataSource.fields;

    return (
      <article className={classNames(style.Faq, 'container')}>
        <h2 className={style.Faq__title}>FAQ</h2>

        <div className={style.Faq__questions}>
          <p className={style.Faq__questions__title}>{title}</p>
          {publishItems(questions).map((question) => (
            <Accordion size="s" key={question.sys.id}>
              <Fragment key={question.sys.id}>
                <AccordionHeader>
                  <AccordionHeading>{question.fields.title}</AccordionHeading>
                </AccordionHeader>

                <AccordionContent>
                  <RichText content={question.fields.paragraph} />
                </AccordionContent>
              </Fragment>
            </Accordion>
          ))}
        </div>
      </article>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default Faq;
