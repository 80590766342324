import style from './CartItems.module.scss';
import Price from 'components/elements/Price/Price';
import { QuantitySelector } from '@mozaic-ds/react';

import Item from './Item/Item';

const CartItems = ({ data }) => {
  const labels = ['Produit', 'Prix unitaire', 'Quantité', 'Prix total'];

  try {
    return (
      <table className={style.CartItems}>
        <caption className="sr-only">Articles du panier</caption>

        <thead className={style.CartItems__head}>
          <tr className={style.CartItems__head__line}>
            {labels.map((label) => (
              <th key={label} className={style.CartItems__head__cell}>
                {label}
              </th>
            ))}
          </tr>
        </thead>

        <tbody className={style.CartItems__body}>
          {[...Array(8)].map((_, i) => (
            <tr key={i} className={style.CartItems__body__line}>
              <td className={style.CartItems__body__cell}>
                <Item />
              </td>

              <td data-label={labels[1]} className={style.CartItems__body__cell}>
                <Price size="xs" data={{ default_formated: '22,09€' }} />
              </td>

              <td data-label={labels[2]} className={style.CartItems__body__cell}>
                <QuantitySelector size="s" minValue={1} theme="primary" value={1} />
              </td>

              <td data-label={labels[3]} className={style.CartItems__body__cell}>
                <Price size="xs" data={{ default_formated: '22,09€', default_original_formated: '33,99€' }} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default CartItems;
