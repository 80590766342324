import style from './Error404.module.scss';
import Picto from 'components/elements/Picto/Picto';
import { Button } from '@mozaic-ds/react';
import Link from 'next/link';

const Error404 = ({ data }) => {
  try {
    const { title, subtitle, buttonLabel, buttonHref } = data.contentful.dataSource.fields;

    return (
      <section className={style.Error404}>
        <h1 className={style.Error404__title}>{title}</h1>
        <h2 className={style.Error404__subtitle}>{subtitle}</h2>

        {buttonLabel && buttonHref && (
          <Link href={buttonHref}>
            <a className={style.Error404__button}>
              <Button theme="secondary" width="full">
                <Picto icon="arrow--down" className="mr-[1rem]" />
                {buttonLabel}
              </Button>
            </a>
          </Link>
        )}
      </section>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default Error404;
