import style from './OrderAddress.module.scss';
import { Button } from '@mozaic-ds/react';
import React from 'react';

const OrderAddress = () => {
  return (
    <div className={style.Orderaddress}>
      <div className={style.Orderaddress__delivery}>
        <p className={style.Orderaddress__delivery__title}>Adresse de livraison</p>
        <div>
          <p>M.Jeremy carlier</p>
          <p>CARREFOUR CITY</p>
          <p>81 ROUTE NATIONALE</p>
          <p>CHERENG, Nord, 59152</p>
          <p>France</p>
        </div>
      </div>
      <div className={style.Orderaddress__billing}>
        <p className={style.Orderaddress__billing__title}>Adresse de facturation</p>
        <div>
          <p>M.Jeremy carlier</p>
          <p>6 bis</p>
          <p>CHERENG, Nord, 59152</p>
          <p>France</p>
        </div>
      </div>
      <div>
        <Button theme="primary" size="full">
          Télécharger ma facture
        </Button>
      </div>
    </div>
  );
};

export default OrderAddress;
