import React from 'react';
import style from './LoyaltyAccount.module.scss';
import LoyaltyCard from 'components/elements/LoyaltyCard/LoyaltyCard';
import { Button, DateInput, Field } from '@mozaic-ds/react';
import { rules } from 'helpers/utils/formRules';
import { useForm } from 'react-hook-form';

const LoyaltyAccount = ({ ...props }) => {
  const { type, fidelity, id } = props;
  const form = React.useRef();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted },
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const onSubmit = (data) => {
    console.log('onSubmit', data);
  };

  return (
    <div className={style.LoyaltyAccount}>
      {fidelity ? (
        <LoyaltyCard userID={id} type={type}>
          <div className={style.LoyaltyAccount__loyalty}>
            <p className={style.LoyaltyAccount__loyalty__housky}>Vous avez</p>
            <p className={style.LoyaltyAccount__loyalty__points}>{fidelity}</p>
            <p className={style.LoyaltyAccount__loyalty__text}>Points</p>
            {type === 'programme' && (
              <div className={style.LoyaltyAccount__loyalty__programme}>
                <hr />
                <p>Mon identifiant</p>
                <p>{id}</p>
              </div>
            )}
            <div className="text-center mt-4 max-w-[32rem] mx-auto w-full">
              <Button width="full" href={'/mon-compte/mon-programme-fidelite'}>
                Je consulte mes récompenses
              </Button>
            </div>
          </div>
        </LoyaltyCard>
      ) : (
        <LoyaltyCard>
          <div>
            <h4>Oser pousser les portes</h4>

            <p className={style.LoyaltyAccount__housky}>de notre belle communauté</p>

            <p className={style.LoyaltyAccount__subtitle}>Entre nous, le programme de fidélité gratuit qui vous ressemble et qui nous rassemble</p>

            <form ref={form} onSubmit={handleSubmit(onSubmit)}>
              <Field htmlFor="birthdate" label={`Date de naissance${rules.birthdate.required ? '*' : ''}`} error={errors?.date?.message}>
                <div>
                  <DateInput id="birthdate" {...register('date', rules.birthdate)} isInvalid={errors.date} isValid={isSubmitted && !errors.date} />
                </div>
              </Field>
              <div className={style.LoyaltyAccount__button}>
                <Button size="full" type="submit" disabled={Object.keys(errors).length}>
                  Je m&apos;inscris au programme
                </Button>
              </div>
            </form>
          </div>
        </LoyaltyCard>
      )}
      {!id && (
        <ul>
          <li>
            <strong>Je collectionne des points</strong>
            <span>grâce à mes rencontres</span>
          </li>

          <li>
            <strong>Je retrouve mes points</strong>
            <span>dans mon compte client</span>
          </li>

          <li>
            <strong>J&apos;active mes récompenses</strong>
            <span>Lors de mon passage en caisse ou sur zodio.fr</span>
          </li>
        </ul>
      )}
    </div>
  );
};

export default LoyaltyAccount;
