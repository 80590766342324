const parts = [
  { transform: 'rotate(0 50 50)', begin: '-0.9333333333333333s' },
  { transform: 'rotate(24 50 50)', begin: '-0.8666666666666667s' },
  { transform: 'rotate(48 50 50)', begin: '-0.8s' },
  { transform: 'rotate(72 50 50)', begin: '-0.7333333333333333s' },
  { transform: 'rotate(96 50 50)', begin: '-0.6666666666666666s' },
  { transform: 'rotate(120 50 50)', begin: '-0.6s' },
  { transform: 'rotate(144 50 50)', begin: '-0.5333333333333333s' },
  { transform: 'rotate(168 50 50)', begin: '-0.4666666666666667s' },
  { transform: 'rotate(192 50 50)', begin: '-0.4s' },
  { transform: 'rotate(216 50 50)', begin: '-0.3333333333333333s' },
  { transform: 'rotate(240 50 50)', begin: '-0.26666666666666666s' },
  { transform: 'rotate(264 50 50)', begin: '-0.2s' },
  { transform: 'rotate(288 50 50)', begin: '-0.13333333333333333s' },
  { transform: 'rotate(312 50 50)', begin: '-0.06666666666666667s' },
  { transform: 'rotate(336 50 50)', begin: '0s' },
];

const Loader = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="1em" height="1em" style={{ margin: 'auto' }}>
      {parts.map((part) => {
        return (
          <g transform={part.transform} key={part.transform}>
            <rect x="48" y="23" rx="2" ry="4" width="4" height="14" fill="currentColor">
              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin={part.begin} repeatCount="indefinite"></animate>
            </rect>
          </g>
        );
      })}
    </svg>
  );
};

export default Loader;
