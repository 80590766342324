import React from 'react';
import Link from 'next/link';
import { publishItems } from 'helpers/utils/publishItems';

const FooterMenu = ({ links }) => {
  try {
    return (
      <ul className="footer__menus">
        {publishItems(links)?.map((link) => {
          const { url, title } = link.fields;
          return (
            <li className="footer__menu__item" key={`footer-menu-item--${title}`}>
              <Link href={url}>
                <a>{title}</a>
              </Link>
            </li>
          );
        })}
      </ul>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default FooterMenu;
