import style from './ProductPlus.module.scss';
import Picto from 'components/elements/Picto/Picto';

const ProductPlus = () => {
  return (
    <section className={style.ProductPlus}>
      <div className={style.ProductPlus__picto}>
        <Picto icon="more" />
      </div>

      <div className={style.ProductPlus__content}>
        <strong>Le plus produit :</strong> {'Made In France, buvant ultra fin, sonorité du cristal'}
      </div>
    </section>
  );
};

export default ProductPlus;
