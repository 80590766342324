import React from 'react';
import style from './WorkshopCards.module.scss';
import Picto from 'components/elements/Picto/Picto';
import { Button } from '@mozaic-ds/react';
import Link from 'next/link';
import { publishItems } from 'helpers/utils/publishItems';

const WorkshopCards = ({ data }) => {
  try {
    const { titre, subtitle, blocs } = data.data.dataSource.fields;

    return (
      <section className={style.WorkshopCards}>
        <h2 className={style.WorkshopCards__title}>{titre}</h2>
        <p className={style.WorkshopCards__subtitle}>{subtitle}</p>

        <div className={style.WorkshopCards__content}>
          {publishItems(blocs).map((item) => {
            const { titre, subtitle, image, cta, ctaUrl } = item.fields;

            return (
              <div key={item.sys.id} className={style.WorkshopCards__item}>
                <img className={style.WorkshopCards__item__image} src={image.fields.file.url} alt="" />
                <div className={style.WorkshopCards__item__content}>
                  <h3 className={style.WorkshopCards__item__content__title}>{titre}</h3>
                  <p className={style.WorkshopCards__item__content__text}>{subtitle}</p>

                  <div className={style.WorkshopCards__item__content__button}>
                    <Link href={ctaUrl}>
                      <Button href={ctaUrl} width="full">
                        {cta} <Picto icon="arrow--right" className="ml-[1rem]" />
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default WorkshopCards;
