import style from './CheckoutTemplate.module.scss';
import Sticky from 'react-stickynode';
import CheckoutSteps from '../CheckoutSteps/CheckoutSteps';
import CheckoutSummary from '../CheckoutSummary/CheckoutSummary';

const CheckoutTemplate = ({ step, children }) => {
  try {
    return (
      <section className={style.CheckoutTemplate}>
        <div className={style.CheckoutTemplate__content}>
          <CheckoutSteps step={step} />

          {children}
        </div>

        <aside className={style.CheckoutTemplate__summary}>
          <Sticky top={32} bottomBoundary="[class*='CheckoutTemplate__content']">
            <CheckoutSummary step={step} />
          </Sticky>
        </aside>
      </section>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default CheckoutTemplate;
