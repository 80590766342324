/**
 * Modify results with user custom rules
 * @param {Object} results Algolia response
 * @param {Object} userData Custom rules by context
 * @returns {Object} Modified results
 */
export function applyUserData(results, userData) {
  userData.forEach((data) => {
    switch (data.type) {
      case 'PHOTOS_RANKING':
        results = photoRanking(results, data.photos);
        break;
      case 'BANNER':
        results = renderBanner(results, data);
        break;
    }
  });

  return results;
}

// Add photo ranking to each hit
function photoRanking(results, ranking) {
  results?.hits.map((hit) => {
    hit.photoRanking = ranking;
  });

  return results;
}

// Add Banner to results
function renderBanner(results, data) {
  results.banner = data;
  return results;
}
