import style from './Offer.module.scss';
import Picto from 'components/elements/Picto/Picto';
import { Button } from '@mozaic-ds/react';

const Offer = () => {
  return (
    <section className={style.Offer}>
      <div className={style.Offer__column}>
        <div className={style.Offer__item}>
          <h3 className={style.Offer__item__title}>Pour qui ?</h3>
          <p className={style.Offer__item__content}>
            Vous êtes une entreprise, un CE, une association ou une collectivité. Faites plaisir à vos collaborateurs en leur offrant une e-carte
            cadeau (1)
          </p>
        </div>

        <div className={style.Offer__item}>
          <h3 className={style.Offer__item__title}>Une e-carte personnalisable et valable 12 mois</h3>
          <p className={style.Offer__item__content}>
            Choisissez la photo et le montant que vous souhaitez. La e-carte est valable en ligne et en magasin, sur l&apos;ensemble de nos produits
            et ateliers.
          </p>
        </div>

        <div className={style.Offer__item}>
          <h3 className={style.Offer__item__title}>Une remise de 5% et un paiement facilité</h3>
          <p className={style.Offer__item__content}>
            Dès 500€ de cartes commandées, bénéficiez de 5% de remise. Profitez également d&apos;exonérations de charges URSSAF. Plus
            d&apos;informations sur le site de l&apos;URSSAF. Réglement possible par carte et dès 500€ d&apos;achat par virement ou mandat
            administratif pour les collectivités
          </p>
        </div>
      </div>

      <div className={style.Offer__column}>
        <div className={style.Offer__item}>
          <h3 className={style.Offer__item__title}>Des équipes à votre disposition</h3>
          <div className={style.Offer__item__buttons}>
            <Button width="full" theme="accent" href="https://service-client.zodio.fr/hc/fr/requests/new" target="_blank" rel="noopener, noreferrer">
              <Picto icon="chat" className="mr-[1.6rem] text-[1.25em]" />
              Je contacte le service client
            </Button>
            <Button width="full" theme="accent" href="https://service-client.zodio.fr/hc/fr/requests/new" target="_blank" rel="noopener, noreferrer">
              <Picto icon="estimation" className="mr-[1.6rem] text-[1.25em]" />
              Je demande un devis
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Offer;
