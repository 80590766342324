import style from './Contributions.module.scss';
import Navigation from '../partials/Navigation/Navigation';
import React from 'react';
import BreadcrumbAccount from '../partials/BreadcrumbAccount/BreadcrumbAccount';
import Contact from '../../../generic/Contact/Contact';

const Contributions = ({ data }) => {
  return (
    <>
      <BreadcrumbAccount name="Contributions" />
      <div className={style.contributions}>
        <div>
          <Navigation />
        </div>
        <div>
          <div className={style.contributions__title}>
            <h2>Mes contributions</h2>
          </div>
          <p>Vous n avez pas de contributions</p>
          <div className="mt-14">
            <Contact data={data} type="account" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Contributions;
