import React from 'react';
import style from './AdressList.module.scss';
import { Button, Select, Modal, ModalHeader, ModalTitle, ModalBody, useModals } from '@mozaic-ds/react';
import CheckoutAdressForm from 'components/scopes/checkout/partials/CheckoutAdressForm/CheckoutAdressForm';

const AdressList = () => {
  const { open } = useModals();

  return (
    <div className={style.AdressList}>
      <h2 className={style.AdressList__title}>Adresse de livraison</h2>

      <Select
        onChange={console.log}
        defaultValue={165161}
        options={[
          { label: 'M Phillipe Martin, 57 avenue de Flandres, 59290 Wasquehal', value: 165161 },
          { label: 'Mme Micheline Dupond, 1 rue de Paris, 59000 Lille', value: 651698 },
        ]}
        size="s"
      />

      <Button theme="accent" onClick={() => open('add-delivery-adress')}>
        Nouvelle adresse
      </Button>

      <Modal id="add-delivery-adress">
        <ModalHeader id="add-delivery-adress">
          <ModalTitle>Ajouter une nouvelle adresse</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <CheckoutAdressForm />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AdressList;
