import { Button, Toggle } from '@mozaic-ds/react';
import React, { useRef } from 'react';
import { useForm } from 'react-hook-form';
import Picto from 'components/elements/Picto/Picto';

const Communication = () => {
  const form = useRef();
  const { register, handleSubmit } = useForm();

  const onSubmit = (data) => {
    console.log('onSubmit', data);
  };
  return (
    <>
      <h2>Préférences de communication</h2>
      <form ref={form} onSubmit={handleSubmit(onSubmit)}>
        <Toggle className="mb-10" size="m" {...register('newsletter')}>
          S&apos;inscrire a la newsletter
        </Toggle>
        <Toggle size="m" {...register('sms')}>
          Recevoir des sms
        </Toggle>
        <div className="text-center mt-4 max-w-[32rem] mx-auto w-full">
          <Button width="full" type="submit">
            <Picto icon="pen" className="mr-[4rem]" /> Modifier mes préférences
          </Button>
        </div>
      </form>
    </>
  );
};

export default Communication;
