import { closeDrawers } from 'helpers/utils/menu';
import HeaderMenuChild from '../HeaderMenuChild/HeaderMenuChild';

const HeaderMenuDrawer = ({ items }) => {
  return items ? (
    <>
      <ul className="header__menu__drawer" data-visible="false">
        {items.map((item) => item && <HeaderMenuChild key={item.id} data={item} />)}
      </ul>

      <button className="header__menu__drawer__overlay" onClick={closeDrawers} aria-label="fermer le sous menu"></button>
    </>
  ) : null;
};

export default HeaderMenuDrawer;
