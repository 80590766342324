import React from 'react';
import style from './CheckoutLoyalty.module.scss';
import image from './entre-nous.webp';
import { Button, DateInput, Field } from '@mozaic-ds/react';
import { rules } from 'helpers/utils/formRules';
import { useForm } from 'react-hook-form';

const CheckoutLoyalty = () => {
  const user = null;

  const {
    register,
    handleSubmit,
    formState: { touchedFields, errors },
  } = useForm({ mode: 'onTouched' });

  const onSubmit = (data) => {
    console.log(data);
  };

  try {
    return (
      <section className={style.CheckoutLoyalty}>
        {user ? (
          <div className={style.CheckoutLoyalty__details}>
            <div className={style.CheckoutLoyalty__details__header}>
              <h2>
                Mon programme de fidélité <span>&quot;Entre nous&quot;</span>
              </h2>

              <p>
                Vous avez <span>50 points</span>
              </p>
            </div>

            <p>Mes récompenses disponibles</p>
          </div>
        ) : (
          <div className={style.CheckoutLoyalty__form}>
            <img className={style.CheckoutLoyalty__form__logo} src={image.src} alt="Entre nous, le programme fidélité de Zôdio" />

            <div className={style.CheckoutLoyalty__form__details}>
              <h2>Avec cet achat vous auriez gagnez des points</h2>
              <p>Renseignez simplement votre date de naissance pour bénéficier des avantages fidélité</p>

              <form onSubmit={handleSubmit(onSubmit)}>
                <Field htmlFor="birthdate" label="Date de naissance*" className="w-[100%] mt-9" error={errors?.birthdate?.message}>
                  <DateInput
                    size="m"
                    id="birthdate"
                    {...register('birthdate', rules.birthdate)}
                    isValid={touchedFields.birthdate && !errors.birthdate}
                    isInvalid={errors.birthdate}
                  />
                </Field>

                <Button className="mt-[1.6rem]" theme="primary" type="submit" width="full">
                  Je inscris au programme
                </Button>
              </form>
            </div>
          </div>
        )}
      </section>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default CheckoutLoyalty;
