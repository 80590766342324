import style from './ItemTileImages.module.scss';

const ItemTileImages = (data) => {
  const { image_url, photoRanking, photos } = data;

  const getUrl = (image) => {
    return image.split('?')[0] + '?width=250&height=250&quality=60&fit=crop&enable=upscale';
  };

  const onError = (image) => {
    image.target.src = 'https://placehold.co/250x250/fff/333?text=Image+manquante';
  };

  let photosArray = photos ? Object.entries(typeof photos === 'string' ? JSON.parse(photos) : photos) : [];

  if (photos && photoRanking) {
    photosArray.sort((a, b) => photoRanking.indexOf(a[0]) - photoRanking.indexOf(b[0]));
  }

  const [mainPhoto, altPhoto] = photosArray;

  return (
    <div className={style.ItemTileImages}>
      {mainPhoto && <img src={getUrl(mainPhoto[1])} alt="" loading="lazy" onError={onError} />}
      {altPhoto && <img src={getUrl(altPhoto[1])} alt="" loading="lazy" onError={onError} />}
      {!mainPhoto && !altPhoto && image_url && <img src={getUrl(image_url)} alt="" loading="lazy" onError={onError} />}
    </div>
  );
};

export default ItemTileImages;
