import style from './ParagraphFull.module.scss';
import RichText from 'components/elements/contentful/RichText/RichText';

const classNames = (...classes) => {
  return classes.join(' ');
};
const ParagraphFull = ({ data }) => {
  try {
    const { imagePosition, products, titleParagraph, mainImage960x960px } = data.data.dataSource.fields;
    return (
      <div className={classNames(style.ParagraphFull, imagePosition === true && style.ParagraphFull__left)}>
        <div className={style.ParagraphFull__content}>
          <div className={style.ParagraphFull__content__images}>
            {products.map((product) => (
              <img key={product.sys.id} src={product.fields.image120x120px.fields.file.url} alt={product.fields.image120x120px.fields.title} />
            ))}
          </div>
          <div className={style.ParagraphFull__content__text}>
            <RichText content={titleParagraph} />
          </div>
        </div>
        <div className={style.ParagraphFull__image}>
          <img src={mainImage960x960px.fields.file.url} alt={mainImage960x960px.fields.title} />
        </div>
      </div>
    );
  } catch (e) {
    console.log(e);
    return <></>;
  }
};

export default ParagraphFull;
