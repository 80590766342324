import common from './common';
import master from './master';
import PLP from './PLP';
import landing from './landing';
import PDP from './PDP';

export default {
  ...common,
  ...master,
  ...landing,
  ...PLP,
  ...PDP,
};
