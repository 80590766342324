import React from 'react';
import style from './ProductATC.module.scss';
import { QuantitySelector, Button } from '@mozaic-ds/react';
import { useForm } from 'react-hook-form';
import { debounce } from 'lodash';

const ProductATC = () => {
  const { setValue, register, handleSubmit } = useForm();
  const [quantity, setQuantity] = React.useState(1);

  const atc = React.useRef();
  const [atcIsFixed, setAtcFixed] = React.useState(false);

  React.useEffect(() => {
    window.addEventListener(
      'scroll',
      debounce(() => {
        const atcOffsetTop = atc.current?.offsetTop || 0;
        atcOffsetTop > 0 && setAtcFixed(document.documentElement.scrollTop > atcOffsetTop + 250);
      }, 50),
    );
    return window.removeEventListener('scroll', () => setAtcFixed(false));
  }, []);

  function onSubmit(data) {
    console.log(data);
  }

  return (
    <>
      <form className={style.ProductATC} ref={atc} data-fixed={atcIsFixed} onSubmit={handleSubmit(onSubmit)}>
        <div className={style.ProductATC__quantity}>
          <QuantitySelector
            minValue={1}
            theme="primary"
            value={quantity}
            onChange={(value) => {
              setQuantity(value);
              setValue('qty', value);
            }}
          />
        </div>

        <input type="hidden" {...register('qty')} />
        <input type="hidden" value="511515656161" {...register('sku')} />
        <input type="hidden" value="76590" {...register('product')} />
        <input type="hidden" value="" {...register('selected_configurable_option')} />
        <input type="hidden" value="" {...register('related_product')} />
        <input type="hidden" value="76590" {...register('item')} />
        <input type="hidden" value="LRApxnrob6ybaffc" {...register('form_key')} />

        <div className={style.ProductATC__button}>
          <Button theme="primary" width="full">
            Ajouter au panier
          </Button>
        </div>
      </form>

      <div className={style.ProductATC__ghost} style={{ height: atc.current?.offsetHeight || 50 }} />
    </>
  );
};

export default ProductATC;
