import LoyaltyAccount from '../partials/LoyaltyAccount/LoyaltyAccount';
import Navigation from '../partials/Navigation/Navigation';
import style from './Fidelity.module.scss';
import React, { useContext, useEffect } from 'react';
import BreadcrumbAccount from '../partials/BreadcrumbAccount/BreadcrumbAccount';
import { Button } from '@mozaic-ds/react';
import Reward from 'components/elements/Reward/Reward';
import mocks from '../account-mock';
import Contact from '../../../generic/Contact/Contact';
import { UserContext } from '../../../../frontastic';
import { useRouter } from 'next/router';

const Fidelity = ({ data }) => {
  const [fidelity, setFidelity] = React.useState(false);
  const { rewards, management_point_of_sale, customer } = mocks.extension_attributes.loyalty_card;
  console.log(rewards);
  const router = useRouter();

  React.useEffect(() => {
    if (management_point_of_sale && customer) setFidelity(true);
  }, []);

  const { user } = useContext(UserContext);

  useEffect(() => {
    if (!user) router.push('/connexion');
  }, []);

  if (!user) return <></>;

  const cardDiscovery = [
    {
      title: 'Je decouvre',
      housky: 'le programme',
      text: 'Découvrez tous les avantages du programme entre nous',
      textCta: 'Je découvre',
    },
    {
      title: 'Je me fais',
      housky: 'plaisir',
      text: 'Des centaines de produits disponibles en ligne ou en magasin',
      textCta: 'Je passe une commande',
    },
  ];
  return (
    <>
      <BreadcrumbAccount name="Mon programme de fidélité" />
      <div className={style['Fidelity']}>
        <div>
          <Navigation />
        </div>
        <div>
          <h2>Mon programme de fidélité</h2>
          {fidelity ? (
            <>
              <div className={style.Fidelity__bloc}>
                <LoyaltyAccount fidelity={management_point_of_sale} id={customer} type="programme" />
                {cardDiscovery.map((card, index) => {
                  const { title, housky, text, textCta } = card;
                  return (
                    <div key={index} className={style.Fidelity__bloc__item}>
                      <h3>{title}</h3>
                      <p>{housky}</p>
                      <span className={style.Fidelity__bloc__item__subtitle}>{text}</span>
                      <div>
                        <Button size="full" theme="primary">
                          {textCta}
                        </Button>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className={style.Fidelity__title}>
                <h3>
                  Recompenses pour soi ou à partager, gourmandes ou créatives
                  <p>C est vous qui choisissez !</p>
                </h3>
                <p>
                  Quand vous en avez envie, <strong>transformez vos points en récompenses</strong> qui vous ressemblent. Une fois votre choix fait
                  parmi nos nombreuses récompenses, les points correspondants seront alors déduits de vos points collectés.{' '}
                  <strong>Votre récompense sera visible dans votre panier</strong> ou{' '}
                  <strong>disponible lors de votre prochain passage en caisse</strong> en magasin.
                </p>
              </div>
              <div className={style.Fidelity__recompense}>
                {rewards.map((card, index) => {
                  return <Reward key={index} {...card} />;
                })}
              </div>
              <div className="flex justify-center mt-9">
                <Button size="full" theme="primary" className="max-w-[30rem]">
                  Je passe commande
                </Button>
              </div>
            </>
          ) : (
            <LoyaltyAccount />
          )}
          <div className="mt-14">
            <Contact data={data} type="account" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Fidelity;
