import React from 'react';
import style from './RangeSliderFilter.module.scss';
import connectRange from 'instantsearch.js/es/connectors/range/connectRange';
import { useConnector } from 'react-instantsearch-hooks-web';
import InputRange from 'react-input-range';

const RangeSliderFilter = ({ attribute }) => {
  const { start, range, refine } = useConnector(connectRange, { attribute });
  const [from, to] = start;

  return (
    <InputRange
      minValue={range.min || 0}
      maxValue={range.max || 9999}
      value={{
        min: Number.isFinite(from) ? from : range.min,
        max: Number.isFinite(to) ? to : range.max,
      }}
      formatLabel={(value) => `${value.toFixed(0)},00 €`}
      onChange={(value) => refine([value.min, value.max])}
      classNames={{
        inputRange: style.RangeSliderFilter,
        labelContainer: style.RangeSliderFilter__labelContainer,
        minLabel: style.RangeSliderFilter__minLabel,
        maxLabel: style.RangeSliderFilter__maxLabel,
        slider: style.RangeSliderFilter__slider,
        track: style.RangeSliderFilter__track,
        valueLabel: style.RangeSliderFilter__valueLabel,
      }}
    />
  );
};

export default RangeSliderFilter;
