import style from './Mentions.module.scss';
import RichText from 'components/elements/contentful/RichText/RichText';

const Mentions = ({ data }) => {
  try {
    const { fields } = data.data.dataSource;
    return (
      <div className={style.mentions}>
        <h2>{fields.title}</h2>
        <RichText content={fields.paragraph} />
      </div>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default Mentions;
