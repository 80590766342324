'use strict';

function handleEvent(event, container, focusableElements) {
  if (!container.dataset.hasFocusLoop || event.key !== 'Tab') return;
  event.preventDefault();

  const index = focusableElements.indexOf(document.activeElement);

  if (event.shiftKey) {
    if (document.activeElement === focusableElements[0]) {
      focusableElements[focusableElements.length - 1]?.focus();
    } else {
      focusableElements[index - 1]?.focus();
    }
  } else {
    if (document.activeElement === focusableElements[focusableElements.length - 1]) {
      focusableElements[0]?.focus();
    } else {
      focusableElements[index + 1]?.focus();
    }
  }
}

/**
 *
 * @param {string|Node} container element in wich we focus loop
 * @param {string[]} selectors css selectors of item to focus on
 */
export function createFocusLoop(container, selectors) {
  if (typeof container === 'string') container = document.querySelector(container);

  if (container.dataset.hasFocusLoop) return;

  const focusableElements = [];
  selectors.forEach((selector) => {
    container.querySelectorAll(selector).forEach((element) => focusableElements.push(element));
  });

  focusableElements[0]?.focus();

  function onKeydown(event) {
    handleEvent(event, container, focusableElements);
  }

  container.setAttribute('data-has-focus-loop', true);
  container.addEventListener('keydown', onKeydown, false);

  container.destroyLoop = function () {
    container.removeAttribute('data-has-focus-loop', true);
    container.removeEventListener('keydown', onKeydown, false);
  };
}

/**
 *
 * @param {string|Node} container element in wich we break focus loop
 */
export function destroyFocusLoop(container) {
  if (typeof container === 'string') container = document.querySelector(container);

  if (typeof container.destroyLoop === 'function') container?.destroyLoop();
}
