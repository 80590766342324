import style from './Retour.module.scss';
import Encart from '../Encart/Encart';
import { publishItems } from 'helpers/utils/publishItems';

const Retour = ({ data }) => {
  try {
    const { fields } = data.data.dataSource;
    return (
      <div className={style.retour}>
        <h2>{fields.title}</h2>
        <div className={style.retour__items}>
          {publishItems(fields.content).map((encart) => (
            <Encart paragraph={encart.fields.paragraph} key={encart.sys.id} type="retour" />
          ))}
        </div>
      </div>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default Retour;
