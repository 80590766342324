import Navigation from '../partials/Navigation/Navigation';
import style from './Orders.module.scss';
import { Field, Select } from '@mozaic-ds/react';
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import BreadcrumbAccount from '../partials/BreadcrumbAccount/BreadcrumbAccount';
import Order from './Order/Order';
import { useRouter } from 'next/router';
import { UserContext } from '../../../../frontastic';

const Orders = ({ data }) => {
  const { register } = useForm();

  const router = useRouter();

  const { user } = useContext(UserContext);

  useEffect(() => {
    if (!user) router.push('/connexion');
  }, []);

  if (!user) return <></>;

  const status = [
    {
      label: 'Commande validée',
      state: true,
    },
    {
      label: 'commande en cours de préparation',
      state: true,
    },
    {
      label: 'commande préparé ',
      state: true,
    },
    {
      label: 'commande expédié ',
      state: false,
    },
    {
      label: 'commande livré ',
      state: false,
    },
  ];
  const orders = [
    {
      date: '2005-02-04',
      id: 1007689896,
      price: '13.90€',
      flag: 'annule',
    },
    {
      date: '2005-02-17',
      id: 1007689870,
      price: '24.90€',
      flag: 'en cours',
    },
  ];

  const stepsTrue = status.filter((item) => item.state === true).length;

  const valueProgress = (100 / status.length) * stepsTrue;

  return (
    <>
      <BreadcrumbAccount name="Mes commandes" />
      <div className={style.orders}>
        <div>
          <Navigation />
        </div>
        <div>
          <div className={style.orders__title}>
            <h2>Mes commandes</h2>
            <Field>
              <Select
                {...register('orders')}
                options={[
                  {
                    label: 'Janvier 2023',
                    value: 'lille',
                  },
                  {
                    label: 'Février 2023',
                    value: 'paris',
                  },
                ]}
                size="m"
                placeholder="Tous"
              />
            </Field>
          </div>
          {/* <p>Mince vous n avez pas encore passé de commande</p>*/}
          <ul className={style.orders__list}>
            {orders.map((order) => (
              <li key={order.id} className={style.orders__list__item}>
                <Order id={order.id} status={status} val={valueProgress} price={order.price} etat={order.flag} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Orders;
