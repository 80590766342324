import Banner from './partials/Banner/Banner';
import Text from './partials/Text/Text';
import Details from './partials/Details/Details';
import Steps from './partials/Steps/Steps';
import Categories from './partials/Categories/Categories';
import Associations from './partials/Associations/Associations';
import RSE from './partials/RSE/RSE';
import Legend from './partials/Legend/Legend';

import mock from './SecondLife.mock';

const SecondLife = () => {
  return (
    <>
      <Banner data={mock.data.dataSource.fields.contentReferences[0]} />
      <Text data={mock.data.dataSource.fields.contentReferences[1]} />
      <Details data={mock.data.dataSource.fields.contentReferences[2]} />
      <Steps data={mock.data.dataSource.fields.contentReferences[3]} />
      <Categories data={mock.data.dataSource.fields.contentReferences[4]} />
      <Associations data={mock.data.dataSource.fields.contentReferences[5]} />
      <RSE data={mock.data.dataSource.fields.contentReferences[6]} />
      <Legend data={mock.data.dataSource.fields.contentReferences[7]} />
    </>
  );
};

export default SecondLife;
