import { Fragment } from 'react';
import { Accordion, AccordionContent, AccordionHeader, AccordionHeading } from '@mozaic-ds/react';

import RichText from 'components/elements/contentful/RichText/RichText';
import style from './FaqSommesNous.module.scss';
import { publishItems } from 'helpers/utils/publishItems';

const classNames = (...classes) => {
  return classes.join(' ');
};
const FaqSommesNous = ({ data }) => {
  try {
    const { title, questions } = data.data.dataSource.fields;
    return (
      <article className={classNames(style.Faq)}>
        <div data-anchor-id="nos-valeurs" data-anchor-label="Nos valeurs" />

        <div className={style.Faq__questions}>
          <p className={style.Faq__questions__title}>{title}</p>
          {publishItems(questions).map((question) => (
            <Accordion size="s" key={question.sys.id}>
              <Fragment key={question.sys.id}>
                <AccordionHeader>
                  <AccordionHeading>{question.fields.title}</AccordionHeading>
                </AccordionHeader>

                <AccordionContent>
                  <RichText content={question.fields.paragraph} />
                </AccordionContent>
              </Fragment>
            </Accordion>
          ))}
        </div>
      </article>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default FaqSommesNous;
