import React from 'react';
import style from './StandardPayment.module.scss';
import Picto from 'components/elements/Picto/Picto';

import CB from './images/CB.png';
import Visa from './images/Visa.png';
import MasterCard from './images/MasterCard.png';
import PayPal from './images/PayPal.png';

const StandardPayment = () => {
  const [activeMethod, setActiveMethod] = React.useState('cb');

  return (
    <div className={style.StandardPayment}>
      <h2 className={style.StandardPayment__title}>Mode de paiement</h2>

      <ul className={style.StandardPayment__methods}>
        <li className={style.StandardPayment__methods__item}>
          <label className={style.StandardPayment__methods__item__inner} aria-label={`Choisir le mode de paiement par carte bancaire`}>
            <input
              className={style.StandardPayment__methods__item__checkbox}
              type="checkbox"
              checked={activeMethod === 'cb'}
              onChange={() => setActiveMethod('cb')}
            />

            <h2 className={style.StandardPayment__methods__item__title}>
              <Picto icon="credit-card" />
              Carte bancaire
            </h2>

            <div className={style.StandardPayment__methods__item__logos}>
              <img src={CB.src} alt="" />
              <img src={Visa.src} alt="" />
              <img src={MasterCard.src} alt="" />
            </div>
          </label>
        </li>

        <li className={style.StandardPayment__methods__item}>
          <label
            className={style.StandardPayment__methods__item__inner}
            aria-label={`Choisir le mode de paiement par virement bancaire ou mandat de gestion`}
          >
            <input
              className={style.StandardPayment__methods__item__checkbox}
              type="checkbox"
              checked={activeMethod === 'transfer'}
              onChange={() => setActiveMethod('transfer')}
            />

            <h2 className={style.StandardPayment__methods__item__title}>
              <Picto icon="transfer" />
              Virement bancaire et mandat de gestion
            </h2>
          </label>
        </li>

        <li className={style.StandardPayment__methods__item}>
          <label className={style.StandardPayment__methods__item__inner} aria-label={`Choisir le mode de paiement par PayPal`}>
            <input
              className={style.StandardPayment__methods__item__checkbox}
              type="checkbox"
              checked={activeMethod === 'paypal'}
              onChange={() => setActiveMethod('paypal')}
            />

            <h2 className={style.StandardPayment__methods__item__title}>
              <Picto icon="paypal" />
              PayPal
            </h2>

            <div className={style.StandardPayment__methods__item__logos}>
              <img src={PayPal.src} alt="" />
            </div>
          </label>
        </li>
      </ul>
    </div>
  );
};

export default StandardPayment;
