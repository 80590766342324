import Head from 'next/head';

const Meta = ({ data }) => {
  try {
    const { dataSource } = data.data;
    const {
      title,
      robots,
      metaTitle,
      metatitle,
      metaDescription = `Achat en ligne ➤ ${title} sur Zodio.fr. Produits et ateliers de cuisine, décoration, création ✚ 15 000 produits  ✅ Livraison gratuite >35€  ✅ Retrait 2h.`,
    } = dataSource.fields;

    return (
      <>
        <Head>
          <>
            <title>{metaTitle || title || metatitle} | Zôdio</title>
            <meta name="title" content={metaTitle || title + ' | Zôdio'}></meta>
          </>

          <meta name="description" content={metaDescription}></meta>

          {robots && <meta name="robots" content={window.location.host === 'zodio.fr' ? robots.toLowerCase() : 'noindex, nofollow'}></meta>}
        </Head>
      </>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default Meta;
