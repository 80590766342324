import React from 'react';
import style from './GiftcardForm.module.scss';
import Picto from 'components/elements/Picto/Picto';
import Subtitle from 'components/elements/Subtitle/Subtitle';
import Sticky from 'react-stickynode';
import { Button } from '@mozaic-ds/react';

import Offer from './partials/Offer/Offer';
import Form from './partials/Form/Form';
import Summary from './partials/Summary/Summary';

const GiftcardForm = () => {
  const section = React.useRef();
  const [scope, setScope] = React.useState('private');
  const [formData, setFormData] = React.useState({});

  return (
    <section ref={section} className={style.GiftcardForm}>
      <div className={style.GiftcardForm__buttons}>
        <Button theme={scope === 'private' ? 'primary' : 'secondary'} width="full" onClick={() => setScope('private')}>
          <Picto icon={scope === 'private' ? 'checkbox--on' : 'checkbox--off'} className="mr-[1.6rem] text-[1.5em]" /> Particulier
        </Button>

        <Button theme={scope === 'pro' ? 'primary' : 'secondary'} width="full" onClick={() => setScope('pro')}>
          <Picto icon={scope === 'pro' ? 'checkbox--on' : 'checkbox--off'} className="mr-[1.6rem] text-[1.5em]" /> Professionnel
        </Button>
      </div>

      {scope === 'pro' && (
        <div className={style.GiftcardForm__offer}>
          <Subtitle title="Je découvre l'offre" />
          <Offer />
          <Subtitle title="Je personnalise ma carte" />
        </div>
      )}

      <div className={style.GiftcardForm__fields}>
        <Form onFormChange={setFormData} scope={scope} />
      </div>

      <div className={style.GiftcardForm__summary}>
        <Sticky top={32} bottomBoundary="[class*='GiftcardForm__fields']">
          <Summary data={formData} />
        </Sticky>
      </div>
    </section>
  );
};

export default GiftcardForm;
