import style from './WorkshopLoyalty.module.scss';
import Subtitle from 'components/elements/Subtitle/Subtitle';
import LoyaltyProgramCard from 'components/elements/LoyaltyProgramCard/LoyaltyProgramCard';
import { publishItems } from 'helpers/utils/publishItems';

const WorkshopLoyalty = ({ data }) => {
  try {
    const { title, subtitle, content } = data.data.dataSource.fields;

    return (
      <section className={style.WorkshopLoyalty}>
        <div className={style.WorkshopLoyalty__content}>
          <Subtitle title={title} />

          <p className={style.WorkshopLoyalty__content__text}>{subtitle}</p>

          <div className={style.WorkshopLoyalty__content__cards}>
            {publishItems(content).map((item) => (
              <LoyaltyProgramCard key={item.sys.id} data={item} />
            ))}
          </div>
        </div>
      </section>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default WorkshopLoyalty;
