import React from 'react';
import { closeLayer } from 'helpers/utils/menu';
import Picto from 'components/elements/Picto/Picto';
import HeaderMenuChild from '../HeaderMenuChild/HeaderMenuChild';

const HeaderMenuLayer = ({ items }) => {
  const layer = React.useRef();

  try {
    return (
      <div className="header__menu__layer" data-visible="false" ref={layer}>
        <div className="header__menu__layer__close">
          <button className="header__menu__layer__close__button" onClick={() => closeLayer(layer.current)} tabIndex="-1">
            <span className="header__menu__layer__close__button__icon">
              <Picto icon="arrow--left" />
            </span>
            Retour
          </button>
        </div>

        <ul className="header__menu__layer__items">
          {items?.map((item) => (
            <HeaderMenuChild key={item.id} data={item} />
          ))}
        </ul>
      </div>
    );
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default HeaderMenuLayer;
