import style from './Video.module.scss';
import SimpleTitle from '../SimpleTitle/SimpleTitle';

const Video = ({ data }) => {
  try {
    const { videoUrlYoutube, title, subTitle } = data.data.dataSource.fields;

    return (
      <section className={style.Video}>
        {title && subTitle && <SimpleTitle title={title} subtitle={subTitle} />}
        <iframe
          className="mt-10"
          width="100%"
          src={videoUrlYoutube}
          title="Découvrez la personnalisation en magasin"
          allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen={true}
        ></iframe>
      </section>
    );
  } catch (e) {
    console.error(e);
    return <></>;
  }
};

export default Video;
