export const pictos = {
  data: {
    dataSource: {
      metadata: {
        tags: [],
      },
      sys: {
        space: {
          sys: {
            type: 'Link',
            linkType: 'Space',
            id: 'cg3jdovg6x56',
          },
        },
        id: '2aYK19BeoZLXSWTLiItiNp',
        type: 'Entry',
        createdAt: '2023-04-14T11:57:52.161Z',
        updatedAt: '2023-04-14T11:57:52.161Z',
        environment: {
          sys: {
            id: 'master',
            type: 'Link',
            linkType: 'Environment',
          },
        },
        revision: 1,
        contentType: {
          sys: {
            type: 'Link',
            linkType: 'ContentType',
            id: 'homepageFooterUnivers',
          },
        },
        locale: 'en-US',
      },
      fields: {
        contentType: '[FR-WORKSHOP] Page Atelier Cuisine | Bloc Pictos',
        iconsUnivers: [
          {
            metadata: {
              tags: [],
            },
            sys: {
              space: {
                sys: {
                  type: 'Link',
                  linkType: 'Space',
                  id: 'cg3jdovg6x56',
                },
              },
              id: '16qPosVoKN2FhYpYmAMDgF',
              type: 'Entry',
              createdAt: '2023-04-14T11:55:34.801Z',
              updatedAt: '2023-06-02T12:46:12.943Z',
              environment: {
                sys: {
                  id: 'master',
                  type: 'Link',
                  linkType: 'Environment',
                },
              },
              revision: 2,
              contentType: {
                sys: {
                  type: 'Link',
                  linkType: 'ContentType',
                  id: 'genIdPicto',
                },
              },
              locale: 'en-US',
            },
            fields: {
              contentTitle: '[FR-WORKSHOP] Page Atelier Cuisine | Pictos Cuisine traditionnelle',
              id: 'workshop-tradifood',
              text: 'Ateliers Cuisine traditionnelle',
              url: '/atelier/cours-de-cuisine/atelier-cuisine-traditionnelle',
            },
          },
          {
            metadata: {
              tags: [],
            },
            sys: {
              space: {
                sys: {
                  type: 'Link',
                  linkType: 'Space',
                  id: 'cg3jdovg6x56',
                },
              },
              id: '2gp5hRsPxjc5KCfhCJgr0r',
              type: 'Entry',
              createdAt: '2023-04-14T11:54:31.047Z',
              updatedAt: '2023-06-08T08:04:13.529Z',
              environment: {
                sys: {
                  id: 'master',
                  type: 'Link',
                  linkType: 'Environment',
                },
              },
              revision: 3,
              contentType: {
                sys: {
                  type: 'Link',
                  linkType: 'ContentType',
                  id: 'genIdPicto',
                },
              },
              locale: 'en-US',
            },
            fields: {
              contentTitle: '[FR-WORKSHOP] Page Atelier Cuisine | Pictos Pâtisserie',
              id: 'workshop-tradipastry',
              text: 'Ateliers Pâtisserie',
              url: '/atelier/cours-de-cuisine/atelier-patisserie',
            },
          },
          {
            metadata: {
              tags: [],
            },
            sys: {
              space: {
                sys: {
                  type: 'Link',
                  linkType: 'Space',
                  id: 'cg3jdovg6x56',
                },
              },
              id: '6GtZFTBmM4Vu5jYvKAiRgQ',
              type: 'Entry',
              createdAt: '2023-04-14T11:53:07.956Z',
              updatedAt: '2023-06-02T12:46:00.717Z',
              environment: {
                sys: {
                  id: 'master',
                  type: 'Link',
                  linkType: 'Environment',
                },
              },
              revision: 2,
              contentType: {
                sys: {
                  type: 'Link',
                  linkType: 'ContentType',
                  id: 'genIdPicto',
                },
              },
              locale: 'en-US',
            },
            fields: {
              contentTitle: '[FR-WORKSHOP] Page Atelier Cuisine | Pictos Cuisine du monde',
              id: 'workshop-worldfood',
              text: 'Ateliers Cuisine du monde',
              url: '/atelier/cours-de-cuisine/atelier-cuisine-du-monde',
            },
          },
          {
            metadata: {
              tags: [],
            },
            sys: {
              space: {
                sys: {
                  type: 'Link',
                  linkType: 'Space',
                  id: 'cg3jdovg6x56',
                },
              },
              id: 'cSTBIAlz7Gh1nNtDQH19o',
              type: 'Entry',
              createdAt: '2023-04-14T11:51:45.626Z',
              updatedAt: '2023-06-02T12:45:47.448Z',
              environment: {
                sys: {
                  id: 'master',
                  type: 'Link',
                  linkType: 'Environment',
                },
              },
              revision: 2,
              contentType: {
                sys: {
                  type: 'Link',
                  linkType: 'ContentType',
                  id: 'genIdPicto',
                },
              },
              locale: 'en-US',
            },
            fields: {
              contentTitle: '[FR-WORKSHOP] Page Atelier Cuisine | Pictos Boulangerie',
              id: 'workshop-bakery',
              text: 'Ateliers boulangerie',
              url: '/atelier/cours-de-cuisine/atelier-boulangerie',
            },
          },
        ],
      },
    },
  },
};
